<mat-dialog-content class="pop-up">
  <body>

  <img src="assets/img/cancel.svg" alt="cancel_icon" mat-dialog-close class="icon">
  <p class="edit-config_h1">Edit {{config_name}} Config</p>
  <p>
    <label for="input-edit" class="button">Select File</label>
    <input id="input-edit" type="file" accept=".xls, .xlsx" hidden ="hidden"/>
    {{edit_fileName}}
  </p>

  <table class="sub-menu">
    <tr>
      <td class="edit-congif_td" (click)="Preview()">
        Preview
      </td>
      <td class="edit-congif_td" (click)="RemoveField()">
        Remove field
      </td>
      <td class="edit-congif_td" mat-dialog-close>
        Remove config
      </td>
    </tr>
  </table>
  <p></p>
  <div class="fields">
    <table *ngIf="is_preview">
      <tr>
        <td>
          <table>
            <tr>
              <th colspan="2">
                COMPANY
              </th>
            </tr>
            <tr *ngFor="let company_field of matchGroup.ExternalFileMap.Company" (click)="addAliasToField('Company', company_field.SourceColumn)" class="hover">
              <td *ngIf="company_field.SourceColumn">
                {{company_field.SourceColumn}}
              </td>
              <td>
                <button *ngIf="company_field.ExternalColumn" (click)="addToNewFields('Company', company_field.ExternalColumn)" class="field_button">
                  {{company_field.ExternalColumn}}</button>
              </td>
            </tr>
          </table>
        </td>
        <td>
          <table>
            <tr>
              <th colspan="2">
                PERSON
              </th>
            </tr>
            <tr *ngFor="let person_field of matchGroup.ExternalFileMap.Person" (click)="addAliasToField('Person', person_field.SourceColumn)" class="hover">
              <td *ngIf="person_field.SourceColumn">
                {{person_field.SourceColumn}}
              </td>
              <td>
                <button *ngIf="person_field.ExternalColumn" (click)="addToNewFields('Person', person_field.ExternalColumn)" class="field_button">
                  {{person_field.ExternalColumn}}</button>
              </td>
            </tr>
          </table>
        </td>
        <td>
          <table>
            <tr>
              <th colspan="2">
                ENGAGEMENTS
              </th>
            </tr>
            <tr *ngFor="let engagement_field of matchGroup.ExternalFileMap.Engagement">
              <td *ngIf="engagement_field.SourceColumn" (click)="addAliasToField('Engagement', engagement_field.SourceColumn)" class="hover">
                {{engagement_field.SourceColumn}}
              </td>
              <td>
                <button *ngIf="engagement_field.ExternalColumn" (click)="addToNewFields('Engagement', engagement_field.ExternalColumn)" class="field_button">
                  {{engagement_field.ExternalColumn}}</button>
              </td>
            </tr>
          </table>
        </td>
        <td class="vertical_line">
        </td>
        <td>
          <table>
            <tr>
              <th>
                NEW FIELDS
              </th>
            </tr>
            <tr *ngFor="let new_field of new_fields">
              <td (click)="selectedNewField(new_field)" id="new_field-{{new_field}}" class="hover">
                {{new_field}}
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>
  <table *ngIf="is_remove_field">
    <tr>
      <td>
        <table>
          <tr>
            <th colspan="2">
              COMPANY
            </th>
          </tr>
          <tr *ngFor="let company_field of matchGroup.ExternalFileMap.Company" class="hover">
            <td *ngIf="company_field.ExternalColumn">
              <img src="assets/img/remove_field.svg" (click)="RemoveSpecificField('Company', company_field.ExternalColumn)" alt="remove_field_icon" class="remove_field">
              {{company_field.ExternalColumn}}
            </td>
          </tr>
        </table>
      </td>
      <td>
        <table>
          <tr>
            <th colspan="2">
              PERSON
            </th>
          </tr>
          <tr *ngFor="let person_field of matchGroup.ExternalFileMap.Person" class="hover">
            <td *ngIf="person_field.ExternalColumn">
              <img src="assets/img/remove_field.svg"  (click)="RemoveSpecificField('Person', person_field.ExternalColumn)" alt="remove_field_icon" class="remove_field">
              {{person_field.ExternalColumn}}
            </td>
          </tr>
        </table>
      </td>
      <td>
        <table>
          <tr>
            <th colspan="2">
              ENGAGEMENTS
            </th>
          </tr>
          <tr *ngFor="let engagement_field of matchGroup.ExternalFileMap.Engagement">
            <td *ngIf="engagement_field.ExternalColumn" class="hover">
              <img src="assets/img/remove_field.svg"  (click)="RemoveSpecificField('Engagement', engagement_field.ExternalColumn)" alt="remove_field_icon" class="remove_field">
              {{engagement_field.ExternalColumn}}
            </td>
          </tr>
        </table>
      </td>
      <td class="vertical_line">
      </td>
      <td>
        <table>
          <tr>
            <th>
              NEW FIELDS
            </th>
          </tr>
          <tr *ngFor="let new_field of new_fields">
            <td *ngIf="new_field" class="hover">
              <img src="assets/img/remove_field.svg"  (click)="RemoveSpecificField('NewField', new_field)" alt="remove_field_icon" class="remove_field">
              {{new_field}}
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
  <div *ngIf="is_preview || is_remove_field">
    <button *ngIf="!is_saveas_clicked" class="right-button" (click)="SaveConfig()" mat-dialog-close>Save</button>
    <button *ngIf="!is_saveas_clicked" class="right-button" (click)="openForm()">Save as</button>
    <button *ngIf="is_remove_field" (click)="SaveRemovedFields()" class="right-button">Save modifications</button>
  </div>

  <div class="form-popup" id="myForm">
    <form action="/action_page.php" class="form-container">
      <h1>Enter Config name</h1>

      <label for="email"></label>
      <input type="text" id="email" placeholder="{{config_name}}" (change)="changed_ConfigName($event)" name="email" required>

      <button type="button" class="btn cancel" (click)="closeForm()">Close</button>
      <button type="submit" class="btn" (click)="save_ConfigName()">Save</button>
    </form>
  </div>


  <div class="my-overlay" *ngIf="showOverlay">
    <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
  </div>

  </body>
</mat-dialog-content>
