import {Component, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {ConfigService} from '../config/config.service';
import {Jobs} from '../shared/Jobs';
import {WarningComponent} from '../warning/warning.component';
import {MatDialog} from '@angular/material/dialog';
import {FormControl} from '@angular/forms';
import {ImportedPerson} from '../shared/ImportedPerson';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {ImportedCompany} from '../shared/ImportedCompany';
import * as XLSX from 'xlsx';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthService} from '@auth0/auth0-angular';
import {BoardDirector} from '../shared/boardDirectors/boardDirectorModel';
import {DatePipe} from '@angular/common';
import {SharedService} from '../shared/sharedService/shared.service';
@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss']
})
export class ArchiveComponent implements OnInit {

  constructor(private api: ConfigService, public auth: AuthService, public dialog: MatDialog,  private snackBar: MatSnackBar,
              public sharedService: SharedService) { }

    // auth0 data
    user: string;
    token: string;
    userEmail: string;
    userRole: string;
    isAdmin: boolean;
    profileJson: string = null;
    selectedDataType = '';
  // tslint:disable-next-line:variable-name
  time_last_job_started = '';
  jobs: Jobs[] = [{
    key: '',
    type: 'empty',
    inProcess: false,
    state: ''
  }];
  // tslint:disable-next-line:variable-name
  are_jobsVisible = false;
  file = new FormControl();
  // fileList: string[];
  fileList = [{
    name: ''
  }];
  selectedFile = '';
  importedPeople: ImportedPerson[];
  importedCompanies: ImportedCompany[];
  dataSource;
  displayedColumns: string[];
  isDataReady = false;
  public showOverlay = false;
  isPeopleSearch = false;
  isCompaniesSearch = false;
  isPopup = false;
  showDirectors = false;
  boardDirectors: BoardDirector[] = [];
  dataFilter = '';
  pageNumber = 0;
  pageSize = 50;
  totalSize: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  resetSelectedTab(): void{
    if (document.getElementById('company-results')){
      document.getElementById('company-results').style.backgroundColor = '#005a87';
      document.getElementById('company-results').style.color = 'white';
      document.getElementById('person-results').style.backgroundColor = '#005a87';
      document.getElementById('person-results').style.color = 'white';
      document.getElementById('directors-results').style.backgroundColor = '#005a87';
      document.getElementById('directors-results').style.color = 'white';
    }
    else if (document.getElementById('company-results-popup')){
      document.getElementById('company-results-popup').style.backgroundColor = '#005a87';
      document.getElementById('company-results-popup').style.color = 'white';
      document.getElementById('person-results-popup').style.backgroundColor = '#005a87';
      document.getElementById('person-results-popup').style.color = 'white';
    }
  }
  selected_datatype(datatype: string): void {
    this.showDirectors = false;
    this.selectedDataType = datatype;
    this.isDataReady = false;
    this.isCompaniesSearch = false;
    this.isPeopleSearch = false;
    this.resetSelectedTab();
    if (document.getElementById('test')){
      // @ts-ignore
      document.getElementById('test').value = '';
    }
    if (this.selectedDataType === 'Company') {
      if (document.getElementById('company-results')){
        document.getElementById('company-results').style.backgroundColor = '#222222';
        document.getElementById('company-results').style.color = 'white';
      }
      else if (document.getElementById('company-results-popup')){
        document.getElementById('company-results-popup').style.backgroundColor = '#222222';
        document.getElementById('company-results-popup').style.color = 'white';
      }
      this.SelectedFile('');
    }
    else if (this.selectedDataType === 'Person') {
      if (document.getElementById('person-results')){
        document.getElementById('person-results').style.backgroundColor = '#222222';
        document.getElementById('person-results').style.color = 'white';
      }
      else if (document.getElementById('person-results-popup')){
        document.getElementById('person-results-popup').style.backgroundColor = '#222222';
        document.getElementById('person-results-popup').style.color = 'white';
      }
      this.SelectedFile('');
    }
    this.api.get_fileList(this.selectedDataType).subscribe(res => {
            //  console.log('init all files ', res);
              // @ts-ignore
              this.fileList = res;
          },
          err => {
              console.log(err);
              this.showOverlay = false;
          });
  }
  showBoardDirectors(): void{
      this.resetSelectedTab();
      this.selectedDataType = null;
      this.showDirectors = true;
      this.isDataReady = false;
      document.getElementById('directors-results').style.backgroundColor = '#222222';
      document.getElementById('directors-results').style.color = 'white';
      this.getDirectorsTable();
  }
  searchDirectors(event: any): void{
      this.pageNumber = 0;
      this.dataFilter = event;
      if ( this.dataFilter !== ''){
            this.dataFilter = new DatePipe('en-US').transform(this.dataFilter, 'MM/dd/yyyy');
      }
      this.getDirectorsTable();
  }
  public linkToCompany(id: number): void {this.sharedService.linkToCompany(id);
  }
  linkToCeo(id: number): void {
      this.sharedService.linkToPerson(id);
  }
  dataChange(event: any): void{
      console.log(event);
  }
  getDirectorsTable(): void{
      this.displayedColumns = ['PersonId', 'FirstName', 'LastName', 'BoardAssociation', 'BoardAssociationCIk', 'Action', 'UpdatedDate'];
      this.api.getBoardDirectorsArchive(this.pageNumber, this.pageSize, this.dataFilter).subscribe(result => {
          this.boardDirectors = result.item1;
          this.totalSize = result.item2;
          this.dataSource = new MatTableDataSource<BoardDirector>(result.item1);
      });
  }
    onPageFired(event: any): void{
        console.log(event);
        this.pageNumber = event.pageIndex;
        this.pageSize = event.pageSize;
        this.getDirectorsTable();
    }
  GetTimeStamp(): void{
    // tslint:disable-next-line:new-parens
    const date = new Date;
    this.time_last_job_started = localStorage.getItem('date');
  }
  showRunningJobs(): void{
    this.GetTimeStamp();
    this.api.get_Jobs().subscribe(res => {
          // @ts-ignore
          this.jobs = res;
          for (const i of this.jobs){
            if (i.type === 'CegIntegration.Web.Jobs.MainDataSynchronizationJob'){
              this.jobs.splice(this.jobs.indexOf(i), 1);
            }
          }
          this.are_jobsVisible = !(this.are_jobsVisible);
          if (this.are_jobsVisible){
            const img = document.getElementById('review-running-jobs');
            img.style.transform = 'rotate(180deg)';
          }
          else{
            const img = document.getElementById('review-running-jobs');
            img.style.transform = 'rotate(0deg)';
          }
        },
        err => {
          console.log(err);
        });
  }
  deleteJob(jobKey: any): void{
    const dialogRef = this.dialog.open(WarningComponent);
    dialogRef.componentInstance.action = 'DeleteJob';

    dialogRef.afterClosed().subscribe(result => {
      console.log(`popup result: ${result}`);
      const response = result;
      if (response === 'yes'){
        this.ProceedDeleteJob(jobKey);
      }
    });
  }
  ProceedDeleteJob(jobKey: any): void{
    const url = this.api.delete_Job(jobKey.toString());

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
    xhr.setRequestHeader('Content-Type', 'application/json-patch+json');
    xhr.setRequestHeader('accept', '*/*');

    // tslint:disable-next-line:only-arrow-functions typedef
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        // tslint:disable-next-line:triple-equals
        if (xhr.status == 200){
          console.log('ok');
          document.getElementById('succeeded_snack').click();
          document.getElementById('refreshJobs').click();
        }
        else{
          document.getElementById('failed_snack').click();
        }
      }
    };
    xhr.send();
  }
  SelectedFile(selectedFile: any): void{
    this.selectedFile = selectedFile;
    if (selectedFile !== ''){
      const importedDatatype = 'Imported' + this.selectedDataType;
      this.getArchive(importedDatatype, this.selectedFile);
    }
  }
  getArchive(importedDatatype: string, filename: string): void{
    this.showOverlay = true;
    this.api.get_Archive(importedDatatype, filename).subscribe(res => {
          // console.log('get archive ', res);
          if (this.selectedDataType === 'Company'){
            // @ts-ignore
            this.importedPeople = res;
            this.displayedColumns = ['CompanyId', 'FileRowId'];
            this.dataSource = new MatTableDataSource<ImportedPerson>(this.importedPeople);
            this.dataSource.paginator = this.paginator;
            this.isDataReady = true;
            this.isCompaniesSearch = true;
            this.isPeopleSearch = false;
            this.showOverlay = false;
          }
          else if (this.selectedDataType === 'Person'){
            // @ts-ignore
            this.importedPeople = res;
            this.displayedColumns = ['PersonId', 'FirstName', 'LastName', 'Ticker', 'FileRowId'];
            this.dataSource = new MatTableDataSource<ImportedPerson>(this.importedPeople);
            this.dataSource.paginator = this.paginator;
            this.isDataReady = true;
            this.isPeopleSearch = true;
            this.isCompaniesSearch = false;
            this.showOverlay = false;
          }
        },
        err => {
          console.log(err);
          this.showOverlay = false;
        });
  }
    // tslint:disable-next-line:typedef
    snackbar_Error(errorMessage: string) {
        const sb = this.snackBar.open(errorMessage, '', {
            duration: 1000,
            panelClass: ['failed'],
            horizontalPosition: 'right',
            verticalPosition: 'top'
        });
    }
  getArchiveOriginal(): void{
      console.log('this.selectedFile');
      console.log(this.selectedFile);
      const importedDatatype = 'Imported' + this.selectedDataType;
      this.api.get_Archive_Combined(importedDatatype, this.selectedFile).subscribe((data) => {
          console.log('get_Archive_Original', data);
          const link = document.createElement('a');
         // const blob = new Blob([data as BlobPart], { type: 'application/vnd.ms-excel' });
          const blob = new Blob([data as BlobPart], { type: 'application/vnd.ms-excel' });
          console.log('blooob', blob);
          link.setAttribute('href', window.URL.createObjectURL(blob));
          link.setAttribute('download', this.selectedFile);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          console.log('dsfdsfgd');
          document.body.removeChild(link);
          },
        err => {
          console.log('err');
                this.snackbar_Error("File doesn't exist");
          });
  }
  exportexcel(fileName: string, datatype: string): void
  {
    const element = document.getElementById('excel-table-' + datatype);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const finalFileName = fileName + this.selectedFile + '.xlsx';
    XLSX.writeFile(wb, finalFileName);
  }
  ngOnInit(): void {
      this.api.getAuthToken().subscribe(auth => {
          this.token = auth;
      });
      this.auth.user$.subscribe(
          (profile) => {
              (this.profileJson = JSON.parse(JSON.stringify(profile, null, 2)));
              // console.log('profile', profile.email);
              // console.log('profile', profile.name);
              // console.log('profile', profile['https://schemas.quickstarts.com/roles'][0]);
              // localStorage.setItem('user', profile.name);
              // localStorage.setItem('userEmail', profile.email);
              localStorage.setItem('userRole', profile['https://schemas.quickstarts.com/roles'][0]);
              this.userRole = profile['https://schemas.quickstarts.com/roles'][0];
              console.log('user role', this.userRole);
              if (this.userRole === 'admin'){
                  this.isAdmin = true;
                  console.log('is admin');
              }
              else{
                  this.isAdmin = false;
                  console.log('is not admin');
              }
          });
      console.log('this.selected', this.selectedDataType);

      // if (this.isPopup){
      //    document.getElementById('body-container').style.backgroundColor = 'rgb(256,256,256)';
      // } else{
      //  // rgb(240,240,240);
      //   document.getElementById('body-container').style.backgroundColor = 'rgb(240,240,240)';
      // }
  }
}
