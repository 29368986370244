<div class="my-overlay" *ngIf="showOverlay">
    <div class="spinner">
        <span class="spinner-inner-1"></span>
        <span class="spinner-inner-2"></span>
        <span class="spinner-inner-3"></span>
    </div>
</div>
<div class="board-directors" *ngIf="showData">
    <div class="" >
        <p class="check-settings" (click)="showSettings()">
            <mat-icon>settings</mat-icon>
            <img id="show-settings" src="assets/img/down_simple_arrow.svg" alt="down_icon" class="icon-right">
        </p>
        <div *ngIf="areSettingsVisible" class="settings-container">
            <div class="fuzzy-selection">
                <div class="checklist">
                    <br>
                    <div *ngFor="let field of allFiltersAvailable" >
                        <label class="field-selection">
                            <input type="checkbox" [checked]="field.isSelected" class="regular-checkbox" (click)="filterSelection(field)">{{field.label}}
                        </label>
                    </div>
                </div>
                <div  class="selected-settings">
                    <table  class="">
                        <tr class="fuzzy-header">
                            <th >Search Field</th>
                            <th >Exact  match</th>
                            <th >Fuzzy  level</th>
                        </tr>
                        <tr *ngFor="let filter of selectedFilters">
                            <td>
                                {{filter.label}}
                            </td>
                            <td class="center-align">
                                <input  type="checkbox" class="input-checkbox" [checked]="filter.exactMatch" (click)="exactMatch(filter)">
                            </td>
                            <td class="center-align">
                                <input type="number" class="input-number" [(ngModel)]="filter.fuzzyLevel" (change)="fuzzyLevelChanges(filter)">
                            </td>
                        </tr>
                    </table>
                </div>

            </div>
            <div class="save-btn">
                <button class=" proceed-btn" (click)="saveSettings()"> Save settings</button>
            </div>
        </div>

    </div>
    <div class="filters-selection">

<!--        <mat-form-field appearance="fill" class="fit-content" >-->
<!--            <mat-label>Select searching parameters</mat-label>-->
<!--            <mat-select [(ngModel)]="selectedFilters" (selectionChange)="selectFilters($event)" [multiple]="true"  >-->
<!--                <mat-option>Select all</mat-option>-->
<!--                <mat-option *ngFor="let item of filters" [value]="item">-->
<!--                    {{item.label}}-->
<!--                </mat-option>-->
<!--            </mat-select>-->
<!--        </mat-form-field>-->
        <div class="bulk-action-section">
            Do this action for all records within this group
            <mat-form-field appearance="fill" >
                <mat-label>Select action</mat-label>
                <mat-select [(value)]="bulkAction" (selectionChange)="bulkSelectActions($event)" >
                    <mat-option *ngFor="let item of bulkActions" [value]="item.action">
                        {{ item.actionDescription }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="bulkSendToResearch || sendRecordsToResearch"  class="research ">
                <mat-select [(value)]="researchAreaId" (selectionChange)="selectResearchArea($event)" placeholder="Select Research Area">
                    <mat-option *ngFor="let res of research"  [value]="res.researchId" >
                        {{ res.researchName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button class="proceed-btn" (click)="proceedActions()"  *ngIf="showActionsBtn">Proceed</button>
        </div>
    </div>
    <div class="board-table">
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="Actions">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Search for a match </th>
                <td mat-cell *matCellDef="let element; let row">
                    <mat-form-field [ngClass]="getSelectClass(element.action)" appearance="fill" class="action-dropdown" >
                        <mat-label>Select action</mat-label>
                        <mat-select [(value)]="element.action" (selectionChange)="selectActions(element.action, element)" >
                            <mat-option *ngFor="let item of actions" [value]="item.action" >
                                {{ item.actionDescription }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
            </ng-container>
            <ng-container matColumnDef="PersonId">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Person Id </th>
                <td mat-cell *matCellDef="let element; let row">
                    <div>
                        <a (click)="LinkToCeo(element.personId)">
                            {{element.personId}}
                        </a>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="FirstName">
                <th mat-header-cell *matHeaderCellDef class="table_header"> First Name </th>
                <td mat-cell *matCellDef="let element; let row">
                    <div *ngIf="element.action ==='edit'" class="input-box">
                        <input type="text" name="comp" [(ngModel)]="element.firstName" class="editable">
                    </div>
                    <div *ngIf="element.action!=='edit'">
                        {{element.firstName}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="LastName">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Last Name </th>
                <td mat-cell *matCellDef="let element; let row">
                    <div *ngIf="element.action ==='edit'" class="input-box">
                        <input type="text" name="comp" [(ngModel)]="element.lastName" class="editable">
                    </div>
                    <div *ngIf="element.action!=='edit'">
                        {{element.lastName}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="PrimaryCompany">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Primary Company </th>
                <td mat-cell *matCellDef="let element; let row">
                    {{element.primaryCompany}}
                </td>
            </ng-container>
            <ng-container matColumnDef="PrimaryTitle">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Title </th>
                <td mat-cell *matCellDef="let element; let row">
                    {{element.primaryTitle}}
                </td>
            </ng-container>
            <ng-container matColumnDef="BoardCikId">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Board Company CIK ID</th>
                <td mat-cell *matCellDef="let element; let row">
                    <a (click)="linkToCompany(element.boardCompanyId)">
                        {{element.boardCompanyCik}}
                    </a>
                </td>
            </ng-container>
            <ng-container matColumnDef="BoardCompanyName">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Board Company Name </th>
                <td mat-cell *matCellDef="let element; let row">
                        {{element.boardCompanyName}}
                </td>
            </ng-container>
            <ng-container matColumnDef="BoardName">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Board Company Name</th>
                <td mat-cell *matCellDef="let element; let row">
                    {{element.boardCompanyName}}
                </td>
            </ng-container>
            <ng-container matColumnDef="DirectorSince">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Director Since</th>
                <td mat-cell *matCellDef="let element; let row">
                    {{element.directorSince}}
                </td>
            </ng-container>
            <ng-container matColumnDef="I/O/N/C/L">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Independent</th>
                <td mat-cell *matCellDef="let element; let row">
                    <div *ngIf="element.action ==='edit'" class="input-box">
                        <input type="text" name="comp" [(ngModel)]="element.insideOutside" class="editable">
                    </div>
                    <div *ngIf="element.action!=='edit'">
                        {{element.insideOutside}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="Audit">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Audit</th>
                <td mat-cell *matCellDef="let element; let row">
                    <div *ngIf="element.action ==='edit'" class="input-box">
                        <input type="text" name="comp" [(ngModel)]="element.audit" class="editable">
                    </div>
                    <div *ngIf="element.action!=='edit'">
                        {{element.audit}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="Comp">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Comp</th>
                <td mat-cell *matCellDef="let element; let row">
                    <div *ngIf="element.action ==='edit'" class="input-box">
                        <input type="text" name="comp" [(ngModel)]="element.comp" class="editable">
                    </div>
                    <div *ngIf="element.action!=='edit'">
                        {{element.comp}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="Gov/Nom">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Gov/Nom</th>
                <td mat-cell *matCellDef="let element; let row">
                    <div *ngIf="element.action ==='edit'" class="input-box">
                        <input type="text" name="comp" [(ngModel)]="element.govNom" class="editable">
                    </div>
                    <div *ngIf="element.action!=='edit'">
                        {{element.govNom}}
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row_darken" ></tr>
        </table>
    </div>
    <mat-paginator [pageSizeOptions]="[50, 100]"
                   [pageIndex]="pageNumber"
                   [pageSize]="pageSize"
                   [length]="totalSize"
                   [showFirstLastButtons] = true
                   (page)="onPageFired($event)">

    </mat-paginator>

</div>