export interface ICompanyApiData{
    companyId: number;
    cikId: number;
    companyName: string;
    exchange: string;
    ticker: string;
    importedInAmplify: boolean;
    excludedFromProcess: boolean;
    createdDate: Date;
    totalCount: number;
}
export class SearchFilters{
    cikId: number;
    date: string;
    companyId: number;
}
export class MappingsDetails{
    excluded: number;
    importedInAmplify: number;
    mapped: number;
}
