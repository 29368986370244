import {Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef} from '@angular/core';
import {SavedConfig} from 'src/app/shared/SavedConfig';
import {ConfigService} from '../config/config.service';
import {MatDialog} from '@angular/material/dialog';
import {NewConfigComponent} from '../new-config/new-config.component';
import { HttpClient } from '@angular/common/http';
import { ProgressStatus, ProgressStatusEnum } from 'src/app/models/progress-status.model';
import * as XLSX from 'xlsx';
import {EditConfigComponent} from '../edit-config/edit-config.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Jobs} from '../shared/Jobs';
import {AuthService} from '@auth0/auth0-angular';
import {AppComponent} from '../app.component';
import {WarningComponent} from '../warning/warning.component';
import {ArchiveComponent} from '../archive/archive.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})

export class ImportComponent implements OnInit {

  public uploadStatus: EventEmitter<ProgressStatus>;

  constructor(private api: ConfigService, public auth: AuthService,
              private route: Router, public dialog: MatDialog, public http: HttpClient,
              private snackBar: MatSnackBar, private appcomponent: AppComponent) {
    this.appcomponent.jobChecking();
  }
  // auth0 data
  token: string;
  user: string;
  userEmail: string;
  userRole: string;
  isAdmin: boolean;
  profileJson: string = null;
  username: string;
  isUploadClicked = false;
  // tslint:disable-next-line:variable-name
  selected_file;
  // tslint:disable-next-line:variable-name
  json_file;
  fileName: string;
  // tslint:disable-next-line:variable-name
  saved_configs: SavedConfig[];
  config;
  // tslint:disable-next-line:variable-name
  selected_config: string;
  // tslint:disable-next-line:variable-name
  is_file_selected = false;
  // tslint:disable-next-line:variable-name
  are_files_imported = false;
  binar;
  fisier;
  jobs: Jobs[] = [{
    key: '',
    type: '',
    inProcess: false,
    state: ''
  }];
  // tslint:disable-next-line:variable-name
  are_jobsVisible = false;
  // tslint:disable-next-line:variable-name
  time_last_job_started = '';
  // tslint:disable-next-line:typedef
  snackbar_SucceededPost() {
    const sb = this.snackBar.open('Your file have successfully started to upload!', '', {
      duration: 1000,
      panelClass: ['succeed'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
  // tslint:disable-next-line:typedef
  snackbar_DeleteJobSucceededPost() {
    const sb = this.snackBar.open('Your modifications have been successfully proceeded!', '', {
      duration: 1000,
      panelClass: ['succeed'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
  // tslint:disable-next-line:typedef
  snackbar_FailedPost() {
    const sb = this.snackBar.open('Your file did NOT start to upload!', '', {
      duration: 1000,
      panelClass: ['failed'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
  // tslint:disable-next-line:typedef
  snackbar_FailedDeleteJobPost() {
    const sb = this.snackBar.open('Your modifications were NOT proceeded!', '', {
      duration: 1000,
      panelClass: ['failed'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
  // tslint:disable-next-line:typedef
  snackbar_Error(errorMessage: string) {
    const sb = this.snackBar.open(errorMessage, '', {
      duration: 1500,
      panelClass: ['failed'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
  refreshJobs(): void{
    this.api.get_Jobs().subscribe(res => {
          // @ts-ignore
          this.jobs = res;
          for (const i of this.jobs){
            if (i.type === 'CegIntegration.Web.Jobs.MainDataSynchronizationJob'){
              this.jobs.splice(this.jobs.indexOf(i), 1);
            }
          }
        },
        err => {
          console.log(err);
        });
  }
  // tslint:disable-next-line:typedef
  CreateNew(){
    this.dialog.open(NewConfigComponent);
  }
  // tslint:disable-next-line:typedef
  SelectFile(){
    console.log('select file');
    this.FileImport();
    console.log(this.selected_file);
  }
  // tslint:disable-next-line:typedef
  FileImport(){
    document.getElementById('input').addEventListener('change', (event) => {
      // @ts-ignore
      this.selected_file = event.target.files;
      console.log('this sel file', this.selected_file);
      // @ts-ignore
      this.fileName = event.target.files[0].name;
      this.is_file_selected = true;
      document.getElementById('file_status').innerHTML = '';
      // @ts-ignore
      document.getElementById('status_icon').src = '';
    });
    document.getElementById('button').addEventListener('click', (event) => {
      // @ts-ignore
      if (this.selected_file){
        this.isUploadClicked = true;
        const fileReader = new FileReader();
        fileReader.readAsBinaryString(this.selected_file[0]);
        this.uploadFile(this.selected_file[0]);
      }
    });
  }
  // tslint:disable-next-line:typedef
  redirect(){
    this.route.navigateByUrl('/');
  }
  StartCheckingJobs(): void{
    this.appcomponent.jobChecking();
  }

  // tslint:disable-next-line:typedef
  uploadWordpressFile(): void{
    if (this.selected_file){
      const fileReader = new FileReader();
      fileReader.readAsBinaryString(this.selected_file[0]);
      this.api.get_Jobs().subscribe(res => {
            // @ts-ignore
            this.jobs = res;
            for (const i of this.jobs){
              console.log('type', i.type);
              if (i.type === 'CegIntegration.Web.Jobs.MatchInternalDataDataJob'){
                this.jobs.splice(this.jobs.indexOf(i), 1);
              }
            }
            if (this.jobs.length > 1){
              this.snackbar_Error('Too many jobs are running now. Please try again later');
            }
            else{
              this.SetTimeStamp();
              const data = new FormData();
              data.append('file', this.selected_file[0], this.fileName);

              const xhr = new XMLHttpRequest();
              xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
              xhr.withCredentials = true;
              let status: string;
              // tslint:disable-next-line:typedef
              xhr.addEventListener('readystatechange', function() {
                if (this.readyState === 4) {
                  console.log('status', xhr.status);
                  // tslint:disable-next-line:triple-equals
                  if (xhr.status == 200){
                    status = 'file uploaded';
                    document.getElementById('file_status').innerHTML = 'file has been uploaded';
                    // @ts-ignore
                    document.getElementById('status_icon').src = 'assets/img/blue_check.svg';
                    document.getElementById('succeeded_snack').click();
                    document.getElementById('start_checking_jobs').click();
                  }
                  else{
                    status = 'file not uploaded';
                    const json = JSON.parse(xhr.response);
                    if (json.Message === 'Please change the filename!') {
                      status = json.Message;
                      document.getElementById('file_status').innerHTML = 'Please change the File name!';
                    }else {
                      document.getElementById('file_status').innerHTML = 'file has NOT been uploaded';
                    }
                    // @ts-ignore
                    document.getElementById('status_icon').src = 'assets/img/red_close.svg';
                    document.getElementById('failed_snack').click();
                  }
                }
              });
              const url = this.api.url_post_WordpressData();
              xhr.open('POST', url );
              xhr.send(data);
            }
          },
          err => {
            console.log(err);
          });
    }
  }
  // tslint:disable-next-line:typedef
  uploadFile(fileInput: Blob){
    this.api.get_Jobs().subscribe(res => {
          // @ts-ignore
          this.jobs = res;
          for (const i of this.jobs){
            console.log('type', i.type);
            if (i.type === 'CegIntegration.Web.Jobs.MatchInternalDataDataJob'){
              this.jobs.splice(this.jobs.indexOf(i), 1);
            }
          }
          if (fileInput.size > 1024 * 1024){
            this.snackbar_Error('Can not upload the file. The file is too large');
          }
          else if (this.jobs.length > 1){
            this.snackbar_Error('Too many jobs are running now. Please try again later');
          }
          else{
            this.SetTimeStamp();
            const data = new FormData();
            data.append('file', fileInput, this.fileName);
            const xhr = new XMLHttpRequest();
            xhr.withCredentials = true;
            let status: string;
            // tslint:disable-next-line:typedef
            xhr.addEventListener('readystatechange', function() {
              if (this.readyState === 4) {
                console.log('status', xhr.status);
                // tslint:disable-next-line:triple-equals
                if (xhr.status == 200){
                  status = 'file uploaded';
                  document.getElementById('file_status').innerHTML = 'file has been uploaded';
                  // @ts-ignore
                  document.getElementById('status_icon').src = 'assets/img/blue_check.svg';
                  document.getElementById('succeeded_snack').click();
                  document.getElementById('start_checking_jobs').click();
                }
                else{
                  status = 'file not uploaded';
                  const json = JSON.parse(xhr.response);
                  if (json.Message === 'Please change the filename!') {
                    status = json.Message;
                    document.getElementById('file_status').innerHTML = 'Please change the File name!';
                  }else {
                    document.getElementById('file_status').innerHTML = 'file has NOT been uploaded';
                  }
                  // @ts-ignore
                  document.getElementById('status_icon').src = 'assets/img/red_close.svg';
                  document.getElementById('failed_snack').click();
                }
              }
            });
            const url = this.api.url_post_Externaldata();
            xhr.open('POST', url + this.selected_config);
            xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
            xhr.send(data);
          }
        },
        err => {
          console.log(err);
        });
  }
  // tslint:disable-next-line:typedef variable-name
  Edit(config_name: string){
    const dialogRef = this.dialog.open(EditConfigComponent, {
      disableClose: true,
    });
    for (const i of this.config){
      if (i.name === config_name){
        dialogRef.componentInstance.config_name = i.name;
      }
    }
  }
  // tslint:disable-next-line:typedef
  ReloadConfig(){
    this.api.getConfigurations_ImportPage().subscribe((data) => {
      this.config = data;
      // @ts-ignore
      this.saved_configs = data;
      console.log(data);
    });
  }
  // tslint:disable-next-line:typedef
  RemoveConfig(configName: string){
    console.log('remove', configName);
    const url = this.api.deleteConfig_ImportPage() + configName;

    const xhr = new XMLHttpRequest();
    xhr.open('DELETE', url);
    xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
    xhr.setRequestHeader('accept', '*/*');

    // tslint:disable-next-line:only-arrow-functions typedef
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        console.log(xhr.status);
        console.log(xhr.responseText);
        document.getElementById('reload_configs').click();
        // window.location.reload();
      }};
    xhr.send();
  }
  // tslint:disable-next-line:typedef
  RemoveFile(){
    console.log('remove file');
    this.fileName = '';
    this.selected_file = null;
    this.selected_config = '';
    this.is_file_selected = false;
  }
  // tslint:disable-next-line:typedef
  selectedConfig(event: any){
    this.selected_config = event.target.value;
    console.log('sel config', this.selected_config);
  }
  SetTimeStamp(): void{
    // tslint:disable-next-line:new-parens
    const date = new Date;
    localStorage.setItem('date', date.toString());
  }
  GetTimeStamp(): void{
    // tslint:disable-next-line:new-parens
    const date = new Date;
    this.time_last_job_started = localStorage.getItem('date');
  }
  // tslint:disable-next-line:typedef
  showRunningJobs(){
    this.GetTimeStamp();
    this.api.get_Jobs().subscribe(res => {
          // @ts-ignore
          this.jobs = res;
          for (const i of this.jobs){
            if (i.type === 'CegIntegration.Web.Jobs.MainDataSynchronizationJob'){
              this.jobs.splice(this.jobs.indexOf(i), 1);
            }
          }
          this.are_jobsVisible = !(this.are_jobsVisible);
          if (this.are_jobsVisible){
            const img = document.getElementById('import-running-jobs');
            img.style.transform = 'rotate(180deg)';
          }
          else{
            const img = document.getElementById('import-running-jobs');
            img.style.transform = 'rotate(0deg)';
          }
        },
        err => {
          console.log(err);
        });
  }
  deleteJob(jobKey: any): void{
    const dialogRef = this.dialog.open(WarningComponent);
    dialogRef.componentInstance.action = 'DeleteJob';

    dialogRef.afterClosed().subscribe(result => {
      console.log(`popup result: ${result}`);
      const response = result;
      if (response === 'yes'){
        this.ProceedDeleteJob(jobKey);
      }
    });
  }
  ProceedDeleteJob(jobKey: any): void{
    const url = this.api.delete_Job(jobKey.toString());

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
    xhr.setRequestHeader('Content-Type', 'application/json-patch+json');
    xhr.setRequestHeader('accept', '*/*');

    // tslint:disable-next-line:only-arrow-functions typedef
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        // tslint:disable-next-line:triple-equals
        if (xhr.status == 200){
          console.log('ok');
          document.getElementById('succeededjob_snack').click();
          document.getElementById('refreshJobs').click();
        }
        else{
          document.getElementById('failedjob_snack').click();
        }
      }
    };
    xhr.send(jobKey.toString());
  }
  openArchive(): void{
    const dialogRef = this.dialog.open(ArchiveComponent, {
      disableClose: true
    });
    dialogRef.componentInstance.isPopup = true;
  }
  ngOnInit(): void {
    this.isUploadClicked = false;
    this.api.getAuthToken().subscribe(auth => {
      this.token = auth;
    });
    this.auth.user$.subscribe(
        (profile) => {
          (this.profileJson = JSON.parse(JSON.stringify(profile, null, 2)));
          // console.log('profile', profile.email);
          // console.log('profile', profile.name);
          // console.log('profile', profile['https://schemas.quickstarts.com/roles'][0]);
          // localStorage.setItem('user', profile.name);
          // localStorage.setItem('userEmail', profile.email);
          this.username = profile.name;
          localStorage.setItem('userRole', profile['https://schemas.quickstarts.com/roles'][0]);
          this.userRole = profile['https://schemas.quickstarts.com/roles'][0];
          console.log('user role', this.userRole);
          if (this.userRole === 'admin'){
            this.isAdmin = true;
            console.log('is admin');
          }
          else{
            this.isAdmin = false;
            console.log('is not admin');
            this.redirect();
          }
        });
    this.api.getConfigurations_ImportPage().subscribe((data) => {
      this.config = data;
      // @ts-ignore
      this.saved_configs = data;
      console.log(data);
    });
    this.FileImport();
  }
}
