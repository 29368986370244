<app-navbar></app-navbar>
<h1>
    <a class="black-box">
        Public CO Directors Log
    </a>
    <a *ngIf="environment==='Production'" class="page-env prod" >PRODUCTION</a>
    <a *ngIf="environment==='Development'" class="page-env dev" >DEVELOPMENT</a>
</h1>
<div class="container-log">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
        <ng-container matColumnDef="createdDate">
            <th mat-header-cell *matHeaderCellDef class="table_header">
                <div> Date Added</div>
                <div>
                    <input (ngModelChange)="dataFilter=$event" (change)="searchBoards()" [(ngModel)]="dataFilter" class="date-added-sort "[ngModelOptions]="{standalone: true}" type="date">
                </div>
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.createdDate | date: 'MM/dd/yyyy'}}
            </td>
        </ng-container>
        <ng-container matColumnDef="{{column}}" *ngFor="let column of filteredColumns">
            <th mat-header-cell *matHeaderCellDef class="table_header"> {{getTableHeaderToDisplay(column)}} </th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="column === 'personId' && element.personId">
                    <a (click)="linkToCeo(element[column])">
                        {{element[column]}}
                    </a>
                </div>
                <div *ngIf="column !== 'personId'">
                    {{element[column]}}
                </div>

            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row_darken" ></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[50, 100]"
                   [pageIndex]="pageNumber"
                   [pageSize]="pageSize"
                   [length]="totalSize"
                   [showFirstLastButtons] = true
                   (page)="onPageFired($event)"></mat-paginator>
</div>
