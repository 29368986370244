import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../config/config.service';
import {DataType} from '../shared/DataType';
import {Groups} from '../shared/Groups';
import {Dictionary} from '../shared/Dictionary';
import {SettingsFields} from '../shared/SettingsFields';
import {MatchGroups} from '../shared/MatchGroups';
import {Fields} from '../shared/Fields';
import {findExportedNameOfNode} from '@angular/compiler-cli/src/ngtsc/imports/src/find_export';
import {MatSnackBar} from '@angular/material/snack-bar';
import {StageGroups} from '../shared/StageGroups';
import {AuthService} from '@auth0/auth0-angular';
import {AppComponent} from '../app.component';

@Component({
  selector: 'app-quarantine-settings',
  templateUrl: './quarantine-settings.component.html',
  styleUrls: ['./quarantine-settings.component.scss']
})
export class QuarantineSettingsComponent implements OnInit {

  constructor(private api: ConfigService, private snackBar: MatSnackBar, private appcomponent: AppComponent)
  {
    this.appcomponent.jobChecking();
  }
  token: string;
  // tslint:disable-next-line:variable-name
  settings_fields: SettingsFields[];
  // fields: Fields[];
  datatypes: DataType[];
  selectedDataType;
  // tslint:disable-next-line:variable-name
  is_Company = false;
  // tslint:disable-next-line:variable-name
  is_Person = false;
  // tslint:disable-next-line:variable-name
  is_Engagement = false;
  // tslint:disable-next-line:variable-name
  is_dataType_selected = false;
  // tslint:disable-next-line:variable-name
  check_list: string[];
  // tslint:disable-next-line:variable-name
  GroupLevel = 0;
  // tslint:disable-next-line:variable-name
  match_group: StageGroups[] = [{
    Groups: [
      {
        Conditions: [
          {
            Fields: [
              {
                TableName: '',
                ColumnName: '',
                ColumnDataType: ''
              },
              {
                TableName: '',
                ColumnName: '',
                ColumnDataType: ''
              }
            ],
            Level: 0,
            Weight: 0,
            DictionaryType: '',
            MatchingType: 0,
            IgnoreNullValues: false
          }
        ],
        GroupId: 0,
        GroupLevel: 0
      }
    ],
    CheckInternal: true,
    MainTable: '',
    FuzzyAlgorithm: 0,
    SearchDeep: 0
  }];
  // @ts-ignore
  hiddenSettingsFields = [
    'NumberOfEmployees',
    'OwnershipTypeDesc',
    'OwnershipSubType',
    'BusinessIndustry',
    'HowVerified',
    'SICCode',
    'QualificationSource',
    'BPAJobTitle',
    'OtherIndustry',
    'OwnershipSubType'
  ];
  // tslint:disable-next-line:variable-name
  selected_group: number;
  Value: string;
  checked = false;
  // tslint:disable-next-line:variable-name
  is_Exact_match = false;
  // tslint:disable-next-line:variable-name
  is_Group_ready = false;
  // tslint:disable-next-line:variable-name
  Level: number;
  dictionary: Dictionary;
  // tslint:disable-next-line:variable-name
  value_to_send: string;
  fields: Fields[];
  // tslint:disable-next-line:typedef
  snackbar_SucceededPost() {
    const sb = this.snackBar.open('Your settings have successfully been saved!', '', {
      duration: 1000,
      panelClass: ['succeed'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
  // tslint:disable-next-line:typedef
  snackbar_FailedPost() {
    const sb = this.snackBar.open('Your settings are NOT saved!', '', {
      duration: 1000,
      panelClass: ['failed'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
  // tslint:disable-next-line:typedef
  select_dataType(event: any) {
    this.selectedDataType = event.target.value;
    console.log('this.selectedDataType ', this.selectedDataType);
    this.WhichDataTypeIsSelected();
    this.selected();
  }
  // tslint:disable-next-line:typedef
  selected() {
    this.check_list = [];
    // tslint:disable-next-line:variable-name
    this.api.get_fieldMap(this.selectedDataType, 'Internal').subscribe(field_result => {
        // @ts-ignore
        this.settings_fields = field_result;
        for (const field of this.settings_fields){
          // @ts-ignore
          if (field.FieldName === 'Industry' || field.FieldName === 'Employees' || field.FieldName === 'Revenue' || field.FieldName === 'Ownership')
          {

            // @ts-ignore
            const modifiedName = field.FieldName.concat(' BPA');
            field.FieldName = modifiedName;
          }
          // @ts-ignore
          if (field.FieldName === 'CikId'){
            // @ts-ignore
            field.FieldName = 'CIK ID';
          }
        }
        // @ts-ignore
        this.fields = field_result;
        console.log('fields ', this.fields);
      },
      err => {
        console.log(err);
      });
    // tslint:disable-next-line:prefer-const
    const elements = document.getElementsByTagName('checkbox');
    // @ts-ignore
    for (const inp of elements) {
      if (inp.type === 'checkbox') {
        inp.checked = false;
      }
    }
    this.get_Matches();
  }
  // tslint:disable-next-line:typedef
  get_Matches(){
    // tslint:disable-next-line:variable-name
    this.api.get_Match(this.selectedDataType, 'External').subscribe(match_result => {
        // @ts-ignore
        this.match_group.push(match_result);
        console.log('match', this.match_group);
      },
      err => {
        console.log(err);
      });
  }
  // tslint:disable-next-line:typedef
  WhichDataTypeIsSelected() {
    this.match_group = [];
    this.is_dataType_selected = true;
    this.is_Group_ready = true;
    switch (this.selectedDataType) {
      case 'Company': {
        this.is_Company = true;
        this.is_Person = false;
        this.is_Engagement = false;
        break;
      }
      case 'Person': {
        this.is_Company = false;
        this.is_Person = true;
        this.is_Engagement = false;
        break;
      }
      case 'Engagement': {
        this.is_Company = false;
        this.is_Person = false;
        this.is_Engagement = true;
        break;
      }
    }
  }
  // tslint:disable-next-line:typedef
  checked_values(event: any) {
    const already = event.target.value;
    console.log(this.match_group);
    for (const i of this.match_group) {
      for (const j of i.Groups) {
        if (j.GroupId === this.selected_group) {
          let found = 0;
          let ct = 0;
          for (const k of j.Conditions){
            for (const q of k.Fields){
              if (already === q.ColumnName){
                found++;
                ct = j.Conditions.indexOf(k);
              }
            }
          }
          if (found === 0){
            if (this.selectedDataType === 'Company'){
              j.Conditions.push({
                DictionaryType: 'Not Required',
                Fields: [
                    {
                  ColumnDataType: this.FieldDataType(already), // this.fields[0].dataType,
                  ColumnName: already,
                  TableName: 'Company'
                    },
                  {
                    ColumnDataType: this.FieldDataType(already), // this.fields[0].dataType,
                    ColumnName: already,
                    TableName: 'ImportedCompany'
                  }
                  ],
                IgnoreNullValues: true,
                Level: 0,
                MatchingType: 0,
                Weight: 0
              });
            } else if (this.selectedDataType === 'Person'){
              j.Conditions.push({
                DictionaryType: 'Not Required',
                Fields: [
                    {
                  ColumnDataType: this.FieldDataType(already), // this.fields[0].dataType,
                  ColumnName: already,
                  TableName: 'Person'
                    },
                  {
                    ColumnDataType: this.FieldDataType(already), // this.fields[0].dataType,
                    ColumnName: already,
                    TableName: 'ImportedPerson'
                  }
                    ],
                IgnoreNullValues: true,
                Level: 0,
                MatchingType: 0,
                Weight: 0
              });
            }
          }
          else{
            j.Conditions.splice(j.Conditions.indexOf(j.Conditions[ct]), 1);
            ct = 0;
            found = 0;
          }
        }
      }
    }
    // reset fields
    const checklistx = (document.querySelectorAll('.checklist input'));
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < checklistx.length; i++) {
      // @ts-ignore
      checklistx[i].checked = false;
    }
    for (const i of this.match_group) {
      for (const j of i.Groups) {
        if (j.GroupId === this.selected_group) {
          // tslint:disable-next-line:prefer-for-of
          for (let k = 0; k < checklistx.length; k++) {
            // tslint:disable-next-line:prefer-for-of
            for (const l of j.Conditions) {
              for (const q of l.Fields){
                // @ts-ignore
                if (q.ColumnName === checklistx[k].value){
                  // @ts-ignore
                  checklistx[k].checked = true;
                  q.ColumnDataType = this.FieldDataType(q.ColumnName);
                }
              }
            }
          }
        }
      }
    }
    let emptyField = 0;
    // delete frirst and empty field
    for (const i of this.match_group) {
      for (const j of i.Groups) {
        if (j.GroupId === this.selected_group) {
            // tslint:disable-next-line:prefer-for-of
            for (const l of j.Conditions) {
              for (const q of l.Fields){
                // @ts-ignore
                if (q.ColumnDataType === 'empty'){
                  l.Fields.splice(emptyField, 1);
                }
                emptyField++;
              }
            }
        }
      }
    }
    // remove empty field
    for (const i of this.match_group) {
      for (const j of i.Groups) {
        for (const k of j.Conditions){
          if (k.DictionaryType === 'empty'){
            j.Conditions.splice(j.Conditions.indexOf(k), 1);
          }
        }
      }
    }
    console.log(this.match_group);
  }
  // tslint:disable-next-line:typedef
  FieldDataType(columnName: string){
    for (const i of this.settings_fields){
      if (i.FieldName === columnName){
        return i.FieldType;
      }
    }
  }
  // tslint:disable-next-line:typedef
  Select_group(event: any) {
    console.log('sel gr', event.target.value);
    // tslint:disable-next-line:variable-name
    let selected_group = event.target.value;
    selected_group = (typeof selected_group === 'undefined') ? 0 : selected_group;
    // tslint:disable-next-line:radix
    selected_group = (typeof selected_group === 'string') ? parseInt(selected_group) : selected_group;
    selected_group = (isNaN(selected_group)) ? 0 : selected_group;
    this.selected_group = selected_group; // assign value

    // reset fields
    const checklistx = (document.querySelectorAll('.checklist input'));
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < checklistx.length; i++) {
      // @ts-ignore
      checklistx[i].checked = false;
      // @ts-ignore
      console.log(checklistx[i].checked);
    }
    console.log(this.match_group);
    console.log('fieldsssss', this.fields);
    // @ts-ignore
    console.log('checklistx', checklistx[0].value);
    console.log('mtch', this.match_group);
    for (const i of this.match_group) {
      console.log('intrat in gr');
      for (const j of i.Groups) {
        if (j.GroupId === this.selected_group) {
          console.log('id egal');
          // tslint:disable-next-line:prefer-for-of
          for (let k = 0; k < checklistx.length; k++){
            // tslint:disable-next-line:prefer-for-of
            for (const c of j.Conditions){
              // check only the inputs that were previously checked
              for (const q of c.Fields){
                // @ts-ignore
                if (q.ColumnName === checklistx[k].value){
                  // @ts-ignore
                  checklistx[k].checked = true;
                  // @ts-ignore
                  q.ColumnDataType = this.FieldDataType(q.ColumnName);
                }
              }
            }
          }
        }
      }
    }
  }
  // tslint:disable-next-line:typedef
  construct_ExactMatch(ColumnName: string, groupId: number) {
    console.log('exact', ColumnName);
    this.selected_group = groupId;
    if (ColumnName !== '') {
      this.checked = !this.checked;
      this.is_Exact_match = this.checked;
      let value;
      if (this.is_Exact_match) {
        value = 1;
      } else {
        value = 0;
      }
      for (const i of this.match_group) {
        for (const j of i.Groups) {
          if (j.GroupId === this.selected_group) {
            for (const k of j.Conditions){
              for (const q of k.Fields){
                if (q.ColumnName === ColumnName){
                  // @ts-ignore
                  // tslint:disable-next-line:radix
                  k.Level = parseInt(value);
                  k.MatchingType = 0;
                }
              }
            }
          }
        }
      }
    } else {
      this.Level = 1;
      }
    console.log(this.match_group);
  }
  // tslint:disable-next-line:typedef
  construct_Fuzzy(event: any, ColumnName: string, groupId: number) {
    console.log('fuzzy', event.target.value, ColumnName);
    this.selected_group = groupId;
    // tslint:disable-next-line:variable-name
    let number: number;
    const value = event.target.value;
    number = +value;
    if (number < 75){
      number = 75;
    }
    if (number > 100){
      number = 100;
    }
    number = number / 100;
    console.log(number, typeof number);
    if (ColumnName === ''){
      if (!this.is_Exact_match) {
        this.Level = number;
      }
      else {
        // @ts-ignore
        document.getElementById(exact_match_simple).checked = false;
        this.checked = false;
      }
    }
    else{
      if (!this.is_Exact_match){
        for (const i of this.match_group) {
          for (const j of i.Groups) {
            if (j.GroupId === this.selected_group) {
              for (const k of j.Conditions){
                for (const q of k.Fields){
                  if (q.ColumnName === ColumnName) {
                    console.log('fuzzy gasit');
                    // @ts-ignore
                    // tslint:disable-next-line:radix
                    k.Level = number;
                    // @ts-ignore
                    k.MatchingType = 1;
                  }
                }
              }
            }
          }
        }
      }
      else{
        // @ts-ignore
        document.getElementById('checked-' + ColumnName).checked = false;
        this.checked = false;
        for (const i of this.match_group) {
          for (const j of i.Groups) {
            if (j.GroupId === this.selected_group) {
              for (const k of j.Conditions){
                for (const q of k.Fields){
                  if (q.ColumnName === ColumnName){
                    // @ts-ignore
                    // tslint:disable-next-line:radix
                    k.Level = number;
                    // @ts-ignore
                    k.MatchingType = 1;
                  }
                }
              }
            }
          }
        }
      }
    }
    console.log(this.match_group);
  }
  // tslint:disable-next-line:typedef
  nullVal(event: any, ColumnName: string, groupId: number) {
    this.selected_group = groupId;
    console.log('nullval', event.target.value, ColumnName);
    const value = event.target.value;
    for (const i of this.match_group) {
      for (const j of i.Groups) {
        if (j.GroupId === this.selected_group) {
          for (const k of j.Conditions) {
            for (const q of k.Fields){
              if (q.ColumnName === ColumnName) {
                if (value === 'false'){
                  k.IgnoreNullValues = false;
                }
                else{
                  k.IgnoreNullValues = true;
                }
              }
            }
          }
        }
      }
    }
  }
  // tslint:disable-next-line:typedef
  construct_DictionaryType(ev: any, ColumnName: string, groupId) {
    this.selected_group = groupId;
    console.log('dict type', ev.target.value, ColumnName);
    const value = ev.target.value;
    for (const i of this.match_group) {
      for (const j of i.Groups) {
        if (j.GroupId === this.selected_group) {
          for (const k of j.Conditions) {
            for (const q of k.Fields){
              if (q.ColumnName === ColumnName) {
                k.DictionaryType = value;
              }
            }
          }
        }
      }
    }
  }
  // tslint:disable-next-line:typedef
  construct_GroupLevel(event: any, groupId: number) {
    this.selected_group = groupId;
    // tslint:disable-next-line:variable-name
    let number: number;
    const value = event.target.value;
    number = +value;
    if (number < 0){
      number = 0;
    }
    if (number > 100){
      number = 100;
    }
    number = number / 100;
    console.log(number, typeof number);
    for (const i of this.match_group) {
      for (const j of i.Groups) {
        if (j.GroupId === this.selected_group) {
          this.GroupLevel = number;
          // @ts-ignore
          // tslint:disable-next-line:radix
          j.GroupLevel = this.GroupLevel;
        }
      }
    }
    console.log(this.match_group);
  }
  // tslint:disable-next-line:typedef
  construct_WeightInGroup(event: any, ColumnName: string, groupId: number) {
    this.selected_group = groupId;
    // tslint:disable-next-line:variable-name
    let number: number;
    const value = event.target.value;
    number = +value;
    if (number < 0){
      number = 0;
    }
    if (number > 100){
      number = 100;
    }
    number = number / 100;
    console.log(number, typeof number);
    for (const i of this.match_group) {
      for (const j of i.Groups) {
        if (j.GroupId === this.selected_group) {
          for (const k of j.Conditions) {
            for (const q of k.Fields){
              if (q.ColumnName === ColumnName) {
                k.Weight = number;
              }
            }
          }
        }
      }
    }
    console.log(this.match_group);
  }
  // tslint:disable-next-line:typedef
  AddGroup() {
    this.match_group[0].Groups.push({
      Conditions: [{
        DictionaryType: 'empty',
        Fields: [
            {ColumnDataType: 'empty', ColumnName: '', TableName: ''},
            {ColumnDataType: 'empty', ColumnName: '', TableName: ''}
        ],
        IgnoreNullValues: true,
        Level: 0,
        MatchingType: 0,
        Weight: 0
      }],
      GroupId: this.match_group[0].Groups.length + 1,
      GroupLevel: this.GroupLevel
    });
    this.is_Group_ready = true;
  }
  RemoveGroup(groupId: number): void{
    for (const i of this.match_group[0].Groups) {
      if (i.GroupId === groupId){
        this.match_group[0].Groups.splice(this.match_group[0].Groups.indexOf(i), 1);
      }
    }
  }
  removeEmptyFields(): void{
    for (const i of this.match_group[0].Groups) {
      console.log('i', i);
      // @ts-ignore
      if (i.Conditions.length === 0){
        this.match_group[0].Groups.splice(this.match_group[0].Groups.indexOf(i), 1);
      }
      else if (i.Conditions[0].Fields[0].ColumnDataType === 'empty'){
        this.match_group[0].Groups.splice(this.match_group[0].Groups.indexOf(i), 1);
      }
    }
  }
  // tslint:disable-next-line:typedef
  getValueToSend() {
    // tslint:disable-next-line:variable-name
    let search_path = '';
    let parameterJson;
    this.removeEmptyFields();
    // tslint:disable-next-line:prefer-const
    for (const i of this.match_group) {
      for (const j of i.Groups){
        parameterJson = ({
          Groups: this.match_group[0].Groups,
          FuzzyAlgorithm: i.FuzzyAlgorithm,
          SearchDeep: i.FuzzyAlgorithm
        });
      }
    }
    search_path =  JSON.stringify(parameterJson); // JSON.stringify(parameterJson);
    search_path = typeof search_path !== 'undefined' ? search_path : '';
    return (search_path);
  }
  // tslint:disable-next-line:typedef
  replacestr(str: string){
    let x = '';
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < str.length; i++){
      if (str[i] === '\"'){
        x += '\\"';
      }
      else{
        x += str[i];
      }
    }
    console.log('str', x);
    this.value_to_send = x;
  }
  // tslint:disable-next-line:typedef
  Send(){
    console.log('sending');
    this.value_to_send = this.getValueToSend();
    this.replacestr(this.value_to_send);
    const res = this.value_to_send.replace('"', '\\"');
    console.log('res', res);

    console.log('val for search', this.value_to_send);
    // tslint:disable-next-line:variable-name
    const url = this.api.url_post_SetSettings();

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
    xhr.setRequestHeader('accept', '*/*');
    xhr.setRequestHeader('Content-Type', 'application/json-patch+json');

    // tslint:disable-next-line:only-arrow-functions typedef
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        console.log(xhr.status);
        console.log(xhr.responseText);
        // tslint:disable-next-line:triple-equals
        if (xhr.status == 200){
          document.getElementById('succeeded_snack').click();
        }
        else{
          document.getElementById('failed_snack').click();
        }
      }};
    let data;
    if (this.is_Company){
      data = '{"name":"MatchExternalDataCompanyParameters", "value":"empty"}';
    }else if (this.is_Person){
      data = '{"name":"MatchExternalDataPersonParameters", "value":"empty"}';
    }else if (this.is_Engagement){
      data = '{"name":"MatchExternalDataEngagementParameters", "value":"empty"}';
    }

    data = data.replace('empty', this.value_to_send);
    console.log('sending', data);
    xhr.send(data);
  }
  ngOnInit(): void {
    this.api.getAuthToken().subscribe(auth => {
      this.token = auth;
    });
    this.api.getSourceData_SimpleSearch().subscribe(res => {
        // @ts-ignore
        this.datatypes = res;
      },
      err => {
        console.log(err);
      });
    this.api.getDictionaries_AdvancedSearch().subscribe(res => {
      // @ts-ignore
      this.dictionary = res;
    });
  }
}
