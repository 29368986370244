import { Component } from '@angular/core';
import {from, interval} from 'rxjs';
import {ConfigService} from './config/config.service';
import {MatDialog} from '@angular/material/dialog';
import {HttpClient} from '@angular/common/http';
import {Jobs} from './shared/Jobs';
import {NotificationComponent} from './notification/notification.component';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  // title = 'CEGroup';
  jobs: Jobs[] = [{
    key: '',
    type: 'empty',
    inProcess: false,
    state: ''
  }];
  title = 'angular-app';
  fileName = 'ExcelSheet.xlsx';
  userList = [

    {

      id: 1,

      name: 'Leanne Graham',

      username: 'Bret',

      email: 'Sincere@april.biz'

    },

    {

      id: 2,

      name: 'Ervin Howell',

      username: 'Antonette',

      email: 'Shanna@melissa.tv'

    },

    {

      id: 3,

      name: 'Clementine Bauch',

      username: 'Samantha',

      email: 'Nathan@yesenia.net'

    },

    {

      id: 4,

      name: 'Patricia Lebsack',

      username: 'Karianne',

      email: 'Julianne.OConner@kory.org'

    },

    {

      id: 5,

      name: 'Chelsey Dietrich',

      username: 'Kamren',

      email: 'Lucio_Hettinger@annie.ca'

    }

  ];
  constructor(private api: ConfigService, public dialog: MatDialog, private http: HttpClient)
  {
    this.jobChecking();
  }
  exportexcel(fileName: string, datatype: string): void
  {
    const element = document.getElementById('excel-table' + datatype);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, this.fileName);
  }
  SetTimeStamp(): void{
    // tslint:disable-next-line:new-parens
    const date = new Date;
    localStorage.setItem('date', '');
  }
  jobChecking(): void{
    // Create an Observable that will publish a value on an interval
    const secondsCounter = interval(60000);
    this.api.get_Jobs().subscribe(res => {
      // @ts-ignore
      this.jobs = res;
    });
    // Subscribe to begin publishing values
    const subscription = secondsCounter.subscribe(n => {
      if (((this.jobs.length === 1) && (this.jobs[0].type === 'empty')) || this.jobs.length === 0){
        // do nothing
      }
      else {
        console.log('checking jobs');
        // Create an Observable out of a promise
        const url = this.api.get_JobsURL();
        const data = from(fetch(url));
        this.api.get_Jobs().subscribe(res => {
          // @ts-ignore
          this.jobs = res;
          if (this.jobs.length === 0){
            this.SetTimeStamp();
            const dialogRef = this.dialog.open(NotificationComponent, {
              disableClose: true,
            });
          }
        });
      }
    });
  }
}
