import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../environments/environment';
import {AuthService} from '@auth0/auth0-angular';
import {AppComponent} from '../app.component';

interface Message {
    message: string;
}
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
    message: string = null;
    isAdmin: boolean;
    userRole: string;
    profileJson: string = null;
    constructor(private http: HttpClient, public auth: AuthService, private appcomponent: AppComponent)
    {
    this.appcomponent.jobChecking();
    }
    ngOnInit(): void {
        this.callSecureApi();
        this.auth.user$.subscribe(
            (profile) => {
                (this.profileJson = JSON.parse(JSON.stringify(profile, null, 2)));
                // console.log('profile', profile.email);
                // console.log('profile', profile.name);
                // console.log('profile', profile['https://schemas.quickstarts.com/roles'][0]);
                localStorage.setItem('user', profile.name);
                localStorage.setItem('userEmail', profile.email);
                localStorage.setItem('userRole', profile['https://schemas.quickstarts.com/roles'][0]);
                if (this.userRole === 'admin'){
                    this.isAdmin = true;
                    console.log('is admin');
                }
                else{
                    this.isAdmin = false;
                    console.log('is not admin');
                }

            });
    }
    callSecureApi(): void {
        this.http
            .get(`${env.dev.serverUrl}/api/messages/protected-message`)
            .subscribe((result: Message) => {
                this.message = result.message;
            });
    }
}
