<header>
    <nav>
      <a href="#" routerLink="/"><img src="assets/img/CEG_Logo.jpeg" alt="Logo" class="logo"></a>
      <ul>
        <li>
            <a href="#" routerLink="/search"><img src="assets/img/search.svg" alt="search_icon" class="icons"> Search</a>
        </li>
        <li>
          <a href="#" routerLink="/matching"><img src="assets/img/review.svg" alt="review_icon" class="icons"> Match</a>
        </li>
        <li  *ngIf="isAdmin" >
            <a href="#" routerLink="/import"><img src="assets/img/import.svg" alt="import_icon" class="icons"> Import</a>
        </li>
        <li  *ngIf="isAdmin" >
            <a href="#" routerLink="/staging"><img src="assets/img/quarantine.svg" alt="quarantine_icon" class="icons"> Stage</a>
        </li>
        <li>
            <a href="#" routerLink="/research"><img src="assets/img/research.svg" alt="research_icon" class="icons"> Research</a>
        </li>
        <li class="pointer">
          <mat-menu #appMenu="matMenu">
            <a href="#" routerLink="/archive" class="dropdown-menu">
              <button mat-menu-item>Archive</button>
            </a>
            <a href="#" routerLink="/matching-settings" class="dropdown-menu">
              <button mat-menu-item>Match Setting</button>
            </a>
            <a *ngIf="isAdmin" href="#" routerLink="/research-settings" class="dropdown-menu">
              <button mat-menu-item>Research  Settings</button>
            </a>
            <a href="#" routerLink="/staging-settings" class="dropdown-menu">
              <button mat-menu-item>Stage  Settings</button>
            </a>
            <a href="#" routerLink="/wordpress-settings" class="dropdown-menu">
              <button mat-menu-item>WordPress Log</button>
            </a>
            <a href="#" routerLink="/memberclicks-log" class="dropdown-menu">
              <button mat-menu-item>MemberClicks Log</button>
            </a>
            <a href="#" routerLink="/company-api-log" class="dropdown-menu">
              <button mat-menu-item>Public CO Api</button>
            </a>
            <a href="#" routerLink="/board-directors-log" class="dropdown-menu">
              <button mat-menu-item>Public CO Directors Log</button>
            </a>
          </mat-menu>
          <img src="assets/img/settings.svg" alt="settings_icon" class="settings" [matMenuTriggerFor]="appMenu">
          <img src="assets/img/down-arrow.svg" alt="down-arrow_icon" class="icons" [matMenuTriggerFor]="appMenu">
        </li>
        <li>
          <a (click)="auth.logout({ returnTo: 'https://winpure-dev.ceo360.pro/#/login' })"><img src="assets/img/logout.svg" alt="logout_icon" class="icons"></a>
        </li>
      </ul>
    </nav>
</header>
