<div *ngIf="!isPopup">
    <app-navbar></app-navbar>
    <body class="body_container">
    <div class="my-overlay" *ngIf="showOverlay">
        <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
    </div>
    <h1>
        <a class="black-box">
            <img src="assets/img/folder.svg" alt="search_icon" class="logo-icon">
            Archive
        </a>
        <!--  <a class="production">PRODUCTION</a>-->
        <a class="development">DEVELOPMENT</a>
        <!--    <a class="right" *ngIf="is_search_ready"> Total results: {{total_searches}}</a>-->
    </h1>
    <div class="content">
        <div class="menu-div">
            <div class="button-div">
                <button (click)="selected_datatype('Company')" id="company-results" class="menu-button">See Imported Company</button>
                <button (click)="selected_datatype('Person')" id="person-results" class="menu-button">See Imported Person</button>
                <button (click)="showBoardDirectors()" id="directors-results" class="menu-button">See Imported Board Directors</button>
            </div>
            <p class="check-jobs" (click)="showRunningJobs()">
                Check running jobs
                <img id="review-running-jobs" src="assets/img/down_simple_arrow.svg" alt="down_icon" class="icon-right">
            </p>
            <br>
            <div *ngIf="are_jobsVisible" id="running-jobs" class="running-jobs">

                <div *ngIf="jobs">
                    <p *ngIf="time_last_job_started !== ''">Last job started at: {{time_last_job_started}}</p>
                    <p>There are {{jobs.length}} job(s) running</p>
                    <div *ngFor="let job of jobs">
                        <p>
                            Job type: {{job.type}}
                        </p>
                        <p>
                            Job state: {{job.state}}
                        </p>
                        <button (click)="deleteJob(job.key.toString())" class="button" style="margin: 0 0 0 1%">Delete</button>
                        <p>--------------------------------------------------------------</p>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="selectedDataType" class="archive-actions">
                <mat-form-field appearance="fill">
                    <mat-select [formControl]="file" (selectionChange)="SelectedFile($event.value)" [(ngModel)]="selectedFile">
                        <mat-option value="">Choose a file</mat-option>
                        <mat-option *ngFor="let files of fileList" [value]="files.name">{{files.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <!--       <button class="button" *ngIf="isCompaniesSearch && isAdmin" (click)="exportexcel('ArchivedCompanies-', 'company')">Download Archive</button>
                       <button class="button" *ngIf="isPeopleSearch && isAdmin" (click)="exportexcel('ArchivedPeople-', 'person')">Download Archive</button>-->
                <button class="button" *ngIf="(isPeopleSearch || isCompaniesSearch) && isAdmin" (click)="getArchiveOriginal()">Download Archive</button>
          </div>
    </div>

    <div style="width: 100%" *ngIf="isDataReady">
        <table mat-table [dataSource]="dataSource" *ngIf="isPeopleSearch" id="excel-table-person">

            <ng-container matColumnDef="PersonId">
                <th mat-header-cell *matHeaderCellDef class="table_header">Mapped to Person Id</th>
                <td mat-cell *matCellDef="let element">
                    <p *ngIf="element.personId == -1">
                        {{element.personId}} (deleted)
                    </p>
                    <p *ngIf="element.personId === -2">
                        {{element.personId}} (kicked)
                    </p>
                    <p *ngIf="element.personId !== -1 && element.personId !== -2">
                        {{element.personId}}
                    </p>
                </td>
            </ng-container>
            <ng-container matColumnDef="FirstName">
                <th mat-header-cell *matHeaderCellDef class="table_header">First Name</th>
                <td mat-cell *matCellDef="let element">
                    {{element.firstName}}
                </td>
            </ng-container>
            <ng-container matColumnDef="LastName">
                <th mat-header-cell *matHeaderCellDef class="table_header">Last Name</th>
                <td mat-cell *matCellDef="let element">
                    {{element.lastName}}
                </td>
            </ng-container>
            <ng-container matColumnDef="Ticker">
                <th mat-header-cell *matHeaderCellDef class="table_header">Ticker</th>
                <td mat-cell *matCellDef="let element">
                    {{element.ticker}}
                </td>
            </ng-container>
            <ng-container matColumnDef="FileRowId">
                <th mat-header-cell *matHeaderCellDef class="table_header">File Row Id</th>
                <td mat-cell *matCellDef="let element">
                    {{element.fileRowId}}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row_darken"></tr>
        </table>
        <table mat-table [dataSource]="dataSource" *ngIf="isCompaniesSearch" id="excel-table-company">

            <ng-container matColumnDef="CompanyId">
                <th mat-header-cell *matHeaderCellDef class="table_header">Mapped to Company Id</th>
                <td mat-cell *matCellDef="let element">
                    <p *ngIf="element.companyId == -1">
                        {{element.companyId}} (deleted)
                    </p>
                    <p *ngIf="element.companyId === -2">
                        {{element.companyId}} (kicked)
                    </p>
                    <p *ngIf="element.companyId !== -1 && element.companyId !== -2">
                        {{element.companyId}}
                    </p>
                </td>
            </ng-container>
            <ng-container matColumnDef="FileRowId">
                <th mat-header-cell *matHeaderCellDef class="table_header">File Row Id</th>
                <td mat-cell *matCellDef="let element">
                    {{element.fileRowId}}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row_darken"></tr>
        </table>
    </div>
    <div *ngIf="showDirectors">
        <div class="directors-table">
            <mat-table class="directors-list"[dataSource]="dataSource">
                <ng-container matColumnDef="PersonId">
                    <mat-header-cell class="table_header" *matHeaderCellDef mat-sort-header>Mapped to Person ID </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <a (click)="linkToCeo(element.personId)">
                            {{element.personId}}
                        </a>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="FirstName">
                    <mat-header-cell class="table_header"  *matHeaderCellDef mat-sort-header>FirstName</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.firstName}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="LastName">
                    <mat-header-cell class="table_header"  *matHeaderCellDef mat-sort-header>Last Name</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.lastName}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="BoardAssociation">
                    <mat-header-cell class="table_header"  *matHeaderCellDef mat-sort-header>Board Company Name </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.boardCompanyName}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="BoardAssociationCIk">
                    <mat-header-cell class="table_header"  *matHeaderCellDef mat-sort-header>Board CIK Id</mat-header-cell>
                    <mat-cell *matCellDef="let element"> <a (click)="linkToCompany(element.boardCompanyId)">
                        {{element.boardCompanyCik}}
                    </a></mat-cell>
                </ng-container>
                <ng-container matColumnDef="Action">
                    <mat-header-cell class="table_header"  *matHeaderCellDef mat-sort-header>Action </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <p *ngIf="element.actionId == 11 "> Kicked</p>
                        <p *ngIf="element.actionId == 14 "> Added Person</p>
                        <p *ngIf="element.actionId == 16 "> Merged Board</p>
                        <p *ngIf="element.actionId == 17 "> Matched by Id</p>
                        <p *ngIf="element.actionId == 19 "> Added Board</p>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="UpdatedDate">
                    <mat-header-cell class="table_header date-picker"  *matHeaderCellDef mat-sort-header>
                        <div>Updated Date</div>
                        <div class="updated-date ">
                            <input (ngModelChange)="searchDirectors($event)" [(ngModel)]="dataFilter" [ngModelOptions]="{standalone: true}" type="date">
                        </div>
                    </mat-header-cell>
                    <mat-cell class="" *matCellDef="let element"> {{element.updatedDate | date:'MM/dd/yyyy'}} </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;">
                </mat-row>
            </mat-table>
            <mat-paginator  [pageSizeOptions]="[25,50, 100]"
                            [pageIndex]="pageNumber"
                            [pageSize]="pageSize"
                            [length]="totalSize"
                            [showFirstLastButtons] = true
                            (page)="onPageFired($event)"></mat-paginator>
        </div>
    </div>
    </body>
</div>
<mat-dialog-content  *ngIf="isPopup">
    <img src="assets/img/cancel.svg" alt="cancel_icon" mat-dialog-close class="icon">
    <body class="body_container_popup">
    <div class="my-overlay" *ngIf="showOverlay">
        <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
    </div>
    <div class="menu-div">
        <div class="button-div">
            <button (click)="selected_datatype('Company')" id="company-results-popup" class="menu-button">See Imported Company</button>
            <button (click)="selected_datatype('Person')" id="person-results-popup" class="menu-button">See Imported Person</button>
        </div>
    </div>
    <br>
    <br>
    <br>
    <p *ngIf="selectedDataType">
        <br>
        <mat-form-field appearance="fill">
            <mat-select [formControl]="file" (selectionChange)="SelectedFile($event.value)" [(ngModel)]="selectedFile">
                <mat-option value="">Choose a file</mat-option>
                <mat-option *ngFor="let files of fileList" [value]="files.name">{{files.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <button class="button" *ngIf="isCompaniesSearch" (click)="exportexcel('ArchivedCompanies-', 'company-popup')">Download To Excel</button>
        <button class="button" *ngIf="isPeopleSearch" (click)="exportexcel('ArchivedPeople-', 'person-popup')">Download To Excel</button>
        <button class="button" *ngIf="isPeopleSearch || isCompaniesSearch" (click)="getArchiveOriginal()">Download Original File</button>
    </p>
    <br>
    <div style="width: 100%" *ngIf="isDataReady">
        <table mat-table [dataSource]="dataSource" *ngIf="isPeopleSearch" id="excel-table-person-popup">

            <ng-container matColumnDef="PersonId">
                <th mat-header-cell *matHeaderCellDef class="table_header">Mapped to Person Id</th>
                <td mat-cell *matCellDef="let element">
                    <p *ngIf="element.personId == -1">
                        {{element.personId}} (deleted)
                    </p>
                    <p *ngIf="element.personId === -2">
                        {{element.personId}} (kicked)
                    </p>
                    <p *ngIf="element.personId !== -1 && element.personId !== -2">
                        {{element.personId}}
                    </p>
                </td>
            </ng-container>
            <ng-container matColumnDef="FirstName">
                <th mat-header-cell *matHeaderCellDef class="table_header">First Name</th>
                <td mat-cell *matCellDef="let element">
                    {{element.firstName}}
                </td>
            </ng-container>
            <ng-container matColumnDef="LastName">
                <th mat-header-cell *matHeaderCellDef class="table_header">Last Name</th>
                <td mat-cell *matCellDef="let element">
                    {{element.lastName}}
                </td>
            </ng-container>
            <ng-container matColumnDef="Ticker">
                <th mat-header-cell *matHeaderCellDef class="table_header">Ticker</th>
                <td mat-cell *matCellDef="let element">
                    {{element.ticker}}
                </td>
            </ng-container>
            <ng-container matColumnDef="FileRowId">
                <th mat-header-cell *matHeaderCellDef class="table_header">File Row Id</th>
                <td mat-cell *matCellDef="let element">
                    {{element.fileRowId}}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row_darken"></tr>
        </table>
        <table mat-table [dataSource]="dataSource" *ngIf="isCompaniesSearch" id="excel-table-company-popup">

            <ng-container matColumnDef="CompanyId">
                <th mat-header-cell *matHeaderCellDef class="table_header">Mapped to Company Id</th>
                <td mat-cell *matCellDef="let element">
                    <p *ngIf="element.companyId == -1">
                        {{element.companyId}} (deleted)
                    </p>
                    <p *ngIf="element.companyId === -2">
                        {{element.companyId}} (kicked)
                    </p>
                    <p *ngIf="element.companyId !== -1 && element.companyId !== -2">
                        {{element.companyId}}
                    </p>
                </td>
            </ng-container>
            <ng-container matColumnDef="FileRowId">
                <th mat-header-cell *matHeaderCellDef class="table_header">File Row Id</th>
                <td mat-cell *matCellDef="let element">
                    {{element.fileRowId}}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row_darken"></tr>
        </table>
    </div>
    </body>
</mat-dialog-content>
