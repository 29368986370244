import {Component, OnInit, ViewChild} from '@angular/core';
import {ConfigService} from '../config/config.service';
import {Router, RouterEvent, NavigationCancel, NavigationEnd, NavigationError, NavigationStart} from '@angular/router';
import {MergedConnectionService} from '../service/mergedConnection.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DataType} from '../shared/DataType';
import {Statistics} from '../shared/Statistics';
import {Company} from '../shared/Company';
import {Person} from '../shared/Person';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {Jobs} from '../shared/Jobs';
import {AppComponent} from '../app.component';
import {WarningComponent} from '../warning/warning.component';
import * as XLSX from 'xlsx';
import {CompanyToReview} from '../shared/CompanyToReview';
import {ReviewedCompany} from '../shared/ReviewedCompany';
import {PersonToReview} from '../shared/PersonToReview';
import {ReviewedPerson} from '../shared/ReviewedPerson';
import {MergeOptionsComponent} from '../merge-options/merge-options.component';
import {AuthService} from '@auth0/auth0-angular';
import {Research} from '../shared/Research';
import {UsersToView} from '../shared/UsersToView';
import {StatisticsWordpress} from '../shared/StatisticsWordpress';
import {Country} from '../shared/Country';
import {State} from '../shared/State';
import {PublicCoDataStatistics} from '../shared/PublicCoDataStatistics';
import {BoardDirectorsComponent} from '../board-directors/board-directors.component';

@Component({
  selector: 'app-research',
  templateUrl: './research.component.html',
  styleUrls: ['./research.component.scss']
})

export class ResearchComponent implements OnInit {
  @ViewChild(BoardDirectorsComponent) boardDirectorComponent: BoardDirectorsComponent;
  constructor(private api: ConfigService, private route: Router, public auth: AuthService, private mergeService: MergedConnectionService,
              private snackBar: MatSnackBar, public dialog: MatDialog, private appcomponent: AppComponent)
  {
    this.appcomponent.jobChecking();
    route.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
  }
  // auth0 data
  token: string;
  teamMemberId: number;
  user: string;
  userEmail: string;
  userRole: string;
  isAdmin: boolean;
  currentPage: number;
  totalPages: number;
  fetchData = 500;
  offset = 0;
  // for showing research areas
  research: Research[];
  profileJson: string = null;
  isResearchSelected: boolean;
  researchAreaId: number;
  bulkactionResearch = '';
  public showOverlay = false;
  // tslint:disable-next-line:variable-name
  is_byGroup = false;
  // tslint:disable-next-line:variable-name
  is_AllData = false;
  // tslint:disable-next-line:variable-name
  is_NonMatches = false;
  // tslint:disable-next-line:variable-name
  is_WordpressSubType = false;
  // tslint:disable-next-line:variable-name
  is_Wordpress = false;
  // tslint:disable-next-line:variable-name
  is_Wordpress_non_matches = false;
  isPublicDataCompany = false;
  isPublicDataCompanyMatches = false;
  isPublicDataCompanyNonMatches = false;
  datatypes: DataType[];
  // tslint:disable-next-line:variable-name
  us_country: Country = {id: 75, countryCode: 'US', countryName: 'United States', countryCodeId: 232 };
  selectedDataType;
  statistics: Statistics;
  statisticsWordpress: StatisticsWordpress;
  publicCoDataStatistics: PublicCoDataStatistics;
  matchGroups: string;
  notMatched: string;
  byGroup: string;
  totalRecords: number;
  totalNonWordpressRecords: number;
  matchGroupsWordpress: string;
  notMatchedWordpress: string;
  totalRecordsWordpress: number;
  // tslint:disable-next-line:variable-name
  group_list: number[] = [];
  companiesToReview: CompanyToReview[] = [];
  mergedCompanies: CompanyToReview[] = [];
  reviewedCompanies: ReviewedCompany[] = [];
  addCompany: ReviewedCompany[] = [];
  peopleToReview: PersonToReview[] = [];
  mergedPeople: PersonToReview[] = [];
  reviewedPeople: ReviewedPerson[] = [];
  // tslint:disable-next-line:variable-name
  is_company_search = false;
  // tslint:disable-next-line:variable-name
  is_person_search = false;
  // tslint:disable-next-line:variable-name
  is_engagement_search = false;
  // tslint:disable-next-line:variable-name
  group_list_length;
  // tslint:disable-next-line:variable-name
  group_list_elements = [];
  // tslint:disable-next-line:variable-name
  group_list_wordpress: number[] = [];
  // tslint:disable-next-line:variable-name
  group_list_elements_wordpress = [];
  // tslint:disable-next-line:variable-name
  group_list_length_wordpress;
  // tslint:disable-next-line:variable-name
  is_totalScore_clicked = false;
  // tslint:disable-next-line:variable-name
  selected_group: string;
  // tslint:disable-next-line:variable-name
  is_data_available = false;
  isApiDataMatchGroups = false;
  isApiDataNonMatchRecords = false;
  href = 'https://dev.ceo360.pro/#/';
  dataSource;
  displayedColumns: string[];
  length: number;
  pageSize = 0;
  pageSizeOptions: number[] = [5, 10, 20];
  // tslint:disable-next-line:variable-name
  total_searches = 0;
  // tslint:disable-next-line:variable-name
  is_search_ready = false;
  // tslint:disable-next-line:variable-name
  is_proceed_visible = 0;
  // tslint:disable-next-line:variable-name
  merge_rule = 'UpdateEmptyField';
  // tslint:disable-next-line:variable-name
  are_alreadyMerged = 0;
  // tslint:disable-next-line:variable-name
  was_manual_merge_accessed = false;
  // tslint:disable-next-line:variable-name
  are_actionButtons_visible = 0;
  users: UsersToView[] = [];
  jobs: Jobs[] = [{
    key: '',
    type: '',
    inProcess: false,
    state: ''
  }];
  // tslint:disable-next-line:variable-name
  are_jobsVisible = false;
  // tslint:disable-next-line:variable-name
  is_long_list = false;
  // tslint:disable-next-line:variable-name
  time_last_job_started = '';
  bulkaction = '';
  constructedReviewedCompanies = '';
  constructedReviewedPeople = '';
  // tslint:disable-next-line:variable-name
  is_selectedGroup = true;
  countries: Country[];
  states: State[];
  // tslint:disable-next-line:variable-name
  active_status = [true, false];
  // tslint:disable-next-line:variable-name
  not_found_state = '';
  // tslint:disable-next-line:variable-name
  not_found_primaryState = '';
  // tslint:disable-next-line:variable-name
  not_found_country = '';
  // tslint:disable-next-line:variable-name
  not_found_activeStatus = '';
  matchingByID = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // MatPaginator Output
  pageEvent: PageEvent;
  // tslint:disable-next-line:typedef
  ShowAllGroups(){
    console.log('this.is research', this.researchAreaId);
    this.is_long_list = true;
  }
  // tslint:disable-next-line:typedef
  isWordpress(){
    this.resetSelectedTab();
    this.is_WordpressSubType = true;
    if (this.is_WordpressSubType){
      document.getElementById('wordpress').style.backgroundColor = '#222222';
      document.getElementById('wordpress').style.color = 'white';
    }
    this.is_byGroup = false;
    this.is_AllData = false;
    this.is_NonMatches = false;
  }
  exportExcel(fileName: string, datatype: string): void
  {
    const element = document.getElementById('excel-table-' + datatype);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, fileName);
  }
  VerifyBulkActions(): void{
    if (this.selectedDataType === 'Company'){
      this.constructedReviewedCompanies = this.construct_reviewedCompanies();
      const actionType = this.reviewedCompanies[0].actionType;
      // tslint:disable-next-line:variable-name
      let different_actions_ct = 0;
      for (const i of this.reviewedCompanies){
        if (i.actionType === actionType){
          different_actions_ct++;
        }
      }
      if (different_actions_ct === this.companiesToReview.length){
        this.bulkaction = actionType;
        this.openWariningPopup();
      }
      else {
        this.Proceed();
      }
    }
    else if (this.selectedDataType === 'Person'){
      this.constructedReviewedPeople = this.construct_reviewdPeople();
      const actionType = this.reviewedPeople[0].actionType;
      console.log('action', actionType);
      // tslint:disable-next-line:variable-name
      let different_actions_ct = 0;
      for (const i of this.reviewedPeople){
        if (i.actionType === actionType){
          different_actions_ct++;
        }
      }
      if (different_actions_ct === this.peopleToReview.length){
        this.bulkaction = actionType;
        this.openWariningPopup();
      }
      else {
        this.Proceed();
      }
    }
  }
  openWariningPopup(): void{
    const dialogRef = this.dialog.open(WarningComponent);
    dialogRef.componentInstance.action = this.bulkaction;
    dialogRef.componentInstance.isBulckAnction = true;
    dialogRef.componentInstance.group = this.selected_group;

    dialogRef.afterClosed().subscribe(result => {
      console.log(`popup result: ${result}`);
      if (result === 'yes'){
        this.Proceed();
      }
    });
  }
// tslint:disable-next-line:typedef
  snackbar_SucceededPost() {
    this.snackBar.open('Your modifications have been successfully proceeded!', '', {
      duration: 1000,
      panelClass: ['succeed'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
  // tslint:disable-next-line:typedef
  snackbar_FailedPost() {
    this.snackBar.open('Your modifications were NOT proceeded!', '', {
      duration: 1000,
      panelClass: ['failed'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
  // tslint:disable-next-line:typedef
  snackbar_Error(errorMessage: string) {
    this.snackBar.open(errorMessage, '', {
      duration: 1000,
      panelClass: ['failed'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
  // tslint:disable-next-line:typedef
  Refresh(){
    this.setWasManualMergeAccessed(false);
    if (this.is_AllData){
      this.GetAllData();
    }
    else if (this.is_NonMatches){
      this.GetNotMatches();
    }
    else if (this.is_byGroup){
      let n: number;
      n = Number(this.selected_group);
      this.Group_Data(n);
    }
    else if (this.is_Wordpress){
      let n: number;
      n = Number(this.selected_group);
      this.GetWordpressData(n);
    }
    else if (this.is_Wordpress_non_matches){
      this.GetWordpressNonMatched();
    }
    else if (this.isPublicDataCompanyMatches) {
      let n: number;
      n = Number(this.selected_group);
      this.GroupDataPublicCO(n);
    }
    else if (this.isPublicDataCompanyNonMatches) {
      this.GetPublicCoNotMatches();
    }
  }
  proceedSelectedAction(actionType: any, element: any): void{
    // proceed normal
    if (this.are_alreadyMerged === 1){
      if (this.selectedDataType === 'Company'){
        for (const i of this.companiesToReview){
          for (const j of this.mergedCompanies){
            if (i.winPurePrimK === j.winPurePrimK){
              i.actionType = 'None';
              if (this.is_AllData){
                document.getElementById('alldata-company-select-' + i.winPurePrimK).style.display = 'none';
              }else if (this.byGroup) {
                document.getElementById('bygroup-company-select-' + i.winPurePrimK).style.display = 'none';
              }
              else if (this.notMatched) {
                document.getElementById('nonmatches-company-select-' + i.winPurePrimK).style.display = 'none';
              }
            }
          }
        }
      }
      else if (this.selectedDataType === 'Person'){
        for (const i of this.peopleToReview){
          for (const j of this.mergedPeople){
            if (i.winPurePrimK === j.winPurePrimK){
              i.actionType = 'None';
              if (this.is_AllData){
                document.getElementById('alldata-person-select-' + i.winPurePrimK).style.display = 'none';
              }else if (this.byGroup) {
                document.getElementById('bygroup-person-select-' + i.winPurePrimK).style.display = 'none';
              }
              else if (this.notMatched) {
                document.getElementById('nonmatches-person-select-' + i.winPurePrimK).style.display = 'none';
              }
              else if (this.is_Wordpress) {
                document.getElementById('wordpress-person-select-' + i.winPurePrimK).style.display = 'none';
              }
            }
          }
        }
      }
    }
    // verify if the merge has been done in the popup
    // tslint:disable-next-line:variable-name
    this.mergeService.shared_are_componentsAlreadyMerged.subscribe(are_alreadyMerged => {
      this.are_alreadyMerged = are_alreadyMerged;
    });
    console.log('actiontype', actionType);
    switch (actionType) {
      case 'Add': {
        this.are_actionButtons_visible++;
        break;
      }
      case 'AddToMainDatabase': {
        this.are_actionButtons_visible++;
        break;
      }
      case 'Merge': {
        this.are_actionButtons_visible++;
        break;
      }
      case 'Delete': {
        this.are_actionButtons_visible++;
        break;
      }
      case 'None': {
        this.are_actionButtons_visible--;
        break;
      }
      case 'Edit': {
        this.are_actionButtons_visible++;
        if (this.selectedDataType === 'Company'){
          this.verifyState(element);
          this.verifyCountry(element);
          this.verifyActiveStatus(element);
        }
        else if (this.selectedDataType === 'Person'){
          this.verifyPrimaryState(element);
          this.verifyActiveStatus(element);
        }
        break;
      }
      case 'Kick': {
        this.are_actionButtons_visible++;
        break;
      }
    }
  }
// tslint:disable-next-line:typedef
  selectedAction(actionType: any, element: any) {
    this.matchingByID = false;
    if (actionType === 'Delete'){
      const dialogRef = this.dialog.open(WarningComponent);
      dialogRef.componentInstance.action = 'Delete';
      dialogRef.componentInstance.isBulckAnction = false;

      dialogRef.afterClosed().subscribe(result => {
        console.log(`popup result: ${result}`);
        if (result === 'yes'){
          this.proceedSelectedAction(actionType, element);
        }
        else{
          // action type = none
          if (this.selectedDataType === 'Company'){
            for (const i of this.companiesToReview){
              if (i === element){
                i.actionType = 'None';
              }
            }
          }
          else if (this.selectedDataType === 'Person'){
            for (const i of this.peopleToReview){
              if (i === element){
                i.actionType = 'None';
              }
            }
          }
        }
      });
    }
    if (actionType === 'MatchByID'){
      this.matchingByID = true;
      if (this.are_actionButtons_visible > 0) {
        this.are_actionButtons_visible = 0;
      }
    }
    else{
      this.proceedSelectedAction(actionType, element);
    }
  }

// tslint:disable-next-line:typedef
  construct_reviewedCompanies() {
    console.log('construct rev companies');
    for (const i of this.companiesToReview) {
      const comp = new ReviewedCompany();
      Object.assign(comp, i);
      switch (i.actionType) {
        case 'Add': {
          // comp.verifiedDate = new Date().toLocaleString();
          comp.actionType = 'AddEdit';
          this.reviewedCompanies.push(comp);
          break;
        }
        case 'AddToMainDatabase': {
          comp.actionType = 'AddToMainDatabase';
          this.reviewedCompanies.push(comp);
          break;
        }
        case 'Merge': {
          comp.actionType = 'Merge';
          this.reviewedCompanies.push(comp);
          break;
        }
        case 'Delete': {
          comp.actionType = 'Delete';
          this.reviewedCompanies.push(comp);
          break;
        }
        case 'Edit': {
          comp.actionType = 'Edit';
          this.reviewedCompanies.push(comp);
          break;
        }
        case 'Kick': {
          comp.actionType = 'Kick';
          this.reviewedCompanies.push(comp);
          break;
        }
        case 'Research': {
          comp.actionType = 'Research';
          this.reviewedCompanies.push(comp);
          break;
        }
        case 'MigratePeople': {
          comp.actionType = 'MigratePeople';
          this.reviewedCompanies.push(comp);
          break;
        }
        case 'MatchByID': {
          comp.actionType = 'MatchByID';
          this.reviewedCompanies.push(comp);
          break;
        }
        case 'None': {
          break;
        }

      }
    }
    // this.reviewedCompanies.splice(0, 1);
    console.log(this.reviewedCompanies);
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.users.length; i++) {
      if (this.users[i].email === this.user){
        this.teamMemberId = this.users[i].teamMemberId;
      }
    }
    const data2 = {verifiedBy : this.teamMemberId};
    console.log('user', this.teamMemberId);
    for (let i = 0; i < this.reviewedCompanies.length; i++) {
      this.reviewedCompanies[i] = Object.assign(this.reviewedCompanies[i], data2);
    }
    console.log('return reviewed companies', this.reviewedCompanies);
    return JSON.stringify(this.reviewedCompanies);
  }

  pushActionsIntoReviewedPeople(i: any, action: string): void{
    this.reviewedPeople.push({
      'source name': i['source name'],
      actionType: action,
      companyId: i.companyId,
      email: i.email,
      // @ts-ignore
      fileName: i.fileName,
      // @ts-ignore
      fileRowId: i.fileRowId,
      firstName: i.firstName,
      groupId: i['group ID'],
      // @ts-ignore
      id: i.id,
      // @ts-ignore
      importId: i.importId,
      lastName: i.lastName,
      lastUpdated: i.lastUpdated,
      middleName: i.middleName,
      mobilePhone: i.mobilePhone,
      personId: i.personId,
      prefix: i.prefix,
      primaryAddress1: i.primaryAddress1,
      primaryAddress2: i.primaryAddress2,
      primaryCity: i.primaryCity,
      primaryCountry: i.primaryCountry,
      primaryState: i.primaryState,
      primaryZip: i.primaryZip,
      alternateAddressSource: i.alternateAddressSource,
      alternateAddressPreferred: i.alternateAddressPreferred,
      alternateAddressType: i.alternateAddressType,
      suffix: i.suffix,
      title: i.title,
      // @ts-ignore
      cexSubscriberCode: i.cexSubscriberCode,
      wordpressId: i.wordpressId,
      cexSubStatus: i.cexSubStatus,
      cbmSubStatus: i.cbmSubStatus,
      cbmDigitalAndPrint: i.cbmDigitalAndPrint,
      ceoDigitalAndPrint: i.ceoDigitalAndPrint,
      ceoDigitalOnly: i.ceoDigitalOnly,
      cbmDigitalOnly: i.cbmDigitalOnly,
      bpaQualDate: i.bpaQualDate,
      industry: i.industry,
      otherIndustry: i.otherIndustry,
      employees: i.employees,
      ownership: i.ownership,
      ownershipSubType: i.ownershipSubType,
      revenue: i.revenue,
      winPurePrimK: i.winPurePrimK,
      ticker: i.ticker,
      primaryCompany: i.primaryCompany,
      directPhone: i.directPhone,
      altEmail: i.altEmail,
      activeStatus: i.activeStatus,
      boardCompanyName: i.boardCompanyName,
      verifiedDate: i.verifiedDate,
      memberClicksId: i.memberClicksId,
      qualificationSource: i.qualificationSource,
      bpaJobTitle: i.bpaJobTitle,
      memberStatus: i.memberStatus,
      cen: i.cen,
      sen: i.sen,
      cebi: i.cebi,
      nextLevelLeaders: i.nextLevelLeaders,
      ceO100: i.ceO100,
      cfO100: i.cfO100
    });
  }
  // tslint:disable-next-line:typedef
  construct_reviewdPeople() {

    for (const i of this.peopleToReview) {
      if (i.alternateAddressPreferred) {
        // tslint:disable-next-line:max-line-length
        if (i.alternateAddressPreferred.toString() === 'TRUE' || i.alternateAddressPreferred.toString() === 'True' || i.alternateAddressPreferred.toString() === 'true' || i.alternateAddressPreferred === 1) {
          i.alternateAddressPreferred = 1;
        } else {
          i.alternateAddressPreferred = 0;
        }
      }else{
        i.alternateAddressPreferred = 0;
      }
      switch (i.actionType) {
        case 'AddToMainDatabase':
          this.pushActionsIntoReviewedPeople(i, 'AddToMainDatabase');
          break;
        case 'MatchByID':
          this.pushActionsIntoReviewedPeople(i, 'MatchByID ');
          break;
        case 'Merge':
          this.pushActionsIntoReviewedPeople(i, 'Merge');
          break;
        case 'Edit':
          this.pushActionsIntoReviewedPeople(i, 'Edit');
          break;
        case 'Kick':
          this.pushActionsIntoReviewedPeople(i, 'Kick');
          break;
        case 'Delete': {
          this.pushActionsIntoReviewedPeople(i, 'Delete');
          break;
        }
        case 'Add': {
          i.verifiedDate = new Date().toLocaleString();
          this.pushActionsIntoReviewedPeople(i, 'AddEdit');
          break;
        }
        case 'None': {
          break;
        }
      }
    }
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.users.length; i++) {
      if (this.users[i].email === this.user){
        this.teamMemberId = this.users[i].teamMemberId;
      }
    }
    const data2 = {verifiedBy : this.teamMemberId};
    console.log('user', this.teamMemberId);
    for (let i = 0; i < this.reviewedPeople.length; i++) {
      this.reviewedPeople[i] = Object.assign(this.reviewedPeople[i], data2);
    }
    console.log('return reviewed person', this.reviewedPeople);
    return JSON.stringify(this.reviewedPeople);
  }
  // tslint:disable-next-line:typedef
  verifySelectedActions(){
    let isOk = false;
    if (this.selectedDataType === 'Company'){
      // tslint:disable-next-line:variable-name
      let select_ct = 0;
      // tslint:disable-next-line:variable-name
      let merge_ct = 0;
      // tslint:disable-next-line:variable-name
      let edit_ct = 0;
      let migrate = 0;
      for (const i of this.companiesToReview){
        if (i.actionType === 'AddToMainDatabase'){
          select_ct++;
        }
        else if (i.actionType === 'Merge'){
          merge_ct++;
        }
        else if (i.actionType === 'Edit'){
          edit_ct ++;
        }
        if (i.actionType === 'MigratePeople' && i.companyId === 0){
          migrate = 1;
          console.log('MigratePeople error');
        }

      }
      if (merge_ct > 0 && select_ct === 0){
        this.snackbar_Error('You need to have one \'Select\' for \'Merge\'');
        isOk = false;
      }
      else if (select_ct > 1){
        this.snackbar_Error('You can only have one \'Select\'');
        isOk = false;
      } else if (migrate === 1){
        this.snackbar_Error('The Company ID can not be 0');
        isOk = false;
      }
          // else if (edit_ct > 1){
          //   this.snackbar_Error('You can only have one \'Edit\'');
          //   isOk = false;
      // }
      else{
        isOk = true;
      }
    }
    else if (this.selectedDataType === 'Person'){
      // tslint:disable-next-line:variable-name
      let select_ct = 0;
      // tslint:disable-next-line:variable-name
      let merge_ct = 0;
      for (const i of this.peopleToReview){
        if (i.actionType === 'AddToMainDatabase'){
          select_ct++;
        }
        else if (i.actionType === 'Merge'){
          merge_ct++;
        }
      }
      if (merge_ct > 0 && select_ct === 0){
        this.snackbar_Error('You need to have one \'Select\' for \'Merge\'');
        isOk = false;
      }
      else if (select_ct > 1){
        this.snackbar_Error('You can only have one \'Select\'');
        isOk = false;
      }
      else{
        isOk = true;
      }
    }
    return isOk;
  }


  continueProceed(): void {
    // tslint:disable-next-line:variable-name
    this.mergeService.shared_are_componentsAlreadyMerged.subscribe(are_alreadyMerged => {
      this.are_alreadyMerged = are_alreadyMerged;
    });
    if (this.selectedDataType === 'Company'){
      if (this.are_alreadyMerged === 1){
        for (const i of this.mergedCompanies){
          if (i['source name'] === 'empty'){
            this.mergedCompanies.splice(this.mergedCompanies.indexOf(i), 1);
          }
        }
        for (const i of this.companiesToReview){
          for (const j of this.mergedCompanies){
            if (i.winPurePrimK === j.winPurePrimK){
              this.companiesToReview.splice(this.companiesToReview.indexOf(i), 1);
            }
          }
        }
        this.unsetMergedConnection();
      }
    }
    else if (this.selectedDataType === 'Person'){

      for (const i of this.reviewedPeople){
        if (i.alternateAddressPreferred) {
          // tslint:disable-next-line:max-line-length
          if (i.alternateAddressPreferred.toString() === 'TRUE' || i.alternateAddressPreferred.toString() === 'True' || i.alternateAddressPreferred.toString() === 'true' || i.alternateAddressPreferred === 1) {
            i.alternateAddressPreferred = 1;
          } else {
            i.alternateAddressPreferred = 0;
          }
        }else{
          i.alternateAddressPreferred = 0;
        }
      }


      if (this.are_alreadyMerged === 1){
        for (const i of this.mergedPeople){
          if (i['source name'] === 'empty'){
            this.mergedPeople.splice(this.mergedPeople.indexOf(i), 1);
          }
        }
        for (const i of this.peopleToReview){
          for (const j of this.mergedPeople){
            if (i.winPurePrimK === j.winPurePrimK){
              this.peopleToReview.splice(this.peopleToReview.indexOf(i), 1);
            }
          }
        }
        this.unsetMergedConnection();
      }
    }
    if (this.verifySelectedActions()){
      let jsonToSend = '{ "mergeOption": "' + this.merge_rule + '", ';
      let url = '';
      if (this.merge_rule === '') {
        this.snackbar_Error('Please select merge rules to continue!');
        // reset
        this.reviewedCompanies = [];
        this.reviewedPeople = [];
      }
      else {
        switch (this.selectedDataType) {
          case 'Company': {
            console.log('this.constructedReviewedCompanies', this.constructedReviewedCompanies);
            jsonToSend += '"companyGroup":' + this.constructedReviewedCompanies + '}';
            this.constructedReviewedCompanies = '';
            url = this.api.url_post_ResearchProcessGroup('company');
            break;
          }
          case 'Person': {
            jsonToSend += '"personGroup":' + this.constructedReviewedPeople + '}';
            this.constructedReviewedPeople = '';
            url = this.api.url_post_ResearchProcessGroup('person');
            break;
          }
        }
        const xhr = new XMLHttpRequest();
        xhr.open('POST', url);
        xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
        xhr.setRequestHeader('Content-Type', 'application/json-patch+json');
        xhr.setRequestHeader('accept', '*/*');
        // tslint:disable-next-line:only-arrow-functions typedef
        xhr.onreadystatechange = function() {
          if (xhr.readyState === 4) {
            console.log(xhr.status);
            console.log(xhr.responseText);
          }
          // tslint:disable-next-line:triple-equals
          if (xhr.status == 200){
            document.getElementById('succeeded_snack').click();
            document.getElementById('refresh').click();
          }
          else{
            document.getElementById('failed_snack').click();
          }
        };
        // @ts-ignore
        jsonToSend = jsonToSend.replaceAll('AddEdit', 'Add');
        xhr.send(jsonToSend);
      }
      this.reviewedCompanies = [];
      this.reviewedPeople = [];
    }
  }

  // tslint:disable-next-line:typedef
  Proceed() {
    if (this.selectedDataType === 'Company'){
      let ct = 0;
      for (const i of this.companiesToReview){
        if (i.actionType === 'AddToMainDatabase' || i.actionType === 'Merge'){
          ct++;
        }
      }
      this.continueProceed();
    } else if (this.selectedDataType === 'Person') {
      let ct = 0;
      for (const i of this.peopleToReview){
        if (i.actionType === 'AddToMainDatabase' || i.actionType === 'Merge'){
          ct++;
        }
        if (i.alternateAddressPreferred) {
          // tslint:disable-next-line:max-line-length
          if (i.alternateAddressPreferred.toString() === 'TRUE' || i.alternateAddressPreferred.toString() === 'True' || i.alternateAddressPreferred.toString() === 'true' || i.alternateAddressPreferred === 1) {
            i.alternateAddressPreferred = 1;
          } else {
            i.alternateAddressPreferred = 0;
          }
        }else{
          i.alternateAddressPreferred = 0;
        }
      }

      for (const i of this.reviewedPeople){
        if (i.alternateAddressPreferred) {
          // tslint:disable-next-line:max-line-length
          if (i.alternateAddressPreferred.toString() === 'TRUE' || i.alternateAddressPreferred.toString() === 'True' || i.alternateAddressPreferred.toString() === 'true' || i.alternateAddressPreferred === 1) {
            i.alternateAddressPreferred = 1;
          } else {
            i.alternateAddressPreferred = 0;
          }
        }else{
          i.alternateAddressPreferred = 0;
        }
      }

      for (const i of this.mergedPeople){
        if (i.alternateAddressPreferred) {
          // tslint:disable-next-line:max-line-length
          if (i.alternateAddressPreferred.toString() === 'TRUE' || i.alternateAddressPreferred.toString() === 'True' || i.alternateAddressPreferred.toString() === 'true' || i.alternateAddressPreferred === 1) {
            i.alternateAddressPreferred = 1;
          } else {
            i.alternateAddressPreferred = 0;
          }
        }else{
          i.alternateAddressPreferred = 0;
        }
      }
      this.continueProceed();
    }

  }
  // tslint:disable-next-line:typedef
  ManualMerge(){
    console.log('manual merge');
   // console.log('send to popup', this.peopleToReview);
    let dialogRef: any;
    // tslint:disable-next-line:variable-name
    this.mergeService.shared_was_manual_merge_accessed.subscribe( was_manual_merge_accessed => {
      this.was_manual_merge_accessed = was_manual_merge_accessed;
    });
    if (!this.was_manual_merge_accessed){
      switch (this.selectedDataType){
        case 'Company': {
          dialogRef = this.dialog.open(MergeOptionsComponent, {
            disableClose: true,
          });
          dialogRef.componentInstance.companiesToReview = this.companiesToReview;
          dialogRef.componentInstance.selectedDataType = this.selectedDataType;
          dialogRef.componentInstance.selected_group = this.selected_group;
          dialogRef.componentInstance.countries = this.countries;
          dialogRef.componentInstance.states = this.states;
          dialogRef.componentInstance.parent_page = 'Research';
          dialogRef.componentInstance.urlType = 3;
          if (this.isPublicDataCompany || this.isPublicDataCompanyMatches){
            dialogRef.componentInstance.isPublicDataCompanyMatches = true;
          }
          dialogRef.afterClosed().subscribe(result => {
            document.getElementById('succeeded_snack').click();
            document.getElementById('refresh').click();
          });
          for (const i of this.companiesToReview) {
            if (i.actionType === 'AddToMainDatabase' || i.actionType === 'Merge') {
              this.mergedCompanies.push(i);
            }
          }
          console.log('merged companies', this.mergedCompanies);
          break;
        }
        case 'Person': {
          dialogRef = this.dialog.open(MergeOptionsComponent, {
            disableClose: true,
          });
          dialogRef.componentInstance.peopleToReview = this.peopleToReview;
          dialogRef.componentInstance.selectedDataType = this.selectedDataType;
          dialogRef.componentInstance.selected_group = this.selected_group;
          dialogRef.componentInstance.countries = this.countries;
          dialogRef.componentInstance.states = this.states;
          dialogRef.componentInstance.parent_page = 'Research';
          dialogRef.componentInstance.urlType = 3;
          dialogRef.afterClosed().subscribe(result => {
            document.getElementById('succeeded_snack').click();
            document.getElementById('refresh').click();
          });
          console.log('is wordpress', this.is_Wordpress);
          if (this.is_Wordpress === true || this.is_Wordpress_non_matches){
            dialogRef.componentInstance.isPersonApiData = true;
          }
          dialogRef.afterClosed().subscribe(result => {
            document.getElementById('succeeded_snack').click();
            document.getElementById('refresh').click();
          });
          for (const i of this.peopleToReview){
            if (i.actionType === 'AddToMainDatabase' || i.actionType === 'Merge'){
              this.mergedPeople.push(i);
            }
          }
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < this.users.length; i++) {
            if (this.users[i].email === this.user){
              this.teamMemberId = this.users[i].teamMemberId;
            }
          }
          const data2 = {verifiedBy : this.teamMemberId};
          console.log('user', this.teamMemberId);
          for (let i = 0; i < this.mergedPeople.length; i++) {
            this.mergedPeople[i] = Object.assign(this.mergedPeople[i], data2);
          }
          console.log('merged', JSON.stringify(this.mergedPeople));
          break;
        }
      }
    }
    else {
      this.snackbar_Error('You can only do one manual merge / group!');
    }
  }
  // tslint:disable-next-line:typedef
  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
      this.GetAllData();
    }
  }

  // tslint:disable-next-line:typedef
  LinkTo(id: number) {
    // tslint:disable-next-line:max-line-length
    if (window.location.href === 'http://localhost:4200/#/research' || window.location.href === 'https://amplify-dev.ceo360.pro/#/research'){
      this.href = 'https://dev.ceo360.pro/#/';
    }
    if (window.location.href === 'https://amplify.ceo360.pro/#/research'){
        this.href = 'https://ceo360.pro/#/';
    }
    if (this.selectedDataType === 'Company'){
      this.href += 'Company/' + id;
      console.log(this.href);
      const win = window.open(this.href, '_blank');
      win.focus();
    }
    else if (this.selectedDataType === 'Person'){
      this.href += 'Person/' + id;
      console.log(this.href);
      const win = window.open(this.href, '_blank');
      win.focus();
    }
  }
  resetSelectedTab(): void{
    if (document.getElementById('by-group')){
      document.getElementById('by-group').style.backgroundColor = '#b8d1de';
      document.getElementById('by-group').style.color = 'black';
      document.getElementById('all-data').style.backgroundColor = '#b8d1de';
      document.getElementById('all-data').style.color = 'black';
      document.getElementById('non-matches').style.backgroundColor = '#b8d1de';
      document.getElementById('non-matches').style.color = 'black';
      if (this.selectedDataType === 'Person'){
        document.getElementById('wordpress').style.backgroundColor = '#b8d1de';
        document.getElementById('wordpress').style.color = 'black';
        if (this.is_WordpressSubType === true){
          document.getElementById('wordpress-table').style.backgroundColor = '#b8d1de';
          document.getElementById('wordpress-table').style.color = 'black';
          document.getElementById('wordpress-non-matches').style.backgroundColor = '#b8d1de';
          document.getElementById('wordpress-non-matches').style.color = 'black';
        }
      }
      if (this.selectedDataType === 'Company') {
        document.getElementById('publicDataCO').style.backgroundColor = '#b8d1de';
        document.getElementById('publicDataCO').style.color = 'black';
        if (this.isPublicDataCompany === true) {
          document.getElementById('public-companies-non-matches').style.backgroundColor = '#b8d1de';
          document.getElementById('public-companies-non-matches').style.color = 'black';
          document.getElementById('public-companies-table').style.backgroundColor = '#b8d1de';
          document.getElementById('public-companies-table').style.color = 'black';
        }
      }
    }
  }
  // tslint:disable-next-line:typedef variable-name
  ClearCompanyName(event: any){

    const personIndex = this.peopleToReview.findIndex((obj => obj.personId === event));
    this.peopleToReview[personIndex].primaryCompany = '';
  }

  researchArea(area: any): void{
    if(this.selectedDataType === 'BoardDirector'){
        this.boardDirectorComponent.afterResearchAreaSelection(area.value);
    }
    else{
      this.resetSelectedTab();
      this.isPublicDataCompany = false;
      this.isPublicDataCompanyMatches = false;
      this.isPublicDataCompanyNonMatches = false;
      if (area.value !== ''){
        this.isResearchSelected = true;
        this.researchAreaId = area.value;
        console.log('id research:',  this.researchAreaId);
        this.selected();
        this.SearchType();
      }
      else{
        this.isResearchSelected = false;
        this.selected();
        this.SearchType();
      }
    }
  }
  isPublicDataCO(): void
  {
    this.resetSelectedTab();
    this.isPublicDataCompany = true;
    if (this.isPublicDataCompany) {
      document.getElementById('publicDataCO').style.backgroundColor = '#222222';
      document.getElementById('publicDataCO').style.color = 'white';
    }
    this.is_byGroup = false;
    this.is_AllData = false;
    this.is_NonMatches = false;
    console.log('in isPublicDataCO');
    this.api.getResearchStatisticsCoApi(this.selectedDataType, this.researchAreaId.toString()).subscribe(result => {
      // @ts-ignore
      this.publicCoDataStatistics = result;
      console.log('this.publicCoDataStatistics', this.publicCoDataStatistics);
      if (this.publicCoDataStatistics.totalRecords > 0) {
        this.is_data_available = true;
      } else {
        this.is_data_available = false;
      }
    });
  }
  // tslint:disable-next-line:typedef
  Show_Data(category: string) {
    console.log('show_data');
    console.log(category);
    this.is_totalScore_clicked = false;
    this.dataSource = [];
    this.resetSelectedTab();
    switch (category) {
      case 'by-group':
        this.selected_datatype(this.selectedDataType);
        this.is_selectedGroup = false;
        this.is_search_ready = false;
        this.is_byGroup = !(this.is_byGroup);
        if (this.is_byGroup){
          document.getElementById('by-group').style.backgroundColor = '#222222';
          document.getElementById('by-group').style.color = 'white';
        } else{
          document.getElementById('by-group').style.backgroundColor = '#b8d1de';
          document.getElementById('by-group').style.color = 'black';
        }
        this.is_AllData = false;
        this.is_NonMatches = false;
        this.is_Wordpress = false;
        this.is_WordpressSubType = false;
        this.is_Wordpress_non_matches = false;
        this.isPublicDataCompany = false;
        this.isPublicDataCompanyNonMatches = false;
        this.isPublicDataCompanyMatches = false;
        this.companiesToReview = [];
        this.peopleToReview = [];
        break;
      case 'non-matches':
        this.selected_datatype(this.selectedDataType);
        this.is_search_ready = false;
        this.is_NonMatches = !(this.is_NonMatches);
        if (this.is_NonMatches){
          document.getElementById('non-matches').style.backgroundColor = '#222222';
          document.getElementById('non-matches').style.color = 'white';
        } else{
          document.getElementById('non-matches').style.backgroundColor = '#b8d1de';
          document.getElementById('non-matches').style.color = 'black';
        }
        this.is_byGroup = false;
        this.is_AllData = false;
        this.is_Wordpress = false;
        this.is_WordpressSubType = false;
        this.is_Wordpress_non_matches = false;
        this.isPublicDataCompany = false;
        this.isPublicDataCompanyNonMatches = false;
        this.isPublicDataCompanyMatches = false;
        this.GetNotMatches();
        break;
      case 'all-data':
        this.selected_datatype(this.selectedDataType);
        this.is_search_ready = false;
        this.is_AllData = !(this.is_AllData);
        if (this.is_AllData){
          document.getElementById('all-data').style.backgroundColor = '#222222';
          document.getElementById('all-data').style.color = 'white';
        } else{
          document.getElementById('all-data').style.backgroundColor = '#b8d1de';
          document.getElementById('all-data').style.color = 'black';
        }
        this.is_byGroup = false;
        this.is_NonMatches = false;
        this.is_Wordpress = false;
        this.is_WordpressSubType = false;
        this.is_Wordpress_non_matches = false;
        this.isPublicDataCompany = false;
        this.isPublicDataCompanyNonMatches = false;
        this.isPublicDataCompanyMatches = false;
        this.GetAllData();
        break;
      case 'wordpress-table':
        // tslint:disable-next-line:max-line-length
        console.log('displayed columns for wordpress');
        this.getDisplayedColumnsWordpress();
        document.getElementById('person-results').style.backgroundColor = '#222222';
        document.getElementById('person-results').style.color = 'white';
        // tslint:disable-next-line:max-line-length
        console.log('wordpress');
        this.is_search_ready = false;
        this.is_Wordpress = !(this.is_Wordpress);
        if (this.is_Wordpress){
          console.log('wordpress is selected:', this.is_Wordpress );
          document.getElementById('wordpress').style.backgroundColor = '#222222';
          document.getElementById('wordpress').style.color = 'white';
          document.getElementById('wordpress-table').style.backgroundColor = '#222222';
          document.getElementById('wordpress-table').style.color = 'white';
        } else{
          document.getElementById('wordpress').style.backgroundColor = '#b8d1de';
          document.getElementById('wordpress').style.color = 'black';
          document.getElementById('wordpress-table').style.backgroundColor = '#b8d1de';
          document.getElementById('wordpress-table').style.color = 'black';
        }
        this.is_byGroup = false;
        this.is_NonMatches = false;
        this.is_AllData = false;
        this.is_Wordpress_non_matches = false;
        this.isPublicDataCompany = false;
        this.isPublicDataCompanyNonMatches = false;
        this.isPublicDataCompanyMatches = false;
        break;
        case 'wordpress-non-matches':
          // tslint:disable-next-line:max-line-length
          console.log('displayed columns for wordpress');
          this.getDisplayedColumnsWordpress();
          document.getElementById('person-results').style.backgroundColor = '#222222';
          document.getElementById('person-results').style.color = 'white';
          this.is_search_ready = false;
          this.is_Wordpress_non_matches = !(this.is_Wordpress_non_matches);
          if (this.is_Wordpress_non_matches){
            document.getElementById('wordpress').style.backgroundColor = '#222222';
            document.getElementById('wordpress').style.color = 'white';
            document.getElementById('wordpress-non-matches').style.backgroundColor = '#222222';
            document.getElementById('wordpress-non-matches').style.color = 'white';
          } else{
            document.getElementById('wordpress').style.backgroundColor = '#b8d1de';
            document.getElementById('wordpress').style.color = 'black';
            document.getElementById('wordpress-non-matches').style.backgroundColor = '#b8d1de';
            document.getElementById('wordpress-non-matches').style.color = 'black';
          }
          this.is_byGroup = false;
          this.is_NonMatches = false;
          this.is_AllData = false;
          this.is_Wordpress = false;
          this.isPublicDataCompany = false;
          this.isPublicDataCompanyNonMatches = false;
          this.isPublicDataCompanyMatches = false;
          this.GetWordpressNonMatched();
          break;
      case 'public-companies-table':
        this.is_byGroup = false;
        this.is_NonMatches = false;
        this.is_AllData = false;
        this.is_Wordpress = false;
        this.is_Wordpress_non_matches = false;
        this.isPublicDataCompanyNonMatches = false;
        this.isPublicDataCompanyMatches = !this.isPublicDataCompanyMatches;
        if (this.isPublicDataCompanyMatches) {
          document.getElementById('publicDataCO').style.backgroundColor = '#222222';
          document.getElementById('publicDataCO').style.color = 'white';
          document.getElementById('public-companies-table').style.backgroundColor = '#222222';
          document.getElementById('public-companies-table').style.color = 'white';
        } else {
          document.getElementById('publicDataCO').style.backgroundColor = '#b8d1de';
          document.getElementById('publicDataCO').style.color = 'black';
          document.getElementById('public-companies-table').style.backgroundColor = '#b8d1de';
          document.getElementById('public-companies-table').style.color = 'black';
        }
        this.companiesToReview = [];
        this.peopleToReview = [];
        break;
      case 'public-companies-non-matches':
        this.is_byGroup = false;
        this.is_NonMatches = false;
        this.is_AllData = false;
        this.is_Wordpress = false;
        this.isPublicDataCompanyMatches = false;
        this.isPublicDataCompanyNonMatches = !this.isPublicDataCompanyNonMatches;
        if (this.isPublicDataCompanyNonMatches) {
          document.getElementById('publicDataCO').style.backgroundColor = '#222222';
          document.getElementById('publicDataCO').style.color = 'white';
          document.getElementById('public-companies-non-matches').style.backgroundColor = '#222222';
          document.getElementById('public-companies-non-matches').style.color = 'white';
        } else {
          document.getElementById('publicDataCO').style.backgroundColor = '#b8d1de';
          document.getElementById('publicDataCO').style.color = 'black';
          document.getElementById('public-companies-non-matches').style.backgroundColor = '#b8d1de';
          document.getElementById('public-companies-non-matches').style.color = 'black';
        }
        this.GetPublicCoNotMatches();
        break;
    }
  }
  getDisplayedColumnsWordpress(): void {
    // tslint:disable-next-line:max-line-length
    this.displayedColumns = ['Action', 'SourceName', 'WordpressId', 'MemberClicksId', 'Person Id', 'First Name', 'Last Name', 'Title', 'Email', 'Alt Email', 'Direct Phone', 'Primary Company', 'Primary Co ID', 'CEXSubStatus', 'CBMSubStatus', 'Shipping Add1', 'Shipping Add2', 'Shipping City', 'Shipping State', 'Shipping Zipcode', 'Shipping Country', 'Alternate Address Preferred', 'Alternate Address Type', 'CEODigitalAndPrint', 'CEODigitalOnly', 'CBMDigitalAndPrint', 'CBMDigitalOnly', 'CEN', 'SEN', 'CEO100', 'CFO100',  'NextLevelLeaders', 'CEBI', 'Revenue', 'Ownership', 'OwnershipSubType', 'Employees', 'Industry', 'OtherIndustry', 'BPAQualDate', 'BPAJobTitle', 'BPAQualSource', 'Active Status'];
  }

  // tslint:disable-next-line:typedef variable-name
  GetWordpressData(group_id: number) {
    this.showOverlay = true;
    this.is_totalScore_clicked = false;
    this.is_proceed_visible = 0;
    this.ColorSelectedGroupW(group_id);
    this.selected_group = group_id.toString();
    this.resetAllData();
    console.log('this.selectgeddata type', this.selectedDataType);
    this.api.get_ResearchGroupData(this.selected_group, this.researchAreaId.toString() , this.selectedDataType).subscribe(res => {
          if (this.is_person_search) {
            // @ts-ignore
            this.peopleToReview = res;
            this.peopleToReview.sort((a, b) => {
              if (a['source name']) {
                if (a['source name'] !== 'ImportedPerson') {
                  return 1;
                }
                if (a['source name'] === 'ImportedPerson') {
                  return -1;
                }
                return 0;
              }
            });
            this.total_searches = this.peopleToReview.length;
            this.is_search_ready = true;
            this.dataSource = new MatTableDataSource<PersonToReview>(this.peopleToReview);
            this.dataSource.paginator = this.paginator;
            this.showOverlay = false;
            console.log(this.peopleToReview);
            this.handlePeopleToReview();
          }
        },
        err => {
          console.log(err);
          this.showOverlay = false;
        });
  }
  GetPublicCoNotMatches(): void{
    this.showOverlay = true;
    this.is_totalScore_clicked = false;
    this.is_proceed_visible = 0;
    this.offset = 0;
    this.currentPage = 1;
    this.getTotalPages('nonMatchesPublicCo');
    this.resetAllData();
    this.ShowPublicCoNonMatches();
  }
  ShowPublicCoNonMatches(): void{
    this.getTotalPages('nonMatchesPublicCo');
    // tslint:disable-next-line:max-line-length
    this.api.getResearchStatisticsCoApiNotMatched(this.offset.toString(), this.fetchData.toString(), this.researchAreaId.toString()).subscribe(result => {
      // @ts-ignore
      this.companiesToReview = result;
      this.CompleteDataSourceCompany();
      this.showOverlay = false;
    });
  }
  CompleteDataSourceCompany(): void{
    this.total_searches = this.companiesToReview.length;
    this.is_search_ready = true;
    this.dataSource = new MatTableDataSource<CompanyToReview>(this.companiesToReview);
    this.dataSource.paginator = this.paginator;
    this.showOverlay = false;
    for (const i of this.companiesToReview) {
      i.actionType = 'None';
    }
  }

  // tslint:disable-next-line:typedef
  GetWordpressNonMatched(){
    console.log('getnotmatcheswordpress');
    this.getTotalPages('nonMatchesWordpress');
    this.currentPage = 1;
    this.offset = 0;
    this.showOverlay = true;
    this.is_totalScore_clicked = false;
    this.is_proceed_visible = 0;
    this.resetAllData();
    this.ShowWordpressNonMatches();

  }
  ShowWordpressNonMatches(): void{
    if (this.selectedDataType === 'Person') {
      // tslint:disable-next-line:max-line-length
      this.api.get_ResearchNotMatchedWordpress(this.selectedDataType, this.offset.toString(), this.fetchData.toString(), this.researchAreaId.toString()).subscribe(res => {
            // @ts-ignore
            this.peopleToReview = res;
            console.log('res', res);
            this.total_searches = this.peopleToReview.length;
            this.is_search_ready = true;
            this.dataSource = new MatTableDataSource<PersonToReview>(this.peopleToReview);
            this.dataSource.paginator = this.paginator;
            this.showOverlay = false;
            console.log('people groups:', this.peopleToReview);
            this.handlePeopleToReview();
            this.showOverlay = false;
          },
          err => {
            console.log(err);
            this.showOverlay = false;
          });
    }
  }
  handlePeopleToReview(): void
  {
    for (const i of this.peopleToReview) {
      i.actionType = 'None';
      if (i.cbmDigitalOnly === undefined){
        i.cbmDigitalOnly = false;
      }
      if (i.cbmDigitalAndPrint === undefined){
        i.cbmDigitalAndPrint = false;
      }
      if (i.ceoDigitalOnly === undefined){
        i.ceoDigitalOnly = false;
      }
      if (i.ceoDigitalAndPrint === undefined){
        i.ceoDigitalAndPrint = false;
      }
      if (i.cen === undefined) {
        i.cen = false;
      }
      if (i.sen === undefined) {
        i.sen = false;
      }
      if (i.ceO100 === undefined) {
        i.ceO100 = false;
      }
      if (i.cfO100 === undefined) {
        i.cfO100 = false;
      }
      if (i.nextLevelLeaders === undefined) {
        i.nextLevelLeaders = false;
      }
      if (i.cebi === undefined) {
        i.cebi = false;
      }
    }
  }  // tslint:disable-next-line:typedef
  GetNotMatches() {
    console.log('getnotmatches');
    this.showOverlay = true;
    this.is_totalScore_clicked = false;
    this.is_proceed_visible = 0;
    this.currentPage = 1;
    this.offset = 0;
    this.getTotalPages('nonMatches');
    this.resetAllData();
    this.ShowNotMatches();
  }

  ShowNotMatches(): void{
    this.getTotalPages('nonMatches');
    if (this.selectedDataType === 'Company') {
      // tslint:disable-next-line:max-line-length
      this.api.get_ResearchNotMatched(this.selectedDataType, this.offset.toString(), this.fetchData.toString(), this.researchAreaId.toString()).subscribe(res => {
            // @ts-ignore
            this.companiesToReview = res;
            this.companiesToReview.sort((a, b) => {
              if (a.companyName){
                if (a.companyName > b.companyName){
                  return 1;
                }
                if (a.companyName < b.companyName){
                  return -1;
                }
                return 0;
              }
            });
            this.CompleteDataSourceCompany();
          },
          err => {
            console.log(err);
            this.showOverlay = false;
          });
  }
    if (this.selectedDataType === 'Person') {
      // tslint:disable-next-line:max-line-length
      this.api.get_ResearchNotMatched(this.selectedDataType, this.offset.toString(), this.fetchData.toString(), this.researchAreaId.toString()).subscribe(res => {
            // @ts-ignore
            this.peopleToReview = res;
            this.total_searches = this.peopleToReview.length;
            this.is_search_ready = true;
            this.dataSource = new MatTableDataSource<PersonToReview>(this.peopleToReview);
            this.dataSource.paginator = this.paginator;
            this.showOverlay = false;
            console.log('people groups:', this.peopleToReview);
            this.handlePeopleToReview();
          },
          err => {
            console.log(err);
            this.showOverlay = false;
          });
    }
  }

  GetAllData(): void {
    this.showOverlay = true;
    this.is_totalScore_clicked = false;
    this.is_proceed_visible = 0;
    this.resetAllData();
    console.log('totalrecords', this.totalRecords);
    if (this.selectedDataType === 'Company') {
      // this.api.get_ResearchAllData(this.selectedDataType, '1', this.totalRecords.toString())
      // tslint:disable-next-line:max-line-length
      this.api.get_ResearchAllData(this.selectedDataType, '1', this.totalRecords.toString(), this.researchAreaId.toString()).subscribe(res => {
            // @ts-ignore
            this.companiesToReview = res;
            this.companiesToReview.sort((a, b) => {
              if (a['source name']) {
                if (a['source name'] !== 'ImportedCompany') {
                  return 1;
                }
                if (a['source name'] === 'ImportedCompany') {
                  return -1;
                }
                return 0;
              }
            });
            this.CompleteDataSourceCompany();
          },
          err => {
            console.log(err);
            this.showOverlay = false;
          });
    }
    if (this.selectedDataType === 'Person') {
      // tslint:disable-next-line:max-line-length
      this.api.get_ResearchAllData(this.selectedDataType, '1', this.totalRecords.toString(), this.researchAreaId.toString()).subscribe(res => {
            // @ts-ignore
            this.peopleToReview = res;
            this.peopleToReview.sort((a, b) => {
              if (a['source name']) {
                if (a['source name'] !== 'ImportedPerson') {
                  return 1;
                }
                if (a['source name'] === 'ImportedPerson') {
                  return -1;
                }
                return 0;
              }
            });
            this.total_searches = this.peopleToReview.length;
            this.is_search_ready = true;
            this.dataSource = new MatTableDataSource<PersonToReview>(this.peopleToReview);
            this.dataSource.paginator = this.paginator;
            this.showOverlay = false;
            this.handlePeopleToReview();
          },
          err => {
            console.log(err);
            this.showOverlay = false;
          });
    }
  }
  ColorGroupById(selectedDatatype: string, groupId: number): void{
    document.getElementById(selectedDatatype + '-group-' + groupId.toString()).style.backgroundColor = '#222222';
    document.getElementById(selectedDatatype + '-group-' + groupId.toString()).style.color = 'white';
    // tslint:disable-next-line:only-arrow-functions typedef
    document.getElementById(selectedDatatype + '-group-' + groupId.toString()).addEventListener('mouseover', function() {
      document.getElementById(selectedDatatype + '-group-' + groupId.toString()).style.backgroundColor = '#222222';
      document.getElementById(selectedDatatype + '-group-' + groupId.toString()).style.color = 'white';
    });
    // tslint:disable-next-line:only-arrow-functions typedef
    document.getElementById(selectedDatatype + '-group-' + groupId.toString()).addEventListener('mouseout', function() {
      document.getElementById(selectedDatatype + '-group-' + groupId.toString()).style.backgroundColor = '#222222';
      document.getElementById(selectedDatatype + '-group-' + groupId.toString()).style.color = 'white';
    });
  }
  ColorTheRestOfGroups(selectedDatatype: string, groupId: number): void{
    document.getElementById(selectedDatatype + '-group-' + groupId.toString()).style.backgroundColor = 'white';
    document.getElementById(selectedDatatype + '-group-' + groupId.toString()).style.color = 'black';
    // tslint:disable-next-line:only-arrow-functions typedef
    document.getElementById(selectedDatatype + '-group-' + groupId.toString()).addEventListener('mouseover', function() {
      document.getElementById(selectedDatatype + '-group-' + groupId.toString()).style.backgroundColor = '#222222';
      document.getElementById(selectedDatatype + '-group-' + groupId.toString()).style.color = 'white';
    });
    // tslint:disable-next-line:only-arrow-functions typedef
    document.getElementById(selectedDatatype + '-group-' + groupId.toString()).addEventListener('mouseout', function() {
      document.getElementById(selectedDatatype + '-group-' + groupId.toString()).style.backgroundColor = 'white';
      document.getElementById(selectedDatatype + '-group-' + groupId.toString()).style.color = 'black';
    });
  }
  ColorSelectedGroupW(groupId: number): void{
    // tslint:disable-next-line:variable-name
    const selected_datatype = 'person-wordpress';
    if (!this.is_long_list){
      document.getElementById(selected_datatype + '-group-1').style.backgroundColor = '#222222';
      document.getElementById(selected_datatype + '-group-1').style.color = 'white';
    }
    else{
      for (const i of this.group_list_wordpress){
        // tslint:disable-next-line:triple-equals
        if (i == groupId){
          this.ColorGroupById(selected_datatype, i);
        }
        else{
          this.ColorTheRestOfGroups(selected_datatype, i);
        }
      }
    }
  }
  ColorSelectedGroup(groupId: number): void{
    // tslint:disable-next-line:variable-name
    const selected_datatype = this.selectedDataType.toLowerCase();
    if (!this.is_long_list){
      document.getElementById(selected_datatype + '-group-1').style.backgroundColor = '#222222';
      document.getElementById(selected_datatype + '-group-1').style.color = 'white';
    }
    else{
      for (const i of this.group_list){
        // tslint:disable-next-line:triple-equals
        if (i == groupId){
          this.ColorGroupById(selected_datatype, i);
        }
        else{
          this.ColorTheRestOfGroups(selected_datatype, i);
        }
      }
    }
  }
  ColorSelectedGroupPublicCO(groupId: number): void{
    const selectedDatatype = this.selectedDataType.toLowerCase();
    if (!this.is_long_list) {
      document.getElementById(selectedDatatype + '-group-1').style.backgroundColor = '#222222';
      document.getElementById(selectedDatatype + '-group-1').style.color = 'white';
    } else {
      for (const i of this.publicCoDataStatistics.listOfGroupsIds) {
        // tslint:disable-next-line:triple-equals
        if (i == groupId) {
          this.ColorGroupById(selectedDatatype, i);
        } else {
          this.ColorTheRestOfGroups(selectedDatatype, i);
        }
      }
    }
  }
  GroupDataPublicCO(groupId: number): void{
    this.is_selectedGroup = true;
    this.setWasManualMergeAccessed(false);
    this.is_totalScore_clicked = false;
    this.showOverlay = true;
    this.is_proceed_visible = 0;
    this.selected_group = groupId.toString();
    this.ColorSelectedGroupPublicCO(groupId);
    this.resetAllData();
    this.GetGroup();
  }
  // tslint:disable-next-line:typedef variable-name
  Group_Data(group_id: number) {
    this.is_selectedGroup = true;
    this.setWasManualMergeAccessed(false);
    this.ColorSelectedGroup(group_id);
    this.is_totalScore_clicked = false;
    this.showOverlay = true;
    this.is_proceed_visible = 0;
    this.selected_group = group_id.toString();
    this.resetAllData();
    this.GetGroup();

  }
  GetGroup(): void {
    this.api.get_ResearchGroupData(this.selected_group, this.researchAreaId.toString() , this.selectedDataType).subscribe(res => {
          if (this.is_company_search) {
            // @ts-ignore
            this.companiesToReview = res;
            this.companiesToReview.sort((a, b) => {
              if (a['source name']) {
                if (a['source name'] !== 'ImportedCompany') {
                  return 1;
                }
                if (a['source name'] === 'ImportedCompany') {
                  return -1;
                }
                return 0;
              }
            });
            this.CompleteDataSourceCompany();
          }
          if (this.is_person_search) {
            // @ts-ignore
            this.peopleToReview = res;
            this.peopleToReview.sort((a, b) => {
              if (a['source name']) {
                if (a['source name'] !== 'ImportedPerson') {
                  return 1;
                }
                if (a['source name'] === 'ImportedPerson') {
                  return -1;
                }
                return 0;
              }
            });
            this.total_searches = this.peopleToReview.length;
            this.is_search_ready = true;
            this.dataSource = new MatTableDataSource<PersonToReview>(this.peopleToReview);
            this.dataSource.paginator = this.paginator;
            this.showOverlay = false;
            this.handlePeopleToReview();
          }
        },
        err => {
          console.log(err);
          this.showOverlay = false;
        });
  }
  resetSelectedDatatype(): void{
    document.getElementById('company-results').style.backgroundColor = '#005a87';
    document.getElementById('company-results').style.color = 'white';
    document.getElementById('person-results').style.backgroundColor = '#005a87';
    document.getElementById('person-results').style.color = 'white';
    document.getElementById('director-results').style.backgroundColor = '#005a87';
    document.getElementById('director-results').style.color = 'white';
  }
  // tslint:disable-next-line:typedef
  selected_datatype(datatype: string) {
    this.resetSelectedTab();
    this.dataSource = [];
    this.is_totalScore_clicked = false;
    this.is_selectedGroup = false;
    this.selectedDataType = datatype;
    this.is_data_available = false;
    // this.isResearchSelected = false;
    this.is_proceed_visible = 0;
    this.resetAllData();
    this.resetSelectedDatatype();
    switch (this.selectedDataType) {
      case 'BoardDirector':
        document.getElementById('director-results').style.backgroundColor = '#222222';
        document.getElementById('director-results').style.color = 'white';
        break;
      default:
        console.log('default');
        if (this.selectedDataType === 'Company') {
          this.displayedColumns = ['Action', 'SourceName', 'CompanyId', 'CompanyName', 'Adress1', 'Adress2', 'City', 'State', 'Zip', 'Country', 'Phone', 'Website', 'Ticker', 'CIK ID', 'SIC Code', 'Exchange', 'Annual Revenue', 'Employee Exact', 'Industry Category', 'Ownership', 'Ownership Sub Type', 'IPO Date', 'Verified Date', 'How Verified', 'Active Status', 'AddressSource'];
          document.getElementById('company-results').style.backgroundColor = '#222222';
          document.getElementById('company-results').style.color = 'white';
        }
        if (this.selectedDataType === 'Person') {
          this.matchingByID = false;
          // tslint:disable-next-line:max-line-length
          this.displayedColumns = [ 'Action', 'SourceName', 'Person Id', 'First Name', 'Middle Name', 'Last Name', 'Suffix', 'Title', 'Email', 'AltEmail', 'Direct Phone',
            'Mobile Phone', 'Primary Company', 'Primary Co ID', 'Board Company Associations', 'Ticker', 'Shipping Add1', 'Shipping Add2', 'Shipping City', 'Shipping State',  'Shipping Zipcode', 'Shipping Country', 'Alternate Address Source', 'Alternate Address Preferred', 'CEODigitalAndPrint', 'CEODigitalOnly', 'CBMDigitalAndPrint', 'CBMDigitalOnly', 'CEN', 'SEN', 'CEO100', 'CFO100',  'NextLevelLeaders', 'CEBI', 'Alternate Address Type', 'BPAQualDate', 'BPAJobTitle', 'BPAQualSource', 'Verified Date', 'Active Status'];
          document.getElementById('person-results').style.backgroundColor = '#222222';
          document.getElementById('person-results').style.color = 'white';
        }
        this.SearchType();
        this.selected();
        break;
    }
  }

  // tslint:disable-next-line:typedef
  selected() {
    if (this.researchAreaId !== undefined){
      // tslint:disable-next-line:variable-name
      this.api.get_ResearchStatistics(this.selectedDataType, this.researchAreaId.toString()).subscribe(field_result => {
            // @ts-ignore
            this.statistics = field_result;
            if (this.statistics.totalRecords > 0) {
              this.is_data_available = true;
            }
            else {
              this.is_data_available = false;
            }
            // console.log('statistics:', this.statistics);
            this.matchGroups = this.statistics.totalMatchGroups;
            this.notMatched = this.statistics.totalNotMatchedRecords;
            console.log('this.notMatched', this.notMatched);
            this.totalRecords = this.statistics.totalRecords;
            this.group_list_elements = this.statistics.listOfGroupsIds;
            if (this.is_NonMatches){
              this.getTotalPages('nonMatches');
            }
            this.GroupList(this.matchGroups);
          },
          err => {
            console.log(err);
            this.group_list_length = 0;
            this.is_data_available = false;
          });
      // tslint:disable-next-line:variable-name
      if (this.selectedDataType === 'Person') {
        // tslint:disable-next-line:variable-name
        this.api.get_ResearchStatisticsWordpress(this.selectedDataType, this.researchAreaId.toString()).subscribe(field_result => {
              // @ts-ignore
              this.statisticsWordpress = field_result;
              if (this.statisticsWordpress !== null){
                // tslint:disable-next-line:radix
                this.isApiDataMatchGroups = parseInt(this.statisticsWordpress.totalWordpressMatchGroups) > 0 ? true : false;
                // tslint:disable-next-line:radix
                this.isApiDataNonMatchRecords = parseInt(this.statisticsWordpress.totalWordpressNotMatchedRecords) > 0 ? true : false;
                this.totalRecordsWordpress = this.statisticsWordpress.totalWordpressRecords;
                this.matchGroupsWordpress = this.statisticsWordpress.totalWordpressMatchGroups;
                this.notMatchedWordpress = this.statisticsWordpress.totalWordpressNotMatchedRecords;
                this.group_list_elements_wordpress = this.statisticsWordpress.listOfWordpressGroupsIds;
                this.GroupListWordpress(this.matchGroupsWordpress);
                if (this.is_NonMatches){
                  this.getTotalPages('nonMatchesWordpress');
                }
              }
            },
            err => {
              console.log(err);
            });
      }
    }
  }

  // tslint:disable-next-line:typedef variable-name
  GroupList(group_count: string) {
    this.group_list = this.group_list_elements;
    this.group_list_length = this.group_list.length;
  }
  // tslint:disable-next-line:typedef variable-name
  GroupListWordpress(group_count: string) {
    this.group_list_wordpress = this.group_list_elements_wordpress;
    this.group_list_length_wordpress = this.group_list_wordpress?.length;
  }
  // tslint:disable-next-line:typedef
  SearchType() {
    if (this.selectedDataType === 'Company') {
      this.is_company_search = true;
      this.is_person_search = false;
      this.is_engagement_search = false;
      this.group_list = [];
      this.group_list_wordpress = [];
      this.is_byGroup = false;
      this.is_AllData = false;
      this.is_NonMatches = false;
      this.is_Wordpress = false;
      this.is_Wordpress_non_matches = false;
    }
    if (this.selectedDataType === 'Person') {
      this.is_company_search = false;
      this.is_person_search = true;
      this.is_engagement_search = false;
      this.group_list = [];
      this.group_list_wordpress = [];
      this.is_byGroup = false;
      this.is_AllData = false;
      this.is_NonMatches = false;
      this.is_Wordpress = false;
      this.is_Wordpress_non_matches = false;
    }
  }

  // tslint:disable-next-line:typedef
  show_totalscore() {
    this.is_totalScore_clicked = !this.is_totalScore_clicked;
  }
// tslint:disable-next-line:typedef
  resetAllData() {
    this.bulkaction = 'None';
    this.is_proceed_visible = 0;
    this.merge_rule = 'UpdateEmptyField';
    this.companiesToReview = [];
    this.reviewedCompanies = [];
    this.peopleToReview = [];
    this.reviewedPeople = [];
  }
  // tslint:disable-next-line:typedef
  unsetMergedConnection(){
    this.mergeService.update_alreadyMerged(0);
  }

  // tslint:disable-next-line:typedef
  setWasManualMergeAccessed(state: boolean){
    this.mergeService.update_manualMergeAccessed(state);
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.showOverlay = true;
    }
    if (event instanceof NavigationEnd) {
      this.showOverlay = false;
    }
    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.showOverlay = false;
    }
    if (event instanceof NavigationError) {
      this.showOverlay = false;
    }
  }
  GetTimeStamp(): void{
    // tslint:disable-next-line:new-parens
    const date = new Date;
    this.time_last_job_started = localStorage.getItem('date');
  }
  MatchByID(): void{
    let  jsonToSend = '';
    let  groupId = '';
    if ( this.selectedDataType === 'Company'){
      this.constructedReviewedCompanies = this.construct_reviewedCompanies();
      jsonToSend = '{"company":' + this.constructedReviewedCompanies + '}';
      groupId = this.reviewedCompanies[0]['group ID'].toString();
    }
    else {
      this.constructedReviewedPeople = this.construct_reviewdPeople();
      jsonToSend = '{"person":' + this.constructedReviewedPeople + '}';
      groupId = this.reviewedPeople[0].groupId.toString();
    }
    let url = '';
    if (this.is_byGroup || this.is_Wordpress){
      url = this.api.url_post_QuarantineMatchByID(this.selectedDataType, this.selected_group, 'Research', this.researchAreaId.toString());
    }
    else{
      url = this.api.url_post_QuarantineMatchByID(this.selectedDataType, groupId, 'Research', this.researchAreaId.toString());
    }
    this.showOverlay = true;
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
    xhr.setRequestHeader('Content-Type', 'application/json-patch+json');
    xhr.setRequestHeader('accept', '*/*');
    if (this.is_byGroup || this.is_Wordpress || this.isPublicDataCompanyMatches){
      // tslint:disable-next-line:only-arrow-functions typedef
      xhr.onreadystatechange = function() {
        if (xhr.readyState === 4) {
          console.log(xhr.status);
          console.log(xhr.responseText);
        }
        // tslint:disable-next-line:triple-equals
        if (xhr.status == 200) {
          document.getElementById('succeeded_snack').click();
          document.getElementById('refresh').click();
        } else {
          document.getElementById('failed_snack').click();
        }
      };
    }
    else{
      this.selected_group = groupId;
      // tslint:disable-next-line:only-arrow-functions typedef
      xhr.onreadystatechange = function() {
        if (xhr.readyState === 4) {
          console.log(xhr.status);
          console.log(xhr.responseText);
        }
        // tslint:disable-next-line:triple-equals
        if (xhr.status == 200) {
          document.getElementById('succeeded_snack').click();
          document.getElementById('refreshAfterMatchById').click();
        } else {
          document.getElementById('failed_snack').click();
        }
      };
    }
    // @ts-ignore
    // console.log('sent', jsonToSend);
    xhr.send(jsonToSend);
  }
  // tslint:disable-next-line:typedef
  async RefreshAfterMatchById() {
    if (this.is_Wordpress_non_matches){
      this.isWordpress();
      this.Show_Data('wordpress-table');
      this.ShowAllGroups();
      setTimeout(() => {
        this.is_Wordpress = true;
        this.is_Wordpress_non_matches = false;
        // tslint:disable-next-line:radix
        this.GetWordpressData(parseInt(this.selected_group));

      }, 700);
    }
    if (this.is_NonMatches){
      this.Show_Data('by-group');
      this.ShowAllGroups();
      console.log('this.selected_group', this.selected_group);
      setTimeout(() => {
        // tslint:disable-next-line:radix
        this.Group_Data(parseInt(this.selected_group));
      }, 700);
    }
    if (this.isPublicDataCompanyNonMatches){
      this.isPublicDataCO();
      this.Show_Data('public-companies-table');
      this.ShowAllGroups();
      setTimeout(() => {
        this.isPublicDataCompanyNonMatches = false;
        this.isPublicDataCompanyMatches = true;
        // tslint:disable-next-line:radix
        this.GroupDataPublicCO(parseInt(this.selected_group));
      }, 700);
    }
  }
  // tslint:disable-next-line:typedef
  showRunningJobs(){
    this.GetTimeStamp();
    this.api.get_Jobs().subscribe(res => {
          // @ts-ignore
          this.jobs = res;
          for (const i of this.jobs){
            if (i.type === 'CegIntegration.Web.Jobs.MainDataSynchronizationJob'){
              this.jobs.splice(this.jobs.indexOf(i), 1);
            }
          }
          this.are_jobsVisible = !(this.are_jobsVisible);
          if (this.are_jobsVisible){
            const img = document.getElementById('research-running-jobs');
            img.style.transform = 'rotate(180deg)';
          }
          else{
            const img = document.getElementById('research-running-jobs');
            img.style.transform = 'rotate(0deg)';
          }
        },
        err => {
          console.log(err);
        });
  }
  deleteJob(jobKey: any): void{
    const dialogRef = this.dialog.open(WarningComponent);
    dialogRef.componentInstance.action = 'DeleteJob';

    dialogRef.afterClosed().subscribe(result => {
      console.log(`popup result: ${result}`);
      const response = result;
      if (response === 'yes'){
        this.ProceedDeleteJob(jobKey);
      }
    });
  }
  ProceedDeleteJob(jobKey: any): void{
    const url = this.api.delete_Job(jobKey.toString());

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
    xhr.setRequestHeader('Content-Type', 'application/json-patch+json');
    xhr.setRequestHeader('accept', '*/*');

    // tslint:disable-next-line:only-arrow-functions typedef
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        // tslint:disable-next-line:triple-equals
        if (xhr.status == 200){
          console.log('ok');
          document.getElementById('succeeded_snack').click();
          document.getElementById('refreshJobs').click();
        }
        else{
          document.getElementById('failed_snack').click();
        }
      }
    };
    xhr.send();
  }
  refreshJobs(): void{
    this.api.get_Jobs().subscribe(res => {
          // @ts-ignore
          this.jobs = res;
          for (const i of this.jobs){
            if (i.type === 'CegIntegration.Web.Jobs.MainDataSynchronizationJob'){
              this.jobs.splice(this.jobs.indexOf(i), 1);
            }
          }
        },
        err => {
          console.log(err);
        });
  }
  ApplyActionToAll(datatype: string, event: any): void{
    this.matchingByID = false;
    this.are_actionButtons_visible++;
    this.bulkaction = event.value;
    const value = event.value;
    if (this.are_alreadyMerged === 1){
      if (datatype === 'company'){
        for (const i of this.companiesToReview){
          for (const j of this.mergedCompanies){
            if (i.winPurePrimK === j.winPurePrimK){
              i.actionType = 'None';
              if (this.is_AllData){
                document.getElementById('alldata-company-select-' + i.winPurePrimK).style.display = 'none';
              }else if (this.byGroup) {
                document.getElementById('bygroup-company-select-' + i.winPurePrimK).style.display = 'none';
              }
              else if (this.notMatched) {
                document.getElementById('nonmatches-company-select-' + i.winPurePrimK).style.display = 'none';
              }
            }
            else{
              i.actionType = value;
            }
          }
        }
      }
      else if (datatype === 'person'){
        for (const i of this.peopleToReview){
          for (const j of this.mergedPeople){
            if (i.winPurePrimK === j.winPurePrimK){
              i.actionType = 'None';
              if (this.is_AllData){
                document.getElementById('alldata-person-select-' + i.winPurePrimK).style.display = 'none';
              }else if (this.byGroup) {
                document.getElementById('bygroup-person-select-' + i.winPurePrimK).style.display = 'none';
              }
              else if (this.notMatched) {
                document.getElementById('nonmatches-person-select-' + i.winPurePrimK).style.display = 'none';
              }else if (this.is_Wordpress) {
                document.getElementById('wordpress-person-select-' + i.winPurePrimK).style.display = 'none';
              }else if (this.is_Wordpress_non_matches) {
                document.getElementById('wordpress-non-matches-person-select-' + i.winPurePrimK).style.display = 'none';
              }
            }
            else{
              i.actionType = value;
            }
          }
        }
      }
    }
    else{
      if (datatype === 'company'){
        for (const i of this.companiesToReview){
          i.actionType = value;
        }
      }
      else if (datatype === 'person'){
        for (const i of this.peopleToReview){
          i.actionType = value;
        }
      }
    }
  }

  verifyActiveStatus(element: any): void{
    this.not_found_activeStatus = '';
    if (!this.active_status.includes(element.activeStatus)){
      this.not_found_activeStatus = element.activeStatus;
    }
  }
  verifyState(element: any): void{
    this.not_found_state = '';
    if (!this.states.includes(element.state)){
      this.not_found_state = element.state;
    }
  }
  verifyPrimaryState(element: any): void{
    this.not_found_primaryState = '';
    if (!this.states.some(e => e.stateName === element.primaryState)){
      this.not_found_primaryState = element.primaryState;
    }
  }
  verifyCountry(element: any): void{
    this.not_found_country = '';
    if (!(this.countries.some(e => e.countryName === element.country))) {
      this.not_found_country = element.country;
    }
  }
  // tslint:disable-next-line:typedef
  show_users(){
    this.api.get_Users().subscribe(res => {
          // @ts-ignore
          this.users = res;
          console.log('users', this.users);
        },
        err => {
          console.log(err);
        });
  }
  getUserReasearcAreas(): void{
    this.api.get_User_ResearchAreas(this.userEmail).subscribe(res => {
          // @ts-ignore
          this.research = res;
          console.log('research', this.research);
        },
        err => {
          console.log(err);
        });
    // setTimeout(() => {
    //   this.getUserResearch();
    // }, 100);
  }
  goToFirstPage(tab: string): void {
    this.currentPage = 1;
    this.resetOffset(tab);
  }
  goToLastPage(tab: string): void {
    this.currentPage = this.totalPages;
    this.resetOffset(tab);
  }
  nextPage(tab: string): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage ++;
      this.resetOffset(tab);
    }
  }
  previousPage(tab: string): void {
    if (this.currentPage > 1) {
      // @ts-ignore
      this.currentPage--;
      this.resetOffset(tab);
    }
  }
  resetOffset(tab: string): void {
    this.offset = ((this.currentPage - 1) * this.fetchData);
    this.showOverlay = true;
    console.log(this.showOverlay);
    // console.log('offset', this.offset);
    switch (tab){
      case 'nonMatches':
        this.ShowNotMatches();
        break;
      case 'nonMatchesWordpress':
        this.ShowWordpressNonMatches();
        break;
      case 'nonMatchesPublicCo':
        this.ShowPublicCoNonMatches();
        break;
    }
  }
  // tslint:disable-next-line:typedef
  getTotalPages(tab: string) {
    switch (tab){
      case  'nonMatches':
        // tslint:disable-next-line:radix
        if ( parseInt(this.notMatched) % this.fetchData) {
          // tslint:disable-next-line:radix
          this.totalPages = Math.floor(parseInt(this.notMatched) / this.fetchData) + 1;
        } else {
          // tslint:disable-next-line:radix
          this.totalPages = Math.floor(parseInt(this.notMatched) / this.fetchData);
        }
        break;
      case 'nonMatchesWordpress':
        // tslint:disable-next-line:radix
        if ( parseInt(this.notMatchedWordpress) % this.fetchData) {
          // tslint:disable-next-line:radix
          this.totalPages = Math.floor(parseInt(this.notMatchedWordpress) / this.fetchData) + 1;
        } else {
          // tslint:disable-next-line:radix
          this.totalPages = Math.floor(parseInt(this.notMatchedWordpress) / this.fetchData);
        }
        break;
      case 'nonMatchesPublicCo':
        if ( this.publicCoDataStatistics.totalNotMatchedRecords % this.fetchData) {
          // tslint:disable-next-line:radix
          this.totalPages = Math.floor(this.publicCoDataStatistics.totalNotMatchedRecords / this.fetchData) + 1;
        } else {
          // tslint:disable-next-line:radix
          this.totalPages = Math.floor(this.publicCoDataStatistics.totalNotMatchedRecords / this.fetchData);
        }
    }
  }
  ngOnInit(): void {
    this.api.getAuthToken().subscribe(auth => {
      this.token = auth;
    });
    this.auth.user$.subscribe(
        (profile) => {
          (this.profileJson = JSON.parse(JSON.stringify(profile, null, 2)));
          this.user = profile.name;
          this.userRole = profile['https://schemas.quickstarts.com/roles'][0];
          // this.userEmail = 'kjalbert@chiefexecutivegroup.com';
          this.userEmail = profile.email;
          console.log('user role', this.userRole);
          if (this.userRole === 'admin'){
            this.isAdmin = true;
            console.log('is admin');
          }
          else{
            this.isAdmin = false;
            console.log('is not admin');
          }
        });
    this.api.getSourceData_SimpleSearch().subscribe(res => {
          // @ts-ignore
          this.datatypes = res;
        },
        err => {
          console.log(err);
        });
    this.api.get_Countries().subscribe(res => {
          // @ts-ignore
          this.countries = res;
          const index = this.countries.findIndex(({countryName}) => countryName === 'United States');
          this.countries.unshift(this.us_country);
          this.countries.splice(index, 1);
        },
        err => {
          console.log(err);
        });
    this.api.get_States().subscribe(res => {
          // @ts-ignore
          this.states = res;
        },
        err => {
          console.log(err);
        });
    this.matchingByID = false;
    this.show_users();
    setTimeout(() => {
      this.getUserReasearcAreas();
    }, 100);
  }
}

