import {Component, ElementRef, OnInit} from '@angular/core';
import {SelectedFile} from '../shared/SelectedFile';
import * as XLSX from 'xlsx';
import {ConfigService} from '../config/config.service';
import {Fields} from '../shared/Fields';
import {MatDialog} from '@angular/material/dialog';
import {FieldMap} from '../shared/FieldMap';
import {MatchGroups} from '../shared/MatchGroups';
import {SavedConfig} from '../shared/SavedConfig';
import {count} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-new-config',
  templateUrl: './new-config.component.html',
  styleUrls: ['./new-config.component.scss']
})
export class NewConfigComponent implements OnInit {

  constructor( private api: ConfigService,  public dialog: MatDialog, private snackBar: MatSnackBar, myElement: ElementRef) { }
  // tslint:disable-next-line:variable-name
  is_config_selected = false;
  obj: SelectedFile;
  arrayBuffer: any;
  // tslint:disable-next-line:variable-name
  selected_file;
  // tslint:disable-next-line:variable-name
  json_elem;
  data: [][];
  // tslint:disable-next-line:variable-name
  is_importfields_selected = false;
  // tslint:disable-next-line:variable-name
  is_preview_selected = false;
  // tslint:disable-next-line:variable-name
  company_fields: Fields[];
  // tslint:disable-next-line:variable-name
  person_fields: Fields[];
  // tslint:disable-next-line:variable-name
  engagement_fields: Fields[];
  fileName;
  // tslint:disable-next-line:variable-name
  nr_sheets = 0;
  // tslint:disable-next-line:variable-name
  all_file_fileds: string[] = [];
  // tslint:disable-next-line:variable-name
  company_length: number;
  // tslint:disable-next-line:variable-name
  person_length;
  // tslint:disable-next-line:variable-name
  engagement_legth;
  // tslint:disable-next-line:variable-name
  new_fields: string[] = [];
  // tslint:disable-next-line:variable-name
  selected_newField: string;
  // tslint:disable-next-line:variable-name
  field_to_be_removed: string;
  // tslint:disable-next-line:variable-name
  is_remove_field = false;
  // tslint:disable-next-line:variable-name
  new_companyFields = [{sourceField: 'empty', externalField: ''}];
  // tslint:disable-next-line:variable-name
  new_personFields = [{sourceField: 'empty', externalField: ''}];
  // tslint:disable-next-line:variable-name
  new_engagementFields = [{sourceField: 'empty', externalField: ''}];
  // tslint:disable-next-line:variable-name
  table_header;
  // tslint:disable-next-line:variable-name
  matchGroup = {
      ExternalFileMap: {
        Company: [
          {
            SourceColumn: 'empty',
            ExternalColumn: '',
            DataType: 'System.String'
          }
        ],
        Person: [
          {
            SourceColumn: 'empty',
            ExternalColumn: '',
            DataType: 'System.String'
          }
        ],
        Engagement: [
          {
            SourceColumn: 'empty',
            ExternalColumn: '',
            DataType: 'System.String'
          }
        ]
      }
    };
  token: string;
  // tslint:disable-next-line:variable-name
  public config_name: string;
  // tslint:disable-next-line:variable-name
  changed_config_name  = '';
  // tslint:disable-next-line:variable-name
  saved_configs: SavedConfig[];
  // tslint:disable-next-line:variable-name
  next_config_id = 1;
  // tslint:disable-next-line:variable-name
  is_saveas_clicked = false;
  // tslint:disable-next-line:variable-name
  is_saved = false;
  public showOverlay = false;
   // @ts-ignore
  renamedFieldCompany = [
    'AnnualRevenue',
    'NumberOfEmployees',
    'OwnershipTypeDesc',
    'OwnershipSubType',
    'BusinessIndustry',
    'HowVerified',
    'SICCode',
    'CikId'
  ];
  renamedFieldPerson = [
    'CompanyId',
    'PrimaryAddress1',
    'PrimaryAddress2',
    'PrimaryCity',
    'PrimaryState',
    'PrimaryZip',
    'PrimaryCountry',
    'AlternateAddressSource',
    'AlternateAddressPreferred',
    'AlternateAddressType',
    'QualificationSource',
    'BPAJobTitle',
    'OtherIndustry',
    'OwnershipSubType',
    'HowVerified'
  ];
  Refresh(): void{
    window.location.reload();
  }
  gotoElement(): void {
    const el = document.getElementById('myForm');
    el.scrollTop = el.scrollHeight;
  }
  // tslint:disable-next-line:typedef
  snackbar_SucceededPost() {
    const sb = this.snackBar.open('Your configuration has successfully been saved!', '', {
      duration: 1000,
      panelClass: ['succeed'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
  // tslint:disable-next-line:typedef
  snackbar_FailedPost() {
    const sb = this.snackBar.open('Your configuration has NOT been saved!', '', {
      duration: 1000,
      panelClass: ['failed'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
  // tslint:disable-next-line:typedef
  snackbar_Error(errorMessage: string) {
    const sb = this.snackBar.open(errorMessage, '', {
      duration: 1000,
      panelClass: ['failed'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
  // tslint:disable-next-line:typedef
  Import() {
    this.is_importfields_selected = false;
    document.getElementById('input-file').addEventListener('change', (event) => {
      // @ts-ignore
      this.selected_file = event.target.files[0];
      console.log('this sel file', this.selected_file);
      // @ts-ignore
      this.fileName = event.target.files[0].name;
      this.importFile(event);
    });
  }
  // tslint:disable-next-line:typedef
  importFile(event: any){
    if (this.selected_file){
      const fileReader = new FileReader();
      fileReader.readAsBinaryString(this.selected_file);
      // tslint:disable-next-line:no-shadowed-variable
      fileReader.onload = (event) => {
        const bstr = event.target.result;
        const wb = XLSX.read(bstr, {type: 'binary'});
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        this.data = (XLSX.utils.sheet_to_json(ws, {header: 1}));
        this.table_header = this.data[0];
        this.data.shift();
        this.getFieldsFromFile();
        this.is_config_selected = true;
      };
    }
    this.getFields();
  }
  // tslint:disable-next-line:typedef
  getFieldsFromFile(){
    this.all_file_fileds = this.table_header;
    // this.all_file_fileds.shift();
    this.getDifferentFields();
  }
  // tslint:disable-next-line:typedef
  getDifferentFields() {
    // tslint:disable-next-line:variable-name
    let results_string = '';
    let results = this.company_fields.concat(this.person_fields);
    results = results.concat(this.engagement_fields);
    for (const i of results) {
      results_string += i.name + ',';
    }
    // tslint:disable-next-line:variable-name
    let results_array = [];
    results_array = results_string.split(',');
    for (const i of this.all_file_fileds){
      for (const j of results_array){
        if (!j.includes(i)){
          const already = i;
          if (!this.new_fields.includes(already)){
            this.new_fields.push(already);
          }
        }
      }
    }
  }
  // tslint:disable-next-line:typedef
  Preview(){
    this.is_importfields_selected = false;
    this.is_preview_selected = true;
    this.is_remove_field = false;
    console.log('this.new_companyFields', this.new_companyFields);
  }
  // tslint:disable-next-line:typedef
  ImportFields(){
    this.is_importfields_selected = true;
    this.is_preview_selected = false;
    this.is_remove_field = false;
  }
  // tslint:disable-next-line:typedef
  getFields(){
    // tslint:disable-next-line:variable-name
    this.api.getFields_SimpleSearch('Company').subscribe(field_result => {
        // @ts-ignore
        this.company_fields = field_result;
        console.log('company fields', field_result);
        this.company_length = this.company_fields.length;
      },
      err => {
        console.log(err);
      });
    // tslint:disable-next-line:variable-name
    this.api.getFields_SimpleSearch('Person').subscribe(field_result => {
        // @ts-ignore
        this.person_fields = field_result;
        console.log('person fields', field_result);
        this.person_length = this.person_fields.length;
      },
      err => {
        console.log(err);
      });
    // tslint:disable-next-line:variable-name
    this.api.getFields_SimpleSearch('Engagement').subscribe(field_result => {
        // @ts-ignore
        this.engagement_fields = field_result;
        console.log('engagement fields', field_result);
        this.engagement_legth = this.engagement_fields.length;
      },
      err => {
        console.log(err);
      });
    this.getNewFields();
  }
  // tslint:disable-next-line:typedef
  getNewFields(){
    const unwantedListCompany = [
        'CompanyType',
      'LastUpdated',
      'NAISCCode',
      'H_D_U_N_S_Number',
      'H_Tradestyle',
      'H_EntityType',
      'H_IsHeadquarters',
      'H_BusinessDescription',
      'H_ParentCompany',
      'H_ParentCountry_Region',
      'H_UltimateParentCompany',
      'H_UltimateParentCountryRegion',
      'H_ImportDate'
    ];
    const unwantedListPerson = [
      'Prefix',
      'LastUpdated',
    ];
    let foundInUnwanted: number;
    for (let i = 0; i < this.company_length; i++){
      foundInUnwanted = 0;
      for (const j of unwantedListCompany){
        if (this.company_fields[i].name === j){
          foundInUnwanted ++;
        }
      }
      if (foundInUnwanted === 0){
        this.new_companyFields.push({
          externalField: '',
          sourceField: this.company_fields[i].name
        });
      }
    }
    for (let i = 0; i < this.person_length; i++){
      foundInUnwanted = 0;
      for (const j of unwantedListPerson){
        if (this.person_fields[i].name === j){
          foundInUnwanted ++;
        }
      }
      if (foundInUnwanted === 0){
        this.new_personFields.push({
          externalField: '',
          sourceField: this.person_fields[i].name
        });
      }
    }
    for (let i = 0; i < this.engagement_legth; i++){
      this.new_engagementFields.push({
        externalField: '',
        sourceField: this.engagement_fields[i].name
      });
    }
    for (const i of this.new_companyFields){
      if (i.sourceField === 'empty'){
        this.new_companyFields.splice(this.new_companyFields.indexOf(i), 1);
      }
    }
    for (const i of this.new_personFields){
      if (i.sourceField === 'empty'){
        this.new_personFields.splice(this.new_personFields.indexOf(i), 1);
      }
    }
    for (const i of this.new_engagementFields){
      if (i.sourceField === 'empty'){
        this.new_engagementFields.splice(this.new_engagementFields.indexOf(i), 1);
      }
    }
  }
  // tslint:disable-next-line:typedef
  selectedNewField(field: string) {
    const id = 'new_field-' + field;
    const prim = document.getElementById(id);
    if (this.selected_newField){
      console.log('just one!');
      if (prim.style.backgroundColor === 'rgb(184, 209, 222)') {
        prim.style.backgroundColor = '#ffffff';
        this.selected_newField = '';
      }
    }
    else{
      prim.style.backgroundColor = '#b8d1de';
      this.selected_newField = field;
    }
  }
  // tslint:disable-next-line:typedef
  addAliasToField(datatype: string, field: string){
    console.log(this.selected_newField);
    if (this.selected_newField !== ''){
      if (datatype === 'Company'){
        for (const i of this.new_companyFields){
          if (field === i.sourceField){
            this.addToNewFields(datatype, i.externalField);
            i.externalField = this.selected_newField;
            this.new_fields.splice(this.new_fields.indexOf(this.selected_newField), 1);
            this.selected_newField = '';
          }
        }
      }else if (datatype === 'Person'){
        for (const i of this.new_personFields){
          if (field === i.sourceField){
            this.addToNewFields(datatype, i.externalField);
            i.externalField = this.selected_newField;
            this.new_fields.splice(this.new_fields.indexOf(this.selected_newField), 1);
            this.selected_newField = '';
          }
        }
      }else if (datatype === 'Engagement'){
        for (const i of this.new_engagementFields){
          if (field === i.sourceField){
            this.addToNewFields(datatype, i.externalField);
            i.externalField = this.selected_newField;
            this.new_fields.splice(this.new_fields.indexOf(this.selected_newField), 1);
            this.selected_newField = '';
          }
        }
      }
    }
  }
  // tslint:disable-next-line:typedef
  addToNewFields(datatype: string, field: string){
    if (datatype === 'Company'){
      for (const i of this.new_companyFields){
        if (field === i.externalField && i.externalField !== ''){
          this.new_fields.push(i.externalField);
          i.externalField = '';
        }
      }
    }else if (datatype === 'Person'){
      for (const i of this.new_personFields){
        if (field === i.externalField && i.externalField !== ''){
          this.new_fields.push(i.externalField);
          i.externalField = '';
        }
      }
    }else if (datatype === 'Engagement'){
      for (const i of this.new_engagementFields){
        if (field === i.externalField && i.externalField !== ''){
          this.new_fields.push(i.externalField);
          i.externalField = '';
        }
      }
    }
    console.log('new fields', this.new_fields);
  }
  // tslint:disable-next-line:typedef
  RemoveField(){
    this.is_preview_selected = false;
    this.is_importfields_selected = false;
    this.is_remove_field = true;
    // this.is_remove_config = false;
    // this.getFields();
    // this.getNewFields();
  }
  // tslint:disable-next-line:typedef
  RemoveSpecificField(datatype: string, field: string){
    if (datatype === 'Company'){
      for (const i of this.new_companyFields){
        if (field === i.externalField){
          this.new_companyFields.splice(this.new_companyFields.indexOf(i), 1);
        }
      }
    }
    if (datatype === 'Person'){
      for (const i of this.new_personFields){
        if (field === i.externalField){
          this.new_personFields.splice(this.new_personFields.indexOf(i), 1);
        }
      }
    }
    if (datatype === 'Engagement'){
      for (const i of this.new_engagementFields){
        if (field === i.externalField){
          this.new_engagementFields.splice(this.new_engagementFields.indexOf(i), 1);
        }
      }
    }
    if (datatype === 'NewFields'){
      for (const i of this.new_fields){
        if (field === i){
          this.new_fields.splice(this.new_fields.indexOf(i), 1);
        }
      }
    }
  }
  // tslint:disable-next-line:typedef
  SaveConfig(){
    this.ConstructConfig();
    this.PostConfig();
  }
  // tslint:disable-next-line:typedef
  ConstructConfig(){
    console.log('?', this.new_companyFields);
    for (const i of this.new_companyFields){
      if (i.externalField !== ''){
        this.matchGroup.ExternalFileMap.Company.push({
          DataType: 'System.String',
          ExternalColumn:  i.externalField,
          SourceColumn: i.sourceField
        });
      }
    }
    for (const i of this.new_personFields){
      if (i.externalField !== ''){
        this.matchGroup.ExternalFileMap.Person.push({
          DataType: 'System.String',
          ExternalColumn:  i.externalField,
          SourceColumn: i.sourceField
        });
      }
    }
    for (const i of this.new_engagementFields){
      if (i.externalField !== ''){
        this.matchGroup.ExternalFileMap.Engagement.push({
          DataType: 'System.String',
          ExternalColumn:  i.externalField,
          SourceColumn: i.sourceField
        });
      }
    }
    for (const i of this.matchGroup.ExternalFileMap.Company){
      if (i.SourceColumn === 'empty'){
        this.matchGroup.ExternalFileMap.Company.splice(this.matchGroup.ExternalFileMap.Company.indexOf(i), 1);
      }
    }
    for (const i of this.matchGroup.ExternalFileMap.Person){
      if (i.SourceColumn === 'empty'){
        this.matchGroup.ExternalFileMap.Person.splice(this.matchGroup.ExternalFileMap.Person.indexOf(i), 1);
      }
    }
    for (const i of this.matchGroup.ExternalFileMap.Engagement){
      if (i.SourceColumn === 'empty'){
        this.matchGroup.ExternalFileMap.Engagement.splice(this.matchGroup.ExternalFileMap.Engagement.indexOf(i), 1);
      }
    }
    console.log('after removieng fields', this.matchGroup);
  }
  // tslint:disable-next-line:typedef
  PostConfig(){
    console.log('next id', this.next_config_id);
    const url = this.api.url_post_ExternalSource();

    const xhr = new XMLHttpRequest();

    xhr.open('POST', url);
    xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
    xhr.setRequestHeader('accept', '*/*');
    xhr.setRequestHeader('Content-Type', 'application/json-patch+json');

    // tslint:disable-next-line:only-arrow-functions typedef
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        console.log(xhr.status);
        console.log(xhr.responseText);
        // tslint:disable-next-line:triple-equals
        if (xhr.status == 200) {
          document.getElementById('refresh_group').click();
          // @ts-ignore
          document.getElementById('succeeded_snack').click();
        }else{
          // @ts-ignore
          document.getElementById('failed_snack').click();
        }
      }};
    let data = '{"id":"id_empty","name":"name_empty","sourceType":"Excel","fieldMap":"fieldMap_empty"}';
    data = data.replace('id_empty', this.next_config_id.toString());
    data = data.replace('name_empty', this.config_name);
    const json = this.replacestr(JSON.stringify(this.matchGroup));
    data = data.replace('fieldMap_empty', json);
    console.log('data to send', data);
    xhr.send(data);
    this.is_saved = true;
  }
  // tslint:disable-next-line:typedef
  changed_ConfigName(event: any){
    this.changed_config_name = event.target.value;
  }
  // tslint:disable-next-line:typedef
  save_ConfigName(){
    this.config_name = this.changed_config_name;
    this.is_saveas_clicked = false;
    document.getElementById('myForm').style.display = 'none';
    document.getElementById('myForm2').style.display = 'none';
    this.showOverlay = true;
    this.SaveConfig();
  }
  // tslint:disable-next-line:typedef
  openForm() {
    this.is_saveas_clicked = true;
    document.getElementById('myForm').style.display = 'block';
    this.gotoElement();
  }
  // tslint:disable-next-line:typedef
  openForm2() {
    this.is_saveas_clicked = true;
    document.getElementById('myForm2').style.display = 'block';
    this.gotoElement();
  }
  // tslint:disable-next-line:typedef
  closeForm() {
    this.is_saveas_clicked = false;
    document.getElementById('myForm').style.display = 'none';
    document.getElementById('myForm2').style.display = 'none';
  }
  // tslint:disable-next-line:typedef
  replacestr(str: string){
    let x = '';
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < str.length; i++){
      if (str[i] === '\"'){
        x += '\\"';
      }
      else{
        x += str[i];
      }
    }
    console.log('str', x);
    return x;
  }
  ngOnInit(): void {
    this.api.getAuthToken().subscribe(auth => {
      this.token = auth;
    });
    this.api.getConfigurations_ImportPage().subscribe((data) => {
      // @ts-ignore
      this.saved_configs = data;
      console.log(data);
      // @ts-ignore
      for (const j of data){
        this.next_config_id++;
      }
      console.log('got it', this.next_config_id);
    });
    this.Import();
    this.getFields();
  }
}
