import { Component, OnInit } from '@angular/core';
import {FieldMap} from '../shared/FieldMap';
import {Config} from '../shared/Config';
import {Fields} from '../shared/Fields';
import {ConfigService} from '../config/config.service';
import {SavedConfig} from '../shared/SavedConfig';
import * as XLSX from 'xlsx';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent} from '@angular/router';

@Component({
  selector: 'app-edit-config',
  templateUrl: './edit-config.component.html',
  styleUrls: ['./edit-config.component.scss']
})
export class EditConfigComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  public config_name: string;
  // tslint:disable-next-line:variable-name
  config_fieldMap;
  // tslint:disable-next-line:variable-name
  saved_configs: SavedConfig[] = [];

  constructor(private api: ConfigService, private router: Router) {
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
  }
  public showOverlay = false;
  token: string;
  // tslint:disable-next-line:variable-name
  is_preview = false;
  // tslint:disable-next-line:variable-name
  is_remove_field = false;
  // tslint:disable-next-line:variable-name
  object_fieldMap: FieldMap[];
  // tslint:disable-next-line:variable-name
  new_fields: string[] = [];
  // tslint:disable-next-line:variable-name
  selected_newField = '';
  // tslint:disable-next-line:variable-name
  is_importfields_selected = false;
  // tslint:disable-next-line:variable-name
  selected_file;
  data: [][];
  // tslint:disable-next-line:variable-name
  all_file_fileds: string[] = [];
  // tslint:disable-next-line:variable-name
  edit_fileName = '';
  // tslint:disable-next-line:variable-name
  is_remove_config = false;
  // tslint:disable-next-line:variable-name
  changed_config_name  = '';
  // tslint:disable-next-line:variable-name
  is_saveas_clicked = false;
  // tslint:disable-next-line:variable-name
  config_id = 0;
  // tslint:disable-next-line:variable-name
  matchGroup = {
    ExternalFileMap: {
      Company: [
        {
          SourceColumn: 'empty',
          ExternalColumn: '',
          DataType: 'System.String'
        }
      ],
      Person: [
        {
          SourceColumn: 'empty',
          ExternalColumn: '',
          DataType: 'System.String'
        }
      ],
      Engagement: [
        {
          SourceColumn: 'empty',
          ExternalColumn: '',
          DataType: 'System.String'
        }
      ]
    }
  };
  // tslint:disable-next-line:typedef
  Preview(){
    this.is_preview = true;
    this.is_remove_field = false;
  //  this.is_remove_config = false;
    this.matchGroup.ExternalFileMap.Company = [];
    this.matchGroup.ExternalFileMap.Person = [];
    this.matchGroup.ExternalFileMap.Engagement = [];
    this.getConfigurations();
    this.getJson();
    this.getFields();
  }
  // tslint:disable-next-line:typedef
  Import() {
    console.log('import');
    document.getElementById('input-edit').addEventListener('change', (event) => {
      console.log('ev', event);
      if (event){
        // @ts-ignore
        this.selected_file = event.target.files[0];
        console.log('this sel file', this.selected_file);
        // @ts-ignore
        this.edit_fileName = event.target.files[0].name;
        this.importFile(event);
      }
    });
  }
  // tslint:disable-next-line:typedef
  importFile(event: any){
    console.log('import file');
    if (this.selected_file){
      const fileReader = new FileReader();
      fileReader.readAsBinaryString(this.selected_file);
      // tslint:disable-next-line:no-shadowed-variable
      fileReader.onload = (event) => {
        const bstr = event.target.result;
        const wb = XLSX.read(bstr, {type: 'binary'});
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        this.data = (XLSX.utils.sheet_to_json(ws, {header: 1}));
        this.getFieldsFromFile();
      };
    }
    this.matchGroup.ExternalFileMap.Company = [];
    this.matchGroup.ExternalFileMap.Person = [];
    this.matchGroup.ExternalFileMap.Engagement = [];
    this.getJson();
    this.getFields();
  }
  // tslint:disable-next-line:typedef
  getFieldsFromFile(){
    this.all_file_fileds = this.data[0];
    this.getDifferentFields();
  }
  // tslint:disable-next-line:typedef
  getDifferentFields() {
    // tslint:disable-next-line:variable-name
    let results_string = '';
    this.new_fields = [];
    for (const i of this.matchGroup.ExternalFileMap.Company){
      results_string += i.SourceColumn + ',' + i.ExternalColumn + ',';
    }
    for (const i of this.matchGroup.ExternalFileMap.Person){
      results_string += i.SourceColumn + ',' + i.ExternalColumn + ',';
    }
    for (const i of this.matchGroup.ExternalFileMap.Engagement){
      results_string += i.SourceColumn + ',' + i.ExternalColumn + ',';
    }
    // tslint:disable-next-line:variable-name
    let results_array = [];
    results_array = results_string.split(',');
    for (const i of results_array){
    }
    for (const i of this.all_file_fileds){
      for (const j of results_array){
        if (!j.includes(i)){
          const already = i;
          if (!this.new_fields.includes(already)){
            this.new_fields.push(already);
          }
        }
      }
    }
    for (const i of this.matchGroup.ExternalFileMap.Company){
      for (const j of this.new_fields){
        if (i.ExternalColumn === j){
          this.new_fields.splice(this.new_fields.indexOf(j), 1);
        }
      }
    }
    for (const i of this.matchGroup.ExternalFileMap.Person){
      for (const j of this.new_fields){
        if (i.ExternalColumn === j){
          this.new_fields.splice(this.new_fields.indexOf(j), 1);
        }
      }
    }
    for (const i of this.matchGroup.ExternalFileMap.Engagement){
      for (const j of this.new_fields){
        if (i.ExternalColumn === j){
          this.new_fields.splice(this.new_fields.indexOf(j), 1);
        }
      }
    }
    console.log('new fields', this.new_fields);
  }
  // tslint:disable-next-line:typedef
  getFields(){
    for (const i of this.object_fieldMap){
      for (const j of i.Company) {
        // tslint:disable-next-line:max-line-length
        this.matchGroup.ExternalFileMap.Company.push({DataType: 'System.String', ExternalColumn: j.ExternalColumn, SourceColumn: j.SourceColumn});
      }
      for (const j of i.Person) {
        // tslint:disable-next-line:max-line-length
        this.matchGroup.ExternalFileMap.Person.push({DataType: 'System.String', ExternalColumn: j.ExternalColumn, SourceColumn: j.SourceColumn});
      }
      for (const j of i.Engagement) {
        // tslint:disable-next-line:max-line-length
        this.matchGroup.ExternalFileMap.Engagement.push({DataType: 'System.String', ExternalColumn: j.ExternalColumn, SourceColumn: j.SourceColumn});
      }
    }
    for (const i of this.matchGroup.ExternalFileMap.Company){
      if (i.SourceColumn === 'empty'){
        this.matchGroup.ExternalFileMap.Company.splice(this.matchGroup.ExternalFileMap.Company.indexOf(i), 1);
      }
    }
    for (const i of this.matchGroup.ExternalFileMap.Person){
      if (i.SourceColumn === 'empty'){
        this.matchGroup.ExternalFileMap.Person.splice(this.matchGroup.ExternalFileMap.Person.indexOf(i), 1);
      }
    }
    for (const i of this.matchGroup.ExternalFileMap.Engagement){
      if (i.SourceColumn === 'empty'){
        this.matchGroup.ExternalFileMap.Engagement.splice(this.matchGroup.ExternalFileMap.Engagement.indexOf(i), 1);
      }
    }
  }
  // tslint:disable-next-line:typedef
  addToNewFields(datatype: string, field: string){
    if (datatype === 'Company'){
      for (const i of this.matchGroup.ExternalFileMap.Company){
        if (field === i.ExternalColumn){
            this.new_fields.push(i.ExternalColumn);
            i.ExternalColumn = '';
        }
      }
    }else if (datatype === 'Person'){
      for (const i of this.matchGroup.ExternalFileMap.Person){
        if (field === i.ExternalColumn){
          this.new_fields.push(i.ExternalColumn);
          i.ExternalColumn = '';
        }
      }
    }else if (datatype === 'Engagement'){
      for (const i of this.matchGroup.ExternalFileMap.Engagement){
        if (field === i.ExternalColumn){
          this.new_fields.push(i.ExternalColumn);
          i.ExternalColumn = '';
        }
      }
    }
  }
  // tslint:disable-next-line:typedef
  RemoveField(){
    this.is_preview = false;
    this.is_remove_field = true;
  //  this.is_remove_config = false;
    this.matchGroup.ExternalFileMap.Company = [];
    this.matchGroup.ExternalFileMap.Person = [];
    this.matchGroup.ExternalFileMap.Engagement = [];
    this.getJson();
    this.getFields();
  }
  // tslint:disable-next-line:typedef
  RemoveSpecificField(datatype: string, field: string){
    if (datatype === 'Company'){
      for (const i of this.matchGroup.ExternalFileMap.Company){
        if (field === i.ExternalColumn){
          i.ExternalColumn = '';
        }
      }
    }
    if (datatype === 'Person'){
      for (const i of this.matchGroup.ExternalFileMap.Person){
        if (field === i.ExternalColumn){
          i.ExternalColumn = '';
        }
      }
    }
    if (datatype === 'Engagement'){
      for (const i of this.matchGroup.ExternalFileMap.Engagement){
        if (field === i.ExternalColumn){
          i.ExternalColumn = '';
        }
      }
    }
    if (datatype === 'NewField'){
      for (const i of this.new_fields){
        if (field === i){
          this.new_fields.splice(this.new_fields.indexOf(i), 1);
        }
      }
    }
    // this.SaveRemovedFields();
    // window.location.reload();
  }
  // tslint:disable-next-line:typedef
  addAliasToField(datatype: string, field: string){
    console.log(' add to', datatype, field);
    console.log(this.selected_newField);
    if (this.selected_newField !== ''){
      if (datatype === 'Company'){
        for (const i of this.matchGroup.ExternalFileMap.Company){
          if (field === i.SourceColumn){
            this.addToNewFields(datatype, i.ExternalColumn);
            i.ExternalColumn = this.selected_newField;
            this.new_fields.splice(this.new_fields.indexOf(this.selected_newField), 1);
            this.selected_newField = '';
          }
        }
      }else if (datatype === 'Person'){
        for (const i of this.matchGroup.ExternalFileMap.Person){
          if (field === i.SourceColumn){
            this.addToNewFields(datatype, i.ExternalColumn);
            i.ExternalColumn = this.selected_newField;
            this.new_fields.splice(this.new_fields.indexOf(this.selected_newField), 1);
            this.selected_newField = '';
          }
        }
      }else if (datatype === 'Engagement'){
        for (const i of this.matchGroup.ExternalFileMap.Engagement){
          if (field === i.SourceColumn){
            this.addToNewFields(datatype, i.ExternalColumn);
            i.ExternalColumn = this.selected_newField;
            this.new_fields.splice(this.new_fields.indexOf(this.selected_newField), 1);
            this.selected_newField = '';
          }
        }
      }
    }
    console.log('after alias', this.matchGroup.ExternalFileMap);
  }

  // tslint:disable-next-line:typedef
  selectedNewField(field: string) {
    const id = 'new_field-' + field;
    // tslint:disable-next-line:variable-name
    const add_field = false;
    const prim = document.getElementById(id);
    if (this.selected_newField){
      console.log('just one!');
      if (prim.style.backgroundColor === 'rgb(184, 209, 222)') {
        prim.style.backgroundColor = '#ffffff';
        this.selected_newField = '';
      }
    }
    else{
      prim.style.backgroundColor = '#b8d1de';
      this.selected_newField = field;
    }
  }
  // tslint:disable-next-line:typedef
  getJson(){
    this.object_fieldMap = [];
    for (const i of this.saved_configs){
      this.config_id = i.id;
      if (i.name === this.config_name){
        this.config_fieldMap = i.fieldMap;
      }
    }
    this.config_fieldMap = this.config_fieldMap.replace('"ExternalFileMap":', '');
    this.config_fieldMap = this.config_fieldMap.replace('{', '');
    // this.config_fieldMap = this.config_fieldMap.replace('\n', '');
    // tslint:disable-next-line:variable-name
    let string_fieldMap = '' ;
    // tslint:disable-next-line:prefer-for-of
    for (let q = 0; q < this.config_fieldMap.length - 1; q++){
      string_fieldMap += this.config_fieldMap[q];
    }
    this.object_fieldMap.push(JSON.parse(string_fieldMap));
    console.log(typeof this.object_fieldMap, this.object_fieldMap.length);
    for (const i of this.object_fieldMap){
      console.log('company fields', i.Company);
      console.log('person fields', i.Person);
      console.log('engagement fields', i.Engagement);
    }
    console.log('mathgroup: ', this.matchGroup);
  }
  // tslint:disable-next-line:typedef
  openForm() {
    this.is_saveas_clicked = true;
    document.getElementById('myForm').style.display = 'block';
  }
  // tslint:disable-next-line:typedef
  closeForm() {
    this.is_saveas_clicked = false;
    document.getElementById('myForm').style.display = 'none';
  }
  // tslint:disable-next-line:typedef
  changed_ConfigName(event: any){
    this.changed_config_name = event.target.value;
  }
  // tslint:disable-next-line:typedef
  save_ConfigName(){
    this.config_name = this.changed_config_name; // + '.xlsx';
    this.is_saveas_clicked = false;
    document.getElementById('myForm').style.display = 'none';
    this.SaveConfig();
    // this.showOverlay = true;
  }
  // tslint:disable-next-line:typedef
  SaveConfig(){
    const url = this.api.url_post_ExternalSource();

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
    xhr.setRequestHeader('accept', '*/*');
    xhr.setRequestHeader('Content-Type', 'application/json-patch+json');

    // tslint:disable-next-line:only-arrow-functions typedef
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        console.log(xhr.status);
        console.log(xhr.responseText);
      }};
    let data = '{"id":"id_empty","name":"name_empty","sourceType":"Excel","fieldMap":"fieldMap_empty"}';
    data = data.replace('id_empty', this.config_id.toString());
    data = data.replace('name_empty', this.config_name);
    const json = this.replacestr(JSON.stringify(this.matchGroup));
    data = data.replace('fieldMap_empty', json);
    console.log('data to send', data);
    xhr.send(data);
    this.getConfigurations();
  }
  // tslint:disable-next-line:typedef
  SaveRemovedFields(){
    this.SaveConfig();
    this.SaveConfig();
  }
  // tslint:disable-next-line:typedef
  replacestr(str: string){
    let x = '';
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < str.length; i++){
      if (str[i] === '\"'){
        x += '\\"';
      }
      else{
        x += str[i];
      }
    }
    console.log('str', x);
    return x;
  }
  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.showOverlay = true;
    }
    if (event instanceof NavigationEnd) {
      this.showOverlay = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.showOverlay = false;
    }
    if (event instanceof NavigationError) {
      this.showOverlay = false;
    }
  }
  // tslint:disable-next-line:typedef
  getConfigurations(){
    this.api.getConfigurations_ImportPage().subscribe((data) => {
      // @ts-ignore
      this.saved_configs = data;
    });
  }
  ngOnInit(): void {
    this.api.getAuthToken().subscribe(auth => {
      this.token = auth;
    });
    this.getConfigurations();
    this.Import();
    // this.getFields();
  }
}
