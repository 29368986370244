import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import {HomeComponent} from './home/home.component';
import {NavbarComponent} from './navbar/navbar.component';
import {ImportComponent} from './import/import.component';
import {ReviewComponent} from './review/review.component';
import {QuarantineComponent} from './quarantine/quarantine.component';
import {SearchComponent} from './search/search.component';
import {ResearchComponent} from './research/research.component';
import {ReviewSettingsComponent} from './review-settings/review-settings.component';
import {QuarantineSettingsComponent} from './quarantine-settings/quarantine-settings.component';
import {ArchiveComponent} from './archive/archive.component';
import {MergeOptionsComponent} from './merge-options/merge-options.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { ResearchSettingsComponent } from './research-settings/research-settings.component';
import {WordpressSettingsComponent} from './wordpress-settings/wordpress-settings.component';
import {MemberclicksLogComponent} from './memberclicks-log/memberclicks-log.component';
import {CompanyApiLogComponent} from './company-api-log/company-api-log.component';
import {BoardDirectorsComponent} from './board-directors/board-directors.component';
import {BoardDirectorsLogComponent} from './board-directors-log/board-directors-log.component';
import {DelistedCompaniesComponent} from './delisted-companies/delisted-companies.component';


const routes: Routes = [

  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    component: HomeComponent
  },
  { path: 'navbar',
    component: NavbarComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'import',
    component: ImportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'matching',
    component: ReviewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'staging',
    component: QuarantineComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'search',
    component: SearchComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'research',
    component: ResearchComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'matching-settings',
    component: ReviewSettingsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'staging-settings',
    component: QuarantineSettingsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'merge-options',
    component: MergeOptionsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'research-settings',
    component: ResearchSettingsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'archive',
    component: ArchiveComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'wordpress-settings',
    component: WordpressSettingsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'memberclicks-log',
    component: MemberclicksLogComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'company-api-log',
    component: CompanyApiLogComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'board-directors',
    component: BoardDirectorsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'board-directors-log',
    component: BoardDirectorsLogComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'delisted-companies',
    component: DelistedCompaniesComponent,
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
