<app-navbar></app-navbar>
<body>
<button id="succeeded_snack" class="snack_button" at-raised-button color="primary" (click)="snackbar_SucceededPost()">
    Snackbar CSS Styles
</button>
<button id="failed_snack" class="snack_button" at-raised-button color="primary" (click)="snackbar_FailedPost()">
    Snackbar CSS Styles
</button>
<div class="my-overlay" *ngIf="showOverlay">
    <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
</div>
<h1>
    <a class="black-box">
        MemberClicks Log
    </a>
    <a *ngIf="environment==='Production'" class="page-env prod" >PRODUCTION</a>
    <a *ngIf="environment==='Development'" class="page-env dev" >DEVELOPMENT</a>
</h1>
<table>
    <tr class="full-width">
        <td>
            <p class="check-jobs" (click)="showRunningJobs()">
                Check running jobs
                <img id="research-running-jobs" src="assets/img/down_simple_arrow.svg" alt="down_icon"
                     class="icon-right">
            </p>
            <div *ngIf="are_jobsVisible" id="running-jobs" class="running-jobs">
                <div *ngIf="jobs">
                    <p *ngIf="time_last_job_started !== ''">Last job started at: {{time_last_job_started}}</p>
                    <p>There are {{jobs.length}} job(s) running</p>
                    <div *ngFor="let job of jobs">
                        <p>
                            Job type: {{job.type}}
                        </p>
                        <p>
                            Job state: {{job.state}}
                        </p>
                        <button (click)="deleteJob(job.key.toString())" class="button" style="margin: 0 0 0 1%">Delete
                        </button>
                        <p>--------------------------------------------------------------</p>
                    </div>
                </div>
            </div>
        </td>
    </tr>
</table>

<table>
    <tr class="full-width">
        <td>
            <p class="check-jobs" (click)="showRules()">
                <img src="assets/img/information-request-icon.webp" alt="down_icon" class="icon-rules">
                <img id="show-rules" src="assets/img/down_simple_arrow.svg" alt="down_icon"
                     class="icon-right">
            </p>
            <div *ngIf="areRulesVisible" id="running-jobs" class="running-jobs">
               <p> Exceptions occur when: </p>
                <ul class="rules">
                    <li>BPA Qual Source is any of the requests or paids AND the BPA Qual Date is less than 3 years old AND Chief Mag Print & Digital or Chief Digital Only are checked, then don’t overwrite with MemberClicks data - just check or uncheck the membership boxes</li>
                    <li>MemberClicks status is NOT Active and BPA Qual Source is Association or Society Membership then don’t overwrite any of the BPA data. Remove sub status, uncheck Chief mag box and uncheck the membership box</li>
                    <li>MemberClicks status is NOT Active and BPA Qual Source is Internal Research, Business Directory, Other Sources or Not Requested AND Qual date is less than 3 years, then don’t overwrite any of the BPA data or magazine boxes simply uncheck the membership box.</li>
                </ul>
            </div>
        </td>
    </tr>
</table>
<div class="table-content">

    <table class="table_header-wp">
        <tr class="table-header-wp-content">
            <th class="table_header-th"> Member Clicks Id</th>
            <th class="table_header-th"> Mapped to Ceo360 Id</th>
            <th class="table_header-th"> WP ID</th>
            <th class="table_header-th"> Imported in Amplify</th>
            <th class="table_header-th"> Member Status</th>
            <th class="table_header-th"> Is Exception</th>
            <th class="table_header-th"> CEN</th>
            <th class="table_header-th"> SEN</th>
            <th class="table_header-th"> CEO100</th>
            <th class="table_header-th"> CFO100</th>
            <th class="table_header-th"> NLL</th>
            <th class="table_header-th"> CEBI</th>
            <th class="table_header-th">
                <div class="date-picker">Date added
                    <input (ngModelChange)="filterData=$event" (change)="filterByDate()" [ngModel]="filterData" class="date-added-sort " type="date">
                </div>
            </th>
        </tr>
        <tr *ngFor="let data of memberClicksData; let i = index; "[class.row_darken]="data.memberStatus==='Lapsed' || data.memberStatus==='Deceased'">
            <td>
                <div class="">
                    <a>{{data.memberClicksId}}</a>
                </div>
            </td>
            <td>
                <div class="ceoId" *ngIf="data.ceoId">
                    <a (click)="LinkToCeo(data.ceoId)">
                        {{data.ceoId}}
                    </a>
                </div>
            </td>
            <td>
                <div class="ceoId" *ngIf="data.wordpressId">
                    <a (click)="LinkToWP(data.wordpressId)">
                        {{data.wordpressId}}
                    </a>
                </div>
            </td>
            <td>
                <div>
                    {{data.importedInAmplify}}
                </div>
            </td>
            <td>
                <div>
                    {{data.memberStatus}}
                </div>
            </td>
            <td>
                <div>
                    {{data.isException}}
                </div>
            </td>
            <td>
                <div *ngIf="data.cen == true">
                    checked
                </div>
                <div *ngIf="data.cen == false">
                    unchecked
                </div>
            </td>
            <td>
                <div *ngIf="data.sen == true">
                    checked
                </div>
                <div *ngIf="data.sen == false">
                    unchecked
                </div>
            </td>
            <td>
                <div *ngIf="data.ceo100 == true">
                    checked
                </div>
                <div *ngIf="data.ceo100 == false">
                    unchecked
                </div>
            </td>
            <td>
                <div *ngIf="data.cfo100 == true">
                    checked
                </div>
                <div *ngIf="data.cfo100 == false">
                    unchecked
                </div>
            </td>
            <td>
                <div *ngIf="data.nextLevelLeaders == true">
                    checked
                </div>
                <div *ngIf="data.nextLevelLeaders == false">
                    unchecked
                </div>
            </td>
            <td>
                <div *ngIf="data.cebi == true">
                    checked
                </div>
                <div *ngIf="data.cebi == false">
                    unchecked
                </div>
            </td>
            <td>
                <div class="date-picker "> {{data.createdDate | date:'MM/dd/yyyy hh:mm:ss'}}</div>
            </td>

        </tr>
    </table>

</div>
<div class="paginator-wp" *ngIf="memberClicksData.length>0">
    <div>
        <div *ngIf="currentPage < totalPages; else lastPage">{{fetchData * currentPage - fetchData + 1}} - {{fetchData * currentPage}}
            from {{totalCounts}}</div>
        <ng-template #lastPage>{{fetchData * currentPage - fetchData + 1}} - {{totalCounts}} from {{totalCounts}}</ng-template>
    </div>
    <div class="back" (click)="goToFirstPage()"> <<</div>
    <div class="back" (click)="previousPage()"> <</div>
    <div class="current-page">Current Page:  {{currentPage}}</div>
    <div  *ngIf="currentPage < totalPages" class="forward" (click)="nextPage()"> > </div>
    <div  *ngIf="currentPage < totalPages" class="forward" (click)="goToLastPage()"> >></div>
    <div  *ngIf="currentPage == totalPages" style="margin-right: 5vh" class="forward"></div>
</div>
<div class="no-data" *ngIf="memberClicksData.length==0">
    No data available
</div>
</body>
