import { Component, OnInit } from '@angular/core';
import {Jobs} from '../shared/Jobs';
import {ConfigService} from '../config/config.service';
import {AuthService} from '@auth0/auth0-angular';
import {Router} from '@angular/router';
import {AppComponent} from '../app.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {WarningComponent} from '../warning/warning.component';
import {MatDialog} from '@angular/material/dialog';
import {ICompanyApiData, MappingsDetails, SearchFilters} from '../shared/CompanyApiData';
import {DatePipe} from '@angular/common';
import {environment as env} from '../../environments/environment';

@Component({
  selector: 'app-company-api-log',
  templateUrl: './company-api-log.component.html',
  styleUrls: ['./company-api-log.component.scss', '../shared/helpers_scss/spinner.scss']
})
export class CompanyApiLogComponent implements OnInit {
  environment: string;
  // tslint:disable-next-line:variable-name
  time_last_job_started = '';
  href = 'https://dev.ceo360.pro/#/';
  currentPage = 1;
  fetchData = 500;
  totalPages = 0;
  offset = 0;
  totalCounts = 0;
  dataFilter = '';
  searchFilter: SearchFilters = {
    date: null,
    cikId: null,
    companyId: null
  };
  jobs: Jobs[] = [{
    key: '',
    type: '',
    inProcess: false,
    state: ''
  }];
  // auth0 data
  token: string;
  user: string;
  userEmail: string;
  userRole: string;
  // tslint:disable-next-line:variable-name
  are_jobsVisible = false;
  areRulesVisible = false;
  isAdmin: boolean;
  profileJson: string = null;
  showOverlay = false;
  mappingsDetails: MappingsDetails;
  companies: ICompanyApiData[] = [];
  constructor(private api: ConfigService,
              public auth: AuthService,
              private route: Router,
              private appcomponent: AppComponent,
              private snackBar: MatSnackBar,
              public dialog: MatDialog) {
    this.appcomponent.jobChecking();
  }
  // tslint:disable-next-line:typedef
  snackbar_SucceededPost() {
    const sb = this.snackBar.open('Your settings have successfully been saved!', '', {
      duration: 1000,
      panelClass: ['succeed'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
  // tslint:disable-next-line:typedef
  snackbar_FailedPost() {
    const sb = this.snackBar.open('Your settings are NOT saved!', '', {
      duration: 1000,
      panelClass: ['failed'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
  GetTimeStamp(): void{
    this.time_last_job_started = localStorage.getItem('date');
  }
  showRunningJobs(): void{
    this.GetTimeStamp();
    this.api.get_Jobs().subscribe(res => {
          // @ts-ignore
          this.jobs = res;
          for (const i of this.jobs){
            if (i.type === 'CegIntegration.Web.Jobs.MainDataSynchronizationJob'){
              this.jobs.splice(this.jobs.indexOf(i), 1);
            }
          }
          this.are_jobsVisible = !(this.are_jobsVisible);
          if (this.are_jobsVisible){
            const img = document.getElementById('research-running-jobs');
            img.style.transform = 'rotate(180deg)';
          }
          else{
            const img = document.getElementById('research-running-jobs');
            img.style.transform = 'rotate(0deg)';
          }
        },
        err => {
          console.log(err);
        });
  }
  DeleteJob(jobKey: any): void{
    const dialogRef = this.dialog.open(WarningComponent);
    dialogRef.componentInstance.action = 'DeleteJob';

    dialogRef.afterClosed().subscribe(result => {
      console.log(`popup result: ${result}`);
      const response = result;
      if (response === 'yes'){
        this.ProceedDeleteJob(jobKey);
      }
    });
  }
  ProceedDeleteJob(jobKey: any): void{
    const url = this.api.delete_Job(jobKey.toString());

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);

    xhr.setRequestHeader('Content-Type', 'application/json-patch+json');
    xhr.setRequestHeader('accept', '*/*');
    xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
    // tslint:disable-next-line:only-arrow-functions typedef
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        // tslint:disable-next-line:triple-equals
        if (xhr.status == 200){
          console.log('ok');
          document.getElementById('succeeded_snack').click();
          document.getElementById('refreshJobs').click();
        }
        else{
          document.getElementById('failed_snack').click();
        }
      }
    };
    xhr.send();
  }

  refreshJobs(): void{
    this.api.get_Jobs().subscribe(res => {
          // @ts-ignore
          this.jobs = res;
          for (const i of this.jobs){
            if (i.type === 'CegIntegration.Web.Jobs.MainDataSynchronizationJob'){
              this.jobs.splice(this.jobs.indexOf(i), 1);
            }
          }
        },
        err => {
          console.log(err);
        });
  }
  showRules(): void{
    this.areRulesVisible = !(this.areRulesVisible);
    if (this.areRulesVisible){
      const img = document.getElementById('show-rules');
      img.style.transform = 'rotate(180deg)';
    }
    else{
      const img = document.getElementById('show-rules');
      img.style.transform = 'rotate(0deg)';
    }
  }
  showTable(): void {
    this.showOverlay = true;
    console.log('this.filterData', this.searchFilter.date);
    if ( this.dataFilter !== '' ){
      this.searchFilter.date = new DatePipe('en-US').transform(this.dataFilter, 'MM/dd/yyyy');
    }
    this.api.getCompaniesApiTable(this.offset, this.fetchData, this.searchFilter).subscribe(res => {
          if (res){
            // @ts-ignore
            this.companies = res;
            console.log('this.companies.length', this.companies.length);
            console.log('this.companies[0].totalCount', this.companies[0].totalCount);
            this.totalCounts = this.companies[0].totalCount;
            this.dataFilter = new DatePipe('en-US').transform( this.companies[0].createdDate, 'yyyy-MM-dd');
            console.log(this.dataFilter);
          }else{
            this.companies = null;
            this.totalCounts = 0;
          }
          this.showOverlay = false;
          this.getMappingsInformation(this.dataFilter);
          this.getTotalPages();
        },
        err => {
          console.log(err);
        });
  }
  searchCompanies(): void{
    this.offset = 0;
    this.currentPage = 1;
    if ( this.dataFilter !== ''){
      this.searchFilter.date = new DatePipe('en-US').transform(this.dataFilter, 'MM/dd/yyyy');
    }else{
      this.searchFilter.date = this.dataFilter;
    }
    this.showOverlay = true;
    this.api.getCompaniesApiTable(this.offset, this.fetchData, this.searchFilter).subscribe(res => {
          console.log(res);
           // @ts-ignore
          this.companies = res;

          if (this.companies.length > 0 ){
            this.totalCounts = this.companies[0].totalCount;
          }
          else{
            this.totalCounts = 0;
          }
          this.showOverlay = false;
          this.getMappingsInformation(this.searchFilter.date);
          this.getTotalPages();
        },
        err => {
          console.log(err);
        });
  }
  getMappingsInformation(date: string): void{
    console.log(date);
    if (date !== ''){
      date =  new DatePipe('en-US').transform(date, 'MM/dd/yyyy');
    }
    this.api.getMappingsInformation(date).subscribe(response => {
      console.log('response', response);
      if (response !== null){
          this.mappingsDetails =  Object.assign(new MappingsDetails(), response[0]);
          console.log('this mappings', this.mappingsDetails);
      }
    });
  }
  goToFirstPage(): void {
    this.currentPage = 1;
    this.resetOffset();
  }
  // tslint:disable-next-line:typedef
  goToLastPage(): void {
    this.currentPage = this.totalPages;
    this.offset = ((this.currentPage - 1) * this.fetchData);
    this.showTable();
  }
  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      // @ts-ignore
      this.currentPage++;
      this.resetOffset();
    }
    console.log('page nr', this.currentPage);
  }
  previousPage(): void {
    if (this.currentPage > 1) {
      // @ts-ignore
      this.currentPage--;
      this.resetOffset();
    }
    console.log('page nr', this.currentPage);
  }
  resetOffset(): void {
    this.offset = ((this.currentPage - 1) * this.fetchData);
    this.showTable();

  }
  LinkToCeo(id: number): void{
    // tslint:disable-next-line:max-line-length
    if (window.location.href === 'http://localhost:4200/#/company-api-log' || window.location.href === 'https://amplify-dev.ceo360.pro/#/company-api-log') {
      this.href = 'https://dev.ceo360.pro/#/';
    }
    if (window.location.href === 'https://amplify.ceo360.pro/#/company-api-log') {
      this.href = 'https://ceo360.pro/#/';
    }
    this.href += 'Company/' + id;
    const win = window.open(this.href, '_blank');
    win.focus();
  }
  getTotalPages(): void {
    if (this.totalCounts % this.fetchData) {
      this.totalPages = Math.floor(this.totalCounts / this.fetchData) + 1;
    } else {
      this.totalPages = Math.floor(this.totalCounts / this.fetchData);
    }
    console.log('totalpages', this.totalPages);
  }
  seeDelisted(): void{
    this.route.navigate(['delisted-companies']);
  }
  ngOnInit(): void {
    this.api.getAuthToken().subscribe(auth => {
      this.token = auth;
    });
    this.environment =  env.environment ;
    this.auth.user$.subscribe(
        (profile) => {
          (this.profileJson = JSON.parse(JSON.stringify(profile, null, 2)));
          localStorage.setItem('userRole', profile['https://schemas.quickstarts.com/roles'][0]);
          this.userRole = profile['https://schemas.quickstarts.com/roles'][0];
          if (this.userRole === 'admin'){
            this.isAdmin = true;
          }
          else{
            this.isAdmin = false;
          }
        });
    this.showTable();
  }

}
