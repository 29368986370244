export class ReviewedCompany{
  id: number;
  importId: string;
  fileName: string;
  fileRowId: number;
  companyId: number;
  companyName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone: string;
  website: string;
  addressSource: string;
  companyType: string;
  lastUpdated: string;
  ticker: string;
  exchange: string;
  sicCode: number;
  naiscCode: number;
  numberOfEmployees: number;
  annualRevenue: number;
  ownershipTypeDesc: string;
  ownershipSubType: string;
  businessIndustry: string;
  // tslint:disable-next-line:variable-name
  h_D_U_N_S_Number: string;
  // tslint:disable-next-line:variable-name
  h_Tradestyle: string;
  // tslint:disable-next-line:variable-name
  h_EntityType: string;
  // tslint:disable-next-line:variable-name
  h_IsHeadquarters: boolean;
  // tslint:disable-next-line:variable-name
  h_BusinessDescription: string;
  // tslint:disable-next-line:variable-name
  h_ParentCompany: string;
  // tslint:disable-next-line:variable-name
  h_ParentCountry_Region: string;
  // tslint:disable-next-line:variable-name
  h_UltimateParentCompany: string;
  // tslint:disable-next-line:variable-name
  h_UltimateParentCountryRegion: string;
  // tslint:disable-next-line:variable-name
  h_ImportDate: string;
  actionType: string;
  winPurePrimK: number;
  groupId: number;
  activeStatus: boolean;
  verifiedDate: string;
  howVerified: string;
  'group ID': number;
  cikId: number;
  ipoDate: string;
}
