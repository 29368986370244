import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ConfigService} from '../config/config.service';
import {Actions, BoardDirector, Filters} from '../shared/boardDirectors/boardDirectorModel';
import {MatTableDataSource} from '@angular/material/table';
import {environment as env} from '../../environments/environment';
import {WarningComponent} from '../warning/warning.component';
import {SharedService} from '../shared/sharedService/shared.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MergeOptionsBoardComponent} from '../merge-options-board/merge-options-board.component';

@Component({
    selector: 'app-board-match',
    templateUrl: './board-match.component.html',
    styleUrls: ['./board-match.component.scss',
                '../board-directors/board-directors.component.scss',
                '../shared/helpers_scss/spinner.scss']
})
export class BoardMatchComponent implements OnInit {

    constructor(public dialog: MatDialog,
                private api: ConfigService,
                public currentDialog: MatDialogRef<BoardMatchComponent>,
                public sharedService: SharedService,
                private snackBar: MatSnackBar) {
    }

    public boardDirectorString: string;
    public boardDirector: BoardDirector;
    public tableName = null;
    public userEmail = null;
    public token = null;
    public boardMatches: BoardDirector[] = [];
    public selectedFilters: Filters[] = [];
    expandedElement: BoardDirector | null;
    displayedColumns: string[] = ['actions', 'source', 'personId', 'firstName', 'lastName', 'primaryCompany', 'primaryCompanyId', 'primaryTitle', 'boardCompanyCik', 'boardCompanyName', 'directorSince', 'audit', 'comp', 'govNom', 'insideOutside', 'education', 'removed', 'activeStatus', 'cbmStatus', 'cbmDigitalAndPrint'];
    columnsToDisplay: string[];
    dataSource;
    environment: string;
    href = 'https://dev.ceo360.pro/#/';
    actions: Actions[] = [];
    mainProfileActions: Actions[] = [];
    showActionsBtn = false;
    isMatchById = false;
    boardsToMerge: BoardDirector[] = [];
    showOverlay = false;

    ngOnInit(): void {
        this.environment = env.environment;
        this.boardDirector = JSON.parse(this.boardDirectorString);
        this.boardMatches.push(this.boardDirector);
        this.columnsToDisplay = this.displayedColumns.filter(item => item !== 'actions');
        this.getMatchingProfiles();
        this.getActions();
    }
    getActions(): void
    {
        this.sharedService.getActions().subscribe((resp) => {
             this.actions = resp.filter(items => !['matchById', 'research', 'addPerson', 'matchBoard', 'addBoard'].includes(items.action));
             this.mainProfileActions = resp.filter(items => !['research', 'matchBoard'].includes(items.action));
        });
    }

    getMatchingProfiles(): void {
        console.log('this.boardMatches', this.boardMatches);
        this.showOverlay = true;
        console.log('this.boardMatches', this.showOverlay);
        this.api.getBoardMatch(this.getFilters(), this.selectedFilters).subscribe(response => {
            response.result.forEach(item => {
                this.boardMatches.push(item);
            });
            this.showOverlay = false;
            this.dataSource = new MatTableDataSource<BoardDirector>(this.boardMatches);
        });
    }

    getFilters(): BoardDirector {
        const boardSearch = new BoardDirector();
        this.selectedFilters.forEach(item => {
            if (this.boardDirector[item.key] !== undefined) {
                boardSearch[item.key] = this.boardDirector[item.key];
            }
            if (this.boardDirector.personId !== null){
                boardSearch.personId = this.boardDirector.personId;
            }
        });
        return boardSearch;
    }
    emptyActions(): void{
        this.boardDirector.action = null;
    }
    selectMainProfileActions(action: any, board: BoardDirector): void{
        switch (action) {
            case 'edit':
            case 'mergeBoard':
            case 'selectBoard':
            case 'addPerson':
            case 'kick':
                this.checkActionsBtn();
                break;
            case 'addBoard':
                console.log(board.personId);
                if (board.personId === null || board.personId === undefined){
                    console.log('alert');
                    this.snackbarAddBoard();
                    this.showActionsBtn = false;
                }
                else{
                    console.log('goo further');
                    this.checkActionsBtn();
                }
                break;
            case 'matchById':
                this.isMatchById = true;
                break;
            case 'none':
                board.action = null;
                this.isMatchById = false;
                this.checkActionsBtn();
                break;
        }
    }
    matchByPersonId(): void{
        const person = this.boardMatches.find(item => item.action === 'matchById');
        this.api.matchByPersonId(person.personId, person.importId, this.tableName).subscribe(response => {
                if (response.result !== undefined){
                    response.result.forEach(item => {
                        this.boardMatches.push(item);
                    });
                    this.boardMatches.forEach(item => (item.action = null));
                    this.isMatchById = false;
                    this.dataSource = new MatTableDataSource<BoardDirector>(this.boardMatches);
                }
        });
    }
    selectActions(action: any, board: BoardDirector): void {
        if (action === 'none'){
            board.action = null;
        }
        // switch (action) {
        //     case 'addBoard':
        //         break;
        //     case 'updateBoard':
        //         break;
        //     case 'none':
        //         board.action = null;
        //         break;
        // }
        console.log('action', board);
        this.checkActionsBtn();
    }
    checkActionsBtn(): void{
        // if no individual action selected
        if (this.boardMatches.filter(item => item.action === null || item.action === undefined ).length === this.boardMatches.length){
            this.showActionsBtn = false;
        }
        else{
            this.showActionsBtn = true;
        }
        console.log(this.boardMatches);
    }
    isFirstRow(row): boolean {
        return this.dataSource.filteredData.indexOf(row) === 0;
    }

    LinkToCeo(id: number): void {
        this.sharedService.linkToPerson(id);
    }
    LinkToCompany(id: number): void {
        this.sharedService.linkToCompany(id);
    }
    checkAutomaticMerge(): BoardDirector[]{
        this.boardsToMerge = [];
        for (const i of this.boardMatches){
            if (i.action === 'selectBoard' || i.action === 'mergeBoard') {
                this.boardsToMerge.push(i);
            }
        }
        const finalDirectors = [];
        if (this.boardsToMerge.length === 1){
            this.snackbarUnableToMerge();
            return finalDirectors;
        }
        else if (this.boardsToMerge.length >=  2){
            const mainRecord = this.boardsToMerge.find(x => x.personId > 0);
            const importedRecord = this.boardsToMerge.find(x => x.importedRecord === true);
            for ( const key of  Object.keys(new BoardDirector())){
                if (mainRecord[key] === null || mainRecord[key] === undefined || mainRecord[key] === ''){
                     if (importedRecord[key] !== null || importedRecord[key] !== undefined || importedRecord[key] !== ''){
                        mainRecord[key] = importedRecord[key];
                    }
                }
            }
            mainRecord.action = 'mergeBoard'; // final constructed obj;
            importedRecord.action = 'matchBoard'; // the object that needs to be archived;
            finalDirectors.push(mainRecord);
            finalDirectors.push(importedRecord);
        }

        return finalDirectors;
    }
    proceedActions(): void{
        const mergedDirectors = this.checkAutomaticMerge();
        if (mergedDirectors.length >= 2 ){
            const jsonToSend = {
                tableName: this.tableName,
                userEmail: this.userEmail,
                directorsList: mergedDirectors,
            };
            console.log('is merge', mergedDirectors);
            this.sendActions(JSON.stringify(jsonToSend));
        }else{
            const directorsToReview = this.boardMatches.filter(item => (item.action !== undefined && item.action !== null));
            console.log('is others actions');
            // add id from the imported row to have the import id in order to set update/add details
            directorsToReview.forEach(item => {
                switch (item.action) {
                    case 'addBoard':
                        item.id = this.boardDirector.id;
                        item.audit = this.boardDirector.audit;
                        item.comp = this.boardDirector.comp;
                        item.govNom = this.boardDirector.govNom;
                        item.insideOutside = this.boardDirector.insideOutside;
                        item.directorSince = this.boardDirector.directorSince;
                        item.boardCompanyCik = this.boardDirector.boardCompanyCik;
                        item.boardCompanyId = this.boardDirector.boardCompanyId;
                        item.boardDirectorId = null;
                        break;
                }
            });
            const jsonToSend = {
                tableName: this.tableName,
                userEmail: this.userEmail,
                directorsList: directorsToReview,
            };
            this.sendActions(JSON.stringify(jsonToSend));
        }


    }
    sendActions(jsonToSend: string): void{
        this.api.proceedSelectedBoardActions(jsonToSend, this.token ).then(result => {
            console.log('api finished', result);
            if (result.status === 200){
                this.sharedService.successfulAction();
                this.currentDialog.close('Success');
            }
            else{
                this.sharedService.failedPost();
            }
        });
    }
    getSelectClass(value: string): string {
        switch (value) {
            case 'selectBoard':
                return 'select-action';
            case 'kick':
                return 'kick-action';
            case 'research':
                return 'research-action';
            case 'edit':
                return 'edit-action';
            case 'mergeBoard':
                return 'merge-action';
            default:
                return '';
        }
    }
    manualMerge(): void{
        this.boardsToMerge = [];
        for (const i of this.boardMatches){
            if (i.action === 'selectBoard' || i.action === 'mergeBoard') {
                this.boardsToMerge.push(i);
            }
        }
        if (this.boardsToMerge.length < 2){
            console.log('unable to match');
            this.snackbarUnableToMerge();
        }
        else{
            console.log('boardsToMerge', this.boardsToMerge);
            const dialogRef = this.dialog.open(MergeOptionsBoardComponent, {
                disableClose: true,
            });
            dialogRef.componentInstance.boardDirectorsToMerge = this.boardsToMerge;
            dialogRef.componentInstance.tableName = this.tableName;
            dialogRef.componentInstance.userEmail = this.userEmail;
            dialogRef.componentInstance.token = this.token;
            dialogRef.afterClosed().subscribe(resp => {
                console.log('response', resp);
            });
        }
    }
    snackbarUnableToMerge(): void {
       this.snackBar.open('Please choose at least 2 records that have to merge!', '', {
            duration: 1000,
            panelClass: ['failed'],
            horizontalPosition: 'right',
            verticalPosition: 'top'
        });
    }
    snackbarAddBoard(): void {
        this.snackBar.open('Please add a Person Id in order to Add the Board', '', {
            duration: 1500,
            panelClass: ['failed'],
            horizontalPosition: 'right',
            verticalPosition: 'top'
        });
    }
    processAssociation(): void {
        const dialogRef = this.dialog.open(WarningComponent);
        dialogRef.componentInstance.action = 'AddBoard';
        dialogRef.afterClosed().subscribe(result => {
            console.log('response', result);
            if (result === 'yes') {
                this.proceedActions();
            }
        });
    }

    linkToCompany(id: number): void {
        this.sharedService.linkToCompany(id);
    }
}
