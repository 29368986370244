import { Component, OnInit } from '@angular/core';
import {environment as env} from '../../environments/environment';
import {SharedService} from '../shared/sharedService/shared.service';
import {ConfigService} from '../config/config.service';
import {MatTableDataSource} from '@angular/material/table';
import {CompanyToReview} from '../shared/CompanyToReview';
import {DatePipe, TitleCasePipe} from '@angular/common';


@Component({
  selector: 'app-delisted-companies',
  templateUrl: './delisted-companies.component.html',
  styleUrls: ['./delisted-companies.component.scss', '../shared/helpers_scss/spinner.scss'],
  providers: [TitleCasePipe]
})
export class DelistedCompaniesComponent implements OnInit {
  environment: string;
  dataFilter = '';
  pageNumber = 0;
  pageSize = 50;
  totalSize: number;
  totalCount: number;
  delistedCompanies: CompanyToReview[] = [];
  displayedColumns: string[] = ['reviewed', 'cikId', 'companyId', 'companyName', 'address1', 'address2', 'city', 'state', 'zip', 'country', 'phone', 'website', 'ticker', 'exchange', 'sicCode', 'employees', 'revenue', 'ownershipType', 'ipoDate', 'createdDate'];
  filteredColumns = this.displayedColumns.filter(item => item !== 'createdDate' && item !== 'ipoDate' && item !== 'reviewed' && item !== 'companyId');
  dataSource;
  showOverlay = false;
  constructor(public sharedService: SharedService, public api: ConfigService, public titleCasePipe: TitleCasePipe) { }

  ngOnInit(): void {
    this.environment =  env.environment ;
    this.getDelistedCompanies();
  }
  getDelistedCompanies(): void {
    this.showOverlay = true;
    this.api.getPotentialDelistedCompanies(this.pageNumber, this.pageSize, this.dataFilter).subscribe(result => {
      this.showOverlay = false;
      console.log(result);
      this.delistedCompanies = result.item1;
      this.totalSize = result.item2;
      this.dataSource = new MatTableDataSource<CompanyToReview>(result.item1);
      console.log(this.dataSource);
    });
  }
  getTableHeaderToDisplay(attribute: string): string{
    switch (attribute){
      case 'cikId':
        return 'CIK ID';
      case 'companyName':
        return 'Company Name';
      case 'sicCode':
        return 'SIC Code';
      case 'ownershipType':
        return 'Ownership Type';
      default:
        return this.titleCasePipe.transform(attribute);
    }
  }
  searchCompanies(event: any): void{
    this.pageNumber = 0;
    this.dataFilter = event;
    console.log(event);
    if ( this.dataFilter !== ''){
      this.dataFilter = new DatePipe('en-US').transform(this.dataFilter, 'MM/dd/yyyy');
    }
    this.getDelistedCompanies();
  }
  linkToCeo(id: number): void {
    this.sharedService.linkToCompany(id);
  }
  onPageFired(event: any): void{
    this.pageNumber = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getDelistedCompanies();
  }
  markAsReviewed(element: any): void{
      this.showOverlay = true;
      this.api.markDelistedCompanyAsReviewed(element.id).subscribe(response => {
        this.getDelistedCompanies();
      });
  }

}
