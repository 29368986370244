import {Component, Input, OnInit} from '@angular/core';
import {BoardDirector, BoardDirectorSelection} from '../shared/boardDirectors/boardDirectorModel';
import {FormControl, FormGroup} from '@angular/forms';
import {ConfigService} from '../config/config.service';
import {AuthService} from '@auth0/auth0-angular';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {SharedService} from '../shared/sharedService/shared.service';

@Component({
  selector: 'app-merge-options-board',
  templateUrl: './merge-options-board.component.html',
  styleUrls: ['./merge-options-board.component.scss']
})
export class MergeOptionsBoardComponent implements OnInit {
  public boardDirectorsToMerge: BoardDirector[] = [];
  public tableName: string;
  public userEmail: string;
  public token: string;
  boardControl: FormGroup;
  boardSelection = new BoardDirectorSelection();
  finalObject = new BoardDirector();
  constructor(private api: ConfigService,
              public auth: AuthService,
              public dialog: MatDialog,
              public sharedService: SharedService) {
    this.boardControl = new FormGroup({
      personId: new FormControl(''),
      firstName: new FormControl(''),
     // middleName: new FormControl(''),
      lastName: new FormControl(''),
      primaryTitle: new FormControl(''),
      primaryCompanyId: new FormControl(''),
      boardCompanyCik: new FormControl(''),
      boardCompanyName: new FormControl(''),
      directorSince: new FormControl(''),
      insideOutside: new FormControl(''),
      audit: new FormControl(''),
      comp: new FormControl(''),
      govNom: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.findSelectedObject();
    this.constructSelectOptions();
  }
  findSelectedObject(): void{
    const mainRecord = this.boardDirectorsToMerge.find(item => item.action === 'selectBoard');
    if (mainRecord !== null && mainRecord !== undefined && mainRecord.personId > 0){
      this.finalObject.personId = mainRecord.personId;
      this.finalObject.boardDirectorId = mainRecord.boardDirectorId;
    }
    else{
      console.log('need to choose another id');
      const record = this.boardDirectorsToMerge.find(item => item.personId  > 0);
      this.finalObject.personId = record.personId;
      this.finalObject.boardDirectorId = record.boardDirectorId;
    }
  }
  constructSelectOptions(): void{
    this.boardSelection = new BoardDirectorSelection();
    this.populateSelectOptionsCompany();
    this.eliminateDuplicates();
  }
  eliminateDuplicates(): void{
      console.log('this.boardSelection', this.boardSelection);
      this.processBoardSelection();
     // await delay(1000);
      this.colorSelectedField();
  }
  processBoardSelection(): void{
    for ( const key of Object.keys(this.boardSelection)){
      // for boardCompanyName should be chosen ceo360 value
      // and for boardCompanyCik should be chosen value according to name if length>1
      switch (key){
        case 'boardCompanyName':
          this.populateBoardName();
          break;
        case 'boardCompanyCik':
          this.populateBoardCikId();
          break;
        default:
          this.populateBoardFields(key);
          break;
      }
    }
  }
  populateBoardName(): void{
    if (this.boardSelection.boardCompanyName.length === 1) {
      if (this.boardSelection.boardCompanyName[0] !== undefined){
        this.boardControl.controls.boardCompanyName.setValue(this.boardSelection.boardCompanyName[0].value);
        this.finalObject.boardCompanyName = this.boardSelection.boardCompanyName[0].value;
      }
    }else if (this.boardSelection.boardCompanyName.length > 1 && this.boardSelection.boardCompanyName.some( e => e.importedData === false)){
      const boardName = this.boardSelection.boardCompanyName.find( e => e.importedData === false).value;
      this.boardControl.controls.boardCompanyName.setValue(boardName);
      this.finalObject.boardCompanyName = boardName;
    }
  }
  populateBoardCikId(): void{
    if (this.boardSelection.boardCompanyCik.length === 1) {
      if (this.boardSelection.boardCompanyCik[0] !== undefined){
        this.boardControl.controls.boardCompanyCik.setValue(this.boardSelection.boardCompanyCik[0]);
        this.finalObject.boardCompanyCik = this.boardSelection.boardCompanyCik[0];
      }
    }else if (this.boardSelection.boardCompanyCik.length > 1){
      if (this.finalObject.boardCompanyName !== undefined){
        const cikId = this.boardDirectorsToMerge.find(x => x.boardCompanyName === this.finalObject.boardCompanyName).boardCompanyCik;
        this.boardControl.controls.boardCompanyCik.setValue(cikId);
        this.finalObject.boardCompanyCik = cikId;
      }
      else if (this.boardSelection.boardCompanyCik.some(item => item === null)){
          const value = this.boardSelection.boardCompanyCik.find(item => item === null);
          this.boardControl.controls.boardCompanyCik.setValue(value);
          this.finalObject.boardCompanyCik = value;
        }
      console.log(this.finalObject.boardCompanyName);
    }
  }
  populateBoardFields(key: string): void{
    if (this.boardSelection[key].length === 1) {
      if (this.boardSelection[key][0] !== undefined){
        this.boardControl.controls[key].setValue(this.boardSelection[key][0]);
        this.finalObject[key] = this.boardSelection[key][0];
      }
    }
    else if (this.boardSelection[key].length > 1){
      if (key === 'primaryCompanyId' || key === 'directorSince' || key === 'boardCompanyCik'){
        if (this.boardSelection[key].some(item => item === null)){
          const value = this.boardSelection[key].find(item => item !== null);
          this.boardControl.controls[key].setValue(value);
          this.finalObject[key] = value;
        }
      }
      else{
        if (this.boardSelection[key].some(item => item === '')){
          const value = this.boardSelection[key].find(item => item !== '');
          this.boardControl.controls[key].setValue(value);
          this.finalObject[key] = value;
        }
      }
    }
  }
  constructMergedBoard(type: string, event: any): void {
    const content = event.value;
    switch (type){
      case 'PersonId':
        break;
      case 'FirstName':
        this.finalObject.firstName = content;
        break;
      case 'LastName':
          this.finalObject.lastName = content;
          break;
      case 'BoardCikId':
        this.finalObject.boardCompanyCik = content;
        if (this.finalObject.boardCompanyName === undefined){
          const boardName = this.boardDirectorsToMerge.find( e => e.boardCompanyCik === content).boardCompanyName;
          this.boardControl.controls.boardCompanyName.setValue(boardName);
        }
        break;
      case 'BoardName':
        this.finalObject.boardCompanyName = content;
        if (this.finalObject.boardCompanyCik === undefined){
          const boardCik = this.boardDirectorsToMerge.find( e => e.boardCompanyName === content).boardCompanyCik;
          this.boardControl.controls.boardCompanyCik.setValue(boardCik);
        }
        break;
      case 'DirectorSince':
        this.finalObject.directorSince = content;
        break;
      case 'InsideOutside':
        this.finalObject.insideOutside = content;
        break;
      case 'Audit':
        this.finalObject.audit = content;
        break;
      case 'Comp':
        this.finalObject.comp = content;
        break;
      case 'GovNom':
        this.finalObject.govNom = content;
        break;
      case 'PrimaryTitle':
        this.finalObject.primaryTitle = content;
        break;
      case 'PrimaryCompanyId':
        this.finalObject.primaryCompanyId = content;
        break;
    }
  }
  populateSelectOptionsCompany(): void{
    for (const i of this.boardDirectorsToMerge){
      i.firstName ? this.checkList('firstName' , i.firstName) : this.checkList('firstName' , '');
     // i.middleName ? this.checkList('firstName' , i.middleName) : this.checkList('middleName' , '');
      i.lastName ? this.checkList('lastName' , i.lastName) : this.checkList('lastName' , '');
      i.boardCompanyCik ? this.checkList('boardCompanyCik' , i.boardCompanyCik) : this.checkList('boardCompanyCik' , null);
      this.handleBoardCompany(i);
      i.insideOutside ? this.checkList('insideOutside' , i.insideOutside) : this.checkList('insideOutside' , '');
      i.directorSince ? this.checkList('directorSince' , i.directorSince) : this.checkList('directorSince' , null);
      i.audit ? this.checkList('audit' , i.audit) : this.checkList('audit' , '');
      i.comp ? this.checkList('comp' , i.comp) : this.checkList('comp' , '');
      i.govNom ? this.checkList('govNom' , i.govNom) : this.checkList('govNom' , '');
      i.primaryTitle ? this.checkList('primaryTitle' , i.primaryTitle) : this.checkList('primaryTitle' , '');
      // tslint:disable-next-line:radix
      i.primaryCompanyId ? this.checkList('primaryCompanyId', i.primaryCompanyId) : this.checkList('primaryCompanyId' , null);
    }
  }
  handleBoardCompany(board: BoardDirector): void{
    let isImported = false;
    if (board.importedRecord === true){
       isImported = true;
    }
    if (!this.boardSelection.boardCompanyName.some(x => x.value === board.boardCompanyName)){
      this.boardSelection.boardCompanyName.push({value: board.boardCompanyName, importedData: isImported});
    }
  }
  checkList(property: any, value: any): void{
    if (typeof value === 'string'){
      if (!this.boardSelection[property].some( word => word.toLowerCase() === value.toLowerCase())){
        this.boardSelection[property].push(value);
      }
    }
    else{
      if (!this.boardSelection[property].includes(value)){
        this.boardSelection[property].push(value);
      }
    }
  }
  colorSelectedField(): void{
    for ( const key of Object.keys(this.boardSelection)){
      switch (key) {
        case 'boardCompanyName':
          break;
        default:
          if (this.boardSelection[key].length > 1){
            document.getElementById('select-' + key).style.backgroundColor = '#98e698';
          }
          break;

      }
    }
  }
  processManualMerge(): void{
    console.log('this.finalObject', this.finalObject);
    console.log('records', this.boardDirectorsToMerge);
    // object that will be saved in database
    this.finalObject.action = 'mergeBoard';
    // need to be archived
    const importedRecord = this.boardDirectorsToMerge.find(item => item.importedRecord === true);
    importedRecord.action = 'matchBoard';
    const directorsToReview = [];
    directorsToReview.push(importedRecord);
    directorsToReview.push(this.finalObject);
    const jsonToSend = {
      tableName: this.tableName,
      userEmail: this.userEmail,
      directorsList: directorsToReview,
    };
    this.api.proceedSelectedBoardActions(JSON.stringify(jsonToSend), this.token ).then(result => {
      console.log('api finished', result);
      if (result.status === 200){
        this.sharedService.successfulAction();
        this.dialog.closeAll();
      }
      else{
        this.sharedService.failedPost();
      }
    });

  }
}
