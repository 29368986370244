import {Component, OnInit} from '@angular/core';
import {ConfigService} from '../config/config.service';
import {AuthService} from '@auth0/auth0-angular';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {AppComponent} from '../app.component';
import {WarningComponent} from '../warning/warning.component';
import {Jobs} from '../shared/Jobs';
import {WordpressData} from '../shared/WordpressData';
import {DatePipe} from '@angular/common';
import {environment as env} from '../../environments/environment';
import {SharedService} from "../shared/sharedService/shared.service";

@Component({
    selector: 'app-wordpress-settings',
    templateUrl: './wordpress-settings.component.html',
    styleUrls: ['./wordpress-settings.component.scss']
})
export class WordpressSettingsComponent implements OnInit {
    environment: string;
    // tslint:disable-next-line:variable-name
    time_last_job_started = '';
    currentPage = 1;
    fetchData = 20;
    totalPages = 20;
    offset = 0;
    totalCounts = 0;
    filterData = '';
    href = 'https://dev.ceo360.pro/#/';
    wordpressData: WordpressData[] = [];
    altWordpressData: WordpressData[] = [];
    jobs: Jobs[] = [{
        key: '',
        type: '',
        inProcess: false,
        state: ''
    }];
    // auth0 data
    token: string;
    user: string;
    userEmail: string;
    userRole: string;
    // tslint:disable-next-line:variable-name
    are_jobsVisible = false;
    isAdmin: boolean;
    profileJson: string = null;
    showOverlay = false;
    columnsToDisplay = ['WordPressId', 'Ceo360Id', 'Imported', 'Status', 'Duplicate', 'CeoDigitalAndPrint', 'CeoDigitalOnly', 'CbmDigitalAndPrint', 'CbmDigitalOnly', 'AltWordpressId', 'DateAdded'];
    columnsToDisplayAltWordpress = [];
    tabCategory: string;

    constructor(private api: ConfigService,
                public auth: AuthService,
                private route: Router,
                private snackBar: MatSnackBar,
                public dialog: MatDialog,
                private appcomponent: AppComponent,
                public sharedService: SharedService) {
        this.appcomponent.jobChecking();
    }

    // tslint:disable-next-line:typedef
    snackbar_SucceededPost() {
        const sb = this.snackBar.open('Your settings have successfully been saved!', '', {
            duration: 1000,
            panelClass: ['succeed'],
            horizontalPosition: 'right',
            verticalPosition: 'top'
        });
    }

    // tslint:disable-next-line:typedef
    snackbar_FailedPost() {
        const sb = this.snackBar.open('Your settings are NOT saved!', '', {
            duration: 1000,
            panelClass: ['failed'],
            horizontalPosition: 'right',
            verticalPosition: 'top'
        });
    }

    modificationProceeded(message: string): void {
        this.snackBar.open(message, '', {
            duration: 2000,
            panelClass: 'succeed',
            horizontalPosition: 'right',
            verticalPosition: 'top'
        });
    }

    GetTimeStamp(): void {
        // tslint:disable-next-line:new-parens
        const date = new Date;
        this.time_last_job_started = localStorage.getItem('date');
    }

    // tslint:disable-next-line:typedef
    showRunningJobs() {
        this.GetTimeStamp();
        this.api.get_Jobs().subscribe(res => {
                // @ts-ignore
                this.jobs = res;
                for (const i of this.jobs) {
                    if (i.type === 'CegIntegration.Web.Jobs.MainDataSynchronizationJob') {
                        this.jobs.splice(this.jobs.indexOf(i), 1);
                    }
                }
                this.are_jobsVisible = !(this.are_jobsVisible);
                if (this.are_jobsVisible) {
                    const img = document.getElementById('research-running-jobs');
                    img.style.transform = 'rotate(180deg)';
                } else {
                    const img = document.getElementById('research-running-jobs');
                    img.style.transform = 'rotate(0deg)';
                }
            },
            err => {
                console.log(err);
            });
    }

    deleteJob(jobKey: any): void {
        const dialogRef = this.dialog.open(WarningComponent);
        dialogRef.componentInstance.action = 'DeleteJob';

        dialogRef.afterClosed().subscribe(result => {
            console.log(`popup result: ${result}`);
            if (result === 'yes') {
                this.ProceedDeleteJob(jobKey);
            }
        });
    }

    ProceedDeleteJob(jobKey: any): void {
        const url = this.api.delete_Job(jobKey.toString());

        const xhr = new XMLHttpRequest();
        xhr.open('POST', url);

        xhr.setRequestHeader('Content-Type', 'application/json-patch+json');
        xhr.setRequestHeader('accept', '*/*');
        xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
        // tslint:disable-next-line:only-arrow-functions typedef
        xhr.onreadystatechange = function() {
            if (xhr.readyState === 4) {
                // tslint:disable-next-line:triple-equals
                if (xhr.status == 200) {
                    console.log('ok');
                    document.getElementById('succeeded_snack').click();
                    document.getElementById('refreshJobs').click();
                } else {
                    document.getElementById('failed_snack').click();
                }
            }
        };
        xhr.send();
    }

    refreshJobs(): void {
        this.api.get_Jobs().subscribe(res => {
                // @ts-ignore
                this.jobs = res;
                for (const i of this.jobs) {
                    if (i.type === 'CegIntegration.Web.Jobs.MainDataSynchronizationJob') {
                        this.jobs.splice(this.jobs.indexOf(i), 1);
                    }
                }
            },
            err => {
                console.log(err);
            });
    }

    filterByDate(): void {
        console.log('dataToFilter', this.filterData);
        this.offset = 0;
        this.currentPage = 1;
        this.refreshTables();
    }
    formatFilterDate(): string{
        if (this.filterData !== '') {
            return new DatePipe('en-US').transform(this.filterData, 'MM/dd/yyyy');
        }
        return '';
    }
    showMainWordpressTable(): void {
        this.showOverlay = true;
        const dataToFilter = this.formatFilterDate();
        this.api.get_Wordpress_Table(this.offset, this.fetchData, dataToFilter).subscribe(res => {
                if (res) {
                    this.mapResponseToTable(res);
                    console.log('res', this.wordpressData);
                    this.totalCounts = this.wordpressData[0].totalCounts;
                    console.log('this.wordpressData', this.wordpressData);
                } else {
                    this.wordpressData = null;
                    this.totalCounts = 0;
                }
                this.showOverlay = false;
                this.getTotalPages();
            },
            err => {
                console.log(err);
            });
    }
    showAltWordpressTable(): void {
        this.showOverlay = true;
        const dataToFilter =  this.formatFilterDate();
        this.api.getAltWordpressTable(this.tabCategory, this.offset, this.fetchData, dataToFilter).subscribe(res => {
                if (res.length > 0) {
                    this.mapResponseToTable(res);
                    this.totalCounts = this.altWordpressData[0].totalCounts;
                } else {
                    this.altWordpressData = null;
                    this.totalCounts = 0;
                }
                this.showOverlay = false;
                this.getTotalPages();
            },
            err => {
                this.showOverlay = false;
                console.log(err);
            });

    }
    mapResponseToTable(response: any): void {

        switch (this.tabCategory){
            case 'ChiefExecutive':
                this.wordpressData = response.map((item): WordpressData => ({
                    ceoId: item.ceO360_Id,
                    wpId: item.wP_Id,
                    status: item.done,
                    duplicateProfile: item.duplicateProfile,
                    sourceSite: item.wP_SiteId,
                    createdDate: item.createDate,
                    cbmDigitalAndPrint: item.cbmDigitalAndPrint,
                    ceoDigitalAndPrint: item.ceoDigitalAndPrint,
                    ceoDigitalOnly: item.ceoDigitalOnly,
                    cbmDigitalOnly: item.cbmDigitalOnly,
                    magazine: item.magazine,
                    totalCounts: item.totalCounts,
                    altWordpressId: item.altWordpressId
                }));
                break;
            default:
                this.altWordpressData = response.map((item): WordpressData => ({
                    ceoId: item.ceO360_Id,
                    wpId: item.wP_Id,
                    status: item.done,
                    duplicateProfile: item.duplicateProfile,
                    sourceSite: item.wP_SiteId,
                    createdDate: item.createDate,
                    cbmNetwork: item.cbmNetwork,
                    accd: item.accd,
                    directorFundamentals: item.directorFundamentals,
                    magazine: item.magazine,
                    totalCounts: item.totalCounts,
                    altWordpressId: item.altWordpressId
                }));
        }
    }

    getTotalPages(): void {
        if (this.totalCounts % this.fetchData) {
            this.totalPages = Math.floor(this.totalCounts / this.fetchData) + 1;
        } else {
            this.totalPages = Math.floor(this.totalCounts / this.fetchData);
        }
        console.log('totalpages', this.totalPages);
    }


    LinkToWP(SiteId: number, wpId: number): void {
        let link = '';
        switch (SiteId) {
            case 1:
                link = 'https://chiefexecutive.net/wp-admin/user-edit.php?user_id=';
                break;
            case 2:
                link = 'https://saduu1bknj-staging.onrocket.site/wp-admin/user-edit.php?user_id=';
                break;
            case 3:
                link = 'https://ka26ol2stl-staging.onrocket.site/wp-admin/user-edit.php?user_id=';
                break;
            case 4:
                link = 'https://boardmember.com/wp-admin/user-edit.php?user_id=';
                break;
        }
        link += wpId;

        const win = window.open(link, '_blank');
        win.focus();
    }

    LinkToCeo(id: number): void {
        this.sharedService.linkToPerson(id);
    }


    goToFirstPage(): void {
        this.currentPage = 1;
        this.resetOffset();
    }


    goToLastPage(): void {
        this.currentPage = this.totalPages;
        this.offset = ((this.currentPage - 1) * this.fetchData);
        this.refreshTables();
        console.log('page nr', this.currentPage);
    }


    nextPage(): void {
        console.log('fitlerData', this.filterData);
        if (this.currentPage < this.totalPages) {
            // @ts-ignore
            this.currentPage++;
            this.resetOffset();
        }
        console.log('page nr', this.currentPage);
    }


    previousPage(): void {
        if (this.currentPage > 1) {
            // @ts-ignore
            this.currentPage--;
            this.resetOffset();
        }
        console.log('page nr', this.currentPage);
    }

    resetOffset(): void {
        this.offset = ((this.currentPage - 1) * this.fetchData);
        this.refreshTables();
    }
    refreshTables(): void{
        switch (this.tabCategory) {
            case 'ChiefExecutive':
                this.showMainWordpressTable();
                break;
            case 'BoardMembers':
                this.showAltWordpressTable();
                break;
        }
    }

    resetPagination(): void {
        this.offset = 0;
        this.currentPage = 1;
    }

    setActiveButton(event: Event): void {

        const tabsDiv = document.querySelector('.tabs');
        if (!tabsDiv) { return; }
        const buttons = tabsDiv.querySelectorAll('.button');

        buttons.forEach(button => {
            button.classList.remove('active');
        });
        const clickedButton = event.currentTarget as HTMLElement;
        clickedButton.classList.add('active');
    }
    colorSelectedTab(): void{
        const tabsDiv = document.querySelector('.tabs');
        if (tabsDiv) {
            const buttons = tabsDiv.querySelectorAll('.button');
            buttons.forEach(button => {
                button.addEventListener('click', this.setActiveButton);
            });
        }
    }
    selectTableLog(tabName: string): void {
        this.tabCategory = tabName;
        this.resetPagination();
        this.filterData = '';
        switch (tabName) {
            case 'ChiefExecutive':
                this.showMainWordpressTable();
                break;
            case 'BoardMembers':
                this.columnsToDisplayAltWordpress = ['WordPressId', 'Ceo360Id', 'Imported', 'Status', 'Duplicate', 'CbmNetwork', 'ACCD', 'DirectorFundamentals', 'AltWordpressId', 'DateAdded'];
                this.showAltWordpressTable();
                break;
        }
    }

    ngOnInit(): void {
        this.api.getAuthToken().subscribe(auth => {
            this.token = auth;
        });
        this.environment = env.environment;
        this.auth.user$.subscribe(
            (profile) => {
                (this.profileJson = JSON.parse(JSON.stringify(profile, null, 2)));
                localStorage.setItem('userRole', profile['https://schemas.quickstarts.com/roles'][0]);
                this.userRole = profile['https://schemas.quickstarts.com/roles'][0];
                // console.log('user role', this.userRole);
                if (this.userRole === 'admin') {
                    this.isAdmin = true;
                    console.log('is admin');
                } else {
                    this.isAdmin = false;
                    console.log('is not admin');
                }
            });
        this.colorSelectedTab();
        const chiefTab = document.getElementById( 'chief-results');
        chiefTab.classList.add('active');
        this.selectTableLog('ChiefExecutive');
    }

    startWordpressApi(): void {
        this.api.startWordPressApi(this.token).then(response => {
            console.log('response', response);
            if (response.status === 200) {
                this.modificationProceeded('WordPress Api started');
            }
        });
    }
    startBoardMembersApi(): void {
        this.api.startBoardMembersApi(this.token).then(response => {
            console.log('response', response);
            if (response.status === 200) {
                this.modificationProceeded('BoardMembers Api started');
            }
        });
    }

}
