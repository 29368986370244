import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ImportComponent } from './import/import.component';
import { ReviewComponent } from './review/review.component';
import { QuarantineComponent } from './quarantine/quarantine.component';
import { SearchComponent } from './search/search.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AuthModule } from '@auth0/auth0-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import {environment as env} from '../environments/environment';

import { MatSliderModule } from '@angular/material/slider';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatDialogModule} from '@angular/material/dialog';
import { NewConfigComponent } from './new-config/new-config.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import { ReviewSettingsComponent } from './review-settings/review-settings.component';
import { QuarantineSettingsComponent } from './quarantine-settings/quarantine-settings.component';
import { EditConfigComponent } from './edit-config/edit-config.component';
import {MatTableModule} from '@angular/material/table';
import { MergeOptionsComponent } from './merge-options/merge-options.component';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { ResearchComponent } from './research/research.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NotificationComponent } from './notification/notification.component';
import { WarningComponent } from './warning/warning.component';
import { ArchiveComponent } from './archive/archive.component';
import { ResearchSettingsComponent } from './research-settings/research-settings.component';
import { WordpressSettingsComponent } from './wordpress-settings/wordpress-settings.component';
import { MemberclicksLogComponent } from './memberclicks-log/memberclicks-log.component';
import { CompanyApiLogComponent } from './company-api-log/company-api-log.component';
import {TokenInterceptor} from './token-interceptor/token-interceptor.interceptor';
import { BoardDirectorsComponent } from './board-directors/board-directors.component';
import {MatIconModule} from '@angular/material/icon';
import { BoardMatchComponent } from './board-match/board-match.component';
import { BoardDirectorsLogComponent } from './board-directors-log/board-directors-log.component';
import { MergeOptionsBoardComponent } from './merge-options-board/merge-options-board.component';
import { DelistedCompaniesComponent } from './delisted-companies/delisted-companies.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    NavbarComponent,
    ImportComponent,
    ReviewComponent,
    QuarantineComponent,
    SearchComponent,
    NewConfigComponent,
    ReviewSettingsComponent,
    QuarantineSettingsComponent,
    EditConfigComponent,
    MergeOptionsComponent,
    ResearchComponent,
    NotificationComponent,
    WarningComponent,
    ArchiveComponent,
    ResearchSettingsComponent,
    WordpressSettingsComponent,
    MemberclicksLogComponent,
    CompanyApiLogComponent,
    BoardDirectorsComponent,
    BoardMatchComponent,
    BoardDirectorsLogComponent,
    MergeOptionsBoardComponent,
    DelistedCompaniesComponent,
  ],
  entryComponents: [NewConfigComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    RouterModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    MatSliderModule,
    MatPaginatorModule,
    MatDialogModule,
    MatMenuModule,
    MatButtonModule,
    MatTableModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    AuthModule.forRoot({
      ...env.auth,
      httpInterceptor: {
        allowedList: [`${env.dev.serverUrl}/api/messages/protected-message`],
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: env.production}),
    MatIconModule,
  ],
  providers: [ {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthHttpInterceptor,
    multi: true,
  },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
