<mat-dialog-content class="pop-up">
  <body>
  <button id="succeeded_snack" style="display: none" at-raised-button color="primary" (click)="snackbar_SucceededPost()">
    Snackbar CSS Styles</button>
  <button id="failed_snack" style="display: none" at-raised-button color="primary" (click)="snackbar_FailedPost()">
    Snackbar CSS Styles</button>
  <button id="refresh_group" (click)="Refresh()" style="display: none"></button>
  <img src="assets/img/cancel.svg" alt="cancel_icon" mat-dialog-close class="icon">
  <p class="new-config_h1" >Create Config</p>

  <p> 1. Please upload file
    <label for="input-file" class="button">Select File</label>
    <input id="input-file" type="file" accept=".xls, .xlsx" hidden ="hidden"/>
  </p>

  <table *ngIf="is_config_selected" class="sub-menu">
    <tr>
      <td *ngIf="is_config_selected" (change)="getFields()" class="file-name">
        File: {{fileName}}
      </td>
      <td class="td" (click)="Preview()">
        Preview
      </td>
      <td class="td" (click)="ImportFields()">
        Import Fields
      </td>
      <td class="td" (click)="RemoveField()">
        Remove field
      </td>
      <td class="td" mat-dialog-close>
        Remove config
      </td>
    </tr>
  </table>
  <div *ngIf="is_importfields_selected">
    <button *ngIf="!is_saveas_clicked" class="right-button" (click)="openForm2()">Save</button>
  </div>

  <div class="form-popup" id="myForm2">
    <form action="/action_page.php" class="form-container">
      <h1>Enter Config name</h1>

      <input type="text" placeholder="{{config_name}}" (change)="changed_ConfigName($event)" required>

      <button type="button" class="btn cancel" (click)="closeForm()">Close</button>
      <button type="submit" class="btn" (click)="save_ConfigName()">Save</button>
    </form>
  </div>
  <table *ngIf="is_importfields_selected" class="import-fields">
    <tr>
      <th *ngFor="let header of table_header" class="table-header">
        {{header}}
      </th>
    </tr>
    <tr *ngFor="let row of data" class="select_row">
      <td *ngFor="let cell of row" class="table-cell">
        {{cell}}
      </td>
    </tr>
  </table>
  <div *ngIf="is_preview_selected">
    <p>
      2. Compose Config: select new fields to join
    </p>
    <div class="fields">
      <table>
        <tr>
          <td>
            <table>
              <tr>
                <th colspan="2">
                  COMPANY
                </th>
              </tr>
              <tr *ngFor="let company_field of new_companyFields" (click)="addAliasToField('Company', company_field.sourceField)" class="hover">
                <td *ngIf="company_field.sourceField">
                  <div *ngIf="company_field.sourceField == 'CikId'">
                    CIK ID
                  </div>
                  <div *ngIf="company_field.sourceField == 'AnnualRevenue'">
                    Exact Annual Rev
                  </div>
                  <div *ngIf="company_field.sourceField == 'NumberOfEmployees'">
                    Employee Exact
                  </div>
                  <div *ngIf="company_field.sourceField == 'SICCode'">
                    SIC Code
                  </div>
                  <div *ngIf="company_field.sourceField == 'BusinessIndustry'">
                    Industry Category
                  </div>
                  <div *ngIf="company_field.sourceField == 'OwnershipTypeDesc'">
                    Ownership Type
                  </div>
                  <div *ngIf="company_field.sourceField == 'OwnershipSubType'">
                    Ownership Sub Type
                  </div>
                  <div *ngIf="company_field.sourceField == 'HowVerified'">
                    How Verified?
                  </div>
                  <div *ngIf="!renamedFieldCompany.includes(company_field.sourceField)">
                    {{company_field.sourceField}}
                  </div>
                </td>
                <td>
                  <button *ngIf="company_field.externalField" (click)="addToNewFields('Company', company_field.externalField)" class="field_button">
                    {{company_field.externalField}}</button>
                </td>
              </tr>
            </table>
          </td>
          <td>
            <table>
              <tr>
                <th colspan="2">
                  PERSON
                </th>
              </tr>
              <tr *ngFor="let person_field of new_personFields" (click)="addAliasToField('Person', person_field.sourceField)" class="hover">
                <td *ngIf="person_field.sourceField">
                  <div *ngIf="person_field.sourceField == 'CompanyId'">
                     Primary Co ID
                  </div>
                  <div *ngIf="person_field.sourceField == 'PrimaryAddress1'">
                     Shipping Address1
                  </div>
                  <div *ngIf="person_field.sourceField == 'PrimaryAddress2'">
                    Shipping Address2
                  </div>
                  <div *ngIf="person_field.sourceField == 'PrimaryCity'">
                    Shipping City
                  </div>
                  <div *ngIf="person_field.sourceField == 'PrimaryState'">
                    Shipping State
                  </div>
                  <div *ngIf="person_field.sourceField == 'PrimaryZip'">
                    Shipping Zip
                  </div>
                  <div *ngIf="person_field.sourceField == 'PrimaryCountry'">
                    Shipping Country
                  </div>
                  <div *ngIf="person_field.sourceField == 'AlternateAddressSource'">
                    Alternate Address Source
                  </div>
                  <div *ngIf="person_field.sourceField == 'AlternateAddressPreferred'">
                    Alternate Address Preferred
                  </div>
                  <div *ngIf="person_field.sourceField == 'AlternateAddressType'">
                    Alternate Address Type
                  </div>
                  <div *ngIf="person_field.sourceField == 'QualificationSource'">
                    BPA Qual Source
                  </div>
                  <div *ngIf="person_field.sourceField == 'BPAJobTitle'">
                    BPA Job Title
                  </div>
                  <div *ngIf="person_field.sourceField == 'OtherIndustry'">
                    Other Industry
                  </div>
                  <div *ngIf="person_field.sourceField == 'OwnershipSubType'">
                    Ownership Sub Type
                  </div>
                  <div *ngIf="!renamedFieldPerson.includes(person_field.sourceField)">
                    {{person_field.sourceField}}
                  </div>
                </td>
                <td>
                  <button *ngIf="person_field.externalField" (click)="addToNewFields('Person', person_field.externalField)" class="field_button">
                    {{person_field.externalField}}</button>
                </td>
              </tr>
            </table>
          </td>
          <td>
            <table>
              <tr>
                <th colspan="2">
                  ENGAGEMENTS
                </th>
              </tr>
              <tr *ngFor="let engagement_field of new_engagementFields" class="hover" (click)="addAliasToField('Engagement', engagement_field.sourceField)">
                <td *ngIf="engagement_field.sourceField">
                  {{engagement_field.sourceField}}
                </td>
                <td>
                  <button *ngIf="engagement_field.externalField" (click)="addToNewFields('Engagement', engagement_field.externalField)" class="field_button">
                    {{engagement_field.externalField}}</button>
                </td>
                <td  *ngIf="!engagement_field.sourceField">
                  No fields available
                </td>
              </tr>
            </table>
          </td>
          <td class="vertical_line">
          </td>
          <td>
            <table>
              <tr>
                <th>
                  NEW FIELDS
                </th>
              </tr>
              <tr *ngFor="let new_field of new_fields">
                <td (click)="selectedNewField(new_field)" id="new_field-{{new_field}}" class="hover">
                  {{new_field}}
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div *ngIf="is_remove_field">
    <table>
      <tr>
        <td>
          <table>
            <tr>
              <th colspan="2">
                COMPANY
              </th>
            </tr>
            <tr *ngFor="let company_field of new_companyFields" class="hover">
              <td *ngIf="company_field.externalField">
                <img src="assets/img/remove_field.svg" (click)="RemoveSpecificField('Company', company_field.externalField)" alt="remove_field_icon" class="remove_field">
                {{company_field.externalField}}
              </td>
            </tr>
          </table>
        </td>
        <td>
          <table>
            <tr>
              <th colspan="2">
                PERSON
              </th>
            </tr>
            <tr *ngFor="let person_field of new_personFields" class="hover">
              <td *ngIf="person_field.externalField">
                <img src="assets/img/remove_field.svg"  (click)="RemoveSpecificField('Person', person_field.externalField)" alt="remove_field_icon" class="remove_field">
                {{person_field.externalField}}
              </td>
            </tr>
          </table>
        </td>
        <td>
          <table>
            <tr>
              <th colspan="2">
                ENGAGEMENTS
              </th>
            </tr>
            <tr *ngFor="let engagement_field of new_engagementFields">
              <td *ngIf="engagement_field.externalField" class="hover">
                <img src="assets/img/remove_field.svg"  (click)="RemoveSpecificField('Engagement', engagement_field.externalField)" alt="remove_field_icon" class="remove_field">
                {{engagement_field.externalField}}
              </td>
            </tr>
          </table>
        </td>
        <td>
          <table>
            <tr>
              <th colspan="2">
                NEW FIELDS
              </th>
            </tr>
            <tr *ngFor="let field of new_fields">
              <td *ngIf="field" class="hover">
                <img src="assets/img/remove_field.svg"  (click)="RemoveSpecificField('NewFields', field)" alt="remove_field_icon" class="remove_field">
                {{field}}
              </td>
              <td *ngIf="!field">
                No fields available
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>
  <div *ngIf="fileName">
    <div *ngIf="!is_saved">
      <button class="right-button" *ngIf="!is_saveas_clicked" class="right-button" (click)="openForm()"> Save</button>
    </div>
  </div>

  <div class="form-popup" id="myForm">
    <form action="/action_page.php" class="form-container">
      <h1>Enter Config name</h1>

      <input type="text" placeholder="{{config_name}}" (change)="changed_ConfigName($event)" required>

      <button type="button" class="btn cancel" (click)="closeForm()">Close</button>
      <button type="submit" class="btn" (click)="save_ConfigName()">Save</button>
    </form>
  </div>

  </body>
</mat-dialog-content>
