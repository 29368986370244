import {Component, OnInit, ViewChild} from '@angular/core';
import {environment as env} from '../../environments/environment';
import {MatPaginator} from '@angular/material/paginator';
import {SharedService} from '../shared/sharedService/shared.service';
import {ConfigService} from '../config/config.service';
import {BoardDirector} from '../shared/boardDirectors/boardDirectorModel';
import {MatTableDataSource} from '@angular/material/table';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-board-directors-log',
  templateUrl: './board-directors-log.component.html',
  styleUrls: ['./board-directors-log.component.scss']
})
export class BoardDirectorsLogComponent implements OnInit {

  constructor(public sharedService: SharedService, public api: ConfigService) { }
  environment: string;
  boardDirectors: BoardDirector[];
  dataSource;
  displayedColumns: string[] = ['personId', 'firstName', 'lastName', 'primaryCompany', 'boardCompanyCik', 'boardCompanyId', 'importedInAmplify', 'createdDate'];
  filteredColumns = this.displayedColumns.filter(x => x !== 'createdDate');
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataFilter = '';
  pageNumber: 0;
  pageSize = 50;
  length: number;
  totalSize: number;
  ngOnInit(): void {
    this.environment =  env.environment ;
    this.getDirectors();
  }
  getTableHeaderToDisplay(attribute: string): string{
    switch (attribute){
      case 'personId':
        return 'Person Id';
      case 'primaryCompany':
        return 'Primary Company';
      case 'boardCompanyCik':
        return 'Board CIK ID';
      case 'boardCompanyId':
        return 'Board Company Id';
      case 'firstName':
        return 'First';
      case 'lastName':
        return 'Last';
      case 'importedInAmplify':
        return 'Imported In Amplify';
    }
  }
  linkToCeo(id: number): void{
    this.sharedService.linkToPerson(id);
  }
  getDirectors(): void {
      this.api.getBoardDirectorsLog(this.pageNumber, this.pageSize, this.dataFilter).subscribe(result => {
        console.log(result);
        this.boardDirectors = result.item1;
        this.totalSize = result.item2;
        this.dataSource = new MatTableDataSource<BoardDirector>(result.item1);
        console.log(this.dataSource);
        console.log(this.filteredColumns);
      });
  }
  onPageFired(event: any): void{
    console.log('get page', this.paginator);
    this.pageNumber = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getDirectors();
  }
  searchBoards(): void{
    this.pageNumber = 0;
    if ( this.dataFilter !== ''){
      this.dataFilter = new DatePipe('en-US').transform(this.dataFilter, 'MM/dd/yyyy');
    }
    this.getDirectors();
  }
}
