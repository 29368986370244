import { Component, OnInit } from '@angular/core';
import {Jobs} from '../shared/Jobs';
import {ConfigService} from '../config/config.service';
import {AuthService} from '@auth0/auth0-angular';
import {Router} from '@angular/router';
import {AppComponent} from '../app.component';
import {WarningComponent} from '../warning/warning.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {MemberClicksData} from '../shared/MemberClicksData';
import {DatePipe} from '@angular/common';
import {environment as env} from '../../environments/environment';

@Component({
  selector: 'app-memberclicks-log',
  templateUrl: './memberclicks-log.component.html',
  styleUrls: ['./memberclicks-log.component.scss']
})
export class MemberclicksLogComponent implements OnInit {
    environment: string;
    token: string;
  // tslint:disable-next-line:variable-name
  time_last_job_started = '';
  href = 'https://dev.ceo360.pro/#/';
  currentPage = 1;
  fetchData = 20;
  totalPages = 20;
  offset = 0;
  totalCounts = 0;
  filterData = '';
  jobs: Jobs[] = [{
    key: '',
    type: '',
    inProcess: false,
    state: ''
  }];
  // auth0 data
  user: string;
  userEmail: string;
  userRole: string;
  // tslint:disable-next-line:variable-name
  are_jobsVisible = false;
  areRulesVisible = false;
  isAdmin: boolean;
  profileJson: string = null;
  showOverlay = false;
  memberClicksData: MemberClicksData[] = [];
  constructor(private api: ConfigService,
              public auth: AuthService,
              private route: Router,
              private appcomponent: AppComponent,
              private snackBar: MatSnackBar,
              public dialog: MatDialog)
  {
    this.appcomponent.jobChecking();
  }
  // tslint:disable-next-line:typedef
  snackbar_SucceededPost() {
    const sb = this.snackBar.open('Your settings have successfully been saved!', '', {
      duration: 1000,
      panelClass: ['succeed'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
  // tslint:disable-next-line:typedef
  snackbar_FailedPost() {
    const sb = this.snackBar.open('Your settings are NOT saved!', '', {
      duration: 1000,
      panelClass: ['failed'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
  GetTimeStamp(): void{
    this.time_last_job_started = localStorage.getItem('date');
  }
  showRunningJobs(): void{
    this.GetTimeStamp();
    this.api.get_Jobs().subscribe(res => {
          // @ts-ignore
          this.jobs = res;
          for (const i of this.jobs){
            if (i.type === 'CegIntegration.Web.Jobs.MainDataSynchronizationJob'){
              this.jobs.splice(this.jobs.indexOf(i), 1);
            }
          }
          this.are_jobsVisible = !(this.are_jobsVisible);
          if (this.are_jobsVisible){
            const img = document.getElementById('research-running-jobs');
            img.style.transform = 'rotate(180deg)';
          }
          else{
            const img = document.getElementById('research-running-jobs');
            img.style.transform = 'rotate(0deg)';
          }
        },
        err => {
          console.log(err);
        });
  }
  deleteJob(jobKey: any): void{
    const dialogRef = this.dialog.open(WarningComponent);
    dialogRef.componentInstance.action = 'DeleteJob';

    dialogRef.afterClosed().subscribe(result => {
      console.log(`popup result: ${result}`);
      const response = result;
      if (response === 'yes'){
        this.ProceedDeleteJob(jobKey);
      }
    });
  }
  ProceedDeleteJob(jobKey: any): void{
    const url = this.api.delete_Job(jobKey.toString());

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
    xhr.setRequestHeader('Content-Type', 'application/json-patch+json');
    xhr.setRequestHeader('accept', '*/*');

    // tslint:disable-next-line:only-arrow-functions typedef
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        // tslint:disable-next-line:triple-equals
        if (xhr.status == 200){
          console.log('ok');
          document.getElementById('succeeded_snack').click();
          document.getElementById('refreshJobs').click();
        }
        else{
          document.getElementById('failed_snack').click();
        }
      }
    };
    xhr.send();
  }

  refreshJobs(): void{
    this.api.get_Jobs().subscribe(res => {
          // @ts-ignore
          this.jobs = res;
          for (const i of this.jobs){
            if (i.type === 'CegIntegration.Web.Jobs.MainDataSynchronizationJob'){
              this.jobs.splice(this.jobs.indexOf(i), 1);
            }
          }
        },
        err => {
          console.log(err);
        });
  }

    showRules(): void{
        this.areRulesVisible = !(this.areRulesVisible);
        if (this.areRulesVisible){
            const img = document.getElementById('show-rules');
            img.style.transform = 'rotate(180deg)';
        }
        else{
            const img = document.getElementById('show-rules');
            img.style.transform = 'rotate(0deg)';
        }
    }

    LinkToWP(wpId: number): void{
        let link = 'https://chiefexecutive.net/wp-admin/user-edit.php?user_id=';
        link += wpId;
        const win = window.open(link, '_blank');
        win.focus();
    }

  showTable(): void {
    this.showOverlay = true;
    let dataToFilter = '';
    console.log('this.filterData', this.filterData);
    if ( this.filterData !== '' ){
        dataToFilter = new DatePipe('en-US').transform(this.filterData, 'MM/dd/yyyy');
    }
    this.api.get_MemberClicks_Table(this.offset, this.fetchData, dataToFilter).subscribe(res => {
          if (res){
            // @ts-ignore
            this.memberClicksData = res;
            this.totalCounts = this.memberClicksData[0].totalCounts;
          }else{
            this.memberClicksData = null;
            this.totalCounts = 0;
          }
          this.showOverlay = false;
          this.getTotalPages();
        },
        err => {
          console.log(err);
        });
  }

  filterByDate(): void{
    console.log('fitlerData', this.filterData );
    this.offset = 0;
    this.currentPage = 1;
    let dataToFilter = '';
    if ( this.filterData !== ''){
      dataToFilter = new DatePipe('en-US').transform(this.filterData, 'MM/dd/yyyy');
    }
    this.showOverlay = true;
    this.api.get_MemberClicks_Table(this.offset, this.fetchData, dataToFilter).subscribe(res => {
          console.log(res);
          // @ts-ignore
          this.memberClicksData = res;
          if ( this.memberClicksData.length > 0){
            this.totalCounts = this.memberClicksData[0].totalCounts;
          }
          else{
            this.totalCounts = 0;
          }
          this.showOverlay = false;
          this.getTotalPages();
        },
        err => {
          console.log(err);
        });
  }
    goToFirstPage(): void {
        this.currentPage = 1;
        this.resetOffset();
    }
    // tslint:disable-next-line:typedef
    goToLastPage(): void {
        this.currentPage = this.totalPages;
        this.offset = ((this.currentPage - 1) * this.fetchData);
        this.showTable();
    }
    nextPage(): void {
        if (this.currentPage < this.totalPages) {
            // @ts-ignore
            this.currentPage++;
            this.resetOffset();
        }
        console.log('page nr', this.currentPage);
    }
    previousPage(): void {
        if (this.currentPage > 1) {
            // @ts-ignore
            this.currentPage--;
            this.resetOffset();
        }
        console.log('page nr', this.currentPage);
    }
    resetOffset(): void {
        this.offset = ((this.currentPage - 1) * this.fetchData);
        this.showTable();

    }
  LinkToCeo(id: number): void{
    // tslint:disable-next-line:max-line-length
    if (window.location.href === 'http://localhost:4200/#/memberclicks-log' || window.location.href === 'https://amplify-dev.ceo360.pro/#/memberclicks-log') {
      this.href = 'https://dev.ceo360.pro/#/';
    }
    if (window.location.href === 'https://amplify.ceo360.pro/#/memberclicks-log') {
      this.href = 'https://ceo360.pro/#/';
    }
    this.href += 'Person/' + id;
    const win = window.open(this.href, '_blank');
    win.focus();
  }
  getTotalPages(): void {
    if (this.totalCounts % this.fetchData) {
      this.totalPages = Math.floor(this.totalCounts / this.fetchData) + 1;
    } else {
      this.totalPages = Math.floor(this.totalCounts / this.fetchData);
    }
    console.log('totalpages', this.totalPages);
  }
  ngOnInit(): void {
      this.api.getAuthToken().subscribe(auth => {
        this.token = auth;
      });
      this.environment =  env.environment ;
      this.auth.user$.subscribe(
        (profile) => {
          (this.profileJson = JSON.parse(JSON.stringify(profile, null, 2)));
          localStorage.setItem('userRole', profile['https://schemas.quickstarts.com/roles'][0]);
          this.userRole = profile['https://schemas.quickstarts.com/roles'][0];
          if (this.userRole === 'admin'){
            this.isAdmin = true;
          }
          else{
            this.isAdmin = false;
          }
        });
      this.showTable();
  }
}
