<app-navbar></app-navbar>
<body>
<button id="succeeded_snack" class="snack_button" at-raised-button color="primary" (click)="snackbar_SucceededPost()">
    Snackbar CSS Styles
</button>
<button id="failed_snack" class="snack_button" at-raised-button color="primary" (click)="snackbar_FailedPost()">
    Snackbar CSS Styles
</button>
<div class="my-overlay" *ngIf="showOverlay">
    <div class="spinner">
        <span class="spinner-inner-1"></span>
        <span class="spinner-inner-2"></span>
        <span class="spinner-inner-3"></span>
    </div>
</div>
<h1>
    <a class="black-box">
        Public CO Api Log
    </a>
    <a *ngIf="environment==='Production'" class="page-env prod" >PRODUCTION</a>
    <a *ngIf="environment==='Development'" class="page-env dev" >DEVELOPMENT</a>
</h1>
<table>
    <tr class="full-width">
        <td>
            <p class="check-jobs" (click)="showRunningJobs()">
                Check running jobs
                <img id="research-running-jobs" src="assets/img/down_simple_arrow.svg" alt="down_icon"
                     class="icon-right">
            </p>
            <div *ngIf="are_jobsVisible" id="running-jobs" class="running-jobs">
                <div *ngIf="jobs">
                    <p *ngIf="time_last_job_started !== ''">Last job started at: {{time_last_job_started}}</p>
                    <p>There are {{jobs.length}} job(s) running</p>
                    <div *ngFor="let job of jobs">
                        <p>
                            Job type: {{job.type}}
                        </p>
                        <p>
                            Job state: {{job.state}}
                        </p>
                        <button (click)="DeleteJob(job.key.toString())" class="button" style="margin: 0 0 0 1%">Delete
                        </button>
                        <p>--------------------------------------------------------------</p>
                    </div>
                </div>
            </div>
        </td>
    </tr>
</table>

<table>
    <tr class="full-width">
        <td>
            <p class="check-jobs" (click)="showRules()">
                <img src="assets/img/information-request-icon.webp" alt="down_icon" class="icon-rules">
                <img id="show-rules" src="assets/img/down_simple_arrow.svg" alt="down_icon"
                     class="icon-right">
            </p>
            <div *ngIf="areRulesVisible" id="running-jobs" class="running-jobs">
                <p>Excluded column is true for the companies that have : </p>
                <ul class="rules">
                    <li>Ownership sub type = Fund</li>
                    <li>Exchange does not = NYSE, NASDAQ, NYSE AMER or OTC</li>
                    <li>Address is not US</li>
                    <li>New data processed every Tuesday</li>
                </ul>
            </div>
        </td>
    </tr>
</table>
<div class="table-content">
    <div  class="running-jobs" *ngIf="mappingsDetails">
        <p *ngIf="dataFilter!==''">Total number from <b>{{dataFilter | date:'MM/dd/yyyy'}}</b> </p>
        <p *ngIf="dataFilter===''">Total number </p>
        <p>Companies imported in Amplify: <b>{{mappingsDetails.importedInAmplify}}</b></p>
        <p>Companies excluded from process: <b>{{mappingsDetails.excluded}}</b></p>
        <p>Companies mapped directly to ceo360: <b>{{mappingsDetails.mapped}}</b></p>
        <button  (click)="seeDelisted()"> See Potential Delisted</button>
    </div>
    <form (ngSubmit)="searchCompanies()" (keyup.enter)="searchCompanies()">
        <table class="table_header-wp">
            <tr  class="table-header-wp-content">
                <th class="table_header-th"> CIK ID
                    <div>
                        <input type="number" name="searchCikId" placeholder="Search by CIK ID"
                               [(ngModel)]="searchFilter.cikId">
                    </div>
                </th>
                <th class="table_header-th"> Mapped to Ceo360 Id
                    <div>
                        <input type="number" name="searchCeoId" placeholder="Search by Ceo360 Id" [(ngModel)]="searchFilter.companyId">
                    </div>
                </th>
                <th class="table_header-th" > Ticker</th>
                <th class="table_header-th" > Exchange</th>
                <th class="table_header-th" > Imported in Amplify</th>
                <th class="table_header-th" > Excluded</th>
                <th class="table_header-th" >
                    <div class="date-picker">Date added
                        <input (ngModelChange)="dataFilter=$event" (change)="searchCompanies()" [(ngModel)]="dataFilter" class="date-added-sort "[ngModelOptions]="{standalone: true}" type="date">
                    </div>
                </th>
            </tr>
            <tr *ngFor="let data of companies; let i = index;">
                <td>
                    <div class="">
                        <a>{{data.cikId}}</a>
                    </div>
                </td>
                <td>
                    <div class="ceoId" *ngIf="data.companyId">
                        <a (click)="LinkToCeo(data.companyId)">
                            {{data.companyId}}
                        </a>
                    </div>
                </td>
                <td>
                    <div>
                        {{data.ticker}}
                    </div>
                </td>
                <td>
                    <div>
                        {{data.exchange}}
                    </div>
                </td>
                <td>
                    <div  *ngIf="data.excludedFromProcess !== true">
                        {{data.importedInAmplify}}
                    </div>
                </td>
                <td>
                    <div *ngIf="data.excludedFromProcess === true">
                        {{data.excludedFromProcess}}
                    </div>
                </td>

                <td>
                    <div class="date-picker "> {{data.createdDate}}</div>
                </td>

            </tr>
        </table>
    </form>


</div>
<div class="pagination" *ngIf="companies.length>0">
    <div>
        <div *ngIf="currentPage < totalPages; else lastPage">{{fetchData * currentPage - fetchData + 1}} - {{fetchData * currentPage}}
            from {{totalCounts}}</div>
        <ng-template #lastPage>{{fetchData * currentPage - fetchData + 1}} - {{totalCounts}} from {{totalCounts}}</ng-template>
    </div>
    <div class="back" (click)="goToFirstPage()"> <<</div>
    <div class="back" (click)="previousPage()"> <</div>
    <div class="current-page">Current Page:  {{currentPage}}</div>
    <div  *ngIf="currentPage < totalPages" class="forward" (click)="nextPage()"> > </div>
    <div  *ngIf="currentPage < totalPages" class="forward" (click)="goToLastPage()"> >></div>
    <div  *ngIf="currentPage == totalPages" style="margin-right: 5vh" class="forward"></div>
</div>
<div class="no-data" *ngIf="companies.length==0">
    No data available
</div>
</body>
