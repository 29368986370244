import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ReviewComponent} from '../review/review.component';

@Component({
  selector: 'app-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.scss']
})
export class WarningComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<WarningComponent>) { }
  action: string;
  group: string;
  isBulckAnction = false;
  Response(resp: string): void{
    this.dialogRef.close(resp);
  }
  ngOnInit(): void {
    switch (this.action){
      case 'AddToMainDatabase': {
        this.action = 'Select';
        break;
      }
      case 'ExcludeFromGroup': {
        this.action = 'Kick';
        break;
      }
      case 'DeleteJob': {
        this.action = 'DeleteJob';
        break;
      }
      case 'Delete': {
        this.action = 'Delete';
        break;
      }
      case 'ResearchGroup': {
        this.action = 'ResearchGroup';
        break;
      }
      case 'Research': {
        this.action = 'Research';
        break;
      }
      case 'KickAll': {
        this.action = 'KickAll';
        break;
      }
    }
  }
}
