<div class="match-container">
    <div class="content">
        <div>
            <p>Construct
                the board association that will be sent to the main database</p>
        </div>
        <div class="table-container">
            <div class="table-div">
                <table class="merge-table" cellspacing="0" cellpadding="0">
                    <tr>
                        <td class="merge-th">PersonId</td>
                        <td class="merge-th">First Name</td>
<!--                        <td class="merge-th">Middle Name</td>-->
                        <td class="merge-th">Last Name</td>
                        <td class="merge-th">Primary Company ID</td>
                        <td class="merge-th">Primary Title</td>
                        <td class="merge-th">Board Company CIK ID</td>
                        <td class="merge-th">Board Company Name</td>
                        <td class="merge-th">Director Since</td>
                        <td class="merge-th">Independent</td>
                        <td class="merge-th">Audit</td>
                        <td class="merge-th">Comp</td>
                        <td class="merge-th">Gov/Nom</td>
                    </tr>
                    <tr [formGroup]="boardControl">
                        <td class="merge-td">
                            <mat-select class="merge-select"
                                        (selectionChange)="constructMergedBoard('PersonId', $event)"
                                        [(ngModel)]="finalObject.personId" [ngModelOptions]="{standalone: true}">
                                <mat-option *ngFor="let i of boardDirectorsToMerge"
                                            [value]="i.personId">{{i.personId}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="select-firstName"
                                        (selectionChange)="constructMergedBoard('FirstName', $event)"
                                        formControlName="firstName" name="firstName">
                                <mat-option *ngFor="let firstName of boardSelection.firstName" [value]="firstName">
                                    {{firstName}}
                                </mat-option>
                            </mat-select>
                        </td>
<!--                        <td class="merge-td">-->
<!--                            <mat-select class="merge-select" id="select-middleName"-->
<!--                                        (selectionChange)="constructMergedBoard('MiddleName', $event)"-->
<!--                                        formControlName="middleName" name="middleName">-->
<!--                                <mat-option *ngFor="let middleName of boardSelection.middleName"-->
<!--                                            [value]="middleName">-->
<!--                                    {{middleName}}-->
<!--                                </mat-option>-->
<!--                            </mat-select>-->
<!--                        </td>-->
                        <td class="merge-td">
                            <mat-select class="merge-select" id="select-lastName"
                                        (selectionChange)="constructMergedBoard('LastName', $event)"
                                        formControlName="lastName" name="lastName">
                                <mat-option *ngFor="let lastName of boardSelection.lastName" [value]="lastName">
                                    {{lastName}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="select-primaryCompanyId"
                                        (selectionChange)="constructMergedBoard('PrimaryCompanyId', $event)"
                                        formControlName="primaryCompanyId" name="primaryCompany">
                                <mat-option *ngFor="let company of boardSelection.primaryCompanyId" [value]="company">
                                    {{company}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="select-primaryTitle"
                                        (selectionChange)="constructMergedBoard('PrimaryTitle', $event)"
                                        formControlName="primaryTitle"  name="title">
                                <mat-option *ngFor="let title of boardSelection.primaryTitle" [value]="title">
                                    {{title}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="select-boardCompanyCik"
                                        (selectionChange)="constructMergedBoard('BoardCikId', $event)"
                                        formControlName="boardCompanyCik"  name="boardCikId">
                                <mat-option *ngFor="let cikId of boardSelection.boardCompanyCik" [value]="cikId">
                                    {{cikId}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="select-boardCompanyName"
                                        (selectionChange)="constructMergedBoard('BoardName', $event)"
                                        formControlName="boardCompanyName"  name="boardCompanyName">
                                <mat-option *ngFor="let boardName of boardSelection.boardCompanyName" [value]="boardName.value">
                                    {{boardName.value}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="select-directorSince"
                                        (selectionChange)="constructMergedBoard('DirectorSince', $event)"
                                        formControlName="directorSince"  name="directorSince">
                                <mat-option *ngFor="let directorSince of boardSelection.directorSince"
                                            [value]="directorSince">
                                    {{directorSince}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td" >
                            <mat-select class="merge-select" id="select-insideOutside"
                                        (selectionChange)="constructMergedBoard('InsideOutside', $event)"
                                        formControlName="insideOutside"  >
                                <mat-option *ngFor="let insideOutside of boardSelection.insideOutside"
                                            [value]="insideOutside">{{insideOutside}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td" >
                            <mat-select class="merge-select" id="select-audit"
                                        (selectionChange)="constructMergedBoard('Audit', $event)"
                                        formControlName="audit">
                                <mat-option *ngFor="let audit of boardSelection.audit"
                                            [value]="audit">{{audit}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="select-comp"
                                        (selectionChange)="constructMergedBoard('Comp', $event)"
                                        formControlName="comp">
                                <mat-option *ngFor="let comp of boardSelection.comp"
                                            [value]="comp">{{comp}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="select-govNom"
                                        (selectionChange)="constructMergedBoard('GovNom', $event)"
                                        formControlName="govNom">
                                <mat-option *ngFor="let govNom of boardSelection.govNom"
                                            [value]="govNom">{{govNom}}</mat-option>
                            </mat-select>
                        </td>

                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div class="div-proceed-btn">
        <button class=" btn proceed-btn" (click)="processManualMerge()"  >Proceed</button>
    </div>
    <div class="div-cancel-btn">
        <button class="btn cancel-button" mat-dialog-close>Cancel</button>
    </div>
</div>
