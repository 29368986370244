<app-navbar></app-navbar>

<body>
<button id="succeeded_snack" class="snack_button" at-raised-button color="primary" (click)="snackbar_SucceededPost()">
    Snackbar CSS Styles
</button>
<button id="failed_snack" class="snack_button" at-raised-button color="primary" (click)="snackbar_FailedPost()">
    Snackbar CSS Styles
</button>
<div class="my-overlay" *ngIf="showOverlay">
    <div class="spinner">
        <span class="spinner-inner-1"></span>
        <span class="spinner-inner-2"></span>
        <span class="spinner-inner-3"></span>
    </div>
</div>

<h1>
    <a class="black-box">
        <img src="assets/img/review_settings_white.svg" alt="search_icon" class="icon">
        Research Settings
    </a>
    <a *ngIf="environment==='Production'" class="page-env prod" >PRODUCTION</a>
    <a *ngIf="environment==='Development'" class="page-env dev" >DEVELOPMENT</a>
</h1>
<table>
    <tr class="full-width">
        <td>
            <p class="check-jobs" (click)="showRunningJobs()">
                Check running jobs
                <img id="research-running-jobs" src="assets/img/down_simple_arrow.svg" alt="down_icon"
                     class="icon-right">
            </p>
            <div *ngIf="are_jobsVisible" id="running-jobs" class="running-jobs">
                <div *ngIf="jobs">
                    <p *ngIf="time_last_job_started !== ''">Last job started at: {{time_last_job_started}}</p>
                    <p>There are {{jobs.length}} job(s) running</p>
                    <div *ngFor="let job of jobs">
                        <p>
                            Job type: {{job.type}}
                        </p>
                        <p>
                            Job state: {{job.state}}
                        </p>
                        <button (click)="deleteJob(job.key.toString())" class="button" style="margin: 0 0 0 1%">Delete
                        </button>
                        <p>--------------------------------------------------------------</p>
                    </div>
                </div>
            </div>
            <button (click)="selectedAction('researchAreas')" id="researchAreas" class="datatype-button">See assigned
                research areas
            </button>
            <button (click)="selectedAction('moveGroups')" id="moveGroups" class="datatype-button">Move multiple groups
            </button>
        </td>
    </tr>
</table>
<div *ngIf="selectResearchAreas">
    <table style="width: 60%" class="table_researchUsers">
        <tr *ngFor="let user of users; let i = index;">
            <td *ngIf="user.teamMemberName !== 'Giorgiana Lazea' && user.teamMemberName !== 'Cristina Badea'">
                {{user.teamMemberName}}
            </td>
            <td>
                <mat-form-field
                        *ngIf="user.teamMemberName !== 'Giorgiana Lazea' && user.teamMemberName !== 'Cristina Badea'"
                        appearance="fill" class="mat-dropdown">
                    <mat-label>Select Research Area</mat-label>
                    <mat-select (selectionChange)="selected_research_area($event, user.id)" [multiple]="true"
                                 [(ngModel)]="user.listOfResearchAreas">
                        <mat-option *ngFor="let res of research" [value]="res.researchId">
                            {{ res.researchName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </tr>
    </table>
    <div class="confirm">
        <button (click)="saveChanges()" class="datatype-button">Confirm changes</button>
    </div>

</div>
<div class="selectedDataType" *ngIf="moveGroupsToResearch">
    <table>
        <tr>
            <td class="spacing" (click)="selected_datatype('Company')" id="company-results">See Company Groups</td>
            <td class="spacing" (click)="selected_datatype('Person')" id="person-results">See Person Groups</td>
        </tr>
    </table>
</div>
<div *ngIf="selectedDataType == 'Person'" class="person-groups">
    <table>
        <tr>
            <td class="header-groups"> Research Areas</td>
            <td class="header-groups"> Across Table Groups</td>
        </tr>
        <tr>
            <td>
                <mat-form-field appearance="fill" class="mat-dropdown">
                    <mat-select [multiple]="false" placeholder="Select Research Area"
                                (selectionChange)="selectedResearchArea( $event, 'AcrossTable')">
                        <mat-option *ngFor="let res of research" [value]="res.researchId">
                            {{ res.researchName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
            <td>
                <mat-form-field appearance="fill" class="mat-dropdown">
                    <mat-select [formControl]="acrossTableGroups" [multiple]="true"
                                placeholder="Select group from Across Table"
                                (selectionChange)="selectedGroupsToBeSent( $event, 'AcrossTable')">
                        <mat-option #allSelected [value] = 0 (click)="toggle()" >
                            Select All
                        </mat-option>
                        <mat-option *ngFor="let group of this.statistics?.listOfGroupsIds" [value]="group">
                            {{ group }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td class="header-groups"></td>
            <td class="header-groups"> Api Data Groups</td>
        </tr>
        <tr>
            <td>
                <mat-form-field appearance="fill" class="mat-dropdown">
                    <mat-select [multiple]="false" placeholder="Select Research Area"
                                (selectionChange)="selectedResearchArea( $event, 'PersonApiData')">
                        <mat-option *ngFor="let res of research" [value]="res.researchId">
                            {{ res.researchName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
            <td>
                <mat-form-field appearance="fill" class="mat-dropdown">
                    <mat-select [formControl]="WordpressTableGroups" [multiple]="true" placeholder="Select group from WordPress Table"
                                (selectionChange)="selectedGroupsToBeSent( $event, 'PersonApiData')">
                        <mat-option #allSelectedWordpress [value] = 0 (click)="toggleWordpress()" >
                            Select All
                        </mat-option>
                        <mat-option *ngFor="let group of statisticsWordpress?.listOfWordpressGroupsIds" [value]="group">
                            {{ group }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </tr>
    </table>
    <div class="confirm">
        <button (click)="proceedSelection('Person')" class="datatype-button">Proceed</button>
    </div>
</div>

<div *ngIf="selectedDataType == 'Company'" class="company-groups">
    <table>
        <tr>
            <td class="header-groups"> Research Areas</td>
            <td class="header-groups"> Across Table groups</td>
        </tr>
        <tr>
            <td>
                <mat-form-field appearance="fill" class="mat-dropdown">
                    <mat-select [multiple]="false" placeholder="Select Research Area"
                                (selectionChange)="selectedResearchArea( $event, 'AcrossTable')">
                        <mat-option *ngFor="let res of research" [value]="res.researchId">
                            {{ res.researchName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
            <td>
                <mat-form-field appearance="fill" class="mat-dropdown">
                    <mat-select [formControl]="acrossTableGroups" [multiple]="true" placeholder="Select group from Across Table"
                                (selectionChange)="selectedGroupsToBeSent( $event, 'AcrossTable')">
                        <mat-option #allSelected [value] = 0 (click)="toggle()" >
                            Select All
                        </mat-option>
                        <mat-option *ngFor="let group of this.statistics?.listOfGroupsIds" [value]="group">
                            {{ group }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td class="header-groups"> Research Areas</td>
            <td class="header-groups"> Public CO API groups</td>
        </tr>
        <tr>
            <td>
                <mat-form-field appearance="fill" class="mat-dropdown">
                    <mat-select [multiple]="false" placeholder="Select Research Area"
                                (selectionChange)="selectedResearchArea( $event, 'PublicCoApi')">
                        <mat-option *ngFor="let res of research" [value]="res.researchId">
                            {{ res.researchName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
            <td>
                <mat-form-field appearance="fill" class="mat-dropdown">
                    <mat-select [formControl]="publicCoApiGroups" [multiple]="true" placeholder="Select group from Public Co Api Data"
                                (selectionChange)="selectedGroupsToBeSent( $event, 'PublicCoApi')">
                        <mat-option #allSelectedPublicCoApi [value] = 0 (click)="togglePublicCoApi()" >
                            Select All
                        </mat-option>
                        <mat-option *ngFor="let group of this.publicCoDataStatistics?.listOfGroupsIds" [value]="group">
                            {{ group }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </tr>
    </table>
    <div class="confirm">
        <button (click)="proceedSelection('Company')" class="datatype-button">Proceed</button>
    </div>
</div>
