<app-navbar></app-navbar>

<body class="body_container">
<button id="succeeded_snack" class="snack_button" at-raised-button color="primary" (click)="snackbar_SucceededPost()">
  Snackbar CSS Styles</button>
<button id="failed_snack" class="snack_button" at-raised-button color="primary" (click)="snackbar_FailedPost()">
  Snackbar CSS Styles</button>
<div class="my-overlay" *ngIf="showOverlay">
  <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
</div>

<h1>
  <a class="black-box">
    <img src="assets/img/search_white.svg" alt="search_icon" class="logo_icon">
    Search Data
  </a>
<!--  <a class="production">PRODUCTION</a>-->
  <a class="development">DEVELOPMENT</a>
</h1>

<h2 (click)="Down(1)">
  Simple Search
  <img id="simple-down-arrow" src="assets/img/down_simple_arrow.svg" alt="down_icon" class="icon-right">
</h2>
<div *ngIf="is_simple_search">
  <p (click)="showSearchSettings()" class="search_settings">
    Search Settings
    <img id="settings-down-arrow" src="assets/img/down_simple_arrow.svg" alt="down_icon" class="icons">
  </p>
  <div *ngIf="is_show_searchSettings" class="show_search_settings">
    <table class="simple_search">
      <tr>
        <td>Exact Match</td>
        <td>Fuzzy Match Level</td>
      </tr>
      <tr>
        <td>
          <label for="exact_match_simple">
            <input type="checkbox" id="exact_match_simple" [checked]="checked" [ngModel]="checked" (click)="construct_ExactMatch('')">
          </label>
        </td>
        <td>
          <label>
            <input *ngIf="!is_Exact_match" class="imput-number" (change)="construct_Fuzzy($event, '')">
          </label>
        </td>
        <td class="table_button">
          <button class="simple-button" (click)="SaveSettings()">Save Settings</button>
        </td>
      </tr>
    </table>
  </div>
  <table class="simple_search">
    <tr>
      <td>Data Type</td>
      <td>Field</td>
      <td>Value for search</td>
      <td></td>
    </tr>
    <tr>
      <td>
        <label>
          <select [(ngModel)]="selectedDataType" (change)="selected()" class="dropdown">
            <option>Select</option>
            <option *ngFor="let type of datatypes" [ngValue]="type">{{type}}</option>
          </select>
        </label>
      </td>
      <td>
        <label>
          <select [(ngModel)]="selectedField" id="selected_type" class="dropdown">
            <option>Select</option>
            <option *ngFor="let field of fields" [ngValue]="field.name">{{field.name}}</option>
          </select>
        </label>
      </td>
      <td>
        <label>
          <input id="searchInput" [(ngModel)]="value_for_search">
        </label>
      </td>
      <td class="table_button" class="no_overflow">
        <button id="btnSave" class="button" (click)="Search()">Search</button>
      </td>
    </tr>
  </table>
</div>
<h2 (click)="Down(2)">
  Advanced Search
  <img id="advanced-down-arrow" src="assets/img/down_simple_arrow.svg" alt="down_icon" class="icon-right">
</h2>
<div *ngIf="is_advanced_search">
  <table>
    <tr class="datatypes">
      <td>
        Data Type:
        <label *ngFor="let type of datatypes">
          <input type="radio" name="datatype" value="{{type}}" (change)="select_advanced($event)">
          <i>{{type}}</i>
        </label>
      </td>
    </tr>
  </table>
  <p></p>

  <tr *ngIf="!is_dataType_ready">
    <td colspan="8">
      Please select a datatype!
    </td>
  </tr>
  <table cellspacing="0" cellpadding="0" class="advanced_search" *ngIf="is_dataType_ready">
    <tr>
      <td class="checklist">
        <p *ngFor="let field of fields">
          <label for="checkbox-{{field.name}}">
          <input type="checkbox" id="checkbox-{{field.name}}" name="{{field.name}}" value="{{field.name}}" [checked]="all_boxes_checked"
                 (change)="checked_values($event)">
          {{field.name}}</label><br>
        </p>
      </td>

      <td>
        <table class="in-table">
          <tr>
            <th>Group</th>
            <th>Value</th>
            <th>Exact <br> match</th>
            <th>Fuzzy <br> level</th>
            <th>Ignore <br> Null values</th>
            <th>Knowledge <br> base Library</th>
            <th>Group <br>Level</th>
            <th>Weight<br> in group</th>
          </tr>

          <td colspan="8" *ngIf="is_Group_ready">
            <table *ngFor="let group of new_groups" class="in-table">
              <tr>
                <td colspan="8" class="list-header">
                  <label for="selecetd_group-{{group.GroupId}}" style="display: block;">
                    <input type="radio" id="selecetd_group-{{group.GroupId}}" name="selecetd_group" value="{{group.GroupId}}" (change)="Select_group($event)">
                    Group {{group.GroupId}}
                  </label>
                </td>
              </tr>
              <tr class="select_row" *ngFor="let check of group.Conditions">
                <td class="column_name">
                  {{check.SearchField.ColumnName}}
                </td>

                <td class="alt">
                  <label for="Value-{{check.SearchField.ColumnName}}">
                    <input id="Value-{{check.SearchField.ColumnName}}" class="imput-value" (change)="ValueConstruct($event, check.SearchField.ColumnName)">
                  </label>
                </td>
                <td class="alt">
                  <label for="checked-{{check.SearchField.ColumnName}}">
                    <input id="checked-{{check.SearchField.ColumnName}}" type="checkbox"
                           (click)="construct_ExactMatch(check.SearchField.ColumnName)" class="imput-checkbox">
                  </label>
                </td>
                <td class="alt-exact">
                  <label for="Level-{{check.SearchField.ColumnName}}">
                    <input id="Level-{{check.SearchField.ColumnName}}" (change)="construct_Fuzzy($event, check.SearchField.ColumnName)" class="imput-number" [value]="check.Level*100">
                  </label>
                </td>
                <td class="alt">
                  <label for="IgnoreNullValues-{{check.SearchField.ColumnName}}">
                    {{check.IgnoreNullValues}}
                    <select id="IgnoreNullValues-{{check.SearchField.ColumnName}}" class="dropdown_advanced" (change)="nullVal($event, check.SearchField.ColumnName)">
                      <option>true</option>
                      <option>false</option>
                    </select>
                  </label>
                </td>
                <td class="alt">
                  <label for="DictionaryType-{{check.SearchField.ColumnName}}">
                    {{check.DictionaryType}}
                    <select id="DictionaryType-{{check.SearchField.ColumnName}}" class="dropdown_advanced" (change)="construct_DictionaryType($event, check.SearchField.ColumnName)">
                      <option *ngFor="let dict of dictionary" [value]="dict">{{dict}}</option>
                    </select>
                  </label>
                </td>
                <td class="alt">
                  <label>
                    <input (change)="construct_GroupLevel($event)" [value]="GroupLevel*100" class="imput-number">
                  </label>
                </td>
                <td class="alt">
                  <label for="Weight-{{check.SearchField.ColumnName}}">
                    <input id="Weight-{{check.SearchField.ColumnName}}" (change)="construct_WeightInGroup($event, check.SearchField.ColumnName)" class="imput-number" [value]="check.Weight*100">
                  </label>
                </td>
              </tr>
            </table>
          </td>
          <tr (click)="AddGroup()">
            <td colspan="8" class="addGroup_button">
              <a>+ Add Group</a>
            </td>
          </tr>
          <tr>
            <td colspan="8">
              <button class="simple-button" (click)="Advanced_Search()">Search</button>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</div>

<div *ngIf="is_CompanySearch">
  <div *ngIf="search_completed">
    <h1 class="shift-down">
      <a class="black-box">Search Result</a>
      <a class="right"> Total results: {{total_searches}}</a>
    </h1>
<!--    <button class="excel" (click)="exportToExcel('CompaniesSearch.xlsx', 'company')">Download To Excel</button>-->
    <table class="alt">
      <tr>
        <td>
          <table class="table_header">
            <tr>
              <td>
                <div class="mat-elevation-z8">
                  <table mat-table [dataSource]="dataSource" id="excel-table-company">
                    <ng-container matColumnDef="CompanyId">
                      <th mat-header-cell *matHeaderCellDef class="table_header"> Company id </th>
                      <td mat-cell *matCellDef="let element" id="company-companyid-{{element.activeStatus}}"> {{element.companyId}} </td>
                    </ng-container>

                    <ng-container matColumnDef="SourceName">
                      <th mat-header-cell *matHeaderCellDef class="table_header"> Source Name </th>
                      <td mat-cell *matCellDef="let element" id="company-sourcename-{{element.activeStatus}}"> {{element['source name']}} </td>
                    </ng-container>

                    <ng-container matColumnDef="CompanyName">
                      <th mat-header-cell *matHeaderCellDef class="table_header"> Company Name </th>
                      <td mat-cell *matCellDef="let element" id="company-companyname-{{element.activeStatus}}" (click)="LinkTo(element.companyId, element.companyName)" class="pointer"> {{element.companyName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Adress1">
                      <th mat-header-cell *matHeaderCellDef class="table_header"> Address1 </th>
                      <td mat-cell *matCellDef="let element" id="company-address1-{{element.activeStatus}}"> {{element.address1}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Adress2">
                      <th mat-header-cell *matHeaderCellDef class="table_header"> Address2 </th>
                      <td mat-cell *matCellDef="let element" id="company-address2-{{element.activeStatus}}"> {{element.address2}} </td>
                    </ng-container>

                    <ng-container matColumnDef="City">
                      <th mat-header-cell *matHeaderCellDef class="table_header"> City </th>
                      <td mat-cell *matCellDef="let element" id="company-city-{{element.activeStatus}}"> {{element.city}} </td>
                    </ng-container>

                    <ng-container matColumnDef="State">
                      <th mat-header-cell *matHeaderCellDef class="table_header"> State </th>
                      <td mat-cell *matCellDef="let element" id="company-state-{{element.activeStatus}}"> {{element.state}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Zip">
                      <th mat-header-cell *matHeaderCellDef class="table_header"> Zip </th>
                      <td mat-cell *matCellDef="let element" id="company-zip-{{element.activeStatus}}"> {{element.zip}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Country">
                      <th mat-header-cell *matHeaderCellDef class="table_header"> Country </th>
                      <td mat-cell *matCellDef="let element" id="company-country-{{element.activeStatus}}"> {{element.country}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Phone">
                      <th mat-header-cell *matHeaderCellDef class="table_header"> Phone </th>
                      <td mat-cell *matCellDef="let element" id="company-phone-{{element.activeStatus}}"> {{element.phone}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Website">
                      <th mat-header-cell *matHeaderCellDef class="table_header"> Website </th>
                      <td mat-cell *matCellDef="let element" id="company-website-{{element.activeStatus}}"> {{element.website}} </td>
                    </ng-container>

                    <ng-container matColumnDef="AnnualRevenue">
                      <th mat-header-cell *matHeaderCellDef class="table_header"> Annual Revenue </th>
                      <td mat-cell *matCellDef="let element" id="company-annualRevenue-{{element.activeStatus}}"> {{element.annualRevenue}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Ticker">
                      <th mat-header-cell *matHeaderCellDef class="table_header"> Ticker </th>
                      <td mat-cell *matCellDef="let element" id="company-ticker-{{element.activeStatus}}">
                        {{element.ticker}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="Verified Date">
                      <th mat-header-cell *matHeaderCellDef class="table_header"> Verified Date </th>
                      <td mat-cell *matCellDef="let element" id="company-verifieddate-{{element.activeStatus}}">
                        <div *ngIf="element.verifiedDate; else lastupdated">
                          {{element.verifiedDate}}
                        </div>
                        <ng-template #lastupdated>
                          {{element.lastUpdated}}
                        </ng-template>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="Active Status">
                      <th mat-header-cell *matHeaderCellDef class="table_header"> Active Status </th>
                      <td mat-cell *matCellDef="let element" id="company-activestatus-{{element.activeStatus}}"> {{element.activeStatus}} </td>
                    </ng-container>

                    <ng-container matColumnDef="AddressSource">
                      <th mat-header-cell *matHeaderCellDef class="table_header"> Address Source </th>
                      <td mat-cell *matCellDef="let element" id="company-addressSource-{{element.activeStatus}}"> {{element.addressSource}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="select_row"></tr>
                  </table>
<!--                  <mat-paginator [length]="length"-->
<!--                                 [pageSize]="pageSize"-->
<!--                                 [pageSizeOptions]="pageSizeOptions"-->
<!--                                 (page)="pageEvent = $event"-->
<!--                                 showFirstLastButtons>-->
<!--                  </mat-paginator>-->
                </div>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>
</div>
<div *ngIf="is_PersonSearch">
  <div *ngIf="search_completed">
    <h1 class="shift-down">
      <a class="black-box">Search Result</a>
      <a class="right"> Total results: {{total_searches}}</a>
    </h1>
<!--    <button class="excel" (click)="exportToExcel('PeopleSearch.xlsx', 'person')">Download To Excel</button>-->
    <table class="alt">
      <tr>
        <td>
          <div class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSource" id="excel-table-person">
              <ng-container matColumnDef="Person Id">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Person Id </th>
                <td mat-cell *matCellDef="let element" id="person-personid-{{element.activeStatus}}"> {{element.personId}} </td>
              </ng-container>

              <ng-container matColumnDef="SourceName">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Source Name </th>
                <td mat-cell *matCellDef="let element" id="person-sourcename-{{element.activeStatus}}"> {{element['source name']}} </td>
              </ng-container>

              <ng-container matColumnDef="First Name">
                <th mat-header-cell *matHeaderCellDef class="table_header"> First Name </th>
                <td mat-cell *matCellDef="let element" id="person-firstname-{{element.activeStatus}}"> {{element.firstName}} </td>
              </ng-container>

              <ng-container matColumnDef="Middle Name">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Middle Name </th>
                <td mat-cell *matCellDef="let element" id="person-middlename-{{element.activeStatus}}"> {{element.middleName}} </td>
              </ng-container>

              <ng-container matColumnDef="Last Name">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Last Name </th>
                <td mat-cell *matCellDef="let element" id="person-lastname-{{element.activeStatus}}"> {{element.lastName}} </td>
              </ng-container>

              <ng-container matColumnDef="Suffix">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Suffix </th>
                <td mat-cell *matCellDef="let element" id="person-suffix-{{element.activeStatus}}"> {{element.suffix}} </td>
              </ng-container>

              <ng-container matColumnDef="Title">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Title </th>
                <td mat-cell *matCellDef="let element" id="person-title-{{element.activeStatus}}"> {{element.title}} </td>
              </ng-container>

              <ng-container matColumnDef="Email">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Email </th>
                <td mat-cell *matCellDef="let element" id="person-email-{{element.activeStatus}}"> {{element.email}} </td>
              </ng-container>

              <ng-container matColumnDef="AltEmail">
                <th mat-header-cell *matHeaderCellDef class="table_header"> AltEmail </th>
                <td mat-cell *matCellDef="let element" id="person-altemail-{{element.activeStatus}}"> {{element.altEmail}} </td>
              </ng-container>

              <ng-container matColumnDef="Direct Phone">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Direct Phone </th>
                <td mat-cell *matCellDef="let element" id="person-directphone-{{element.activeStatus}}"> {{element.directPhone}} </td>
              </ng-container>

              <ng-container matColumnDef="Mobile Phone">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Mobile Phone </th>
                <td mat-cell *matCellDef="let element" id="person-mobilephone-{{element.activeStatus}}"> {{element.mobilePhone}} </td>
              </ng-container>

              <ng-container matColumnDef="Primary Company">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Primary Company </th>
                <td mat-cell *matCellDef="let element" id="person-primarycompany-{{element.activeStatus}}"> {{element.primaryCompany}} </td>
              </ng-container>

              <ng-container matColumnDef="Board Company Associations">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Board Company Associations </th>
                <td mat-cell *matCellDef="let element" id="person-boardcpassoc-{{element.activeStatus}}"> {{element.boardCompanyName}} </td>
              </ng-container>

              <ng-container matColumnDef="Ticker">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Ticker </th>
                <td mat-cell *matCellDef="let element" id="person-ticker-{{element.activeStatus}}"> {{element.ticker}} </td>
              </ng-container>

              <ng-container matColumnDef="Shipping City">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Shipping City </th>
                <td mat-cell *matCellDef="let element"id="person-primarycity-{{element.activeStatus}}"> {{element.primaryCity}} </td>
              </ng-container>

              <ng-container matColumnDef="Shipping State">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Shipping State </th>
                <td mat-cell *matCellDef="let element" id="person-primarystate-{{element.activeStatus}}"> {{element.primaryState}} </td>
              </ng-container>

              <ng-container matColumnDef="Verified Date">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Verified Date </th>
                <td mat-cell *matCellDef="let element" id="person-verifieddate-{{element.activeStatus}}"> {{element.verifiedDate}} </td>
              </ng-container>

              <ng-container matColumnDef="Active Status">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Active Status </th>
                <td mat-cell *matCellDef="let element" id="person-activestatus-{{element.activeStatus}}"> {{element.activeStatus}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="select_row"></tr>
            </table>
            <mat-paginator [length]="length"
                           [pageSize]="pageSize"
                           [pageSizeOptions]="pageSizeOptions"
                           (page)="pageEvent = $event"
                           showFirstLastButtons>
            </mat-paginator>
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>
<div *ngIf="is_EngagementSearch">
  <div *ngIf="search_completed">
    <h1 class="shift-down">
      <a class="black-box">Search Result</a>
      <a class="right"> Total results: {{total_searches}}</a>
    </h1>
<!--    <button class="excel" (click)="exportToExcel('EngagementsSearch.xlsx', 'engagement')">Download To Excel</button>-->
    <table class="alt">
      <tr>
        <td>
          <table class="table_header">
            <tr>
              <td>
                <div class="mat-elevation-z8">
                  <table mat-table [dataSource]="dataSource" id="excel-table-engagement">

                    <ng-container matColumnDef="SourceName">
                      <th mat-header-cell *matHeaderCellDef class="table_header"> Source Name </th>
                      <td mat-cell *matCellDef="let element"> {{element['source name']}} </td>
                    </ng-container>

                    <ng-container matColumnDef="TotalScore">
                      <th mat-header-cell *matHeaderCellDef class="table_header">Total Score</th>
                      <td mat-cell *matCellDef="let element">
                        {{element['total score']}} \\ {{element['group 1 score']}}</td>
                    </ng-container>

                    <ng-container matColumnDef="EngagementName">
                      <th mat-header-cell *matHeaderCellDef class="table_header"> Engagement Name </th>
                      <td mat-cell *matCellDef="let element"> {{element.engagementName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="AmountPaid">
                      <th mat-header-cell *matHeaderCellDef class="table_header">  Amount Paid </th>
                      <td mat-cell *matCellDef="let element"> {{element.amountPaid}} </td>
                    </ng-container>

                    <ng-container matColumnDef="EngagementCatDesc">
                      <th mat-header-cell *matHeaderCellDef class="table_header"> Engagement Cat Desc </th>
                      <td mat-cell *matCellDef="let element"> {{element.engagementCatDesc}} </td>
                    </ng-container>

                    <ng-container matColumnDef="EngagementDate">
                      <th mat-header-cell *matHeaderCellDef class="table_header"> Engagement Date </th>
                      <td mat-cell *matCellDef="let element"> {{element.engagementDate}} </td>
                    </ng-container>

                    <ng-container matColumnDef="EngagementTypeDesc">
                      <th mat-header-cell *matHeaderCellDef class="table_header"> Engagement Type Desc </th>
                      <td mat-cell *matCellDef="let element"> {{element.engagementTypeDesc}} </td>
                    </ng-container>

                    <ng-container matColumnDef="GroupId">
                      <th mat-header-cell *matHeaderCellDef class="table_header"> Group Id </th>
                      <td mat-cell *matCellDef="let element"> {{element.groupId}} </td>
                    </ng-container>

                    <ng-container matColumnDef="PersonId">
                      <th mat-header-cell *matHeaderCellDef class="table_header"> Person Id </th>
                      <td mat-cell *matCellDef="let element"> {{element.personId}} </td>
                    </ng-container>

                    <ng-container matColumnDef="LastUpdated">
                      <th mat-header-cell *matHeaderCellDef class="table_header"> Last Updated </th>
                      <td mat-cell *matCellDef="let element"> {{element.lastUpdated}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="select_row"></tr>
                  </table>
                  <mat-paginator [length]="length"
                                 [pageSize]="pageSize"
                                 [pageSizeOptions]="pageSizeOptions"
                                 (page)="pageEvent = $event"
                                 showFirstLastButtons>
                  </mat-paginator>
                </div>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>
</div>

</body>
