import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})


export class MergedConnectionService {
  // use this service for setting a global variable that helps when you come back from 'manual merge popup'
  // to not continue merging what you already merged in popup
  // tslint:disable-next-line:variable-name
  private are_componentsAlreadyMergedSubject = new BehaviorSubject(0);
  // tslint:disable-next-line:variable-name
  private was_manual_merge_accessed = new BehaviorSubject(false);
  // tslint:disable-next-line:variable-name
  shared_are_componentsAlreadyMerged = this.are_componentsAlreadyMergedSubject.asObservable();
  // tslint:disable-next-line:variable-name
  shared_was_manual_merge_accessed = this.was_manual_merge_accessed.asObservable();

  constructor(){}

  // tslint:disable-next-line:typedef variable-name
  update_alreadyMerged(is_alreadyMerged: number){
    this.are_componentsAlreadyMergedSubject.next(is_alreadyMerged);
  }
  // tslint:disable-next-line:typedef variable-name
  update_manualMergeAccessed(state: boolean){
    this.was_manual_merge_accessed.next(state);
  }
}
