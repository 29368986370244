import {Component, OnInit, ViewChild} from '@angular/core';
import {DataType} from '../shared/DataType';
import {ConfigService} from '../config/config.service';
import {Statistics} from '../shared/Statistics';
import {Company} from '../shared/Company';
import {Person} from '../shared/Person';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent} from '@angular/router';
import {ReviewedPerson} from '../shared/ReviewedPerson';
import {CompanyToReview} from '../shared/CompanyToReview';
import {PersonToReview} from '../shared/PersonToReview';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MergedConnectionService} from '../service/mergedConnection.service';
import {MergeOptionsComponent} from '../merge-options/merge-options.component';
import {MatDialog} from '@angular/material/dialog';
import {Jobs} from '../shared/Jobs';
import {AuthService} from '@auth0/auth0-angular';
import {AppComponent} from '../app.component';
import {WarningComponent} from '../warning/warning.component';
import * as XLSX from 'xlsx';
import {StatisticsWordpress} from '../shared/StatisticsWordpress';
import {Research} from '../shared/Research';
import {UsersToView} from '../shared/UsersToView';
import {Country} from '../shared/Country';
import {State} from '../shared/State';
import {PublicCoDataStatistics} from '../shared/PublicCoDataStatistics';
import {first} from 'rxjs/operators';
import {fromEvent} from 'rxjs';


@Component({
    selector: 'app-quarantine',
    templateUrl: './quarantine.component.html',
    styleUrls: ['./quarantine.component.scss']
})
export class QuarantineComponent implements OnInit {

    // tslint:disable-next-line:variable-name
    constructor(private api: ConfigService, private route: Router, public auth: AuthService, private _mergeservice: MergedConnectionService,
                private snackBar: MatSnackBar, public dialog: MatDialog, private appcomponent: AppComponent) {
        this.appcomponent.jobChecking();
        route.events.subscribe((event: RouterEvent) => {
            this.navigationInterceptor(event);
        });
    }

    // auth0 data
    teamMemberId: number;
    user: string;
    userEmail: string;
    userRole: string;
    isAdmin: boolean;
    token: string;
    profileJson: string = null;
    users: UsersToView[] = [];
    research: Research[];
    isBulkSendResearchSelected = false;
    isSendRecordsToResearchSelected = false;
    researchAreaId: number;
    responseFromPopup: string;
    // tslint:disable-next-line:variable-name
    selected_state: string;
    // tslint:disable-next-line:variable-name
    selected_country: string;
    public showOverlay = false;
    // tslint:disable-next-line:variable-name
    is_onlyFileData = false;
    // tslint:disable-next-line:variable-name
    is_AccrossTable = false;
    // tslint:disable-next-line:variable-name
    is_NonMatches = false;
    // tslint:disable-next-line:variable-name
    is_AllData = false;
    // tslint:disable-next-line:variable-name
    is_WordpressSubType = false;
    // tslint:disable-next-line:variable-name
    is_Wordpress = false;
    // tslint:disable-next-line:variable-name
    is_Wordpress_non_matches = false;
    isPublicDataCompany = false;
    isPublicDataCompanyMatches = false;
    isPublicDataCompanyNonMatches = false;
    dataTypes: DataType[];
    selectedDataType;
    statistics: Statistics;
    statisticsWordpress: StatisticsWordpress;
    publicCoDataStatistics: PublicCoDataStatistics;
    matchGroups: string;
    notMatched: string;
    totalRecords: number;
    totalNonWordpressRecords: number;
    matchGroupsWordpress: string;
    notMatchedWordpress: string;
    totalRecordsWordpress: number;
    currentPage: number;
    totalPages: number;
    totalRecordsNonMatches: number;
    fetchData = 500;
    offset = 0;
    showKickAll = false;
    // tslint:disable-next-line:variable-name
    group_list: number[] = [];
    // tslint:disable-next-line:variable-name
    group_list_wordpress: number[] = [];
    // tslint:disable-next-line:variable-name
    us_country: Country = {id: 75, countryCode: 'US', countryName: 'United States', countryCodeId: 232 };
    companiesToReview: CompanyToReview[] = [];
    mergedCompanies: CompanyToReview[] = [];
    reviewedCompanies: CompanyToReview[] = [];
    addCompany: CompanyToReview[] = [];
    peopleToReview: PersonToReview[] = [];
    mergedPeople: PersonToReview[] = [];
    reviewedPeople: ReviewedPerson[] = [];
    // tslint:disable-next-line:variable-name
    is_company_search = false;
    // tslint:disable-next-line:variable-name
    is_person_search = false;
    // tslint:disable-next-line:variable-name
    is_engagement_search = false;
    // tslint:disable-next-line:variable-name
    group_list_length;
    // tslint:disable-next-line:variable-name
    group_list_elements = [];
    // tslint:disable-next-line:variable-name
    group_list_length_wordpress;
    // tslint:disable-next-line:variable-name
    group_list_elements_wordpress = [];
    // tslint:disable-next-line:variable-name
    is_totalScore_clicked = false;
    // tslint:disable-next-line:variable-name
    selected_group: string;
    // tslint:disable-next-line:variable-name
    is_data_available = false;
    href = 'https://dev.ceo360.pro/#/';
    dataSource;
    displayedColumns: string[];
    length: number;
    pageSize = 0;
    pageSizeOptions: number[] = [5, 10, 20];
    // tslint:disable-next-line:variable-name
    total_searches = 0;
    // tslint:disable-next-line:variable-name
    is_search_ready = false;
    // tslint:disable-next-line:variable-name
    is_proceed_visible = 0;
    // tslint:disable-next-line:variable-name
    merge_rule = 'UpdateEmptyField';
    // tslint:disable-next-line:variable-name
    are_alreadyMerged = 0;
    // tslint:disable-next-line:variable-name
    was_manual_merge_accessed = false;
    // tslint:disable-next-line:variable-name
    are_actionButtons_visible = 0;
    // tslint:disable-next-line:variable-name
    matchingByID = false;
    jobs: Jobs[] = [{
        key: '',
        type: '',
        inProcess: false,
        state: ''
    }];
    // tslint:disable-next-line:variable-name
    are_jobsVisible = false;
    // tslint:disable-next-line:variable-name
    is_long_list = false;
    // tslint:disable-next-line:variable-name
    time_last_job_started = '';
    bulkaction = '';
    constructedReviewedCompanies = '';
    constructedReviewedPeople = '';
    // tslint:disable-next-line:variable-name
    is_selectedGroup = true;
    countries: Country[];
    states: State[];
    // tslint:disable-next-line:variable-name
    active_status = [true, false];
    // tslint:disable-next-line:variable-name
    not_found_state = '';
    // tslint:disable-next-line:variable-name
    not_found_primaryState = '';
    // tslint:disable-next-line:variable-name
    not_found_country = '';
    // tslint:disable-next-line:variable-name
    not_found_activeStatus = '';
    @ViewChild(MatPaginator) paginator: MatPaginator;
    // MatPaginator Output
    pageEvent: PageEvent;

    // tslint:disable-next-line:typedef
    ShowAllGroups() {
        this.is_long_list = true;
    }

    exportexcel(fileName: string, datatype: string): void {
        const element = document.getElementById('excel-table-' + datatype);
        const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, fileName);
    }

    openWarning(): void {
        const dialogRef = this.dialog.open(WarningComponent);
        dialogRef.componentInstance.action = 'ResearchGroup';
        dialogRef.componentInstance.isBulckAnction = true;
        dialogRef.componentInstance.group = this.selected_group;
        dialogRef.afterClosed().subscribe(result => {
        });
    }

    openWarning2(): void {
        const dialogRef = this.dialog.open(WarningComponent);
        dialogRef.componentInstance.action = 'Research';
        dialogRef.componentInstance.isBulckAnction = true;
        dialogRef.componentInstance.group = this.selected_group;
        dialogRef.afterClosed().subscribe(result => {
            this.responseFromPopup = result;
        });

    }

    SendGroupToResearch(): void {

        const area = this.researchAreaId.toString();
        this.api.get_sendGroupToResearch(this.selectedDataType, this.selected_group, area).subscribe(res => {
                document.getElementById('succeeded_snack').click();
                document.getElementById('refreshJobs').click();
                // window.location.reload();
                this.selected();
                this.is_long_list = false;
                this.dataSource = [];
                // this.ColorSelectedGroup(this.group_list[0]);
            },
            err => {
                console.log(err);
                document.getElementById('failed_snack').click();
            });
    }

    SendAllDataToResearch(): void {
        this.showOverlay = true;
        const area = this.researchAreaId.toString();
        this.api.get_sendAllDataToResearch(this.selectedDataType, area).subscribe(res => {
                document.getElementById('succeeded_snack').click();
                document.getElementById('refreshJobs').click();
                this.showOverlay = false;
                window.location.reload();
            },
            err => {
                console.log(err);
                this.showOverlay = false;
                document.getElementById('failed_snack').click();

            });
    }

    SendWordpressNonMatchesToResearch(): void {
        this.showOverlay = true;
        const area = this.researchAreaId.toString();
        this.api.get_sendWordpressNonMatchesToResearch(this.offset, this.fetchData, area).subscribe(res => {
                document.getElementById('succeeded_snack').click();
                document.getElementById('refreshJobs').click();
                this.showOverlay = false;
                window.location.reload();
            },
            err => {
                console.log(err);
                this.showOverlay = false;
                document.getElementById('failed_snack').click();

            });
    }
    SendNonMatchesPublicCoToResearch(): void{
        this.showOverlay = true;
        const area = this.researchAreaId.toString();
        this.api.sendNonMatchesPublicCoToResearch(this.offset, this.fetchData, area).subscribe(res => {
                document.getElementById('succeeded_snack').click();
                document.getElementById('refreshJobs').click();
                this.showOverlay = false;
                window.location.reload();
            },
            err => {
                console.log(err);
                this.showOverlay = false;
                document.getElementById('failed_snack').click();

            });
    }
    SendNonMatchesToResearch(): void {
        this.showOverlay = true;
        const area = this.researchAreaId.toString();
        this.api.get_sendNonMatchesToResearch(this.selectedDataType, area, this.offset, this.fetchData).subscribe(res => {
                document.getElementById('succeeded_snack').click();
                document.getElementById('refreshJobs').click();
                this.showOverlay = false;
                window.location.reload();
            },
            err => {
                console.log(err);
                this.showOverlay = false;
                document.getElementById('failed_snack').click();

            });
    }
    MatchByID(): void{
        let  jsonToSend = '';
        let  groupId = '';
        if ( this.selectedDataType === 'Company'){
            this.constructedReviewedCompanies = this.construct_reviewedCompanies();
            jsonToSend = '{"company":' + this.constructedReviewedCompanies + '}';
            groupId = this.reviewedCompanies[0]['group ID'].toString();
        }
        else {
            this.constructedReviewedPeople = this.construct_reviewedPeople();
            jsonToSend = '{"person":' + this.constructedReviewedPeople + '}';
            groupId = this.reviewedPeople[0].groupId.toString();
        }
        let url = '';
        if (this.is_AccrossTable || this.is_Wordpress || this.isPublicDataCompanyMatches){
            url = this.api.url_post_QuarantineMatchByID(this.selectedDataType, this.selected_group, 'Quarantine', '');
        }
        else{
            url = this.api.url_post_QuarantineMatchByID(this.selectedDataType, groupId, 'Quarantine', '');
        }
        this.showOverlay = true;
        const xhr = new XMLHttpRequest();
        xhr.open('POST', url);
        xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
        xhr.setRequestHeader('Content-Type', 'application/json-patch+json');
        xhr.setRequestHeader('accept', '*/*');
        if (this.is_AccrossTable || this.is_Wordpress || this.isPublicDataCompanyMatches){
            // tslint:disable-next-line:only-arrow-functions typedef
            xhr.onreadystatechange = function() {
                if (xhr.readyState === 4) {
                    console.log(xhr.status);
                    console.log(xhr.responseText);
                }
                // tslint:disable-next-line:triple-equals
                if (xhr.status == 200) {
                    document.getElementById('succeeded_snack').click();
                    document.getElementById('refresh').click();
                } else {
                    document.getElementById('failed_snack').click();
                }
            };
        }
        else{
            this.selected_group = groupId;
            // tslint:disable-next-line:only-arrow-functions typedef
            xhr.onreadystatechange = function() {
                if (xhr.readyState === 4) {
                    console.log(xhr.status);
                    console.log(xhr.responseText);
                }
                // tslint:disable-next-line:triple-equals
                if (xhr.status == 200) {
                    document.getElementById('succeeded_snack').click();
                    document.getElementById('refreshAfterMatchById').click();
                } else {
                    document.getElementById('failed_snack').click();
                }
            };
        }
        // @ts-ignore
       // console.log('sent', jsonToSend);
        xhr.send(jsonToSend);
    }
    // tslint:disable-next-line:typedef
    async RefreshAfterMatchById() {
        if (this.is_Wordpress_non_matches){
            this.isWordpress();
            this.Show_Data('wordpress-table');
            this.ShowAllGroups();
            setTimeout(() => {
                this.is_Wordpress = true;
                this.is_Wordpress_non_matches = false;
                // tslint:disable-next-line:radix
                this.GetWordpressData(parseInt(this.selected_group));
            }, 700);
        }
        if (this.is_NonMatches){
            this.Show_Data('accross-table');
            this.ShowAllGroups();
            setTimeout(() => {
                // tslint:disable-next-line:radix
                this.Group_Data(parseInt(this.selected_group));
            }, 700);
        }
        if (this.isPublicDataCompanyNonMatches){
            this.isPublicDataCO();
            this.Show_Data('public-companies-table');
            this.ShowAllGroups();
            setTimeout(() => {
                this.isPublicDataCompanyNonMatches = false;
                this.isPublicDataCompanyMatches = true;
                // tslint:disable-next-line:radix
                this.GroupDataPublicCO(parseInt(this.selected_group));
            }, 700);
        }
    }
    VerifyBulkActions(): void {
        console.log('companiesToReview 3', this.companiesToReview);
        if (this.isBulkSendResearchSelected === true) {

            if (this.researchAreaId === undefined) {
                this.openWarning();
            }
            // tslint:disable-next-line:max-line-length
            else if (this.is_AllData === false && this.bulkaction === 'ResearchGroup' && this.is_Wordpress_non_matches === false && this.is_NonMatches === false && this.isPublicDataCompanyNonMatches === false ) {
                this.SendGroupToResearch();
            } else if (this.is_AllData === true && this.bulkaction === 'ResearchGroup') {
                this.SendAllDataToResearch();
            } else if (this.is_Wordpress_non_matches === true && this.bulkaction === 'ResearchGroup') {
                this.SendWordpressNonMatchesToResearch();
            } else if (this.is_NonMatches === true && this.bulkaction === 'ResearchGroup') {
                this.SendNonMatchesToResearch();
            }else if (this.isPublicDataCompanyNonMatches === true && this.bulkaction === 'ResearchGroup'){
                this.SendNonMatchesPublicCoToResearch();
            }
        }
        if (this.isBulkSendResearchSelected === false) {
            console.log('vvvvv 2', this.companiesToReview);
            if (this.selectedDataType === 'Company') {
                this.constructedReviewedCompanies = this.construct_reviewedCompanies();
                console.log('constructedReviewedCompanies 1', this.constructedReviewedCompanies);
                const actionType = this.reviewedCompanies[0].actionType;
                // tslint:disable-next-line:variable-name
                let different_actions_ct = 0;
                for (const i of this.reviewedCompanies) {
                    if (i.actionType === actionType) {
                        different_actions_ct++;
                    }
                }
                if (different_actions_ct === this.companiesToReview.length) {
                    this.bulkaction = actionType;
                    this.openWariningPopup();
                } else {
                    this.Proceed();
                }
            } else if (this.selectedDataType === 'Person') {
                this.constructedReviewedPeople = this.construct_reviewedPeople();
                const actionType = this.reviewedPeople[0].actionType;
                // tslint:disable-next-line:variable-name
                let different_actions_ct = 0;
                for (const i of this.reviewedPeople) {
                    if (i.actionType === actionType) {
                        different_actions_ct++;
                    }
                }
                if (different_actions_ct === this.peopleToReview.length) {
                    this.bulkaction = actionType;
                    this.openWariningPopup();
                } else {
                    this.Proceed();
                }
            }
        }
    }

    openWariningPopup(): void {
        const dialogRef = this.dialog.open(WarningComponent);
        dialogRef.componentInstance.action = this.bulkaction;
        dialogRef.componentInstance.isBulckAnction = true;
        dialogRef.componentInstance.group = this.selected_group;

        dialogRef.afterClosed().subscribe(result => {
            console.log(`popup result: ${result}`);
            const response = result;
            if (response === 'yes') {
                this.Proceed();
            }
        });
    }

    // tslint:disable-next-line:typedef no-shadowed-variable
    Input_group_Data(group: string) {
        console.log('input_groupdata');
        console.log('this.selected_group', this.selected_group);
        // tslint:disable-next-line:variable-name radix
        let group_nr = parseInt(group);
        if (group_nr < 0) {
            group_nr = 1;
        } else if (group_nr > this.group_list_length) {
            group_nr = this.group_list_length;
        }
        this.Group_Data(group_nr);
    }

    // tslint:disable-next-line:typedef no-shadowed-variable
    Input_group_DataW(group: string) {
        console.log('input_groupdata');
        // tslint:disable-next-line:variable-name radix
        let group_nr = parseInt(group);
        if (group_nr < 0) {
            group_nr = 1;
        } else if (group_nr > this.group_list_length_wordpress) {
            group_nr = this.group_list_length_wordpress;
        }
        this.GetWordpressData(group_nr);
    }

// tslint:disable-next-line:typedef
    snackbar_SucceededPost() {
        const sb = this.snackBar.open('Your modifications have been successfully proceeded!', '', {
            duration: 1000,
            panelClass: ['succeed'],
            horizontalPosition: 'right',
            verticalPosition: 'top'
        });
        this.showOverlay = false;
    }

    // tslint:disable-next-line:typedef
    snackbar_FailedPost() {
        const sb = this.snackBar.open('Your modifications were NOT proceeded!', '', {
            duration: 1000,
            panelClass: ['failed'],
            horizontalPosition: 'right',
            verticalPosition: 'top'
        });
        this.showOverlay = false;
    }
    // tslint:disable-next-line:typedef
    snackbar_WaitingPost() {
        const sb = this.snackBar.open('Modifications are processing. Reload the page in a few minutes to see the changes.', '', {
            duration: 10000,
            panelClass: ['warning'],
            horizontalPosition: 'right',
            verticalPosition: 'top'
        });
        this.showOverlay = false;
    }

    // tslint:disable-next-line:typedef
    snackbar_Error(errorMessage: string) {
        const sb = this.snackBar.open(errorMessage, '', {
            duration: 1000,
            panelClass: ['failed'],
            horizontalPosition: 'right',
            verticalPosition: 'top'
        });
    }

    // tslint:disable-next-line:typedef
    isWordpress() {
        console.log('in isWordpress');
        this.resetSelectedTab();
        this.is_WordpressSubType = true;
        if (this.is_WordpressSubType) {
            document.getElementById('wordpress').style.backgroundColor = '#222222';
            document.getElementById('wordpress').style.color = 'white';
        }
        this.is_AccrossTable = false;
        this.is_AllData = false;
        this.is_NonMatches = false;
    }
    async isPublicDataCO(): Promise<void>
    {
        this.resetSelectedTab();
        this.isPublicDataCompany = true;
        if (this.isPublicDataCompany) {
            document.getElementById('publicDataCO').style.backgroundColor = '#222222';
            document.getElementById('publicDataCO').style.color = 'white';
        }
        this.is_AccrossTable = false;
        this.is_AllData = false;
        this.is_NonMatches = false;
        console.log('in isPublicDataCO');
    }
    // tslint:disable-next-line:typedef
    getPublicCoStatistics(){
        this.api.getQuarantineStatisticsCoApi(this.selectedDataType).subscribe(result => {
                // @ts-ignore
                this.publicCoDataStatistics = result;
                if (this.publicCoDataStatistics && this.publicCoDataStatistics.totalRecords > 0) {
                    this.is_data_available = true;
                } else {
                    this.is_data_available = false;
                }
                if (this.isPublicDataCompanyNonMatches){
                    this.getTotalPages('nonMatchesPublicCo');
                }
            },
            err => {
                console.log(err);
                this.showOverlay = false;
            });
    }
    // tslint:disable-next-line:typedef
    async Refresh() {
        if (this.isPublicDataCompany){
            this.RefreshPublicCoData();
        }else{
            await this.selected();
            this.setWasManualMergeAccessed(false);
            if (this.is_AllData) {
                this.GetAllData();
            } else if (this.is_onlyFileData) {
                this.getOnlyFileData();
            }else if (this.is_NonMatches) {
                this.GetNotMatches();
            } else if (this.is_AccrossTable) {
                let n: number;
                n = Number(this.selected_group);
                this.Group_Data(n);
            } else if (this.is_Wordpress) {
                let n: number;
                n = Number(this.selected_group);
                this.GetWordpressData(n);
            } else if (this.is_Wordpress_non_matches) {
                this.GetWordpressNonMatched();
            }
        }
    }
    RefreshPublicCoData(): void{
        this.getPublicCoStatistics();
        if (this.isPublicDataCompanyMatches) {
            let n: number;
            n = Number(this.selected_group);
            this.is_data_available = this.publicCoDataStatistics && this.publicCoDataStatistics.totalRecords > 0 ? true : false;
            this.GroupDataPublicCO(n);
        } else if (this.isPublicDataCompanyNonMatches) {
            this.showOverlay = true;
            this. GetPublicCoNotMatches();  }
    }
    proceedSelectedAction(actionType: any, element: any): void {
        console.log('people to review', this.peopleToReview)
        // proceed normal
        this.isSendRecordsToResearchSelected = false;
        if (this.are_alreadyMerged === 1) {
            if (this.selectedDataType === 'Company') {
                for (const i of this.companiesToReview) {
                    for (const j of this.mergedCompanies) {
                        if (i.winPurePrimK === j.winPurePrimK) {
                            i.actionType = 'None';
                            if (this.is_AccrossTable) {
                                document.getElementById('acrosstable-company-select-' + i.winPurePrimK).style.display = 'none';
                            } else if (this.is_AllData) {
                                document.getElementById('alldata-company-select-' + i.winPurePrimK).style.display = 'none';
                            } else if (this.notMatched) {
                                document.getElementById('nonmatches-company-select-' + i.winPurePrimK).style.display = 'none';
                            }
                        }
                    }
                }
            } else if (this.selectedDataType === 'Person') {
                for (const i of this.peopleToReview) {
                    for (const j of this.mergedPeople) {
                        if (i.winPurePrimK === j.winPurePrimK) {
                            i.actionType = 'None';
                            if (this.is_AccrossTable) {
                                document.getElementById('acrosstable-person-select-' + i.winPurePrimK).style.display = 'none';
                            } else if (this.is_AllData) {
                                document.getElementById('alldata-person-select-' + i.winPurePrimK).style.display = 'none';
                            } else if (this.notMatched) {
                                document.getElementById('nonmatches-person-select-' + i.winPurePrimK).style.display = 'none';
                            } else if (this.is_Wordpress) {
                                document.getElementById('wordpress-person-select-' + i.winPurePrimK).style.display = 'none';
                            }
                        }
                    }
                }
            }
        }
        // verify if the merge has been done in the popup
        // tslint:disable-next-line:variable-name
        this._mergeservice.shared_are_componentsAlreadyMerged.subscribe(are_alreadyMerged => {
            this.are_alreadyMerged = are_alreadyMerged;
        });
        switch (actionType) {
            case 'Add': {
                this.are_actionButtons_visible++;
                break;
            }
            case 'AddToMainDatabase': {
                this.are_actionButtons_visible++;
                break;
            }
            case 'Merge': {
                this.are_actionButtons_visible++;
                break;
            }
            case 'Delete': {
                this.are_actionButtons_visible++;
                break;
            }
            case 'None': {
                if (this.are_actionButtons_visible > 0) {
                    this.are_actionButtons_visible--;
                }
                break;
            }
            case 'Edit': {
                this.are_actionButtons_visible++;
                if (this.selectedDataType === 'Company') {
                    this.verifyState(element);
                    this.verifyCountry(element.country);
                    this.verifyActiveStatus(element);
                } else if (this.selectedDataType === 'Person') {
                    this.verifyPrimaryState(element);
                    this.verifyActiveStatus(element);
                }
                break;
            }
            case 'Kick': {
                this.are_actionButtons_visible++;
                break;
            }
            case 'Research': {
                this.are_actionButtons_visible++;
                this.isSendRecordsToResearchSelected = true;
                break;
            }
        }
    }

    verifyActiveStatus(element: any): void {
        this.not_found_activeStatus = '';
        if (!this.active_status.includes(element.activeStatus)) {
            this.not_found_activeStatus = element.activeStatus;
        }
    }

    verifyState(element: any): void {
        this.not_found_state = '';
        if (!this.states.includes(element.state)) {
            this.not_found_state = element.state;
        }
    }

    verifyPrimaryState(element: any): void {
        this.not_found_primaryState = '';
        if (!this.states.includes(element.primaryState)) {
            this.not_found_primaryState = element.primaryState;
        }
    }

    verifyCountry(country: any): void {
        this.not_found_country = '';
        if (!(this.countries.some(e => e.countryName === country))) {
            this.not_found_country = country;
        }
    }

// tslint:disable-next-line:typedef
    selectedAction(actionType: any, element: any) {
        this.matchingByID = false;
        console.log('action type', actionType);
        console.log('companiesToReview 1', this.companiesToReview);
        console.log('element', element);
        if (actionType === 'Delete') {
            const dialogRef = this.dialog.open(WarningComponent);
            dialogRef.componentInstance.action = 'DeleteRecord';
            dialogRef.componentInstance.isBulckAnction = false;

            dialogRef.afterClosed().subscribe(result => {
                const response = result;
                if (response === 'yes') {
                    this.proceedSelectedAction(actionType, element);
                } else {
                    // action type = none
                    if (this.selectedDataType === 'Company') {
                        for (const i of this.companiesToReview) {
                            if (i === element) {
                                i.actionType = 'None';
                            }
                        }
                    } else if (this.selectedDataType === 'Person') {
                        for (const i of this.peopleToReview) {
                            if (i === element) {
                                i.actionType = 'None';
                            }
                        }
                    }
                }
            });
        }
        if (actionType === 'MatchByID'){
            this.matchingByID = true;
            if (this.are_actionButtons_visible > 0) {
                this.are_actionButtons_visible = 0;
            }
        }
        else {
            this.proceedSelectedAction(actionType, element);
        }
    }

// tslint:disable-next-line:typedef
    construct_reviewedCompanies() {
        for (const i of this.companiesToReview) {
            const comp = new CompanyToReview();
            Object.assign(comp, i, {
                groupId : i['group ID']
            });
            switch (i.actionType) {
                case 'Add': {
                    // comp.verifiedDate = new Date().toLocaleString();
                    comp.actionType = 'AddEdit';
                    this.reviewedCompanies.push(comp);
                    break;
                }
                case 'AddToMainDatabase': {
                    comp.actionType = 'AddToMainDatabase';
                    this.reviewedCompanies.push(comp);
                    break;
                }
                case 'Merge': {
                    comp.actionType = 'Merge';
                    this.reviewedCompanies.push(comp);
                    break;
                }
                case 'Delete': {
                    comp.actionType = 'Delete';
                    this.reviewedCompanies.push(comp);
                    break;
                }
                case 'Edit': {
                    comp.actionType = 'Edit';
                    this.reviewedCompanies.push(comp);
                    break;
                }
                case 'Kick': {
                    comp.actionType = 'Kick';
                    this.reviewedCompanies.push(comp);
                    break;
                }
                case 'Research': {
                    comp.actionType = 'Research';
                    this.reviewedCompanies.push(comp);
                    break;
                }
                case 'MigratePeople': {
                    comp.actionType = 'MigratePeople';
                    this.reviewedCompanies.push(comp);
                    break;
                }
                case 'MatchByID': {
                    comp.actionType = 'MatchByID';
                    this.reviewedCompanies.push(comp);
                    break;
                }
                case 'None': {
                    break;
                }

            }
        }
        // this.reviewedCompanies.splice(0, 1);
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.users.length; i++) {
            if (this.users[i].email === this.user) {
                this.teamMemberId = this.users[i].teamMemberId;
            }
        }
        if (this.isPublicDataCompany === false){
            const data2 = {verifiedBy: this.teamMemberId};
            for (let i = 0; i < this.reviewedCompanies.length; i++) {
                this.reviewedCompanies[i] = Object.assign(this.reviewedCompanies[i], data2);
            }
        }
        // console.log('return reviewed companies', this.reviewedCompanies);
        return JSON.stringify(this.reviewedCompanies);
    }

    pushActionsIntoReviewedPeople(i: any, action: string): void{
        this.reviewedPeople.push({
            'source name': i['source name'],
            actionType: action,
            companyId: i.companyId,
            email: i.email,
            // @ts-ignore
            fileName: i.fileName,
            // @ts-ignore
            fileRowId: i.fileRowId,
            firstName: i.firstName,
            groupId: i['group ID'],
            // @ts-ignore
            id: i.id,
            // @ts-ignore
            importId: i.importId,
            lastName: i.lastName,
            lastUpdated: i.lastUpdated,
            middleName: i.middleName,
            mobilePhone: i.mobilePhone,
            personId: i.personId,
            prefix: i.prefix,
            primaryAddress1: i.primaryAddress1,
            primaryAddress2: i.primaryAddress2,
            primaryCity: i.primaryCity,
            primaryCountry: i.primaryCountry,
            primaryState: i.primaryState,
            primaryZip: i.primaryZip,
            alternateAddressSource: i.alternateAddressSource,
            alternateAddressPreferred: i.alternateAddressPreferred,
            alternateAddressType: i.alternateAddressType,
            suffix: i.suffix,
            title: i.title,
            // @ts-ignore
            cexSubscriberCode: i.cexSubscriberCode,
            wordpressId: i.wordpressId,
            cexSubStatus: i.cexSubStatus,
            cbmSubStatus: i.cbmSubStatus,
            cbmDigitalAndPrint: i.cbmDigitalAndPrint,
            ceoDigitalAndPrint: i.ceoDigitalAndPrint,
            ceoDigitalOnly: i.ceoDigitalOnly,
            cbmDigitalOnly: i.cbmDigitalOnly,
            bpaQualDate: i.bpaQualDate,
            industry: i.industry,
            otherIndustry: i.otherIndustry,
            employees: i.employees,
            ownership: i.ownership,
            ownershipSubType: i.ownershipSubType,
            revenue: i.revenue,
            winPurePrimK: i.winPurePrimK,
            ticker: i.ticker,
            primaryCompany: i.primaryCompany,
            directPhone: i.directPhone,
            altEmail: i.altEmail,
            activeStatus: i.activeStatus,
            boardCompanyName: i.boardCompanyName,
            verifiedDate: i.verifiedDate,
            memberClicksId: i.memberClicksId,
            qualificationSource: i.qualificationSource,
            bpaJobTitle: i.bpaJobTitle,
            memberStatus: i.memberStatus,
            cen: i.cen,
            sen: i.sen,
            cebi: i.cebi,
            nextLevelLeaders: i.nextLevelLeaders,
            ceO100: i.ceO100,
            cfO100: i.cfO100
        });
    }
    // tslint:disable-next-line:typedef
    construct_reviewedPeople() {
        for (const i of this.peopleToReview) {
            // tslint:disable-next-line:max-line-length
            if (i.alternateAddressPreferred) {
                // tslint:disable-next-line:max-line-length
                if (i.alternateAddressPreferred.toString() === 'TRUE' || i.alternateAddressPreferred.toString() === 'True' || i.alternateAddressPreferred.toString() === 'true' || i.alternateAddressPreferred === 1) {
                    i.alternateAddressPreferred = 1;
                } else {
                    i.alternateAddressPreferred = 0;
                }
            } else {
                i.alternateAddressPreferred = 0;
            }

            switch (i.actionType) {
                case 'AddToMainDatabase': {
                    this.pushActionsIntoReviewedPeople(i, 'AddToMainDatabase');
                    // this.reviewedPeople.push({
                    //     'source name': i['source name'],
                    //     actionType: 'AddToMainDatabase',
                    //     companyId: i.companyId,
                    //     email: i.email,
                    //     // @ts-ignore
                    //     fileName: i.fileName,
                    //     // @ts-ignore
                    //     fileRowId: i.fileRowId,
                    //     firstName: i.firstName,
                    //     groupId: i['group ID'],
                    //     // @ts-ignore
                    //     id: i.id,
                    //     // @ts-ignore
                    //     importId: i.importId,
                    //     lastName: i.lastName,
                    //     lastUpdated: i.lastUpdated,
                    //     middleName: i.middleName,
                    //     mobilePhone: i.mobilePhone,
                    //     personId: i.personId,
                    //     prefix: i.prefix,
                    //     primaryAddress1: i.primaryAddress1,
                    //     primaryAddress2: i.primaryAddress2,
                    //     primaryCity: i.primaryCity,
                    //     primaryCountry: i.primaryCountry,
                    //     primaryState: i.primaryState,
                    //     primaryZip: i.primaryZip,
                    //     alternateAddressSource: i.alternateAddressSource,
                    //     alternateAddressPreferred: i.alternateAddressPreferred,
                    //     alternateAddressType: i.alternateAddressType,
                    //     suffix: i.suffix,
                    //     title: i.title,
                    //     // @ts-ignore
                    //     cexSubscriberCode: i.cexSubscriberCode,
                    //     wordpressId: i.wordpressId,
                    //     cexSubStatus: i.cexSubStatus,
                    //     cbmSubStatus: i.cbmSubStatus,
                    //     cbmDigitalAndPrint: i.cbmDigitalAndPrint,
                    //     ceoDigitalAndPrint: i.ceoDigitalAndPrint,
                    //     ceoDigitalOnly: i.ceoDigitalOnly,
                    //     cbmDigitalOnly: i.cbmDigitalOnly,
                    //     bpaQualDate: i.bpaQualDate,
                    //     industry: i.industry,
                    //     otherIndustry: i.otherIndustry,
                    //     employees: i.employees,
                    //     ownership: i.ownership,
                    //     ownershipSubType: i.ownershipSubType,
                    //     revenue: i.revenue,
                    //     winPurePrimK: i.winPurePrimK,
                    //     ticker: i.ticker,
                    //     primaryCompany: i.primaryCompany,
                    //     directPhone: i.directPhone,
                    //     altEmail: i.altEmail,
                    //     activeStatus: i.activeStatus,
                    //     boardCompanyName: i.boardCompanyName,
                    //     verifiedDate: i.verifiedDate,
                    //     memberClicksId: i.memberClicksId,
                    //     qualificationSource: i.qualificationSource,
                    //     bpaJobTitle: i.bpaJobTitle,
                    //     memberStatus: i.memberStatus,
                    //     cen: i.cen,
                    //     sen: i.sen
                    // });
                    break;
                }
                case 'Add': {
                    i.verifiedDate = new Date().toLocaleString();
                    this.pushActionsIntoReviewedPeople(i, 'AddEdit');
                    // this.reviewedPeople.push({
                    //     'source name': i['source name'],
                    //     actionType: 'AddEdit',
                    //     companyId: i.companyId,
                    //     email: i.email,
                    //     // @ts-ignore
                    //     fileName: i.fileName,
                    //     // @ts-ignore
                    //     fileRowId: i.fileRowId,
                    //     firstName: i.firstName,
                    //     groupId: i['group ID'],
                    //     // @ts-ignore
                    //     id: i.id,
                    //     // @ts-ignore
                    //     importId: i.importId,
                    //     lastName: i.lastName,
                    //     lastUpdated: i.lastUpdated,
                    //     middleName: i.middleName,
                    //     mobilePhone: i.mobilePhone,
                    //     personId: i.personId,
                    //     prefix: i.prefix,
                    //     primaryAddress1: i.primaryAddress1,
                    //     primaryAddress2: i.primaryAddress2,
                    //     primaryCity: i.primaryCity,
                    //     primaryCountry: i.primaryCountry,
                    //     primaryState: i.primaryState,
                    //     primaryZip: i.primaryZip,
                    //     alternateAddressSource: i.alternateAddressSource,
                    //     alternateAddressPreferred: i.alternateAddressPreferred,
                    //     alternateAddressType: i.alternateAddressType,
                    //     suffix: i.suffix,
                    //     title: i.title,
                    //     // @ts-ignore
                    //     cexSubscriberCode: i.cexSubscriberCode,
                    //     wordpressId: i.wordpressId,
                    //     cexSubStatus: i.cexSubStatus,
                    //     cbmSubStatus: i.cbmSubStatus,
                    //     cbmDigitalAndPrint: i.cbmDigitalAndPrint,
                    //     ceoDigitalAndPrint: i.ceoDigitalAndPrint,
                    //     ceoDigitalOnly: i.ceoDigitalOnly,
                    //     cbmDigitalOnly: i.cbmDigitalOnly,
                    //     bpaQualDate: i.bpaQualDate,
                    //     industry: i.industry,
                    //     otherIndustry: i.otherIndustry,
                    //     employees: i.employees,
                    //     ownership: i.ownership,
                    //     ownershipSubType: i.ownershipSubType,
                    //     revenue: i.revenue,
                    //     winPurePrimK: i.winPurePrimK,
                    //     ticker: i.ticker,
                    //     primaryCompany: i.primaryCompany,
                    //     directPhone: i.directPhone,
                    //     altEmail: i.altEmail,
                    //     activeStatus: i.activeStatus,
                    //     boardCompanyName: i.boardCompanyName,
                    //     verifiedDate: i.verifiedDate,
                    //     memberClicksId: i.memberClicksId,
                    //     qualificationSource: i.qualificationSource,
                    //     bpaJobTitle: i.bpaJobTitle,
                    //     memberStatus: i.memberStatus,
                    //     cen: i.cen,
                    //     sen: i.sen
                    // });
                    break;
                }
                case 'Merge': {
                    this.pushActionsIntoReviewedPeople(i, 'Merge');
                    // this.reviewedPeople.push({
                    //     'source name': i['source name'],
                    //     actionType: 'Merge',
                    //     companyId: i.companyId,
                    //     email: i.email,
                    //     // @ts-ignore
                    //     fileName: i.fileName,
                    //     fileRowId: 0,
                    //     firstName: i.firstName,
                    //     groupId: i['group ID'],
                    //     // @ts-ignore
                    //     id: i.id,
                    //     // @ts-ignore
                    //     importId: i.importId,
                    //     lastName: i.lastName,
                    //     lastUpdated: i.lastUpdated,
                    //     middleName: i.middleName,
                    //     mobilePhone: i.mobilePhone,
                    //     personId: i.personId,
                    //     prefix: i.prefix,
                    //     primaryAddress1: i.primaryAddress1,
                    //     primaryAddress2: i.primaryAddress2,
                    //     primaryCity: i.primaryCity,
                    //     primaryCountry: i.primaryCountry,
                    //     primaryState: i.primaryState,
                    //     primaryZip: i.primaryZip,
                    //     alternateAddressSource: i.alternateAddressSource,
                    //     alternateAddressPreferred: i.alternateAddressPreferred,
                    //     alternateAddressType: i.alternateAddressType,
                    //     suffix: i.suffix,
                    //     title: i.title,
                    //     // @ts-ignore
                    //     cexSubscriberCode: i.cexSubscriberCode,
                    //     wordpressId: i.wordpressId,
                    //     cexSubStatus: i.cexSubStatus,
                    //     cbmSubStatus: i.cbmSubStatus,
                    //     cbmDigitalAndPrint: i.cbmDigitalAndPrint,
                    //     ceoDigitalAndPrint: i.ceoDigitalAndPrint,
                    //     ceoDigitalOnly: i.ceoDigitalOnly,
                    //     cbmDigitalOnly: i.cbmDigitalOnly,
                    //     bpaQualDate: i.bpaQualDate,
                    //     industry: i.industry,
                    //     otherIndustry: i.otherIndustry,
                    //     employees: i.employees,
                    //     ownership: i.ownership,
                    //     ownershipSubType: i.ownershipSubType,
                    //     revenue: i.revenue,
                    //     winPurePrimK: i.winPurePrimK,
                    //     ticker: i.ticker,
                    //     primaryCompany: i.primaryCompany,
                    //     directPhone: i.directPhone,
                    //     altEmail: i.altEmail,
                    //     activeStatus: i.activeStatus,
                    //     boardCompanyName: i.boardCompanyName,
                    //     verifiedDate: i.verifiedDate,
                    //     memberClicksId: i.memberClicksId,
                    //     qualificationSource: i.qualificationSource,
                    //     bpaJobTitle: i.bpaJobTitle,
                    //     memberStatus: i.memberStatus,
                    //     cen: i.cen,
                    //     sen: i.sen
                    // });
                    break;
                }
                case 'Delete': {
                    this.pushActionsIntoReviewedPeople(i, 'Delete');
                    // this.reviewedPeople.push({
                    //     // @ts-ignore
                    //     'source name': i['source name'],
                    //     id: i.id,
                    //     actionType: 'Delete',
                    //     companyId: i.companyId,
                    //     email: i.email,
                    //     // @ts-ignore
                    //     fileName: i.fileName,
                    //     // @ts-ignore
                    //     fileRowId: i.fileRowId,
                    //     firstName: i.firstName,
                    //     groupId: i['group ID'],
                    //     // @ts-ignore
                    //     winPurePrimK: i.winPurePrimK,
                    //     // @ts-ignore
                    //     importId: i.importId,
                    //     lastName: i.lastName,
                    //     lastUpdated: i.lastUpdated,
                    //     middleName: i.middleName,
                    //     mobilePhone: i.mobilePhone,
                    //     personId: i.personId,
                    //     prefix: i.prefix,
                    //     primaryAddress1: i.primaryAddress1,
                    //     primaryAddress2: i.primaryAddress2,
                    //     primaryCity: i.primaryCity,
                    //     primaryCountry: i.primaryCountry,
                    //     primaryState: i.primaryState,
                    //     primaryZip: i.primaryZip,
                    //     alternateAddressSource: i.alternateAddressSource,
                    //     alternateAddressPreferred: i.alternateAddressPreferred,
                    //     alternateAddressType: i.alternateAddressType,
                    //     cexSubscriberCode: i.cexSubscriberCode,
                    //     wordpressId: i.wordpressId,
                    //     cexSubStatus: i.cexSubStatus,
                    //     cbmSubStatus: i.cbmSubStatus,
                    //     cbmDigitalAndPrint: i.cbmDigitalAndPrint,
                    //     ceoDigitalAndPrint: i.ceoDigitalAndPrint,
                    //     ceoDigitalOnly: i.ceoDigitalOnly,
                    //     cbmDigitalOnly: i.cbmDigitalOnly,
                    //     bpaQualDate: i.bpaQualDate,
                    //     industry: i.industry,
                    //     otherIndustry: i.otherIndustry,
                    //     employees: i.employees,
                    //     ownership: i.ownership,
                    //     ownershipSubType: i.ownershipSubType,
                    //     revenue: i.revenue,
                    //     suffix: i.suffix,
                    //     title: i.title,
                    //     ticker: i.ticker,
                    //     primaryCompany: i.primaryCompany,
                    //     directPhone: i.directPhone,
                    //     altEmail: i.altEmail,
                    //     activeStatus: i.activeStatus,
                    //     boardCompanyName: i.boardCompanyName,
                    //     verifiedDate: i.verifiedDate,
                    //     memberClicksId: i.memberClicksId,
                    //     qualificationSource: i.qualificationSource,
                    //     bpaJobTitle: i.bpaJobTitle,
                    //     memberStatus: i.memberStatus,
                    //     cen: i.cen,
                    //     sen: i.sen
                    // });
                    break;
                }
                case 'Edit': {
                    // console.log('peopleToReview', this.peopleToReview);
                   // console.log('reviedpeope', this.reviewedPeople);
                    this.pushActionsIntoReviewedPeople(i, 'Edit');
                    // this.reviewedPeople.push({
                    //     'source name': i['source name'],
                    //     actionType: 'Edit',
                    //     companyId: i.companyId,
                    //     email: i.email,
                    //     // @ts-ignore
                    //     fileName: i.fileName,
                    //     // @ts-ignore
                    //     winPurePrimK: i.winPurePrimK,
                    //     // @ts-ignore
                    //     fileRowId: i.fileRowId,
                    //     firstName: i.firstName,
                    //     groupId: i['group ID'],
                    //     // @ts-ignore
                    //     id: i.id,
                    //     // @ts-ignore
                    //     importId: i.importId,
                    //     lastName: i.lastName,
                    //     lastUpdated: i.lastUpdated,
                    //     middleName: i.middleName,
                    //     mobilePhone: i.mobilePhone,
                    //     personId: i.personId,
                    //     prefix: i.prefix,
                    //     primaryAddress1: i.primaryAddress1,
                    //     primaryAddress2: i.primaryAddress2,
                    //     primaryCity: i.primaryCity,
                    //     primaryCountry: i.primaryCountry,
                    //     primaryState: i.primaryState,
                    //     primaryZip: i.primaryZip,
                    //     alternateAddressSource: i.alternateAddressSource,
                    //     alternateAddressPreferred: i.alternateAddressPreferred,
                    //     alternateAddressType: i.alternateAddressType,
                    //     cexSubscriberCode: i.cexSubscriberCode,
                    //     wordpressId: i.wordpressId,
                    //     cexSubStatus: i.cexSubStatus,
                    //     cbmSubStatus: i.cbmSubStatus,
                    //     cbmDigitalAndPrint: i.cbmDigitalAndPrint,
                    //     ceoDigitalAndPrint: i.ceoDigitalAndPrint,
                    //     ceoDigitalOnly: i.ceoDigitalOnly,
                    //     cbmDigitalOnly: i.cbmDigitalOnly,
                    //     bpaQualDate: i.bpaQualDate,
                    //     industry: i.industry,
                    //     otherIndustry: i.otherIndustry,
                    //     employees: i.employees,
                    //     ownership: i.ownership,
                    //     ownershipSubType: i.ownershipSubType,
                    //     revenue: i.revenue,
                    //     suffix: i.suffix,
                    //     title: i.title,
                    //     ticker: i.ticker,
                    //     primaryCompany: i.primaryCompany,
                    //     directPhone: i.directPhone,
                    //     altEmail: i.altEmail,
                    //     activeStatus: i.activeStatus,
                    //     boardCompanyName: i.boardCompanyName,
                    //     verifiedDate: i.verifiedDate,
                    //     memberClicksId: i.memberClicksId,
                    //     qualificationSource: i.qualificationSource,
                    //     bpaJobTitle: i.bpaJobTitle,
                    //     memberStatus: i.memberStatus,
                    //     cen: i.cen,
                    //     sen: i.sen
                    // });
                    break;
                }
                case 'MatchByID': {

                    // console.log('reviedpeope', this.reviewedPeople);
                    this.pushActionsIntoReviewedPeople(i, 'MatchById');
                    // this.reviewedPeople.push({
                    //     'source name': i['source name'],
                    //     actionType: 'MatchByID',
                    //     companyId: i.companyId,
                    //     email: i.email,
                    //     // @ts-ignore
                    //     fileName: i.fileName,
                    //     // @ts-ignore
                    //     winPurePrimK: i.winPurePrimK,
                    //     // @ts-ignore
                    //     fileRowId: i.fileRowId,
                    //     firstName: i.firstName,
                    //     groupId: i['group ID'],
                    //     // @ts-ignore
                    //     id: i.id,
                    //     // @ts-ignore
                    //     importId: i.importId,
                    //     lastName: i.lastName,
                    //     lastUpdated: i.lastUpdated,
                    //     middleName: i.middleName,
                    //     mobilePhone: i.mobilePhone,
                    //     personId: i.personId,
                    //     prefix: i.prefix,
                    //     primaryAddress1: i.primaryAddress1,
                    //     primaryAddress2: i.primaryAddress2,
                    //     primaryCity: i.primaryCity,
                    //     primaryCountry: i.primaryCountry,
                    //     primaryState: i.primaryState,
                    //     primaryZip: i.primaryZip,
                    //     alternateAddressSource: i.alternateAddressSource,
                    //     alternateAddressPreferred: i.alternateAddressPreferred,
                    //     alternateAddressType: i.alternateAddressType,
                    //     cexSubscriberCode: i.cexSubscriberCode,
                    //     wordpressId: i.wordpressId,
                    //     cexSubStatus: i.cexSubStatus,
                    //     cbmSubStatus: i.cbmSubStatus,
                    //     cbmDigitalAndPrint: i.cbmDigitalAndPrint,
                    //     ceoDigitalAndPrint: i.ceoDigitalAndPrint,
                    //     ceoDigitalOnly: i.ceoDigitalOnly,
                    //     cbmDigitalOnly: i.cbmDigitalOnly,
                    //     bpaQualDate: i.bpaQualDate,
                    //     industry: i.industry,
                    //     otherIndustry: i.otherIndustry,
                    //     employees: i.employees,
                    //     ownership: i.ownership,
                    //     ownershipSubType: i.ownershipSubType,
                    //     revenue: i.revenue,
                    //     suffix: i.suffix,
                    //     title: i.title,
                    //     ticker: i.ticker,
                    //     primaryCompany: i.primaryCompany,
                    //     directPhone: i.directPhone,
                    //     altEmail: i.altEmail,
                    //     activeStatus: i.activeStatus,
                    //     boardCompanyName: i.boardCompanyName,
                    //     verifiedDate: i.verifiedDate,
                    //     memberClicksId: i.memberClicksId,
                    //     qualificationSource: i.qualificationSource,
                    //     bpaJobTitle: i.bpaJobTitle,
                    //     memberStatus: i.memberStatus,
                    //     cen: i.cen,
                    //     sen: i.sen
                    // });
                    break;
                }
                case 'Kick': {
                    if (i['source name'] === 'Person') { // ceo360
                        this.pushActionsIntoReviewedPeople(i, 'Kick');
                        // this.reviewedPeople.push({
                        //     'source name': i['source name'],
                        //     actionType: 'Kick',
                        //     companyId: i.companyId,
                        //     email: i.email,
                        //     // @ts-ignore
                        //     fileName: i.fileName,
                        //     // @ts-ignore
                        //     winPurePrimK: i.winPurePrimK,
                        //     // @ts-ignore
                        //     fileRowId: i.fileRowId,
                        //     firstName: i.firstName,
                        //     groupId: i['group ID'],
                        //     // @ts-ignore
                        //     id: i.id,
                        //     // @ts-ignore
                        //     importId: i.importId,
                        //     lastName: i.lastName,
                        //     lastUpdated: i.lastUpdated,
                        //     middleName: i.middleName,
                        //     mobilePhone: i.mobilePhone,
                        //     personId: i.personId,
                        //     prefix: i.prefix,
                        //     primaryAddress1: i.primaryAddress1,
                        //     primaryAddress2: i.primaryAddress2,
                        //     primaryCity: i.primaryCity,
                        //     primaryCountry: i.primaryCountry,
                        //     primaryState: i.primaryState,
                        //     primaryZip: i.primaryZip,
                        //     alternateAddressSource: i.alternateAddressSource,
                        //     alternateAddressPreferred: i.alternateAddressPreferred,
                        //     alternateAddressType: i.alternateAddressType,
                        //     cexSubscriberCode: i.cexSubscriberCode,
                        //     wordpressId: i.wordpressId,
                        //     cexSubStatus: i.cexSubStatus,
                        //     cbmSubStatus: i.cbmSubStatus,
                        //     cbmDigitalAndPrint: i.cbmDigitalAndPrint,
                        //     ceoDigitalAndPrint: i.ceoDigitalAndPrint,
                        //     ceoDigitalOnly: i.ceoDigitalOnly,
                        //     cbmDigitalOnly: i.cbmDigitalOnly,
                        //     bpaQualDate: i.bpaQualDate,
                        //     industry: i.industry,
                        //     otherIndustry: i.otherIndustry,
                        //     employees: i.employees,
                        //     ownership: i.ownership,
                        //     ownershipSubType: i.ownershipSubType,
                        //     revenue: i.revenue,
                        //     suffix: i.suffix,
                        //     title: i.title,
                        //     ticker: i.ticker,
                        //     primaryCompany: i.primaryCompany,
                        //     directPhone: i.directPhone,
                        //     altEmail: i.altEmail,
                        //     activeStatus: i.activeStatus,
                        //     boardCompanyName: i.boardCompanyName,
                        //     verifiedDate: i.verifiedDate,
                        //     memberClicksId: i.memberClicksId,
                        //     qualificationSource: i.qualificationSource,
                        //     bpaJobTitle: i.bpaJobTitle,
                        //     memberStatus: i.memberStatus,
                        //     cen: i.cen,
                        //     sen: i.sen
                        // });
                    } else {
                        console.log('kick4');
                        this.pushActionsIntoReviewedPeople(i, 'Kick');
                        // this.reviewedPeople.push({
                        //     'source name': i['source name'],
                        //     actionType: 'Kick',
                        //     companyId: i.companyId,
                        //     email: i.email,
                        //     // @ts-ignore
                        //     fileName: i.fileName,
                        //     // @ts-ignore
                        //     winPurePrimK: i.winPurePrimK,
                        //     // @ts-ignore
                        //     fileRowId: i.fileRowId,
                        //     firstName: i.firstName,
                        //     groupId: i['group ID'],
                        //     // @ts-ignore
                        //     id: i.id,
                        //     // @ts-ignore
                        //     importId: i.importId,
                        //     lastName: i.lastName,
                        //     lastUpdated: i.lastUpdated,
                        //     middleName: i.middleName,
                        //     mobilePhone: i.mobilePhone,
                        //     personId: i.personId,
                        //     prefix: i.prefix,
                        //     primaryAddress1: i.primaryAddress1,
                        //     primaryAddress2: i.primaryAddress2,
                        //     primaryCity: i.primaryCity,
                        //     primaryCountry: i.primaryCountry,
                        //     primaryState: i.primaryState,
                        //     primaryZip: i.primaryZip,
                        //     alternateAddressSource: i.alternateAddressSource,
                        //     alternateAddressPreferred: i.alternateAddressPreferred,
                        //     alternateAddressType: i.alternateAddressType,
                        //     cexSubscriberCode: i.cexSubscriberCode,
                        //     wordpressId: i.wordpressId,
                        //     cexSubStatus: i.cexSubStatus,
                        //     cbmSubStatus: i.cbmSubStatus,
                        //     cbmDigitalAndPrint: i.cbmDigitalAndPrint,
                        //     ceoDigitalAndPrint: i.ceoDigitalAndPrint,
                        //     ceoDigitalOnly: i.ceoDigitalOnly,
                        //     cbmDigitalOnly: i.cbmDigitalOnly,
                        //     bpaQualDate: i.bpaQualDate,
                        //     industry: i.industry,
                        //     otherIndustry: i.otherIndustry,
                        //     employees: i.employees,
                        //     ownership: i.ownership,
                        //     ownershipSubType: i.ownershipSubType,
                        //     revenue: i.revenue,
                        //     suffix: i.suffix,
                        //     title: i.title,
                        //     ticker: i.ticker,
                        //     primaryCompany: i.primaryCompany,
                        //     directPhone: i.directPhone,
                        //     altEmail: i.altEmail,
                        //     activeStatus: i.activeStatus,
                        //     boardCompanyName: i.boardCompanyName,
                        //     verifiedDate: i.verifiedDate,
                        //     memberClicksId: i.memberClicksId,
                        //     qualificationSource: i.qualificationSource,
                        //     bpaJobTitle: i.bpaJobTitle,
                        //     memberStatus: i.memberStatus,
                        //     cen: i.cen,
                        //     sen: i.sen
                        // });
                    }
                    break;
                }
                case 'EditIds':
                    this.pushActionsIntoReviewedPeople(i, 'EditIds');
                    // this.reviewedPeople.push({
                    //     'source name': i['source name'],
                    //     actionType: 'EditIds',
                    //     companyId: i.companyId,
                    //     email: i.email,
                    //     // @ts-ignore
                    //     fileName: i.fileName,
                    //     // @ts-ignore
                    //     fileRowId: i.fileRowId,
                    //     firstName: i.firstName,
                    //     groupId: i['group ID'],
                    //     // @ts-ignore
                    //     id: i.id,
                    //     // @ts-ignore
                    //     importId: i.importId,
                    //     lastName: i.lastName,
                    //     lastUpdated: i.lastUpdated,
                    //     middleName: i.middleName,
                    //     mobilePhone: i.mobilePhone,
                    //     personId: i.personId,
                    //     prefix: i.prefix,
                    //     primaryAddress1: i.primaryAddress1,
                    //     primaryAddress2: i.primaryAddress2,
                    //     primaryCity: i.primaryCity,
                    //     primaryCountry: i.primaryCountry,
                    //     primaryState: i.primaryState,
                    //     primaryZip: i.primaryZip,
                    //     alternateAddressSource: i.alternateAddressSource,
                    //     alternateAddressPreferred: i.alternateAddressPreferred,
                    //     alternateAddressType: i.alternateAddressType,
                    //     suffix: i.suffix,
                    //     title: i.title,
                    //     // @ts-ignore
                    //     cexSubscriberCode: i.cexSubscriberCode,
                    //     wordpressId: i.wordpressId,
                    //     cexSubStatus: i.cexSubStatus,
                    //     cbmSubStatus: i.cbmSubStatus,
                    //     cbmDigitalAndPrint: i.cbmDigitalAndPrint,
                    //     ceoDigitalAndPrint: i.ceoDigitalAndPrint,
                    //     ceoDigitalOnly: i.ceoDigitalOnly,
                    //     cbmDigitalOnly: i.cbmDigitalOnly,
                    //     bpaQualDate: i.bpaQualDate,
                    //     industry: i.industry,
                    //     otherIndustry: i.otherIndustry,
                    //     employees: i.employees,
                    //     ownership: i.ownership,
                    //     ownershipSubType: i.ownershipSubType,
                    //     revenue: i.revenue,
                    //     winPurePrimK: i.winPurePrimK,
                    //     ticker: i.ticker,
                    //     primaryCompany: i.primaryCompany,
                    //     directPhone: i.directPhone,
                    //     altEmail: i.altEmail,
                    //     activeStatus: i.activeStatus,
                    //     boardCompanyName: i.boardCompanyName,
                    //     verifiedDate: i.verifiedDate,
                    //     memberClicksId: i.memberClicksId,
                    //     qualificationSource: i.qualificationSource,
                    //     bpaJobTitle: i.bpaJobTitle,
                    //     memberStatus: i.memberStatus,
                    //     cen: i.cen,
                    //     sen: i.sen
                    // });
                    break;
                case 'Research': {
                    if (i['source name'] === 'Person') { // ceo360
                        this.pushActionsIntoReviewedPeople(i, 'Research');
                        // this.reviewedPeople.push({
                        //     'source name': i['source name'],
                        //     actionType: 'Research',
                        //     companyId: i.companyId,
                        //     email: i.email,
                        //     // @ts-ignore
                        //     fileName: i.fileName,
                        //     // @ts-ignore
                        //     winPurePrimK: i.winPurePrimK,
                        //     // @ts-ignore
                        //     fileRowId: i.fileRowId,
                        //     firstName: i.firstName,
                        //     groupId: i['group ID'],
                        //     // @ts-ignore
                        //     id: i.id,
                        //     // @ts-ignore
                        //     importId: i.importId,
                        //     lastName: i.lastName,
                        //     lastUpdated: i.lastUpdated,
                        //     middleName: i.middleName,
                        //     mobilePhone: i.mobilePhone,
                        //     personId: i.personId,
                        //     prefix: i.prefix,
                        //     primaryAddress1: i.primaryAddress1,
                        //     primaryAddress2: i.primaryAddress2,
                        //     primaryCity: i.primaryCity,
                        //     primaryCountry: i.primaryCountry,
                        //     primaryState: i.primaryState,
                        //     primaryZip: i.primaryZip,
                        //     alternateAddressSource: i.alternateAddressSource,
                        //     alternateAddressPreferred: i.alternateAddressPreferred,
                        //     alternateAddressType: i.alternateAddressType,
                        //     cexSubscriberCode: i.cexSubscriberCode,
                        //     wordpressId: i.wordpressId,
                        //     cexSubStatus: i.cexSubStatus,
                        //     cbmSubStatus: i.cbmSubStatus,
                        //     cbmDigitalAndPrint: i.cbmDigitalAndPrint,
                        //     ceoDigitalAndPrint: i.ceoDigitalAndPrint,
                        //     ceoDigitalOnly: i.ceoDigitalOnly,
                        //     cbmDigitalOnly: i.cbmDigitalOnly,
                        //     bpaQualDate: i.bpaQualDate,
                        //     industry: i.industry,
                        //     otherIndustry: i.otherIndustry,
                        //     employees: i.employees,
                        //     ownership: i.ownership,
                        //     ownershipSubType: i.ownershipSubType,
                        //     revenue: i.revenue,
                        //     suffix: i.suffix,
                        //     title: i.title,
                        //     ticker: i.ticker,
                        //     primaryCompany: i.primaryCompany,
                        //     directPhone: i.directPhone,
                        //     altEmail: i.altEmail,
                        //     activeStatus: i.activeStatus,
                        //     boardCompanyName: i.boardCompanyName,
                        //     verifiedDate: i.verifiedDate,
                        //     memberClicksId: i.memberClicksId,
                        //     qualificationSource: i.qualificationSource,
                        //     bpaJobTitle: i.bpaJobTitle,
                        //     memberStatus: i.memberStatus,
                        //     cen: i.cen,
                        //     sen: i.sen
                        // });
                    } else {
                        this.pushActionsIntoReviewedPeople(i, 'Research');
                        // this.reviewedPeople.push({
                        //     'source name': i['source name'],
                        //     actionType: 'Research',
                        //     companyId: i.companyId,
                        //     email: i.email,
                        //     // @ts-ignore
                        //     fileName: i.fileName,
                        //     // @ts-ignore
                        //     winPurePrimK: i.winPurePrimK,
                        //     // @ts-ignore
                        //     fileRowId: i.fileRowId,
                        //     firstName: i.firstName,
                        //     groupId: i['group ID'],
                        //     // @ts-ignore
                        //     id: i.id,
                        //     // @ts-ignore
                        //     importId: i.importId,
                        //     lastName: i.lastName,
                        //     lastUpdated: i.lastUpdated,
                        //     middleName: i.middleName,
                        //     mobilePhone: i.mobilePhone,
                        //     personId: i.personId,
                        //     prefix: i.prefix,
                        //     primaryAddress1: i.primaryAddress1,
                        //     primaryAddress2: i.primaryAddress2,
                        //     primaryCity: i.primaryCity,
                        //     primaryCountry: i.primaryCountry,
                        //     primaryState: i.primaryState,
                        //     primaryZip: i.primaryZip,
                        //     alternateAddressSource: i.alternateAddressSource,
                        //     alternateAddressPreferred: i.alternateAddressPreferred,
                        //     alternateAddressType: i.alternateAddressType,
                        //     cexSubscriberCode: i.cexSubscriberCode,
                        //     wordpressId: i.wordpressId,
                        //     cexSubStatus: i.cexSubStatus,
                        //     cbmSubStatus: i.cbmSubStatus,
                        //     cbmDigitalAndPrint: i.cbmDigitalAndPrint,
                        //     ceoDigitalAndPrint: i.ceoDigitalAndPrint,
                        //     ceoDigitalOnly: i.ceoDigitalOnly,
                        //     cbmDigitalOnly: i.cbmDigitalOnly,
                        //     bpaQualDate: i.bpaQualDate,
                        //     industry: i.industry,
                        //     otherIndustry: i.otherIndustry,
                        //     employees: i.employees,
                        //     ownership: i.ownership,
                        //     ownershipSubType: i.ownershipSubType,
                        //     revenue: i.revenue,
                        //     suffix: i.suffix,
                        //     title: i.title,
                        //     ticker: i.ticker,
                        //     primaryCompany: i.primaryCompany,
                        //     directPhone: i.directPhone,
                        //     altEmail: i.altEmail,
                        //     activeStatus: i.activeStatus,
                        //     boardCompanyName: i.boardCompanyName,
                        //     verifiedDate: i.verifiedDate,
                        //     memberClicksId: i.memberClicksId,
                        //     qualificationSource: i.qualificationSource,
                        //     bpaJobTitle: i.bpaJobTitle,
                        //     memberStatus: i.memberStatus,
                        //     cen: i.cen,
                        //     sen: i.sen
                        // });
                    }
                    break;
                }
                case 'None': {
                    break;
                }
            }
        }
        // this.reviewedPeople.splice(0, 1);
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.users.length; i++) {
            if (this.users[i].email === this.user) {
                this.teamMemberId = this.users[i].teamMemberId;
            }
        }
        const data2 = {verifiedBy: this.teamMemberId};
        for (let i = 0; i < this.reviewedPeople.length; i++) {
            this.reviewedPeople[i] = Object.assign(this.reviewedPeople[i], data2);
        }
        return JSON.stringify(this.reviewedPeople);
    }

    // tslint:disable-next-line:typedef
    verifySelectedActions() {
        let isOk = false;
        if (this.selectedDataType === 'Company') {
            // tslint:disable-next-line:variable-name
            let select_ct = 0;
            // tslint:disable-next-line:variable-name
            let merge_ct = 0;
            // tslint:disable-next-line:variable-name
            let edit_ct = 0;
            let migrate = 0;
            for (const i of this.companiesToReview) {
                if (i.actionType === 'AddToMainDatabase') {
                    select_ct++;
                } else if (i.actionType === 'Merge') {
                    merge_ct++;
                } else if (i.actionType === 'Edit') {
                    edit_ct++;
                }
                if (i.actionType === 'MigratePeople' && i.companyId === 0) {
                    migrate = 1;
                }
            }
            if (merge_ct > 0 && select_ct === 0) {
                this.snackbar_Error('You need to have one \'Select\' for \'Merge\'');
                isOk = false;
            } else if (select_ct > 1) {
                this.snackbar_Error('You can only have one \'Select\'');
                isOk = false;
            } else if (migrate === 1) {
                this.snackbar_Error('The Company ID can not be 0');
                isOk = false;
            }
                // else if (edit_ct > 1){
                //   this.snackbar_Error('You can only have one \'Edit\'');
                //   isOk = false;
            // }
            else {
                isOk = true;
            }
        } else if (this.selectedDataType === 'Person') {
            // tslint:disable-next-line:variable-name
            let select_ct = 0;
            // tslint:disable-next-line:variable-name
            let merge_ct = 0;
            for (const i of this.peopleToReview) {
                if (i.actionType === 'AddToMainDatabase') {
                    select_ct++;
                } else if (i.actionType === 'Merge') {
                    merge_ct++;
                }
            }
            if (merge_ct > 0 && select_ct === 0) {
                this.snackbar_Error('You need to have one \'Select\' for \'Merge\'');
                isOk = false;
            } else if (select_ct > 1) {
                this.snackbar_Error('You can only have one \'Select\'');
                isOk = false;
            } else {
                isOk = true;
            }
        }
        return isOk;
    }

    continueProceed(): void {
        this.responseFromPopup = '';
        // tslint:disable-next-line:variable-name
        this._mergeservice.shared_are_componentsAlreadyMerged.subscribe(are_alreadyMerged => {
            this.are_alreadyMerged = are_alreadyMerged;
        });
        if (this.selectedDataType === 'Company') {
            if (this.are_alreadyMerged === 1) {
                for (const i of this.mergedCompanies) {
                    if (i['source name'] === 'empty') {
                        this.mergedCompanies.splice(this.mergedCompanies.indexOf(i), 1);
                    }
                }
                for (const i of this.companiesToReview) {
                    for (const j of this.mergedCompanies) {
                        if (i.winPurePrimK === j.winPurePrimK) {
                            this.companiesToReview.splice(this.companiesToReview.indexOf(i), 1);
                        }
                    }
                }
                this.unsetMergedConnection();
            }
        } else if (this.selectedDataType === 'Person') {

            for (const i of this.reviewedPeople) {
                if (i.alternateAddressPreferred) {
                    // tslint:disable-next-line:max-line-length
                    if (i.alternateAddressPreferred.toString() === 'TRUE' || i.alternateAddressPreferred.toString() === 'True' || i.alternateAddressPreferred.toString() === 'true' || i.alternateAddressPreferred === 1) {
                        i.alternateAddressPreferred = 1;
                    } else {
                        i.alternateAddressPreferred = 0;
                    }
                } else {
                    i.alternateAddressPreferred = 0;
                }
            }


            if (this.are_alreadyMerged === 1) {
                for (const i of this.mergedPeople) {
                    if (i['source name'] === 'empty') {
                        this.mergedPeople.splice(this.mergedPeople.indexOf(i), 1);
                    }
                }
                for (const i of this.peopleToReview) {
                    for (const j of this.mergedPeople) {
                        if (i.winPurePrimK === j.winPurePrimK) {
                            this.peopleToReview.splice(this.peopleToReview.indexOf(i), 1);
                        }
                    }
                }
                this.unsetMergedConnection();
            }
        }
        if (this.verifySelectedActions()) {
            let jsonToSend = '{ "mergeOption": "' + this.merge_rule + '", ';
            let url = '';
            if (this.merge_rule === '') {
                this.snackbar_Error('Please select merge rules to continue!');
                // reset
                this.reviewedCompanies = [];
                this.reviewedPeople = [];
            } else {
                switch (this.selectedDataType) {
                    case 'Company': {
                        jsonToSend += '"companyGroup":' + this.constructedReviewedCompanies + '}';
                        this.constructedReviewedCompanies = '';
                        if (this.researchAreaId === undefined && this.isSendRecordsToResearchSelected === false) {
                            url = this.api.url_post_QuarantineProcessGroup('company', '');
                        }
                        if (this.researchAreaId === undefined && this.isSendRecordsToResearchSelected === true) {
                            this.openWarning2();
                        }
                        if (this.researchAreaId !== undefined) {
                            url = this.api.url_post_QuarantineProcessGroup('company', this.researchAreaId.toString());
                        }
                        break;
                    }
                    case 'Person': {
                        jsonToSend += '"personGroup":' + this.constructedReviewedPeople + '}';
                        this.constructedReviewedPeople = '';
                        if (this.researchAreaId === undefined && this.isSendRecordsToResearchSelected === false) {
                            url = this.api.url_post_QuarantineProcessGroup('person', '');
                        }
                        if (this.researchAreaId === undefined && this.isSendRecordsToResearchSelected === true) {
                            this.openWarning2();
                        }
                        if (this.researchAreaId !== undefined) {
                            url = this.api.url_post_QuarantineProcessGroup('person', this.researchAreaId.toString());
                        }
                        break;
                    }
                }
                this.showOverlay = true;
                // @ts-ignore
                jsonToSend = jsonToSend.replaceAll('AddEdit', 'Add');
                if ((this.is_NonMatches !== true && this.is_Wordpress_non_matches !== true) || this.bulkaction === 'None'){
                    const xhr = new XMLHttpRequest();
                    xhr.open('POST', url);
                    xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
                    xhr.setRequestHeader('Content-Type', 'application/json-patch+json');
                    xhr.setRequestHeader('accept', '*/*');
                    xhr.timeout = 600000;
                    // tslint:disable-next-line:only-arrow-functions typedef
                    xhr.onreadystatechange = function() {
                        if (xhr.readyState === 4) {
                            console.log(xhr.status);
                            console.log(xhr.responseText);
                        }
                        // tslint:disable-next-line:triple-equals
                        if (xhr.status == 200) {
                            document.getElementById('succeeded_snack').click();
                            document.getElementById('refresh').click();
                        } else {
                            document.getElementById('failed_snack').click();
                        }
                    };
                    // console.log('sent', jsonToSend);
                    xhr.send(jsonToSend);
                }
                else{
                    this.snackbar_WaitingPost();
                    const xhr = new XMLHttpRequest();
                    xhr.open('POST', url);
                    xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
                    xhr.setRequestHeader('Content-Type', 'application/json-patch+json');
                    xhr.setRequestHeader('accept', '*/*');
                    xhr.onload = () => {
                       // this.snackbar_SucceededPost();
                    };
                    xhr.send(jsonToSend);
                }
            }
            this.reviewedCompanies = [];
            this.construct_reviewedCompanies();
            this.reviewedPeople = [];
            this.construct_reviewedPeople();
        }
    }

    // tslint:disable-next-line:typedef
    Proceed() {
        if (this.selectedDataType === 'Company') {
            let ct = 0;
            for (const i of this.companiesToReview) {
                if (i.actionType === 'AddToMainDatabase' || i.actionType === 'Merge') {
                    ct++;
                }
            }
            this.continueProceed();
        }
        else if (this.selectedDataType === 'Person') {
            let ct = 0;
            for (const i of this.peopleToReview) {
                if (i.actionType === 'AddToMainDatabase' || i.actionType === 'Merge') {
                    ct++;
                }
                if (i.alternateAddressPreferred) {
                    // tslint:disable-next-line:max-line-length
                    if (i.alternateAddressPreferred.toString() === 'TRUE' || i.alternateAddressPreferred.toString() === 'True' || i.alternateAddressPreferred.toString() === 'true' || i.alternateAddressPreferred === 1) {
                        i.alternateAddressPreferred = 1;
                    } else {
                        i.alternateAddressPreferred = 0;
                    }
                } else {
                    i.alternateAddressPreferred = 0;
                }
            }
            for (const i of this.reviewedPeople) {
                if (i.alternateAddressPreferred) {
                    // tslint:disable-next-line:max-line-length
                    if (i.alternateAddressPreferred.toString() === 'TRUE' || i.alternateAddressPreferred.toString() === 'True' || i.alternateAddressPreferred.toString() === 'true' || i.alternateAddressPreferred === 1) {
                        i.alternateAddressPreferred = 1;
                    } else {
                        i.alternateAddressPreferred = 0;
                    }
                } else {
                    i.alternateAddressPreferred = 0;
                }
            }

            for (const i of this.mergedPeople) {
                if (i.alternateAddressPreferred) {
                    // tslint:disable-next-line:max-line-length
                    if (i.alternateAddressPreferred.toString() === 'TRUE' || i.alternateAddressPreferred.toString() === 'True' || i.alternateAddressPreferred.toString() === 'true' || i.alternateAddressPreferred === 1) {
                        i.alternateAddressPreferred = 1;
                    } else {
                        i.alternateAddressPreferred = 0;
                    }
                } else {
                    i.alternateAddressPreferred = 0;
                }
            }
            this.continueProceed();
            // if ( ct !== 0){
            //  const dialogRef = this.dialog.open(WarningComponent);
            // dialogRef.componentInstance.action = 'ManualMerge';
            // dialogRef.componentInstance.isBulckAnction = false;
            //   dialogRef.afterClosed().subscribe(result => {
            //     console.log(`popup result: ${result}`);
            //     const response = result;
            //     if (response === 'yes'){
            //       this.ManualMerge();
            //     }
            //     else if (response === 'no') {
            //       this.continueProceed();
            //     }
            //   });
            // }
            // else{
            //   this.continueProceed();
            // }
        }

    }
    ManualMerge(): void {
        let dialogRef: any;
        // tslint:disable-next-line:variable-name
        this._mergeservice.shared_was_manual_merge_accessed.subscribe(was_manual_merge_accessed => {
            this.was_manual_merge_accessed = was_manual_merge_accessed;
        });
        if (!this.was_manual_merge_accessed) {
            switch (this.selectedDataType) {
                case 'Company': {
                    dialogRef = this.dialog.open(MergeOptionsComponent, {
                        disableClose: true,
                    });
                    dialogRef.componentInstance.companiesToReview = this.companiesToReview;
                    dialogRef.componentInstance.selectedDataType = this.selectedDataType;
                    dialogRef.componentInstance.selected_group = this.selected_group;
                    dialogRef.componentInstance.countries = this.countries;
                    dialogRef.componentInstance.states = this.states;
                    dialogRef.componentInstance.parent_page = 'Stage';
                    dialogRef.componentInstance.urlType = 2;
                    if (this.isPublicDataCompany || this.isPublicDataCompanyMatches){
                        dialogRef.componentInstance.isPublicDataCompanyMatches = true;
                    }
                    dialogRef.afterClosed().subscribe(result => {
                        document.getElementById('succeeded_snack').click();
                        document.getElementById('refresh').click();
                    });
                    for (const i of this.companiesToReview) {
                        if (i.actionType === 'AddToMainDatabase' || i.actionType === 'Merge') {
                            this.mergedCompanies.push(i);
                        }
                    }
                    break;
                }
                case 'Person': {
                    dialogRef = this.dialog.open(MergeOptionsComponent, {
                        disableClose: true,
                    });
                    dialogRef.componentInstance.peopleToReview = this.peopleToReview;
                    dialogRef.componentInstance.selectedDataType = this.selectedDataType;
                    dialogRef.componentInstance.selected_group = this.selected_group;
                    dialogRef.componentInstance.parent_page = 'Stage';
                    dialogRef.componentInstance.countries = this.countries;
                    dialogRef.componentInstance.states = this.states;
                    dialogRef.componentInstance.urlType = 2;
                    if (this.is_Wordpress === true || this.is_Wordpress_non_matches) {
                        dialogRef.componentInstance.isPersonApiData = true;
                    }
                    dialogRef.afterClosed().subscribe(result => {
                        document.getElementById('succeeded_snack').click();
                        document.getElementById('refresh').click();
                    });
                    for (const i of this.peopleToReview) {
                        if (i.actionType === 'AddToMainDatabase' || i.actionType === 'Merge') {
                            this.mergedPeople.push(i);
                        }
                    }
                    break;
                }
            }
        } else {
            this.snackbar_Error('You can only do one manual merge / group!');
        }
    }

    // tslint:disable-next-line:typedef
    redirect() {
        this.route.navigateByUrl('/');
    }

    // tslint:disable-next-line:typedef
    setPageSizeOptions(setPageSizeOptionsInput: string) {
        if (setPageSizeOptionsInput) {
            this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
            this.GetAllData();
        }
    }

    // tslint:disable-next-line:typedef
    LinkTo(id: number) {
        // tslint:disable-next-line:max-line-length
        if (window.location.href === 'http://localhost:4200/#/staging' || window.location.href === 'https://amplify-dev.ceo360.pro/#/staging') {
            this.href = 'https://dev.ceo360.pro/#/';
        }
        if (window.location.href === 'https://amplify.ceo360.pro/#/staging') {
            this.href = 'https://ceo360.pro/#/';
        }
        if (this.selectedDataType === 'Company') {
            this.href += 'Company/' + id;
            const win = window.open(this.href, '_blank');
            win.focus();
        } else if (this.selectedDataType === 'Person') {
            this.href += 'Person/' + id;
            const win = window.open(this.href, '_blank');
            win.focus();
        }
    }

    resetSelectedTab(): void {
        if (document.getElementById('non-matches')) {
            document.getElementById('non-matches').style.backgroundColor = '#b8d1de';
            document.getElementById('non-matches').style.color = 'black';
            document.getElementById('accross-table').style.backgroundColor = '#b8d1de';
            document.getElementById('accross-table').style.color = 'black';
            document.getElementById('all-data').style.backgroundColor = '#b8d1de';
            document.getElementById('all-data').style.color = 'black';
            document.getElementById('file-only').style.backgroundColor = '#b8d1de';
            document.getElementById('file-only').style.color = 'black';
            if (this.selectedDataType === 'Person') {
                document.getElementById('wordpress').style.backgroundColor = '#b8d1de';
                document.getElementById('wordpress').style.color = 'black';
                if (this.is_WordpressSubType === true) {
                    document.getElementById('wordpress-table').style.backgroundColor = '#b8d1de';
                    document.getElementById('wordpress-table').style.color = 'black';
                    document.getElementById('wordpress-non-matches').style.backgroundColor = '#b8d1de';
                    document.getElementById('wordpress-non-matches').style.color = 'black';
                }
            }
            if (this.selectedDataType === 'Company') {
                document.getElementById('publicDataCO').style.backgroundColor = '#b8d1de';
                document.getElementById('publicDataCO').style.color = 'black';
                if (this.isPublicDataCompany === true) {
                    document.getElementById('public-companies-non-matches').style.backgroundColor = '#b8d1de';
                    document.getElementById('public-companies-non-matches').style.color = 'black';
                    document.getElementById('public-companies-table').style.backgroundColor = '#b8d1de';
                    document.getElementById('public-companies-table').style.color = 'black';
                }
            }
        }
    }

    // tslint:disable-next-line:typedef
    Show_Data(category: string) {
        this.is_totalScore_clicked = false;
        this.dataSource = [];
        this.resetSelectedTab();
        switch (category) {
            case 'accross-table':
                this.is_data_available = this.statistics.totalRecords > 0 ? true : false;
                this.selected_datatype(this.selectedDataType);
                this.is_selectedGroup = false;
                this.is_search_ready = false;
                this.is_AccrossTable = !(this.is_AccrossTable);
                if (this.is_AccrossTable) {
                    document.getElementById('accross-table').style.backgroundColor = '#222222';
                    document.getElementById('accross-table').style.color = 'white';
                } else {
                    document.getElementById('accross-table').style.backgroundColor = '#b8d1de';
                    document.getElementById('accross-table').style.color = 'black';
                }
                this.is_onlyFileData = false;
                this.is_NonMatches = false;
                this.is_AllData = false;
                this.is_Wordpress = false;
                this.is_WordpressSubType = false;
                this.is_Wordpress_non_matches = false;
                this.isPublicDataCompany = false;
                this.isPublicDataCompanyNonMatches = false;
                this.isPublicDataCompanyMatches = false;
                this.companiesToReview = [];
                this.peopleToReview = [];
                break;
            case 'non-matches':
                this.selected_datatype(this.selectedDataType);
                this.is_search_ready = false;
                this.is_NonMatches = !(this.is_NonMatches);
                if (this.is_NonMatches) {
                    document.getElementById('non-matches').style.backgroundColor = '#222222';
                    document.getElementById('non-matches').style.color = 'white';
                } else {
                    document.getElementById('non-matches').style.backgroundColor = '#b8d1de';
                    document.getElementById('non-matches').style.color = 'black';
                }
                this.is_onlyFileData = false;
                this.is_AccrossTable = false;
                this.is_AllData = false;
                this.is_Wordpress = false;
                this.is_WordpressSubType = false;
                this.is_Wordpress_non_matches = false;
                this.isPublicDataCompany = false;
                this.isPublicDataCompanyNonMatches = false;
                this.isPublicDataCompanyMatches = false;
                this.GetNotMatches();
                break;
            case 'all-data':
                this.selected_datatype(this.selectedDataType);
                this.is_search_ready = false;
                this.is_AllData = !(this.is_AllData);
                if (this.is_AllData) {
                    document.getElementById('all-data').style.backgroundColor = '#222222';
                    document.getElementById('all-data').style.color = 'white';
                } else {
                    document.getElementById('all-data').style.backgroundColor = '#b8d1de';
                    document.getElementById('all-data').style.color = 'black';
                }
                this.is_onlyFileData = false;
                this.is_AccrossTable = false;
                this.is_NonMatches = false;
                this.is_Wordpress = false;
                this.is_WordpressSubType = false;
                this.is_Wordpress_non_matches = false;
                this.isPublicDataCompany = false;
                this.isPublicDataCompanyNonMatches = false;
                this.isPublicDataCompanyMatches = false;
                this.GetAllData();
                break;
            case 'file-only':
                this.selected_datatype(this.selectedDataType);
                this.is_selectedGroup = false;
                this.is_search_ready = false;
                this.is_onlyFileData = !(this.is_onlyFileData);
                if (this.is_onlyFileData) {
                    document.getElementById('file-only').style.backgroundColor = '#222222';
                    document.getElementById('file-only').style.color = 'white';
                } else {
                    document.getElementById('file-only').style.backgroundColor = '#b8d1de';
                    document.getElementById('file-only').style.color = 'black';
                }
                this.is_AccrossTable = false;
                this.is_NonMatches = false;
                this.is_AllData = false;
                this.is_Wordpress = false;
                this.is_WordpressSubType = false;
                this.is_Wordpress_non_matches = false;
                this.isPublicDataCompany = false;
                this.isPublicDataCompanyNonMatches = false;
                this.isPublicDataCompanyMatches = false;
                this.companiesToReview = [];
                this.peopleToReview = [];
                this.getOnlyFileData();
                break;
            case 'wordpress-table':
                this.selected_datatype(this.selectedDataType);
                this.getDisplayedColumnsWordpress();
                document.getElementById('person-results').style.backgroundColor = '#222222';
                document.getElementById('person-results').style.color = 'white';
                // tslint:disable-next-line:max-line-length
                this.is_search_ready = false;
                this.is_Wordpress = !(this.is_Wordpress);
                if (this.is_Wordpress) {
                    document.getElementById('wordpress').style.backgroundColor = '#222222';
                    document.getElementById('wordpress').style.color = 'white';
                    document.getElementById('wordpress-table').style.backgroundColor = '#222222';
                    document.getElementById('wordpress-table').style.color = 'white';
                } else {
                    document.getElementById('wordpress').style.backgroundColor = '#b8d1de';
                    document.getElementById('wordpress').style.color = 'black';
                    document.getElementById('wordpress-table').style.backgroundColor = '#b8d1de';
                    document.getElementById('wordpress-table').style.color = 'black';
                }
                this.is_onlyFileData = false;
                this.is_AccrossTable = false;
                this.is_NonMatches = false;
                this.is_AllData = false;
                this.is_Wordpress_non_matches = false;
                this.isPublicDataCompanyMatches = false;
                this.isPublicDataCompanyNonMatches = false;
                break;
            case 'wordpress-non-matches':
                this.getDisplayedColumnsWordpress();
                document.getElementById('person-results').style.backgroundColor = '#222222';
                document.getElementById('person-results').style.color = 'white';
                // tslint:disable-next-line:max-line-length
                this.is_search_ready = false;
                this.is_Wordpress_non_matches = !(this.is_Wordpress_non_matches);
                if (this.is_Wordpress_non_matches) {
                    document.getElementById('wordpress').style.backgroundColor = '#222222';
                    document.getElementById('wordpress').style.color = 'white';
                    document.getElementById('wordpress-non-matches').style.backgroundColor = '#222222';
                    document.getElementById('wordpress-non-matches').style.color = 'white';
                } else {
                    document.getElementById('wordpress').style.backgroundColor = '#b8d1de';
                    document.getElementById('wordpress').style.color = 'black';
                    document.getElementById('wordpress-non-matches').style.backgroundColor = '#b8d1de';
                    document.getElementById('wordpress-non-matches').style.color = 'black';
                }
                this.is_onlyFileData = false;
                this.is_AccrossTable = false;
                this.is_NonMatches = false;
                this.is_AllData = false;
                this.is_Wordpress = false;
                this.isPublicDataCompany = false;
                this.isPublicDataCompanyNonMatches = false;
                this.isPublicDataCompanyMatches = false;
                this.GetWordpressNonMatched();
                break;
            case 'public-companies-table':
                this.is_data_available = this.publicCoDataStatistics && this.publicCoDataStatistics.totalRecords > 0 ? true : false;
                this.is_onlyFileData = false;
                this.is_AccrossTable = false;
                this.is_NonMatches = false;
                this.is_AllData = false;
                this.is_Wordpress = false;
                this.is_Wordpress_non_matches = false;
                this.isPublicDataCompanyNonMatches = false;
                this.isPublicDataCompanyMatches = !this.isPublicDataCompanyMatches;
                if (this.isPublicDataCompanyMatches) {
                     document.getElementById('publicDataCO').style.backgroundColor = '#222222';
                     document.getElementById('publicDataCO').style.color = 'white';
                     document.getElementById('public-companies-table').style.backgroundColor = '#222222';
                     document.getElementById('public-companies-table').style.color = 'white';
                } else {
                    document.getElementById('publicDataCO').style.backgroundColor = '#b8d1de';
                    document.getElementById('publicDataCO').style.color = 'black';
                    document.getElementById('public-companies-table').style.backgroundColor = '#b8d1de';
                    document.getElementById('public-companies-table').style.color = 'black';
                }
                this.companiesToReview = [];
                this.peopleToReview = [];
                break;
            case 'public-companies-non-matches':
                this.is_onlyFileData = false;
                this.is_AccrossTable = false;
                this.is_NonMatches = false;
                this.is_AllData = false;
                this.is_Wordpress = false;
                this.isPublicDataCompanyMatches = false;
                this.isPublicDataCompanyNonMatches = !this.isPublicDataCompanyNonMatches;
                if (this.isPublicDataCompanyNonMatches) {
                    document.getElementById('publicDataCO').style.backgroundColor = '#222222';
                    document.getElementById('publicDataCO').style.color = 'white';
                    document.getElementById('public-companies-non-matches').style.backgroundColor = '#222222';
                    document.getElementById('public-companies-non-matches').style.color = 'white';
                } else {
                    document.getElementById('publicDataCO').style.backgroundColor = '#b8d1de';
                    document.getElementById('publicDataCO').style.color = 'black';
                    document.getElementById('public-companies-non-matches').style.backgroundColor = '#b8d1de';
                    document.getElementById('public-companies-non-matches').style.color = 'black';
                }
                this.GetPublicCoNotMatches();
                break;

        }
    }

    getDisplayedColumnsWordpress(): void {
        // tslint:disable-next-line:max-line-length
        this.displayedColumns = ['Action', 'SourceName', 'WordpressId', 'MemberClicksId', 'Person Id', 'First Name', 'Last Name', 'Title', 'Email', 'Alt Email', 'Direct Phone', 'Primary Company', 'Primary Co ID', 'CEXSubStatus', 'CBMSubStatus', 'Shipping Add1', 'Shipping Add2', 'Shipping City', 'Shipping State', 'Shipping Zipcode', 'Shipping Country', 'Alternate Address Preferred', 'Alternate Address Type', 'CEODigitalAndPrint', 'CEODigitalOnly', 'CBMDigitalAndPrint', 'CBMDigitalOnly', 'CEN', 'SEN', 'CEO100', 'CFO100',  'NextLevelLeaders', 'CEBI', 'Revenue', 'Ownership', 'OwnershipSubType', 'Employees', 'Industry', 'OtherIndustry', 'BPAQualData', 'BPAJobTitle', 'BPAQualSource', 'Active Status'];
    }
    // tslint:disable-next-line:typedef variable-name
    ClearCompanyName(event: any) {

        const personIndex = this.peopleToReview.findIndex((obj => obj.personId === event));
        this.peopleToReview[personIndex].primaryCompany = '';
    }

    // tslint:disable-next-line:typedef variable-name
    GetWordpressData(group_id: number) {
        this.showOverlay = true;
        this.is_totalScore_clicked = false;
        this.is_proceed_visible = 0;
        this.ColorSelectedGroupW(group_id);
        this.selected_group = group_id.toString();
        this.resetAllData();
        this.api.get_QuarantineGroupData(this.selected_group, this.selectedDataType).subscribe(res => {
                if (this.is_person_search) {
                    // @ts-ignore
                    this.peopleToReview = res;
                    console.log(this.peopleToReview);
                    this.peopleToReview.sort((a, b) => {
                        if (a['source name']) {
                            if (a['source name'] !== 'ImportedPerson') {
                                return 1;
                            }
                            if (a['source name'] === 'ImportedPerson') {
                                return -1;
                            }
                            return 0;
                        }
                    });
                    this.total_searches = this.peopleToReview.length;
                    this.is_search_ready = true;
                    this.dataSource = new MatTableDataSource<PersonToReview>(this.peopleToReview);
                    console.log('row',this.dataSource)
                    this.dataSource.paginator = this.paginator;
                    this.showOverlay = false;
                    this.handleMagazines();
                }
            },
            err => {
                console.log(err);
                this.showOverlay = false;
            });
    }
    handleMagazines(): void{
        for (const i of this.peopleToReview) {
            i.actionType = 'None';
            if (i.cbmDigitalOnly === undefined) {
                i.cbmDigitalOnly = false;
            }
            if (i.cbmDigitalAndPrint === undefined) {
                i.cbmDigitalAndPrint = false;
            }
            if (i.ceoDigitalOnly === undefined) {
                i.ceoDigitalOnly = false;
            }
            if (i.ceoDigitalAndPrint === undefined) {
                i.ceoDigitalAndPrint = false;
            }
            if (i.cen === undefined) {
                i.cen = false;
            }
            if (i.sen === undefined) {
                i.sen = false;
            }
            if (i.ceO100 === undefined) {
                i.ceO100 = false;
            }
            if (i.cfO100 === undefined) {
                i.cfO100 = false;
            }
            if (i.nextLevelLeaders === undefined) {
                i.nextLevelLeaders = false;
            }
            if (i.cebi === undefined) {
                i.cebi = false;
            }
        }
    }
    // tslint:disable-next-line:typedef
    goToFirstPage(tab: string) {
        this.currentPage = 1;
        this.resetOffset(tab);
    }
    // tslint:disable-next-line:typedef
    goToLastPage(tab: string) {
        this.currentPage = this.totalPages;
        this.resetOffset(tab);
        // console.log('offset', this.offset);
    }
    // tslint:disable-next-line:typedef
    nextPage(tab: string) {
        if (this.currentPage < this.totalPages) {
          this.currentPage ++;
          this.resetOffset(tab);
        }
        // console.log('page nr', this.currentWordpressPage);
    }
    // tslint:disable-next-line:typedef
    previousPage(tab: string) {
        if (this.currentPage > 1) {
            // @ts-ignore
            this.currentPage--;
            this.resetOffset(tab);
        }
    }
    // tslint:disable-next-line:typedef
    resetOffset(tab: string) {
        this.offset = ((this.currentPage - 1) * this.fetchData);
        this.showOverlay = true;
        // console.log('offset', this.offset);
        switch (tab){
            case 'nonMatches':
                this.ShowNonMatches();
                break;
            case 'nonMatchesWordpress':
                this.ShowWordpressNonMatches();
                break;
            case 'nonMatchesPublicCo':
                this.ShowPublicCoNonMatches();
                break;
        }
    }
    // tslint:disable-next-line:typedef
    getTotalPages(tab: string) {
        switch (tab){
            case  'nonMatches':
                // tslint:disable-next-line:radix
                if ( parseInt(this.notMatched) % this.fetchData) {
                    // tslint:disable-next-line:radix
                    this.totalPages = Math.floor(parseInt(this.notMatched) / this.fetchData) + 1;
                } else {
                    // tslint:disable-next-line:radix
                    this.totalPages = Math.floor(parseInt(this.notMatched) / this.fetchData);
                }
                break;
            case 'nonMatchesWordpress':
                // tslint:disable-next-line:radix
                if ( parseInt(this.notMatchedWordpress) % this.fetchData) {
                    // tslint:disable-next-line:radix
                    this.totalPages = Math.floor(parseInt(this.notMatchedWordpress) / this.fetchData) + 1;
                } else {
                    // tslint:disable-next-line:radix
                    this.totalPages = Math.floor(parseInt(this.notMatchedWordpress) / this.fetchData);
                }
                break;
            case 'nonMatchesPublicCo':
                if (this.publicCoDataStatistics){
                    if ( this.publicCoDataStatistics.totalNotMatchedRecords % this.fetchData) {
                        // tslint:disable-next-line:radix
                        this.totalPages = Math.floor(this.publicCoDataStatistics.totalNotMatchedRecords / this.fetchData) + 1;
                    } else {
                        // tslint:disable-next-line:radix
                        this.totalPages = Math.floor(this.publicCoDataStatistics.totalNotMatchedRecords / this.fetchData);
                    }
                }
        }
    }
    // tslint:disable-next-line:typedef
    GetWordpressNonMatched() {
        this.currentPage = 1;
        this.offset = 0;
        this.getTotalPages('nonMatchesWordpress');
        this.showOverlay = true;
        this.is_totalScore_clicked = false;
        this.is_proceed_visible = 0;
        this.resetAllData();
        this.ShowWordpressNonMatches();
    }
    // tslint:disable-next-line:typedef
    ShowWordpressNonMatches(){
        if (this.selectedDataType === 'Person') {
            // tslint:disable-next-line:max-line-length
            this.api.get_QuarantineNotMatchedWordpress(this.selectedDataType, this.offset.toString(), this.fetchData.toString()).subscribe(res => {
                    // @ts-ignore
                    this.peopleToReview = res;
                    // this.peopleToReview.sort((a, b) => {
                    //     if (a.lastName) {
                    //         if (a.lastName > b.lastName) {
                    //             return 1;
                    //         }
                    //         if (a.lastName < b.lastName) {
                    //             return -1;
                    //         }
                    //         return 0;
                    //     }
                    // });
                    this.total_searches = this.peopleToReview.length;
                    this.is_search_ready = true;
                    this.dataSource = new MatTableDataSource<PersonToReview>(this.peopleToReview);
                    this.dataSource.paginator = this.paginator;
                    this.showOverlay = false;
                    this.handleMagazines();
                    this.showOverlay = false;
                },
                err => {
                    console.log(err);
                    this.showOverlay = false;
            });
        }
    }
    // tslint:disable-next-line:typedef
    GetNotMatches() {
        this.showOverlay = true;
        this.is_totalScore_clicked = false;
        this.is_proceed_visible = 0;
        this.offset = 0;
        this.currentPage = 1;
        this.getTotalPages('nonMatches');
        this.resetAllData();
        this.ShowNonMatches();
    }
    GetPublicCoNotMatches(): void{
        this.showOverlay = true;
        this.is_totalScore_clicked = false;
        this.is_proceed_visible = 0;
        this.offset = 0;
        this.currentPage = 1;
        this.getTotalPages('nonMatchesPublicCo');
        this.resetAllData();
        this.ShowPublicCoNonMatches();
    }
    ShowPublicCoNonMatches(): void{
        this.getTotalPages('nonMatchesPublicCo');
        this.api.getQuarantineStatisticsCoApiNotMatched(this.offset.toString(), this.fetchData.toString()).subscribe(result => {
            // @ts-ignore
            this.companiesToReview = result;
            this.total_searches = this.companiesToReview.length;
            this.is_search_ready = true;
            this.dataSource = new MatTableDataSource<CompanyToReview>(this.companiesToReview);
            this.dataSource.paginator = this.paginator;
            this.showOverlay = false;
            for (const i of this.companiesToReview) {
                i.actionType = 'None';
            }
        }, err => {
            console.log(err);
            this.showOverlay = false;
        });
    }
    // tslint:disable-next-line:typedef
    ShowNonMatches(){
        // console.log('QUARANTINE NON MATCHED');
        this.getTotalPages('nonMatches');
        if (this.selectedDataType === 'Company') {
            this.api.get_QuarantineNotMatched(this.selectedDataType, this.offset.toString(), this.fetchData.toString()).subscribe(res => {
                    // @ts-ignore
                    this.companiesToReview = res;
                    this.companiesToReview.sort((a, b) => {
                        if (a.companyName) {
                            if (a.companyName > b.companyName) {
                                return 1;
                            }
                            if (a.companyName < b.companyName) {
                                return -1;
                            }
                            return 0;
                        }
                    });
                    this.total_searches = this.companiesToReview.length;
                    this.is_search_ready = true;
                    this.dataSource = new MatTableDataSource<CompanyToReview>(this.companiesToReview);
                    this.dataSource.paginator = this.paginator;
                    this.showOverlay = false;
                    for (const i of this.companiesToReview) {
                        i.actionType = 'None';
                    }
                },
                err => {
                    console.log(err);
                    this.showOverlay = false;
                });
        }
        if (this.selectedDataType === 'Person') {
        this.api.get_QuarantineNotMatched(this.selectedDataType, this.offset.toString(), this.fetchData.toString()
        ).subscribe(res => {
                // @ts-ignore
                this.peopleToReview = res;
                // this.peopleToReview.sort((a, b) => {
                //     if (a.lastName) {
                //         if (a.lastName > b.lastName) {
                //             return 1;
                //         }
                //         if (a.lastName < b.lastName) {
                //             return -1;
                //         }
                //         return 0;
                //     }
                // });
                this.total_searches = this.peopleToReview.length;
                this.is_search_ready = true;
                this.dataSource = new MatTableDataSource<PersonToReview>(this.peopleToReview);
                this.dataSource.paginator = this.paginator;
                this.showOverlay = false;
                this.handleMagazines();
                for (const i of this.peopleToReview) {
                    i.actionType = 'None';
                }
                this.handleMagazines();
            },
            err => {
                console.log(err);
                this.showOverlay = false;
            });
        }
    }
    // tslint:disable-next-line:typedef
    getOnlyFileData(){
        this.showOverlay = true;
        this.is_totalScore_clicked = false;
        this.is_proceed_visible = 0;
        this.resetAllData();
        if (this.selectedDataType === 'Company') {
            this.api.get_QuarantineOnlyFileData(this.selectedDataType).subscribe(res => {
                // @ts-ignore
                this.companiesToReview = res;
                this.total_searches = this.companiesToReview.length;
                this.is_search_ready = true;
                this.dataSource = new MatTableDataSource<CompanyToReview>(this.companiesToReview);
                this.dataSource.paginator = this.paginator;
                this.showOverlay = false;
                for (const i of this.companiesToReview) {
                    i.actionType = 'None';
                }
        });
        }
        if (this.selectedDataType === 'Person') {
               this.api.get_QuarantineOnlyFileData(this.selectedDataType).subscribe(res => {
                   // @ts-ignore
                   this.peopleToReview = res;
                   this.total_searches = this.peopleToReview.length;
                   this.is_search_ready = true;
                   this.dataSource = new MatTableDataSource<PersonToReview>(this.peopleToReview);
                   this.dataSource.paginator = this.paginator;
                   this.showOverlay = false;
                   for (const i of this.peopleToReview) {
                       i.actionType = 'None';
                   }
                   this.handleMagazines();
               });
        }
    }

    // tslint:disable-next-line:typedef
    GetAllData() {
        this.showOverlay = true;
        this.is_totalScore_clicked = false;
        this.is_proceed_visible = 0;
        this.resetAllData();
        if (this.selectedDataType === 'Company') {
            // tslint:disable-next-line:max-line-length
            this.api.get_QuarantineAllData(this.selectedDataType, '1', this.totalRecords.toString()).subscribe(res => {
                    // @ts-ignore
                    this.companiesToReview = res;
                    this.companiesToReview.sort((a, b) => {
                        if (a['source name']) {
                            if (a['source name'] !== 'ImportedCompany') {
                                return 1;
                            }
                            if (a['source name'] === 'ImportedCompany') {
                                return -1;
                            }
                            return 0;
                        }
                    });
                    this.total_searches = this.companiesToReview.length;
                    this.is_search_ready = true;
                    this.dataSource = new MatTableDataSource<CompanyToReview>(this.companiesToReview);
                    this.dataSource.paginator = this.paginator;
                    this.showOverlay = false;
                    for (const i of this.companiesToReview) {
                        i.actionType = 'None';
                    }
                },
                err => {
                    console.log(err);
                    this.showOverlay = false;
                });
        }
        if (this.selectedDataType === 'Person') {
            this.api.get_QuarantineAllData(this.selectedDataType, '1', this.totalRecords.toString()).subscribe(res => {
                    // @ts-ignore
                    this.peopleToReview = res;
                    this.peopleToReview.sort((a, b) => {
                        if (a['source name']) {
                            if (a['source name'] !== 'ImportedPerson') {
                                return 1;
                            }
                            if (a['source name'] === 'ImportedPerson') {
                                return -1;
                            }
                            return 0;
                        }
                    });
                    this.total_searches = this.peopleToReview.length;
                    this.is_search_ready = true;
                    this.dataSource = new MatTableDataSource<PersonToReview>(this.peopleToReview);
                    this.dataSource.paginator = this.paginator;
                    this.showOverlay = false;
                    for (const i of this.peopleToReview) {
                        i.actionType = 'None';
                    }
                    this.handleMagazines();
                },
                err => {
                    console.log(err);
                    this.showOverlay = false;
                });
        }
    }

    ColorGroupById(selectedDatatype: string, groupId: number): void{
        document.getElementById(selectedDatatype + '-group-' + groupId.toString()).style.backgroundColor = '#222222';
        document.getElementById(selectedDatatype + '-group-' + groupId.toString()).style.color = 'white';
        // tslint:disable-next-line:only-arrow-functions typedef
        document.getElementById(selectedDatatype + '-group-' + groupId.toString()).addEventListener('mouseover', function() {
            document.getElementById(selectedDatatype + '-group-' + groupId.toString()).style.backgroundColor = '#222222';
            document.getElementById(selectedDatatype + '-group-' + groupId.toString()).style.color = 'white';
        });
        // tslint:disable-next-line:only-arrow-functions typedef
        document.getElementById(selectedDatatype + '-group-' + groupId.toString()).addEventListener('mouseout', function() {
            document.getElementById(selectedDatatype + '-group-' + groupId.toString()).style.backgroundColor = '#222222';
            document.getElementById(selectedDatatype + '-group-' + groupId.toString()).style.color = 'white';
        });
    }
    ColorTheRestOfGroups(selectedDatatype: string, groupId: number): void{
        document.getElementById(selectedDatatype + '-group-' + groupId.toString()).style.backgroundColor = 'white';
        document.getElementById(selectedDatatype + '-group-' + groupId.toString()).style.color = 'black';
        // tslint:disable-next-line:only-arrow-functions typedef
        document.getElementById(selectedDatatype + '-group-' + groupId.toString()).addEventListener('mouseover', function() {
            document.getElementById(selectedDatatype + '-group-' + groupId.toString()).style.backgroundColor = '#222222';
            document.getElementById(selectedDatatype + '-group-' + groupId.toString()).style.color = 'white';
        });
        // tslint:disable-next-line:only-arrow-functions typedef
        document.getElementById(selectedDatatype + '-group-' + groupId.toString()).addEventListener('mouseout', function() {
            document.getElementById(selectedDatatype + '-group-' + groupId.toString()).style.backgroundColor = 'white';
            document.getElementById(selectedDatatype + '-group-' + groupId.toString()).style.color = 'black';
        });
    }
    // tslint:disable-next-line:variable-name
    // tslint:disable-next-line:variable-name
    ColorSelectedGroupW(group_id: number): void {
        // tslint:disable-next-line:variable-name
        const selected_datatype = 'person-wordpress';
        if (!this.is_long_list) {
            document.getElementById(selected_datatype + '-group-1').style.backgroundColor = '#222222';
            document.getElementById(selected_datatype + '-group-1').style.color = 'white';
        } else {
            for (const i of this.group_list_wordpress) {
                // tslint:disable-next-line:triple-equals
                if (i == group_id) {
                    this.ColorGroupById(selected_datatype, i);
                } else {
                    this.ColorTheRestOfGroups(selected_datatype, i);
                }
            }
        }
    }

    // tslint:disable-next-line:variable-name
    ColorSelectedGroup(group_id: number): void {
        // tslint:disable-next-line:variable-name
        const selected_datatype = this.selectedDataType.toLowerCase();
        if (!this.is_long_list) {
            document.getElementById(selected_datatype + '-group-1').style.backgroundColor = '#222222';
            document.getElementById(selected_datatype + '-group-1').style.color = 'white';
        } else {
            for (const i of this.group_list) {
                // tslint:disable-next-line:triple-equals
                if (i == group_id) {
                    this.ColorGroupById(selected_datatype, i);
                } else {
                    this.ColorTheRestOfGroups(selected_datatype, i);
                }
            }
        }
    }
    ColorSelectedGroupPublicCO(groupId: number): void{
        const selectedDatatype = this.selectedDataType.toLowerCase();
        if (!this.is_long_list) {
            document.getElementById(selectedDatatype + '-group-1').style.backgroundColor = '#222222';
            document.getElementById(selectedDatatype + '-group-1').style.color = 'white';
        } else {
            for (const i of this.publicCoDataStatistics.listOfGroupsIds) {
                // tslint:disable-next-line:triple-equals
                if (i == groupId) {
                    this.ColorGroupById(selectedDatatype, i);
                } else {
                    this.ColorTheRestOfGroups(selectedDatatype, i);
                }
            }
        }
    }
    GroupDataPublicCO(groupId: number): void{
        this.isSendRecordsToResearchSelected = false;
        this.isBulkSendResearchSelected = false;
        this.is_selectedGroup = true;
        this.setWasManualMergeAccessed(false);
        this.is_totalScore_clicked = false;
        this.showOverlay = true;
        this.is_proceed_visible = 0;
        this.selected_group = groupId.toString();
        this.ColorSelectedGroupPublicCO(groupId);
        this.resetAllData();
        this.GetGroup();
    }
    // tslint:disable-next-line:typedef variable-name
    Group_Data(group_id: number) {
        this.isSendRecordsToResearchSelected = false;
        this.isBulkSendResearchSelected = false;
        this.is_selectedGroup = true;
        this.setWasManualMergeAccessed(false);
        this.ColorSelectedGroup(group_id);
        this.is_totalScore_clicked = false;
        this.showOverlay = true;
        this.is_proceed_visible = 0;
        this.selected_group = group_id.toString();
        this.resetAllData();
        this.GetGroup();

    }
   GetGroup(): void{
       this.api.get_QuarantineGroupData(this.selected_group, this.selectedDataType).subscribe(res => {
               if (this.is_company_search) {
                   // @ts-ignore
                   this.companiesToReview = res;
                   this.companiesToReview.sort((a, b) => {
                       if (a['source name']) {
                           if (a['source name'] !== 'ImportedCompany') {
                               return 1;
                           }
                           if (a['source name'] === 'ImportedCompany') {
                               return -1;
                           }
                           return 0;
                       }
                   });
                   this.total_searches = this.companiesToReview.length;
                   this.is_search_ready = true;
                   this.dataSource = new MatTableDataSource<CompanyToReview>(this.companiesToReview);
                   this.dataSource.paginator = this.paginator;
                   this.showOverlay = false;
                   for (const i of this.companiesToReview) {
                       i.actionType = 'None';
                   }
               }
               if (this.is_person_search) {
                   // @ts-ignore
                   this.peopleToReview = res;
                   this.peopleToReview.sort((a, b) => {
                       if (a['source name']) {
                           if (a['source name'] !== 'ImportedPerson') {
                               return 1;
                           }
                           if (a['source name'] === 'ImportedPerson') {
                               return -1;
                           }
                           return 0;
                       }
                   });
                   this.total_searches = this.peopleToReview.length;
                   this.is_search_ready = true;
                   this.dataSource = new MatTableDataSource<PersonToReview>(this.peopleToReview);
                   this.dataSource.paginator = this.paginator;
                   this.showOverlay = false;
                   // console.log(this.peopleToReview);
                   for (const i of this.peopleToReview) {
                       i.actionType = 'None';
                   }
                   this.handleMagazines();
                   // console.log('people to review from group_data', this.peopleToReview);
               }
           },
           err => {
               console.log(err);
               this.showOverlay = false;
           });
   }
    resetSelectedDatatype(): void {
        document.getElementById('company-results').style.backgroundColor = '#005a87';
        document.getElementById('company-results').style.color = 'white';
        document.getElementById('person-results').style.backgroundColor = '#005a87';
        document.getElementById('person-results').style.color = 'white';
        document.getElementById('director-results').style.backgroundColor = '#005a87';
        document.getElementById('director-results').style.color = 'white';
    }

    // tslint:disable-next-line:typedef
    selected_datatype(datatype: string) {
        this.resetSelectedTab();
        this.showKickAll = false;
        this.dataSource = [];
        this.is_totalScore_clicked = false;
        this.is_selectedGroup = false;
        this.selectedDataType = datatype;
        this.is_data_available = false;
        this.is_proceed_visible = 0;
        this.resetAllData();
        this.resetSelectedDatatype();
        switch (datatype) {
            case 'BoardDirector':
                document.getElementById('director-results').style.backgroundColor = '#222222';
                document.getElementById('director-results').style.color = 'white';
                break;
            default:
                if (this.selectedDataType === 'Company') {

                    // tslint:disable-next-line:max-line-length
                    this.displayedColumns = ['Action', 'SourceName', 'CompanyId', 'Company Name', 'Address 1', 'Address 2', 'City', 'State', 'Zip', 'Country', 'Phone', 'Website', 'Ticker', 'CIK ID', 'SIC Code', 'Exchange', 'Annual Revenue', 'Employee Exact', 'Industry Category', 'Ownership', 'Ownership Sub Type', 'IPO Date' , 'Verified Date', 'How Verified', 'Active Status', 'AddressSource'];
                    document.getElementById('company-results').style.backgroundColor = '#222222';
                    document.getElementById('company-results').style.color = 'white';
                }
                if (this.selectedDataType === 'Person') {
                    this.matchingByID = false;
                    this.displayedColumns = ['Action', 'SourceName', 'Person Id', 'First Name', 'Middle Name', 'Last Name', 'Suffix', 'Title', 'Email', 'AltEmail', 'Direct Phone', 'Mobile Phone', 'Primary Company', 'Primary Co ID', 'Board Company Associations', 'Ticker', 'Shipping Add1', 'Shipping Add2', 'Shipping City', 'Shipping State', 'Shipping Zipcode', 'Shipping Country', 'Alternate Address Source', 'Alternate Address Preferred', 'Alternate Address Type', 'CEODigitalAndPrint', 'CEODigitalOnly', 'CBMDigitalAndPrint', 'CBMDigitalOnly', 'CEN', 'SEN', 'CEO100', 'CFO100',  'NextLevelLeaders', 'CEBI', 'BPAQualDate', 'BPAJobTitle', 'BPAQualSource', 'Verified Date', 'Active Status'];
                    document.getElementById('person-results').style.backgroundColor = '#222222';
                    document.getElementById('person-results').style.color = 'white';
                }
                this.SearchType();
                this.selected();
                if (this.user === 'cristina.badea@scriptics.ro' || this.user === 'giorgiana.damian@scriptics.ro' || this.user === 'giorgiana.lazea@scriptics.ro' || this.user === 'kjalbert@chiefexecutivegroup.com'){
                    this.showKickAll = true;
                }
                break;
        }

    }

    // tslint:disable-next-line:typedef
    async selected() {
        // tslint:disable-next-line:variable-name
        this.api.get_QuarantineStatistics(this.selectedDataType).subscribe(field_result => {
                // @ts-ignore
                this.statistics = field_result;
                if (this.statistics.totalRecords > 0) {
                    this.is_data_available = true;
                } else {
                    this.is_data_available = false;
                }
                if (this.selectedDataType === 'Person') {
                    this.totalNonWordpressRecords = this.statistics.totalNonWordpressRecords;
                } else {
                    this.totalNonWordpressRecords = 0;
                }
                this.matchGroups = this.statistics.totalMatchGroups;
                this.notMatched = this.statistics.totalNotMatchedRecords;
                this.totalRecords = this.statistics.totalRecords;
                this.group_list_elements = this.statistics.listOfGroupsIds;
                if (this.is_NonMatches){
                    this.getTotalPages('nonMatches');
                }
                this.GroupList(this.matchGroups);
            },
            err => {
                console.log(err);
            });
        // tslint:disable-next-line:variable-name
        if (this.selectedDataType === 'Person') {
            // tslint:disable-next-line:variable-name
            this.api.get_QuarantineStatisticsWordpress(this.selectedDataType).subscribe(field_result => {
                    // @ts-ignore
                    this.statisticsWordpress = field_result;
                    if (this.statisticsWordpress.totalWordpressRecords > 0) {
                        this.is_data_available = true;
                    } else {
                        this.is_data_available = false;
                    }
                    this.totalRecordsWordpress = this.statisticsWordpress.totalWordpressRecords;
                    this.matchGroupsWordpress = this.statisticsWordpress.totalWordpressMatchGroups;
                    this.notMatchedWordpress = this.statisticsWordpress.totalWordpressNotMatchedRecords;
                    this.group_list_elements_wordpress = this.statisticsWordpress.listOfWordpressGroupsIds;
                    this.GroupListWordpress(this.matchGroupsWordpress);
                    if (this.is_NonMatches){
                        this.getTotalPages('nonMatchesWordpress');
                    }
                },
                err => {
                    console.log(err);
            });
        }
        else{
            this.getPublicCoStatistics();
        }
    }

    // tslint:disable-next-line:typedef variable-name
    GroupList(group_count: string) {
        this.group_list = this.group_list_elements;
        this.group_list_length = this.group_list.length;
    }

    // tslint:disable-next-line:typedef variable-name
    GroupListWordpress(group_count: string) {
        this.group_list_wordpress = this.group_list_elements_wordpress;
        this.group_list_length_wordpress = this.group_list_wordpress.length;
    }

    // tslint:disable-next-line:typedef
    SearchType() {
        if (this.selectedDataType === 'Company') {
            this.is_company_search = true;
            this.is_person_search = false;
            this.is_engagement_search = false;
            this.group_list = [];
            this.group_list_wordpress = [];
            this.is_AccrossTable = false;
            this.is_NonMatches = false;
            this.is_onlyFileData = false;
            this.is_AllData = false;
            this.is_Wordpress = false;
            this.is_Wordpress_non_matches = false;
            this.isPublicDataCompany = false;
            this.isPublicDataCompanyMatches = false;
            this.isPublicDataCompanyNonMatches = false;
        }
        if (this.selectedDataType === 'Person') {
            this.is_company_search = false;
            this.is_person_search = true;
            this.is_engagement_search = false;
            this.group_list = [];
            this.group_list_wordpress = [];
            this.is_AccrossTable = false;
            this.is_NonMatches = false;
            this.is_onlyFileData = false;
            this.is_AllData = false;
            this.is_Wordpress = false;
            this.is_Wordpress_non_matches = false;
        }
    }

    // tslint:disable-next-line:typedef
    show_totalscore() {
        this.is_totalScore_clicked = !this.is_totalScore_clicked;
        // console.log(this.is_totalScore_clicked);
    }

    resetAllData(): void {
        this.bulkaction = 'None';
        this.is_proceed_visible = 0;
        this.merge_rule = 'UpdateEmptyField';

        this.companiesToReview = [];
        this.reviewedCompanies = [];

        this.peopleToReview = [];
        this.reviewedPeople = [];
    }

    // tslint:disable-next-line:typedef
    unsetMergedConnection() {
        this._mergeservice.update_alreadyMerged(0);
    }

    // tslint:disable-next-line:typedef
    setWasManualMergeAccessed(state: boolean) {
        this._mergeservice.update_manualMergeAccessed(state);
    }

    // Shows and hides the loading spinner during RouterEvent changes
    navigationInterceptor(event: RouterEvent): void {
        if (event instanceof NavigationStart) {
            this.showOverlay = true;
        }
        if (event instanceof NavigationEnd) {
            this.showOverlay = false;
        }
        // Set loading state to false in both of the below events to hide the spinner in case a request fails
        if (event instanceof NavigationCancel) {
            this.showOverlay = false;
        }
        if (event instanceof NavigationError) {
            this.showOverlay = false;
        }
    }

    GetTimeStamp(): void {
        // tslint:disable-next-line:new-parens
        const date = new Date;
        this.time_last_job_started = localStorage.getItem('date');
    }

    // tslint:disable-next-line:typedef
    showRunningJobs() {
        this.GetTimeStamp();
        this.api.get_Jobs().subscribe(res => {
                // @ts-ignore
                this.jobs = res;
                for (const i of this.jobs) {
                    if (i.type === 'CegIntegration.Web.Jobs.MainDataSynchronizationJob') {
                        this.jobs.splice(this.jobs.indexOf(i), 1);
                    }
                }
                this.are_jobsVisible = !(this.are_jobsVisible);
                if (this.are_jobsVisible) {
                    const img = document.getElementById('quarantine-running-jobs');
                    img.style.transform = 'rotate(180deg)';
                } else {
                    const img = document.getElementById('quarantine-running-jobs');
                    img.style.transform = 'rotate(0deg)';
                }
            },
            err => {
                console.log(err);
            });
    }

    deleteJob(jobKey: any): void {
        const dialogRef = this.dialog.open(WarningComponent);
        dialogRef.componentInstance.action = 'DeleteJob';
        dialogRef.componentInstance.isBulckAnction = false;

        dialogRef.afterClosed().subscribe(result => {
            const response = result;
            if (response === 'yes') {
                this.ProceedDeleteJob(jobKey);
            }
        });
    }

    ProceedDeleteJob(jobKey: any): void {
        const url = this.api.delete_Job(jobKey.toString());

        const xhr = new XMLHttpRequest();
        xhr.open('POST', url);
        xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
        xhr.setRequestHeader('Content-Type', 'application/json-patch+json');
        xhr.setRequestHeader('accept', '*/*');

        // tslint:disable-next-line:only-arrow-functions typedef
        xhr.onreadystatechange = function() {
            if (xhr.readyState === 4) {
                // tslint:disable-next-line:triple-equals
                if (xhr.status == 200) {
                    document.getElementById('succeeded_snack').click();
                    document.getElementById('refreshJobs').click();
                } else {
                    document.getElementById('failed_snack').click();
                }
            }
        };
        xhr.send();
    }

    refreshJobs(): void {
        this.api.get_Jobs().subscribe(res => {
                // @ts-ignore
                this.jobs = res;
                for (const i of this.jobs) {
                    if (i.type === 'CegIntegration.Web.Jobs.MainDataSynchronizationJob') {
                        this.jobs.splice(this.jobs.indexOf(i), 1);
                    }
                }
            },
            err => {
                console.log(err);
            });
    }

    ApplyActionToAll(datatype: string, event: any): void {
        this.matchingByID = false;
        this.are_actionButtons_visible++;
        this.bulkaction = event.value;
        const value = event.value;
        this.isBulkSendResearchSelected = false;
        if (this.are_alreadyMerged === 1) {
            if (datatype === 'company') {
                for (const i of this.companiesToReview) {
                    for (const j of this.mergedCompanies) {
                        if (i.winPurePrimK === j.winPurePrimK) {
                            i.actionType = 'None';
                            if (this.is_AccrossTable) {
                                document.getElementById('acrosstable-company-select-' + i.winPurePrimK).style.display = 'none';
                            } else if (this.is_AllData) {
                                document.getElementById('alldata-company-select-' + i.winPurePrimK).style.display = 'none';
                            } else if (this.notMatched) {
                                document.getElementById('nonmatches-company-select-' + i.winPurePrimK).style.display = 'none';
                            }
                        } else {
                            i.actionType = value;
                        }
                    }
                }
            } else if (datatype === 'person') {
                for (const i of this.peopleToReview) {
                    for (const j of this.mergedPeople) {
                        if (i.winPurePrimK === j.winPurePrimK) {
                            i.actionType = 'None';
                            if (this.is_AccrossTable) {
                                document.getElementById('acrosstable-person-select-' + i.winPurePrimK).style.display = 'none';
                            } else if (this.is_AllData) {
                                document.getElementById('alldata-person-select-' + i.winPurePrimK).style.display = 'none';
                            }else if (this.is_onlyFileData) {
                                document.getElementById('file-person-select-' + i.winPurePrimK).style.display = 'none';
                            }  else if (this.notMatched) {
                                document.getElementById('nonmatches-person-select-' + i.winPurePrimK).style.display = 'none';
                            } else if (this.is_Wordpress) {
                                document.getElementById('wordpress-person-select-' + i.winPurePrimK).style.display = 'none';
                            } else if (this.is_Wordpress_non_matches) {
                                document.getElementById('wordpress-non-matches-person-select-' + i.winPurePrimK).style.display = 'none';
                            }
                        } else {
                            i.actionType = value;
                        }
                    }
                }
            }
        } else {
            if (value !== 'ResearchGroup') {
                if (datatype === 'company') {
                    for (const i of this.companiesToReview) {
                        i.actionType = value;
                    }
                } else if (datatype === 'person') {
                    for (const i of this.peopleToReview) {
                        i.actionType = value;
                    }
                }
            }
            if (value === 'ResearchGroup') {
                const newvalue = 'Research';
                if (datatype === 'company') {
                    for (const i of this.companiesToReview) {
                        i.actionType = newvalue;
                    }
                } else if (datatype === 'person') {
                    for (const i of this.peopleToReview) {
                        i.actionType = newvalue;
                    }
                }
            }
        }
        if (value === 'ResearchGroup') {
            this.isBulkSendResearchSelected = true;
        }
    }
    // tslint:disable-next-line:typedef
    selectResearchArea(event: any) {
        this.researchAreaId = event.value;
    }

    show_research(): void {
        this.api.get_Research().subscribe(res => {
                // @ts-ignore
                this.research = res;
            },
            err => {
                console.log(err);
            });
    }

    // tslint:disable-next-line:typedef
    show_users() {
        this.api.get_Users().subscribe(res => {
                // @ts-ignore
                this.users = res;
            },
            err => {
                console.log(err);
            });
    }
    ngOnInit(): void {
        this.api.getAuthToken().subscribe(auth => {
            this.token = auth;
        });
        this.auth.user$.subscribe(
            (profile) => {
                (this.profileJson = JSON.parse(JSON.stringify(profile, null, 2)));
                // console.log('profile', profile.email);
                // console.log('profile', profile.name);
                // console.log('profile', profile['https://schemas.quickstarts.com/roles'][0]);
                // localStorage.setItem('user', profile.name);
                // localStorage.setItem('userEmail', profile.email);


                this.user = profile.name;
                // console.log('this.user', this.user);
                localStorage.setItem('userRole', profile['https://schemas.quickstarts.com/roles'][0]);
                this.userRole = profile['https://schemas.quickstarts.com/roles'][0];
                // console.log('user role', this.userRole);
                if (this.userRole === 'admin') {
                    this.isAdmin = true;
                    console.log('is admin');
                } else {
                    this.isAdmin = false;
                    console.log('is not admin');
                    this.redirect();
                }
            });
        this.matchingByID = false;
        this.show_users();
        this.show_research();
        this.api.getSourceData_SimpleSearch().subscribe(res => {
                // @ts-ignore
                this.dataTypes = res;
            },
            err => {
                console.log(err);
            });
        this.api.get_Countries().subscribe(res => {
                // @ts-ignore
                this.countries = res;
                const index = this.countries.findIndex(({countryName}) => countryName === 'United States');
                this.countries.unshift(this.us_country);
                this.countries.splice(index, 1);
            },
            err => {
                console.log(err);
            });
        this.api.get_States().subscribe(res => {
                // @ts-ignore
                this.states = res;
            },
            err => {
                console.log(err);
            });
    }
    // tslint:disable-next-line:typedef
    StartImportMemberClicks() {
        let url = '';
        url = this.api.get_MemberClicks();
        const xhr = new XMLHttpRequest();
        xhr.open('POST', url);
        xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
        xhr.setRequestHeader('Content-Type', 'application/json-patch+json');
        xhr.setRequestHeader('accept', '*/*');
        // tslint:disable-next-line:only-arrow-functions typedef
        xhr.onreadystatechange = function() {
            if (xhr.readyState === 4) {
                console.log(xhr.status);
                console.log(xhr.responseText);
            }
            // tslint:disable-next-line:triple-equals
            if (xhr.status == 200) {
                document.getElementById('succeeded_snack').click();
                // document.getElementById('refresh_group').click();
            } else {
                document.getElementById('failed_snack').click();
            }
        };
        xhr.send();
    }
    // tslint:disable-next-line:typedef
    KickAllData() {
        const dialogRef = this.dialog.open(WarningComponent);
        dialogRef.componentInstance.action = 'KickAll';
        dialogRef.afterClosed().subscribe(result => {
            const response = result;
            if (response === 'yes') {
                this.showOverlay = true;
                let url = '';
                url = this.api.url_post_KickAllData(this.selectedDataType);
                const xhr = new XMLHttpRequest();
                xhr.open('POST', url);
                xhr.setRequestHeader('Content-Type', 'application/json-patch+json');
                xhr.setRequestHeader('accept', '*/*');
                xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
                // tslint:disable-next-line:only-arrow-functions typedef
                xhr.onreadystatechange = function() {
                    if (xhr.readyState === 4) {
                        console.log(xhr.status);
                        console.log(xhr.responseText);
                    }
                    // tslint:disable-next-line:triple-equals
                    if (xhr.status == 200) {
                        document.getElementById('succeeded_snack').click();
                        // document.getElementById('refresh_group').click();
                    } else {
                        document.getElementById('failed_snack').click();
                    }
                };
                xhr.send();
            }
        });

    }
    // tslint:disable-next-line:typedef
    StartMatching(datatype: string) {
        let url = '';
        url = this.api.get_matching(datatype);
        console.log(url);
        const xhr = new XMLHttpRequest();
        xhr.open('POST', url);
        xhr.setRequestHeader('Content-Type', 'application/json-patch+json');
        xhr.setRequestHeader('accept', '*/*');
        xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
        // tslint:disable-next-line:only-arrow-functions typedef
        xhr.onreadystatechange = function() {
            if (xhr.readyState === 4) {
                console.log(xhr.status);
                console.log(xhr.responseText);
            }
            // tslint:disable-next-line:triple-equals
            if (xhr.status == 200) {
                document.getElementById('succeeded_snack').click();
                // document.getElementById('refresh_group').click();
            } else {
                document.getElementById('failed_snack').click();
            }
        };
        xhr.send();
    }
}
