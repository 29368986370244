export class BoardDirector{
    id: number;
    importId: number;
    personId: number;
    boardCompanyCik: number;
    boardCompanyId: number;
    boardCompanyName: string;
    boardDirectorId: string;
    directorSince: number;
    firstName: string;
    lastName: string;
    primaryCompany: string;
    primaryCompanyId: number;
    primaryTitle: string;
    audit: string;
    govNom: string;
    comp: string;
    importedRecord?: boolean;
    activeStatus: boolean;
    insideOutside: string;
    cbmStatus: boolean;
    cbmPrint: boolean;
    removed: boolean;
    action: string;
    actionId: number;
    constructor() {
        this.id = 0;
        this.importId = 0;
        this.personId = 0;
        this.boardCompanyCik = 0;
        this.boardCompanyId = 0;
        this.boardCompanyName = '';
        this.boardDirectorId = '';
        this.directorSince = 0;
        this.firstName = '';
        this.lastName = '';
        this.primaryCompany = '';
        this.primaryCompanyId = 0;
        this.primaryTitle = '';
        this.audit = '';
        this.govNom = '';
        this.comp = '';
        this.importedRecord = false;
        this.activeStatus = false;
        this.insideOutside = '';
        this.cbmStatus = false;
        this.cbmPrint = false;
        this.removed = false;
    }
}
export class BoardDirectorSelection{
    personId: number[] = [];
    firstName: string[] = [];
    middleName: string[] = [];
    lastName: string[] = [];
    primaryCompany: string[] = [];
    primaryCompanyId: number[] = [];
    primaryTitle: string[] = [];
    boardCompanyName: DataType[] = [];
    boardCompanyCik: number[] = [];
    directorSince: number[] = [];
    audit: string[] = [];
    govNom: string[] = [];
    comp: string[] = [];
    insideOutside: string[] = [];
}

export class Filters {
    key: string;
    label: string;
    exactMatch: boolean;
    fuzzyLevel: number;
    constructor(key: string, label: string, exactMatch: boolean, fuzzy: number) {
        this.key = key;
        this.label = label;
        this.exactMatch = exactMatch;
        this.fuzzyLevel = fuzzy;
    }
}
export interface Actions {
    id: number;
    action: string;
    actionDescription: string;
}

export interface DataType{
    value: string;
    importedData: boolean;
}
