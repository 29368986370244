import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ConfigService} from '../config/config.service';
import {AppComponent} from '../app.component';
import {WarningComponent} from '../warning/warning.component';
import {Jobs} from '../shared/Jobs';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {MergedConnectionService} from '../service/mergedConnection.service';
import {UsersToView} from '../shared/UsersToView';
import {GroupsToBeMoved, Research} from '../shared/Research';
import {AuthService} from '@auth0/auth0-angular';
import {Statistics} from '../shared/Statistics';
import {StatisticsWordpress} from '../shared/StatisticsWordpress';
import { MatOption } from '@angular/material/core';
import {FormControl} from '@angular/forms';
import {environment as env} from '../../environments/environment';
import {PublicCoDataStatistics} from '../shared/PublicCoDataStatistics';

@Component({
    selector: 'app-research-settings',
    templateUrl: './research-settings.component.html',
    styleUrls: ['./research-settings.component.scss', '../shared/helpers_scss/spinner.scss']
})
export class ResearchSettingsComponent implements OnInit {
    token: string;
    environment: string;
    index: number;
    users: UsersToView[] = [];
    selectResearchAreas: boolean;
    moveGroupsToResearch: boolean;
    myArray: number[] = [];
    research: Research[];
    // tslint:disable-next-line:variable-name
    is_data_available = false;
    // tslint:disable-next-line:variable-name
    is_groups_available = false;
    statistics: Statistics;
    statisticsWordpress: StatisticsWordpress;
    publicCoDataStatistics: PublicCoDataStatistics;
    totalNonWordpressRecords: number;
    selectedDataType;
    moveAcrossTableGroups: GroupsToBeMoved;
    movePersonApiGroups: GroupsToBeMoved;
    movePublicCoApiGroups: GroupsToBeMoved;
    jobs: Jobs[] = [{
        key: '',
        type: '',
        inProcess: false,
        state: ''
    }];
    // tslint:disable-next-line:variable-name
    time_last_job_started = '';
    // tslint:disable-next-line:variable-name
    are_jobsVisible = false;
    public showOverlay = false;
    acrossTableGroups = new FormControl('');
    publicCoApiGroups = new FormControl('');
    WordpressTableGroups = new FormControl('');
    @ViewChild('allSelected') private allSelected: MatOption;
    @ViewChild('allSelectedWordpress') private allSelectedWordpress: MatOption;
    @ViewChild('allSelectedPublicCoApi') private allSelectedPublicCoApi: MatOption;
    // tslint:disable-next-line:variable-name
    constructor(private api: ConfigService, public auth: AuthService, private route: Router, private _mergeservice: MergedConnectionService,
                private snackBar: MatSnackBar, public dialog: MatDialog, private appcomponent: AppComponent) {
        this.appcomponent.jobChecking();
    }

    // tslint:disable-next-line:typedef
    snackbar_SucceededPost() {
        const sb = this.snackBar.open('Your changes have successfully processed!', '', {
            duration: 2000,
            panelClass: ['succeed'],
            horizontalPosition: 'right',
            verticalPosition: 'top'
        });
        this.showOverlay = false;
        window.location.reload();
    }

    // tslint:disable-next-line:typedef
    snackbar_FailedPost() {
        const sb = this.snackBar.open('Your changes was not processed!', '', {
            duration: 1000,
            panelClass: ['failed'],
            horizontalPosition: 'right',
            verticalPosition: 'top'
        });
        this.showOverlay = false;
    }

    // tslint:disable-next-line:typedef
    show_users() {
        this.api.get_Users().subscribe(res => {
                console.log(res);
                // @ts-ignore
                this.users = res.map((item): UsersToView => ({
                    id : item.id,
                    email: item.email,
                    teamMemberId: item.teamMemberId,
                    teamMemberName: item.teamMemberName,
                    listOfResearchAreas: item.listOfResearchAreas?.split(',').map(Number)
                }));
            },
            err => {
                console.log(err);
            });
        console.log('users', this.users);
        }

    GetTimeStamp(): void {
        // tslint:disable-next-line:new-parens
        const date = new Date;
        this.time_last_job_started = localStorage.getItem('date');
    }

    // tslint:disable-next-line:typedef
    showRunningJobs() {
        this.GetTimeStamp();
        this.api.get_Jobs().subscribe(res => {
                // @ts-ignore
                this.jobs = res;
                for (const i of this.jobs) {
                    if (i.type === 'CegIntegration.Web.Jobs.MainDataSynchronizationJob') {
                        this.jobs.splice(this.jobs.indexOf(i), 1);
                    }
                }
                this.are_jobsVisible = !(this.are_jobsVisible);
                if (this.are_jobsVisible) {
                    const img = document.getElementById('research-running-jobs');
                    img.style.transform = 'rotate(180deg)';
                } else {
                    const img = document.getElementById('research-running-jobs');
                    img.style.transform = 'rotate(0deg)';
                }
            },
            err => {
                console.log(err);
            });
    }

    deleteJob(jobKey: any): void {
        const dialogRef = this.dialog.open(WarningComponent);
        dialogRef.componentInstance.action = 'DeleteJob';

        dialogRef.afterClosed().subscribe(result => {
            console.log(`popup result: ${result}`);
            const response = result;
            if (response === 'yes') {
                this.ProceedDeleteJob(jobKey);
            }
        });
    }

    ProceedDeleteJob(jobKey: any): void {
        const url = this.api.delete_Job(jobKey.toString());

        const xhr = new XMLHttpRequest();
        xhr.open('POST', url);
        xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
        xhr.setRequestHeader('Content-Type', 'application/json-patch+json');
        xhr.setRequestHeader('accept', '*/*');

        // tslint:disable-next-line:only-arrow-functions typedef
        xhr.onreadystatechange = function() {
            if (xhr.readyState === 4) {
                // tslint:disable-next-line:triple-equals
                if (xhr.status == 200) {
                    console.log('ok');
                    document.getElementById('succeeded_snack').click();
                    document.getElementById('refreshJobs').click();
                } else {
                    document.getElementById('failed_snack').click();
                }
            }
        };
        xhr.send();
    }

    refreshJobs(): void {
        this.api.get_Jobs().subscribe(res => {
                // @ts-ignore
                this.jobs = res;
                for (const i of this.jobs) {
                    if (i.type === 'CegIntegration.Web.Jobs.MainDataSynchronizationJob') {
                        this.jobs.splice(this.jobs.indexOf(i), 1);
                    }
                }
            },
            err => {
                console.log(err);
            });
    }

// tslint:disable-next-line:typedef
    show_research() {
        this.api.get_Research().subscribe(res => {
                // @ts-ignore
                this.research = res;
                console.log('research', this.research);
            },
            err => {
                console.log(err);
            });
    }

    // tslint:disable-next-line:typedef
    selectedAction(actionType: string) {
        this.resetSelectedDatatype();
        if (actionType === 'researchAreas') {
            this.selectResearchAreas = true;
            this.moveGroupsToResearch = false;
            document.getElementById('researchAreas').style.backgroundColor = '#222222';
            document.getElementById('researchAreas').style.color = 'white';
            this.selectedDataType = null;
        } else {
            this.moveGroupsToResearch = true;
            this.selectResearchAreas = false;
            document.getElementById('moveGroups').style.backgroundColor = '#222222';
            document.getElementById('moveGroups').style.color = 'white';
        }
    }

    // tslint:disable-next-line:typedef
    selected_datatype(datatype: string) {
        this.selectedDataType = datatype;
        this.moveAcrossTableGroups =  new GroupsToBeMoved();
        this.is_groups_available = false;
        this.resetSelectedActionType();
        if (this.selectedDataType === 'Company') {
            this.movePublicCoApiGroups = new  GroupsToBeMoved();
            document.getElementById('company-results').style.backgroundColor = '#222222';
            document.getElementById('company-results').style.color = 'white';
            this.getStatistics();
        }
        if (this.selectedDataType === 'Person') {
            this.movePersonApiGroups = new  GroupsToBeMoved();
            document.getElementById('person-results').style.backgroundColor = '#222222';
            document.getElementById('person-results').style.color = 'white';
            this.getStatistics();
        }
        this.show_research();
    }

    // tslint:disable-next-line:typedef
    getStatistics() {
        // tslint:disable-next-line:variable-name
        this.api.get_QuarantineStatistics(this.selectedDataType).subscribe(field_result => {
                // @ts-ignore
                this.statistics = field_result;
                if (this.statistics.totalRecords > 0) {
                    this.is_data_available = true;
                } else {
                    this.is_data_available = false;
                }
                if (this.selectedDataType === 'Person') {
                    this.totalNonWordpressRecords = this.statistics.totalNonWordpressRecords;
                } else {
                    this.totalNonWordpressRecords = 0;
                }
            },
            err => {
                console.log(err);
            });
        // tslint:disable-next-line:variable-name
        if (this.selectedDataType === 'Person') {
            // tslint:disable-next-line:variable-name
            this.api.get_QuarantineStatisticsWordpress(this.selectedDataType).subscribe(field_result => {
                    // @ts-ignore
                    this.statisticsWordpress = field_result;
                    if (this.statisticsWordpress.totalWordpressRecords > 0) {
                        this.is_data_available = true;
                    } else {
                        this.is_data_available = false;
                    }
                    // console.log('this.statisticsWordpress', this.statisticsWordpress);
                },
                err => {
                    console.log(err);
                });
        }
        else{
            this.getPublicCoStatistics();
        }
    }
    // tslint:disable-next-line:typedef
    getPublicCoStatistics(): void{
        this.api.getQuarantineStatisticsCoApi(this.selectedDataType).subscribe(result => {
                // @ts-ignore
                this.publicCoDataStatistics = result;
                if (this.publicCoDataStatistics && this.publicCoDataStatistics.totalRecords > 0) {
                    this.is_data_available = true;
                } else {
                    this.is_data_available = false;
                }
            },
            err => {
                console.log(err);
                this.showOverlay = false;
            });
    }

    resetSelectedActionType(): void {
        document.getElementById('company-results').style.color = 'black';
        document.getElementById('company-results').style.backgroundColor = '#b8d1de';
        document.getElementById('person-results').style.color = 'black';
        document.getElementById('person-results').style.backgroundColor = '#b8d1de';
    }

    resetSelectedDatatype(): void {
        document.getElementById('researchAreas').style.backgroundColor = '#005a87';
        document.getElementById('moveGroups').style.backgroundColor = '#005a87';
    }

    // tslint:disable-next-line:typedef
    saveChanges() {
        let url = '';
        console.log(this.users);
        console.log(JSON.stringify(this.users));
        url = this.api.url_post_ResearchChanges(JSON.stringify(this.users));
        console.log('am trimis datele');
        const xhr = new XMLHttpRequest();
        xhr.open('POST', url);
        xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
        xhr.setRequestHeader('Content-Type', 'application/json-patch+json');
        xhr.setRequestHeader('accept', '*/*');
        // tslint:disable-next-line:only-arrow-functions typedef
        xhr.onreadystatechange = function() {
            if (xhr.readyState === 4) {
                console.log(xhr.status);
                console.log(xhr.responseText);
            }
            // tslint:disable-next-line:triple-equals
            if (xhr.status == 200) {
                console.log('ok');
                document.getElementById('succeeded_snack').click();
            } else {
                document.getElementById('failed_snack').click();
            }
        };
        xhr.send();
        setTimeout(() => {
            this.refreshPage();
        }, 100);
    }

    // tslint:disable-next-line:typedef
    refreshPage() {
        window.location.reload();
    }

    proceedSelection(dataType: string): void {
        console.log('proceedSelection');
        const arrayToSend = [];
        if (this.moveAcrossTableGroups.listOfGroups){
            arrayToSend.push(this.moveAcrossTableGroups);
        }
        if (dataType === 'Person' && this.movePersonApiGroups.listOfGroups ) {
            arrayToSend.push(this.movePersonApiGroups);
        }else if (dataType === 'Company' && this.movePublicCoApiGroups.listOfGroups){
            arrayToSend.push(this.movePublicCoApiGroups);
        }
        this.showOverlay = true;
        console.log(arrayToSend);
        const url = this.api.url_post_PocessdMoveMultipleGroups(dataType);
        const xhr = new XMLHttpRequest();
        xhr.open('POST', url);
        xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
        xhr.setRequestHeader('Content-Type', 'application/json-patch+json');
        xhr.setRequestHeader('accept', '*/*');
        // tslint:disable-next-line:only-arrow-functions typedef
        xhr.onreadystatechange = function() {
            if (xhr.readyState === 4) {
                console.log(xhr.status);
                console.log(xhr.responseText);
            }
            // tslint:disable-next-line:triple-equals
            if (xhr.status == 200) {
                document.getElementById('succeeded_snack').click();
                // document.getElementById('refresh').click();
            } else {
                document.getElementById('failed_snack').click();
            }
        };
        xhr.send(JSON.stringify(arrayToSend));
    }
    toggle(): void{
        if (this.allSelected.selected === true){
            this.acrossTableGroups.patchValue([...this.statistics.listOfGroupsIds, 0] );
            this.moveAcrossTableGroups.listOfGroups = this.statistics.listOfGroupsIds;
        }
        if (this.allSelected.selected === false) {
            this.acrossTableGroups.reset();
            this.moveAcrossTableGroups.listOfGroups = [];
        }
    }
    togglePublicCoApi(): void{
        if (this.allSelectedPublicCoApi.selected === true){
            this.publicCoApiGroups.patchValue([...this.publicCoDataStatistics.listOfGroupsIds, 0] );
            this.movePublicCoApiGroups.listOfGroups = this.publicCoDataStatistics.listOfGroupsIds;
        }
        if (this.allSelectedPublicCoApi.selected === false) {
            this.publicCoApiGroups.reset();
            this.movePublicCoApiGroups.listOfGroups = [];
        }
    }
    selectedResearchArea(event: any, section: string): void {
        console.log(event.value);
        switch (section) {
            case 'PersonApiData':
                this.movePersonApiGroups.researchArea = event.value;
                console.log('this.moveWordpressGroups', this.movePersonApiGroups);
                break;
            case 'AcrossTable':
                this.moveAcrossTableGroups.researchArea = event.value;
                console.log('this.moveAcrosssTableGroups.researchArea', this.moveAcrossTableGroups);
                break;
            case 'PublicCoApi':
                this.movePublicCoApiGroups.researchArea = event.value;
                console.log('this.publiccoAPi.researchArea', this.movePublicCoApiGroups);
                break;
        }
    }

    selectedGroupsToBeSent(event: any, section: string): void {
        switch (section) {
            case 'PersonApiData':
                this.movePersonApiGroups.listOfGroups = event.value;
                console.log('this.moveWordpressGroups', this.movePersonApiGroups);
                break;
            case 'AcrossTable':
                this.moveAcrossTableGroups.listOfGroups = event.value;
                console.log('this.moveAcrosssTableGroups', this.moveAcrossTableGroups);
                break;
            case 'PublicCoApi':
                this.movePublicCoApiGroups.listOfGroups = event.value;
                console.log('this.movepubliccoapi', this.movePublicCoApiGroups);
                break;
        }
    }
    toggleWordpress(): void{
        if (this.allSelectedWordpress.selected === true){
            this.WordpressTableGroups.patchValue([...this.statisticsWordpress.listOfWordpressGroupsIds, 0] );
            this.movePersonApiGroups.listOfGroups = this.statisticsWordpress.listOfWordpressGroupsIds;
        }
        if (this.allSelectedWordpress.selected === false) {
            this.WordpressTableGroups.reset();
            this.movePersonApiGroups.listOfGroups = [];
        }
    }
// tslint:disable-next-line:typedef
    selected_research_area(event: any, userId: number) {
        const userIndex = this.users.findIndex(item => item.id === userId);
        this.users[userIndex].listOfResearchAreas = event.value;
        console.log('users', this.users);
    }

    // tslint:disable-next-line:typedef
    onItemSelect(item: any) {
        console.log('onItemSelect', item);
    }

    // tslint:disable-next-line:typedef
    onSelectAll(items: any) {
        console.log('onSelectAll', items);
    }

    ngOnInit(): void {
        this.api.getAuthToken().subscribe(auth => {
            this.token = auth;
        });
        this.environment =  env.environment ;
        this.show_research();
        this.show_users();
        this.selectedAction('researchAreas');
        this.selectResearchAreas = true;
        this.moveGroupsToResearch = false;

    }

}
