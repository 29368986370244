<app-navbar></app-navbar>

<body class="body_container">
<button id="succeeded_snack" class="snack_button"  color="primary" (click)="snackbar_SucceededPost()">
  Snackbar CSS Styles</button>
<button id="failed_snack" class="snack_button"  color="primary" (click)="snackbar_FailedPost()">
  Snackbar CSS Styles</button>
<button id="succeededjob_snack" class="snack_button"  color="primary" (click)="snackbar_DeleteJobSucceededPost()">
  Snackbar CSS Styles</button>
<button id="failedjob_snack" class="snack_button" color="primary" (click)="snackbar_FailedDeleteJobPost()">
  Snackbar CSS Styles</button>
<button id="reload_configs" style="display: none" (click)="ReloadConfig()">Reload Configs</button>
<button id="refreshJobs" style="display: none" (click)="refreshJobs()"></button>
<button id="start_checking_jobs" class="snack_button" (click)="StartCheckingJobs()"></button>
    <h1>
        <a class="black-box">
          <img src="assets/img/import_white.svg" alt="trash_icon" class="logo-icon">
          File Import
        </a>
<!--      <a class="production">PRODUCTION</a>-->
      <a class="development">DEVELOPMENT</a>
    </h1>
<p class="check-jobs" (click)="showRunningJobs()">
    Check running jobs
    <img id="import-running-jobs" src="assets/img/down_simple_arrow.svg" alt="down_icon" class="icon-right">
</p>
<div>
<div *ngIf="are_jobsVisible" id="running-jobs" class="running-jobs">
    <div *ngIf="jobs">
        <p *ngIf="time_last_job_started !== ''">Last job started at: {{time_last_job_started}}</p>
        <p>There are {{jobs.length}} job(s) running</p>
        <div *ngFor="let job of jobs">
            <p>
                Job type: {{job.type}}
            </p>
            <p>
                Job state: {{job.state}}
            </p>
            <button (click)="deleteJob(job.key.toString())" class="button" style="margin: 0 0 0 1%">Delete</button>
            <p>--------------------------------------------------------------</p>
        </div>
    </div>
</div>
<button (click)="openArchive()" class="button">See Archive</button>
    <p>Please upload file from external source
      <label for="input" class="button" >Select File</label>
      <input id="input" type="file" hidden accept=".xls, .xlsx"/>
    </p>
    <table class="right">
        <thead>
        <tr>
            <th>Config Preferences</th>
        </tr>
        </thead>
        <tr style="width: 100%">
            <table style="width: 100%">
                <tbody>
                <tr *ngFor="let config of saved_configs">
                    <td style="overflow: hidden; width: 50%">{{config.name}}</td>
                    <td style="width: 25%">
                        <a (click)="Edit(config.name)">
                            <img src="assets/img/pencil.svg" alt="pencil_icon" class="icons">
                        </a>
                    </td>
                    <td style="width: 25%">
                        <a (click)="RemoveConfig(config.name)">
                            <img src="assets/img/trash.svg" alt="trash_icon" class="icons">
                        </a>
                    </td>
                </tr>
                </tbody>

            </table>
        </tr>
        <tr class="table_button">
          <td><button (click)="CreateNew()" class="mat_button_config">+CreateNew</button></td>
        </tr>
      </table>

      <div *ngIf="fileName">
        <p class="new_data">
          <img src="assets/img/cancel.svg" (click)="RemoveFile()" alt="cancel_icon" class="remove-file-icon">
          {{fileName}}
          <label>
            <select class="dropdown" (change)="selectedConfig($event)">
              <option>Select config</option>
              <option *ngFor="let config of saved_configs" [value]="config.name">
                {{config.name}}</option>
            </select>
          </label>
          <img id="status_icon" src="" class="status_icon">
          <label id="file_status" class="italic"></label>
        </p>
      </div>

      <div>
        <button id="button" class="button" [disabled]="isUploadClicked === true"> Start Upload </button>
      </div>

        <div *ngIf="validationErrors && validationErrors.length > 0" class="error-panel">
            Ops.. there's a problem with uploaded file. Please check the error/s:
            <ul *ngFor= "let error of validationErrors">
                <li>{{error}}</li>
            </ul>
        </div>


    <div *ngIf="are_files_imported">
    <h1 class="shift-down">
        <a class="black-box">Guide for next steps</a>
    </h1>
    <p class="transparent-blue-box">
      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
    </p>
    </div>
</div>
</body>
