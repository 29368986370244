<app-navbar></app-navbar>

<button id="succeeded_snack" class="snack_button" at-raised-button color="primary" (click)="snackbar_SucceededPost()">
  Snackbar CSS Styles</button>
<button id="failed_snack" class="snack_button" at-raised-button color="primary" (click)="snackbar_FailedPost()">
  Snackbar CSS Styles</button>
<button id="warning_snack" class="snack_button" at-raised-button color="primary" (click)="snackbar_WaitingPost()">
  Snackbar CSS Styles</button>
<button id="refresh" class="snack_button" (click)="Refresh()"></button>
<button id="refreshAfterMatchById" class="snack_button" (click)="RefreshAfterMatchById()"></button>
<button id="refreshJobs" style="display: none" (click)="refreshJobs()"></button>
<body class="body_container">
<div class="my-overlay" *ngIf="showOverlay">
  <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
</div>

<h1>
  <a class="black-box">
    <img src="assets/img/quarantine_white.svg" alt="search_icon" class="logo-icon">
    Stage
  </a>
<!--  <a class="production">PRODUCTION</a>-->
  <a class="development">DEVELOPMENT</a>
  <a class="right" *ngIf="is_search_ready"> Total results: {{total_searches}}</a>
</h1>
<div  *ngIf="isAdmin">
<table>
  <tr class="full-width">
    <td>
      <p class="check-jobs" (click)="showRunningJobs()">
        Check running jobs
        <img id="quarantine-running-jobs" src="assets/img/down_simple_arrow.svg" alt="down_icon" class="icon-right">
      </p>
      <div *ngIf="are_jobsVisible" id="running-jobs" class="running-jobs">
        <div *ngIf="jobs">
          <p *ngIf="time_last_job_started !== ''">Last job started at: {{time_last_job_started}}</p>
          <p>There are {{jobs.length}} job(s) running</p>
          <div *ngFor="let job of jobs">
            <p>
              Job type: {{job.type}}
            </p>
            <p>
              Job state: {{job.state}}
            </p>
            <button (click)="deleteJob(job.key.toString())" class="button" style="margin: 0 0 0 1%">Delete</button>
            <p>--------------------------------------------------------------</p>
          </div>
        </div>
      </div>

      <button (click)="selected_datatype('Company')" id="company-results" class="datatype-button">See Company Results</button>
      <button (click)="selected_datatype('Person')" id="person-results" class="datatype-button">See Person Results</button>
      <button style='display: block'(click)="selected_datatype('BoardDirector')" id="director-results" class="datatype-button">See Board Directors</button>
      <button (click)="StartMatching('Company')" id="start-matching-Company" class="datatype-button" style="margin-left: 5px">Start Matching Company</button>
      <button (click)="StartMatching('Person')" id="start-matching-Person" class="datatype-button">Start Matching Person</button>
      <button *ngIf="showKickAll == true"  (click)="KickAllData()" id="kick-allData" class="datatype-button" style="margin-left: 5px;">Kick all data</button>
     <!-- <button *ngIf="user == 'cristina.badea@scriptics.ro'" (click)="StartImportMemberClicks()"  class="datatype-button">Start Memberclicks</button>-->

    </td>
  </tr>
</table>
<div  *ngIf="selectedDataType == 'Person' || selectedDataType == 'Company' ">
  <div class="quarantine_data_type">
    <table>
      <tr>
        <td class="spacing" (click)="Show_Data('accross-table')" id="accross-table">Across table</td>
        <td class="spacing" (click)="Show_Data('non-matches')" id="non-matches">Non Matches</td>
        <td class="spacing" (click)="Show_Data('all-data')" id="all-data">All data</td>
        <td class="spacing" (click)="Show_Data('file-only')" id="file-only">Imported data</td>
        <td *ngIf="selectedDataType == 'Person'" class="spacing" (click)="isWordpress()" id="wordpress">Api Data</td>
        <td *ngIf="selectedDataType == 'Company'" class="spacing" (click)="isPublicDataCO()" id="publicDataCO">Public CO Api Data</td>
      </tr>
    </table>
  </div>
  <div class="quarantine_data_type2" *ngIf="selectedDataType == 'Person' && is_WordpressSubType">
    <table>
      <tr>
        <td class="spacing2" (click)="Show_Data('wordpress-table')" id="wordpress-table">Api Data Match</td>
        <td class="spacing2" id="wordpress-non-matches"  (click)="Show_Data('wordpress-non-matches')">Api Data Non Matches</td>
      </tr>
    </table>
  </div>
  <div class="quarantine_data_type2" *ngIf="selectedDataType == 'Company' && isPublicDataCompany">
    <table>
      <tr>
        <td class="spacing2" (click)="Show_Data('public-companies-table')" id="public-companies-table">Public CO Api Match</td>
        <td class="spacing2" id="public-companies-non-matches"  (click)="Show_Data('public-companies-non-matches')">Public CO Api Non Matches</td>
      </tr>
    </table>
  </div>
  <div *ngIf="is_AccrossTable || isPublicDataCompanyMatches">
    <div *ngIf="is_company_search">
      <div *ngIf="!is_data_available">
        No data available
      </div>
      <div *ngIf="is_data_available">
        <p class="paragraph" *ngIf="is_AccrossTable"> Total groups: {{group_list_length}} (Total number of records across all groups: {{totalRecords}}) </p>
        <p class="paragraph" *ngIf="isPublicDataCompanyMatches"> Total groups api data: {{publicCoDataStatistics.listOfGroupsIds.length}} (Total number of records across all groups: {{publicCoDataStatistics.totalRecords}}) </p>
        <div *ngIf="selected_group">
          Do this action for all records within this group
          <mat-form-field appearance="fill">
            <mat-select value="{{bulkaction}}" (selectionChange)="ApplyActionToAll('company', $event)">
              <mat-option title="No action" value="None">None</mat-option>
              <mat-option title="Add this record to main database" value="Add">Add</mat-option>
              <mat-option title="Add to main database" value="AddToMainDatabase">Select</mat-option>
              <mat-option title="Merge to selected" value="Merge">Merge</mat-option>
              <mat-option title="Send group to research" value="ResearchGroup">Send group to research</mat-option>
              <mat-option title="Remove from group" value="Kick">Kick</mat-option>
<!--              <mat-option title="Delete" value="Delete">Delete</mat-option>-->
              <mat-option title="Edit fields" value="Edit">Edit</mat-option>
            </mat-select>
          </mat-form-field>
          <button class="button" (click)="MatchByID()" *ngIf="matchingByID">Match Company</button>
          <mat-form-field *ngIf="isBulkSendResearchSelected || isSendRecordsToResearchSelected"  class="research ">
            <mat-select (selectionChange)="selectResearchArea($event)" placeholder="Select Research Area">
              <mat-option *ngFor="let res of research"  [value]="res.researchId" >
                {{ res.researchName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button class="button" (click)="ManualMerge()"  *ngIf="are_actionButtons_visible > 0 && isBulkSendResearchSelected ==false && isSendRecordsToResearchSelected==false">Proceed Manually</button>
          <button class="button" (click)="VerifyBulkActions()"  *ngIf="are_actionButtons_visible > 0">Proceed</button>
        </div>
        <p></p>
        <p></p>
        <div *ngIf="is_long_list">
          <div class="scrollable">
            <table class="in-table">
              <tr *ngIf="is_AccrossTable" >
                <td *ngFor="let group of group_list" id="company-group-{{group}}" class="table-group" (click)="Group_Data(group)">
                  Group <br> {{group}}
                </td>
              </tr>
              <tr *ngIf="isPublicDataCompanyMatches" >
                <td *ngFor="let group of publicCoDataStatistics.listOfGroupsIds" id="company-group-{{group}}" class="table-group" (click)="GroupDataPublicCO(group)">
                  Group <br> {{group}}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div *ngIf="!is_long_list">
          <div class="single-nonscrollable">
            <table class="in-table">
              <tr *ngIf="is_AccrossTable">
                <td id="company-group-1" class="single-table-group" (click)="Group_Data(group_list[0])">
                  Group <br> {{group_list[0]}}
                </td>
                <td class="table-group-noscroll">
                  <button class="moregroups-button" (click)="ShowAllGroups()">Load all groups</button>
                </td>
              </tr>
              <tr *ngIf="isPublicDataCompanyMatches">
                <td id="company-group-1" class="single-table-group" (click)="GroupDataPublicCO(publicCoDataStatistics.listOfGroupsIds[0])">
                  Group <br> {{publicCoDataStatistics.listOfGroupsIds[0]}}
                </td>
                <td class="table-group-noscroll">
                  <button class="moregroups-button" (click)="ShowAllGroups()">Load all groups</button>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="company-table mat-elevation-z8">
          <table mat-table [dataSource]="dataSource" id="excel-table-acrosstable-company">
            <ng-container matColumnDef="Action">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Action</th>
              <td mat-cell *matCellDef="let element" class="td-dropdown" id="acrosstable-company-field-{{element.actionType}}">
                <mat-form-field appearance="fill" class="mat-dropdown" id="acrosstable-company-select-{{element.winPurePrimK}}">
                  <mat-select [(value)]="element.actionType" (selectionChange)="selectedAction(element.actionType, element)">
                    <mat-option title="No action" value="None" id="acrosstable-company-none">None</mat-option>
                    <mat-option title="Add this record to main database" value="Add" id="acrosstable-company-add" *ngIf="element.companyId==0">Add</mat-option>
                    <mat-option title="Add to main database" value="AddToMainDatabase" id="acrosstable-company-select">Select</mat-option>
                    <mat-option title="Merge to selected" value="Merge" id="acrosstable-company-merge">Merge</mat-option>
                    <mat-option title="Remove from group" value="Kick" id="acrosstable-company-change">Kick</mat-option>
                    <mat-option title="MigratePeople" value="MigratePeople" id="acrosstable-company-migratepeople">Migrate People</mat-option>
                    <mat-option title="Research" value="Research" id="acrosstable-company-research">Move to Research</mat-option>
<!--                              <mat-option title="Delete" value="Delete" id="acrosstable-company-delete">Delete</mat-option>-->
                    <mat-option title="Edit fields" value="Edit" id="acrosstable-company-edit">Edit</mat-option>
                    <mat-option title="Match by ID" value="MatchByID" id="acrosstable-company-MatchByID" *ngIf="element['source name'] == 'ImportedCompany'">Match by ID</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="CompanyId">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Company Id</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}"  class="pointer">
                <div (click)="LinkTo(element.companyId)" *ngIf="element.companyId !== 0 && element.actionType!='Edit' && element.actionType!='MatchByID'">
                  {{element.companyId}}
                </div>
                <div *ngIf="element.actionType=='Edit' || element.actionType=='MatchByID'" class="input-box">
                  <input type="text" name="companyName" [(ngModel)]="element.companyId" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="SourceName">
              <th mat-header-cell *matHeaderCellDef class="table_header">Source Name</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}" >
                <div *ngIf="element['source name'] == 'ImportedCompany'" >
                  {{element.fileName}}
                </div>
                <div *ngIf="element['source name'] == 'Company'" >
                  ceo360 DB
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Company Name">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Company Name</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}" >
                <div *ngIf="element.actionType!='Edit'">
                  {{element.companyName}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="companyname" [(ngModel)]="element.companyName" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Address 1">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Address1</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}" >
                <div *ngIf="element.actionType!='Edit'">
                  {{element.address1}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="address1" [(ngModel)]="element.address1" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Address 2">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Address2</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.address2}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  name="address2" [(ngModel)]="element.address2" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="City">
              <th mat-header-cell *matHeaderCellDef class="table_header"> City</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}" >
                <div *ngIf="element.actionType!='Edit'">
                  {{element.city}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="city" [(ngModel)]="element['city']" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="State">
              <th mat-header-cell *matHeaderCellDef class="table_header"> State</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.state}}
                </div>
                <div *ngIf="element.actionType=='Edit'">
                  <select class="dropdown-address" [(ngModel)]="element.state">
                    <option value="{{not_found_state}}">{{not_found_state}}</option>
                    <option *ngFor="let state of states"  value="{{state.stateName}}" selected="state">
                      {{state.stateName}}
                    </option>
                  </select>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Zip">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Zip</th>
              <td mat-cell *matCellDef="let element;let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.zip}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="zip" [(ngModel)]="element.zip" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Country">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Country</th>
              <td mat-cell *matCellDef="let element;let row" [ngClass]="{'text-color': row.activeStatus == false}" >
                <div *ngIf="element.actionType!='Edit'">
                  {{element.country}}
                </div>
                <div *ngIf="element.actionType=='Edit'">
                  <select class="dropdown-address" [(ngModel)]="element['country']">
                    <option value="{{not_found_country}}">{{not_found_country}}</option>
                    <option *ngFor="let country of countries"  value="{{country.countryName}}" selected="country">
                      {{country.countryName}}
                    </option>
                  </select>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Phone">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Phone</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}" >
                <div *ngIf="element.actionType!='Edit'">
                  {{element.phone}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  name="phone" [(ngModel)]="element['phone']" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Website">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Website </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.website}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  name="website" [(ngModel)]="element.website" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Ticker">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Ticker </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}" >
                {{element.ticker}}
              </td>
            </ng-container>
            <ng-container matColumnDef="CIK ID">
              <th mat-header-cell *matHeaderCellDef class="table_header"> CIK ID </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.cikId}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  name="cikId" [(ngModel)]="element.cikId" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="SIC Code">
              <th mat-header-cell *matHeaderCellDef class="table_header"> SIC Code </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.sicCode}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  name="sicCode" [(ngModel)]="element.sicCode" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="Exchange">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Exchange </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.exchange}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  name="annualRevenue" [(ngModel)]="element.exchange" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Annual Revenue">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Annual Revenue </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}" >
                <div *ngIf="element.actionType!='Edit'">
                  {{element.annualRevenue}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  name="annualRevenue" [(ngModel)]="element.annualRevenue" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="Employee Exact">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Employee Exact </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.numberOfEmployees}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" [(ngModel)]="element.numberOfEmployees" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Industry Category">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Industry Category </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.businessIndustry}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" [(ngModel)]="element.businessIndustry" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Ownership">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Ownership Type </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.ownershipTypeDesc}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  [(ngModel)]="element.ownershipTypeDesc" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="Ownership Sub Type">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Ownership Sub Type </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.ownershipSubType}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  [(ngModel)]="element.ownershipSubType" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="IPO Date">
              <th mat-header-cell *matHeaderCellDef class="table_header"> IPO Date </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.ipoDate}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  [(ngModel)]="element.ipoDate" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="Verified Date">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Verified Date </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  <div *ngIf="element.verifiedDate; else lastupdated">
                    {{element.verifiedDate}}
                  </div>
                  <ng-template #lastupdated>
                    {{element.lastUpdated}}
                  </ng-template>
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <div *ngIf="element.verifiedDate; else lastupdatedEdit">
                    <input type="text" name="person-verifieddate" [(ngModel)]="element.verifiedDate" class="edit-fields">
                  </div>
                  <ng-template #lastupdatedEdit>
                    <input type="text" name="company-lastupdated" [(ngModel)]="element.lastUpdated" class="edit-fields">
                  </ng-template>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="How Verified">
              <th mat-header-cell *matHeaderCellDef class="table_header"> How Verified? </th>
              <td mat-cell *matCellDef="let element let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.howVerified}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  [(ngModel)]="element.howVerified" class="edit-fields">
                </div>
              </td>
            </ng-container>


            <ng-container matColumnDef="Active Status">
              <th mat-header-cell *matHeaderCellDef class="table_header">Active Status</th>
              <td mat-cell *matCellDef="let element;  let row" [ngClass]="{'text-color': row.activeStatus == false}" >
                <div *ngIf="element.actionType!='Edit' ">
                  {{element.activeStatus}}
                </div>
                <div *ngIf="element.actionType=='Edit'">
                  <select class="table-select" [(ngModel)]="element.activeStatus">
                    <option value="{{not_found_activeStatus}}">{{not_found_activeStatus}}</option>
                    <option *ngFor="let status of active_status"  value="{{status}}" selected="status">
                      {{status}}
                    </option>
                  </select>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="AddressSource">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Address Source </th>
              <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                {{element.addressSource}}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row_darken" [ngClass]="{'gray-row': row.activeStatus == false}" ></tr>
          </table>
        </div>
      </div>
    </div>
    <div *ngIf="is_person_search">
      <div *ngIf="!is_data_available">
        No data available
      </div>
      <div *ngIf="is_data_available">
        <br>
        <p> Total groups: {{group_list_length}} (Total number of records across all groups: {{totalNonWordpressRecords}})  </p>
        <div *ngIf="selected_group">
          Do this action for all records within this group
          <mat-form-field appearance="fill">
            <mat-select value="{{bulkaction}}" (selectionChange)="ApplyActionToAll('person', $event)">
              <mat-option title="No action" value="None">None</mat-option>
              <mat-option title="Add this record to main database" value="Add">Add</mat-option>
              <mat-option title="Add to main database" value="AddToMainDatabase">Select</mat-option>
              <mat-option title="Merge to selected" value="Merge">Merge</mat-option>
              <mat-option title="Send group to research" value="ResearchGroup">Send group to research</mat-option>
              <mat-option title="Remove from group" value="Kick">Kick</mat-option>
<!--              <mat-option title="Delete" value="Delete">Delete</mat-option>-->
              <mat-option title="Edit fields" value="Edit">Edit</mat-option>
            </mat-select>
          </mat-form-field>
          <button class="button" (click)="MatchByID()" *ngIf="matchingByID">Match Person</button>
          <mat-form-field *ngIf="isBulkSendResearchSelected || isSendRecordsToResearchSelected"  class="research ">
            <mat-select (selectionChange)="selectResearchArea($event)" placeholder="Select Research Area">
              <mat-option *ngFor="let res of research"  [value]="res.researchId" >
                {{ res.researchName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!--        <button class="button" (click)="exportexcel('AcrossTableStagedPeople.xlsx', 'acrosstable-person')">Download To Excel</button>-->
          <button class="button" (click)="ManualMerge()"  *ngIf="are_actionButtons_visible > 0 && isBulkSendResearchSelected ==false && isSendRecordsToResearchSelected==false">Proceed Manually</button>
          <button class="button" (click)="VerifyBulkActions()"  *ngIf="are_actionButtons_visible > 0">Proceed</button>
          <!--    <button class="button" (click)="SendGroupToResearch()" >Send Selected group to Research</button> -->
        </div>
        <p></p>
        <p></p>
        <div *ngIf="is_long_list">
          <div class="scrollable">
            <table class="in-table">
              <tr>
                <td *ngFor="let group of group_list" id="person-group-{{group}}" class="table-group" (click)="Group_Data(group)">
                  Group <br> {{group}}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div *ngIf="!is_long_list">
          <div class="single-nonscrollable">
            <table class="in-table">
              <tr>
                <td class="single-table-group" id="person-group-1" (click)="Group_Data(group_list[0])">
                  Group <br> {{group_list[0]}}
                </td>
                <td class="table-group-noscroll">
                  <button class="moregroups-button" (click)="ShowAllGroups()">Load all groups</button>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="person-table mat-elevation-z8">
          <table mat-table [dataSource]="dataSource"  id="excel-table-across-table-person">
            <ng-container matColumnDef="Action"  >
              <th mat-header-cell *matHeaderCellDef class="table_header"> Action</th>
              <td mat-cell *matCellDef="let element" class="td-dropdown"
                  id="acrosstable-person-field-{{element.actionType}}">
                <mat-form-field appearance="fill" class="mat-dropdown" id="acrosstable-person-select-{{element.winPurePrimK}}">
                  <mat-select name="actionType" [(value)]="element.actionType" (selectionChange)="selectedAction(element.actionType, element)">
                    <mat-option title="No action" value="None" id="acrosstable-person-none">None</mat-option>
                    <mat-option title="Add this record to main database" value="Add" *ngIf="element.personId==0" id="acrosstable-person-add">Add</mat-option>
                    <mat-option title="Add to main database" value="AddToMainDatabase" id="acrosstable-person-select">Select</mat-option>
                    <mat-option title="Merge to selected" value="Merge" id="acrosstable-person-merge">Merge</mat-option>
                    <mat-option title="Remove from group" value="Kick" id="acrosstable-person-change">Kick</mat-option>
                    <mat-option title="Research" value="Research" id="acrosstable-person-research">Move to Research</mat-option>
<!--                              <mat-option title="Delete" value="Delete" id="acrosstable-person-delete">Delete</mat-option>-->
                    <mat-option title="Edit fields" value="Edit" id="acrosstable-person-edit">Edit</mat-option>
                    <mat-option title="Match by ID" value="MatchByID" id="acrosstable-person-MatchByID" *ngIf="element['source name'] == 'ImportedPerson'">Match by ID</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="Person Id">
              <th mat-header-cell *matHeaderCellDef class="table_header">Person Id</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}" class="pointer">
                <div (click)="LinkTo(element.personId)" *ngIf="element.personId!=0 && element.actionType!='Edit' && element.actionType!='MatchByID'">
                  {{element.personId}}
                </div>
                <div *ngIf="element.actionType=='Edit' || element.actionType=='MatchByID'" class="input-box">
                  <input type="text"  name="companyName" [(ngModel)]="element.personId" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="SourceName">
              <th mat-header-cell *matHeaderCellDef class="table_header">Source Name</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element['source name'] == 'ImportedPerson'" >
                  {{element.fileName}}
                </div>
                <div *ngIf="element['source name'] == 'Person'" >
                  ceo360 DB
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="First Name">
              <th mat-header-cell *matHeaderCellDef class="table_header">First Name</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.firstName}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="acrosstable-person-firstname" [(ngModel)]="element.firstName" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Middle Name">
              <th mat-header-cell *matHeaderCellDef class="table_header">Middle Name</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit' ">
                  {{element.middleName}}
                </div>
                <div *ngIf="element.actionType=='Edit' " class="input-box">
                  <input type="text" name="acrosstable-person-middlename" [(ngModel)]="element.middleName" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Last Name">
              <th mat-header-cell *matHeaderCellDef class="table_header">Last Name</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.lastName}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="acrosstable-person-lastname" [(ngModel)]="element.lastName" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Suffix">
              <th mat-header-cell *matHeaderCellDef class="table_header">Suffix</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.suffix}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="acrosstable-person-suffix" [(ngModel)]="element.suffix" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Title">
              <th mat-header-cell *matHeaderCellDef class="table_header">Title</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.title}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="acrosstable-person-title" [(ngModel)]="element.title" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Email">
              <th mat-header-cell *matHeaderCellDef class="table_header">Email</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.email}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="acrosstable-person-email" [(ngModel)]="element.email" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="AltEmail">
              <th mat-header-cell *matHeaderCellDef class="table_header">AltEmail</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.altEmail}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="acrosstable-person-altemail" [(ngModel)]="element.altEmail" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Direct Phone">
              <th mat-header-cell *matHeaderCellDef class="table_header">Direct Phone</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.directPhone}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="acrosstable-person-directphone" [(ngModel)]="element.directPhone" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Mobile Phone">
              <th mat-header-cell *matHeaderCellDef class="table_header">Mobile Phone</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.mobilePhone}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="acrosstable-person-mobilephone" [(ngModel)]="element.mobilePhone" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Primary Company">
              <th mat-header-cell *matHeaderCellDef class="table_header">Primary Company</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}" >
                {{element.primaryCompany}}
              </td>
            </ng-container>

            <ng-container matColumnDef="Primary Co ID">
              <th mat-header-cell *matHeaderCellDef class="table_header">Primary Co ID</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.companyId}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="acrosstable-person-companyId" [(ngModel)]="element.companyId" class="edit-fields" (ngModelChange)="ClearCompanyName(element.personId)">
                </div>
              </td>
            </ng-container>


            <ng-container matColumnDef="Board Company Associations">
              <th mat-header-cell *matHeaderCellDef class="table_header">Board Company Assoc.</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                {{element.boardCompanyName}}
              </td>
            </ng-container>

            <ng-container matColumnDef="Ticker">
              <th mat-header-cell *matHeaderCellDef class="table_header">Ticker</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                {{element.ticker}}
                <!--                    <div *ngIf="element.actionType!='Edit'">-->
                <!--                      {{element.ticker}}-->
                <!--                    </div>-->
                <!--                    <div *ngIf="element.actionType=='Edit'">-->
                <!--                      <input type="text" id="acrosstable-person-ticker" name="acrosstable-person-ticker" [(ngModel)]="element.ticker" class="edit-fields">-->
                <!--                    </div>-->
              </td>
            </ng-container>
            <ng-container matColumnDef="Shipping Add1">
              <th mat-header-cell *matHeaderCellDef class="table_header">Shipping Add1</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.primaryAddress1}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="acrosstable-person-primaryaddress1" [(ngModel)]="element.primaryAddress1" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="Shipping Add2">
              <th mat-header-cell *matHeaderCellDef class="table_header">Shipping Add2</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.primaryAddress2}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="acrosstable-person-primaryaddress2" [(ngModel)]="element.primaryAddress2" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="Shipping City">
              <th mat-header-cell *matHeaderCellDef class="table_header">Shipping City</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                {{element.primaryCity}}
              </td>
            </ng-container>

            <ng-container matColumnDef="Shipping State">
              <th mat-header-cell *matHeaderCellDef class="table_header">Shipping State</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.primaryState}}
                </div>
                <div *ngIf="element.actionType=='Edit'">
                  <select class="dropdown-address" [(ngModel)]="element.primaryState">
                    <option value="{{not_found_primaryState}}">{{not_found_primaryState}}</option>
                    <option *ngFor="let state of states"  value="{{state.stateName}}" selected="state">
                      {{state.stateName}}
                    </option>
                  </select>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Shipping Zipcode">
              <th mat-header-cell *matHeaderCellDef class="table_header">Shipping Zipcode</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.primaryZip}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="acrosstable-person-primaryzipcode" [(ngModel)]="element.primaryZip" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Shipping Country">
              <th mat-header-cell *matHeaderCellDef class="table_header">Shipping Country</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.primaryCountry}}
                </div>
                <div *ngIf="element.actionType=='Edit'">
                  <select class="dropdown-address" [(ngModel)]="element.primaryCountry">
                    <option value="{{not_found_country}}">{{not_found_country}}</option>
                    <option *ngFor="let country of countries"  value="{{country.countryName}}" selected="country">
                      {{country.countryName}}
                    </option>
                  </select>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Alternate Address Source">
              <th mat-header-cell *matHeaderCellDef class="table_header">Alternate Address Source</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.alternateAddressSource}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="acrosstable-person-alternateAddressSource" [(ngModel)]="element.alternateAddressSource" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Alternate Address Preferred">
              <th mat-header-cell *matHeaderCellDef class="table_header">Alternate Address Preferred</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.alternateAddressPreferred=='1'">
                  <div *ngIf="element.actionType!='Edit'">
                    True
                  </div>
                </div>
                <div *ngIf="element.alternateAddressPreferred=='0'">
                  <div *ngIf="element.actionType!='Edit'">
                    False
                  </div>
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  name="acrosstable-person-alternateAddressPreferred" [(ngModel)]="element.alternateAddressPreferred" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Alternate Address Type">
              <th mat-header-cell *matHeaderCellDef class="table_header">Alternate Address Type</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.alternateAddressType}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="acrosstable-person-alternateAddressType" [(ngModel)]="element.alternateAddressType" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="CEODigitalAndPrint">
              <th mat-header-cell *matHeaderCellDef class="table_header">CEO Digital & Print</th>
              <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.ceoDigitalAndPrint}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="wordpress-person-CEODigitalAndPrint" [(ngModel)]="element.ceoDigitalAndPrint" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="CEODigitalOnly">
              <th mat-header-cell *matHeaderCellDef class="table_header">CEO Digital Only</th>
              <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.ceoDigitalOnly}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="wordpress-person-CEODigitalOnly" [(ngModel)]="element.ceoDigitalOnly" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="CBMDigitalAndPrint">
              <th mat-header-cell *matHeaderCellDef class="table_header">CBM Digital & Print</th>
              <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.cbmDigitalAndPrint}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="wordpress-person-CBMDigitalAndPrint" [(ngModel)]="element.cbmDigitalAndPrint" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="CBMDigitalOnly">
              <th mat-header-cell *matHeaderCellDef class="table_header">CBM Digital Only</th>
              <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.cbmDigitalOnly}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="wordpress-person-CBMDigitalOnly" [(ngModel)]="element.cbmDigitalOnly" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="CEN">
              <th mat-header-cell *matHeaderCellDef class="table_header">CEN</th>
              <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.cen}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="wordpress-person-cen" [(ngModel)]="element.cen" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="SEN">
              <th mat-header-cell *matHeaderCellDef class="table_header">SEN</th>
              <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.sen}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="wordpress-person-sen" [(ngModel)]="element.sen" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="CEO100">
              <th mat-header-cell *matHeaderCellDef class="table_header">CEO100</th>
              <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.ceO100}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="wordpress-person-ceo100" [(ngModel)]="element.ceO100" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="CFO100">
              <th mat-header-cell *matHeaderCellDef class="table_header">CFO100</th>
              <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.cfO100}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="wordpress-person-cfo100" [(ngModel)]="element.cfO100" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="NextLevelLeaders">
              <th mat-header-cell *matHeaderCellDef class="table_header">NLL</th>
              <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.nextLevelLeaders}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="wordpress-person-nll" [(ngModel)]="element.nextLevelLeaders" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="CEBI">
              <th mat-header-cell *matHeaderCellDef class="table_header">CEBI</th>
              <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.cebi}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="wordpress-person-cebi" [(ngModel)]="element.cebi" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="BPAQualDate">
              <th mat-header-cell *matHeaderCellDef class="table_header">BPA Qual Date</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.bpaQualDate}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="acrosstable-person-bpaQualDate" [(ngModel)]="element.bpaQualDate" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="BPAJobTitle">
              <th mat-header-cell *matHeaderCellDef class="table_header">BPA Job Title</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.bpaJobTitle}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="acrosstable-person-bpaJobTitle" [(ngModel)]="element.bpaJobTitle" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="BPAQualSource">
              <th mat-header-cell *matHeaderCellDef class="table_header">BPA Qual Source</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.qualificationSource}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  name="acrosstable-person-qualificationSource" [(ngModel)]="element.qualificationSource" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Verified Date">
              <th mat-header-cell *matHeaderCellDef class="table_header">Verified Date</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  <div *ngIf="element.verifiedDate; else lastupdated">
                    {{element.verifiedDate}}
                  </div>
                  <ng-template #lastupdated>
                    {{element.lastUpdated}}
                  </ng-template>
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <div *ngIf="element.verifiedDate; else lastupdatedEdit">
                    <input type="text" name="acrosstable-person-verifieddate" [(ngModel)]="element.verifiedDate" class="edit-fields">
                  </div>
                  <ng-template #lastupdatedEdit>
                    <input type="text" name="acrosstable-person-lastupdated" [(ngModel)]="element.lastUpdated" class="edit-fields">
                  </ng-template>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="HowVerified">
              <th mat-header-cell *matHeaderCellDef class="table_header">How Verified?</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.howVerified}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  [(ngModel)]="element.howVerified" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Active Status">
              <th mat-header-cell *matHeaderCellDef class="table_header">Active Status</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.activeStatus}}
                </div>
                <div *ngIf="element.actionType=='Edit'">
                  <select class="table-select" [(ngModel)]="element.activeStatus">
                    <option value="{{not_found_activeStatus}}">{{not_found_activeStatus}}</option>
                    <option *ngFor="let status of active_status"  value="{{status}}" selected="status">
                      {{status}}
                    </option>
                  </select>
                </div>
              </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row_darken" [ngClass]="{'gray-row': row.activeStatus == false}"></tr>
          </table>
        </div>
      </div>
      <p></p>
    </div>
  </div>

  <div *ngIf="is_NonMatches || isPublicDataCompanyNonMatches">
    <div *ngIf="is_company_search">
      <div *ngIf="!is_data_available">
        No data available
      </div>
      <div *ngIf="is_data_available">
        Do this action for all records within this group
        <mat-form-field appearance="fill">
          <mat-select value="{{bulkaction}}" (selectionChange)="ApplyActionToAll('company', $event)">
            <mat-option title="No action" value="None">None</mat-option>
            <mat-option title="Add this record to main database" value="Add">Add</mat-option>
            <mat-option title="Add to main database" value="AddToMainDatabase">Select</mat-option>
            <mat-option title="Merge to selected" value="Merge">Merge</mat-option>
            <mat-option title="Remove from group" value="Kick">Kick</mat-option>
            <mat-option title="Send group to research" value="ResearchGroup">Send group to research</mat-option>
<!--            <mat-option title="Delete" value="Delete">Delete</mat-option>-->
            <mat-option title="Edit fields" value="Edit">Edit</mat-option>
          </mat-select>
        </mat-form-field>
        <button class="button" (click)="MatchByID()" *ngIf="matchingByID">Match Company</button>
        <mat-form-field *ngIf="isBulkSendResearchSelected || isSendRecordsToResearchSelected"  class="research ">
          <mat-select (selectionChange)="selectResearchArea($event)" placeholder="Select Research Area">
            <mat-option *ngFor="let res of research"  [value]="res.researchId" >
              {{ res.researchName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button class="button" (click)="ManualMerge()"  *ngIf="are_actionButtons_visible > 0 && isBulkSendResearchSelected ==false && isSendRecordsToResearchSelected==false">Proceed Manually</button>
        <button class="button" (click)="VerifyBulkActions()"  *ngIf="are_actionButtons_visible > 0">Proceed</button>
        <!--      <button class="button" (click)="exportexcel('NonMatchesStagedCompanies.xlsx', 'nonmatches-company')">Download To Excel</button>-->
        <div class="mat-elevation-z8 company-table">
          <table mat-table [dataSource]="dataSource" id="excel-table-non-matches-company">
            <ng-container matColumnDef="Action">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Action</th>
              <td mat-cell *matCellDef="let element" class="td-dropdown" id="nonmatches-company-field-{{element.actionType}}">
                <mat-form-field appearance="fill" class="mat-dropdown" id="nonmatches-company-select-{{element.winPurePrimK}}">
                  <mat-select [(value)]="element.actionType" (selectionChange)="selectedAction(element.actionType, element)">
                    <mat-option title="No action" value="None" id="nonmatches-company-none">None</mat-option>
                    <mat-option title="Add this record to main database" value="Add" id="nonmatches-company-add" *ngIf="element.companyId==0">Add</mat-option>
                    <mat-option title="Add to main database" value="AddToMainDatabase" id="nonmatches-company-select">Select</mat-option>
                    <mat-option title="Merge to selected" value="Merge" id="nonmatches-company-merge">Merge</mat-option>
                    <mat-option title="Remove from group" value="Kick" id="nonmatches-company-change">Kick</mat-option>
                    <mat-option title="MigratePeople" value="MigratePeople" id="nonmatches-company-migratepeople">Migrate People</mat-option>
                    <mat-option title="Research" value="Research" id="nonmatches-company-research">Move to Research</mat-option>
                    <!--                              <mat-option title="Delete" value="Delete" id="nonmatches-company-delete">Delete</mat-option>-->
                    <mat-option title="Edit fields" value="Edit" id="nonmatches-company-edit">Edit</mat-option>
                    <mat-option title="Match by ID" value="MatchByID" id="nonmatches-company-MatchByID" *ngIf="element['source name'] == 'ImportedCompany'">Match by ID</mat-option>

                  </mat-select>
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="CompanyId">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Company Id</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}"   class="pointer" >
                <div  (click)="LinkTo(element.companyId)" *ngIf="element.companyId !== 0 && element.actionType!='Edit' && element.actionType!='MatchByID'">
                  {{element.companyId}}
                </div>
                <div *ngIf="element.actionType=='Edit' || element.actionType=='MatchByID'" class="input-box">
                  <input type="text" name="companyName" [(ngModel)]="element.companyId" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="SourceName">
              <th mat-header-cell *matHeaderCellDef class="table_header">Source Name</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}" >
                <div *ngIf="element['source name'] == 'ImportedCompany'" >
                  {{element.fileName}}
                </div>
                <div *ngIf="element['source name'] == 'Company'" >
                  ceo360 DB
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Company Name">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Company Name</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}" >
                <div *ngIf="element.actionType!='Edit'">
                  {{element.companyName}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  name="companyName" [(ngModel)]="element.companyName" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Address 1">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Address1</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}" >
                <div *ngIf="element.actionType!='Edit' ">
                  {{element.address1}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  name="address1" [(ngModel)]="element.address1" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Address 2">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Address2</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.address2}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="address2" [(ngModel)]="element.address2" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="City">
              <th mat-header-cell *matHeaderCellDef class="table_header"> City</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.city}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="city" [(ngModel)]="element.city" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="State">
              <th mat-header-cell *matHeaderCellDef class="table_header"> State</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.state}}
                </div>
                <div *ngIf="element.actionType=='Edit'">
                  <select class="dropdown-address" [(ngModel)]="element.state">
                    <option value="{{not_found_state}}">{{not_found_state}}</option>
                    <option *ngFor="let state of states"  value="{{state.stateName}}" selected="state">
                      {{state.stateName}}
                    </option>
                  </select>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Zip">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Zip</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.zip}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="zip" [(ngModel)]="element.zip" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Country">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Country</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}" >
                <div *ngIf="element.actionType!='Edit'">
                  {{element.country}}
                </div>
                <div *ngIf="element.actionType=='Edit'">
                  <select class="dropdown-address" [(ngModel)]="element.country">
                    <option value="{{not_found_country}}">{{not_found_country}}</option>
                    <option *ngFor="let country of countries"  value="{{country.countryName}}" selected="country">
                      {{country.countryName}}
                    </option>
                  </select>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Phone">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Phone</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.phone}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="phone" [(ngModel)]="element.phone" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Website">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Website </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.website}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="website" [(ngModel)]="element.website" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Ticker">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Ticker </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit' ">
                  {{element.ticker}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="nonmatches-company-ticker" [(ngModel)]="element.ticker" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="CIK ID">
              <th mat-header-cell *matHeaderCellDef class="table_header"> CIK ID </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.cikId}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  name="cikId" [(ngModel)]="element.cikId" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="SIC Code">
              <th mat-header-cell *matHeaderCellDef class="table_header"> SIC Code </th>
              <td mat-cell *matCellDef="let element" id="nonmatches-company-sicCode-{{element.actionType}}-{{element.activeStatus}}">
                {{element.sicCode}}
              </td>
            </ng-container>

            <ng-container matColumnDef="Exchange">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Exchange </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}" >
                <div *ngIf="element.actionType!='Edit' ">
                  {{element.exchange}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="exchange" [(ngModel)]="element.exchange" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Annual Revenue">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Annual Revenue </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}" >
                <div *ngIf="element.actionType!='Edit' ">
                  {{element.annualRevenue}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="annualRevenue" [(ngModel)]="element.annualRevenue" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="Employee Exact">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Employee Exact </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.numberOfEmployees}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" [(ngModel)]="element.numberOfEmployees" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Industry Category">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Industry Category </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.businessIndustry}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  [(ngModel)]="element.businessIndustry" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Ownership">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Ownership Type </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.ownershipTypeDesc}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  [(ngModel)]="element.ownershipTypeDesc" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="Ownership Sub Type">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Ownership Sub Type </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.ownershipSubType}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" [(ngModel)]="element.ownershipSubType" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="IPO Date">
              <th mat-header-cell *matHeaderCellDef class="table_header"> IPO Date </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.ipoDate}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  [(ngModel)]="element.ipoDate" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Verified Date">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Verified Date </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  <div *ngIf="element.verifiedDate; else lastupdated">
                    {{element.verifiedDate}}
                  </div>
                  <ng-template #lastupdated>
                    {{element.lastUpdated}}
                  </ng-template>
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <div *ngIf="element.verifiedDate; else lastupdatedEdit">
                    <input type="text" name="person-verifieddate" [(ngModel)]="element.verifiedDate" class="edit-fields">
                  </div>
                  <ng-template #lastupdatedEdit>
                    <input type="text" name="company-lastupdated" [(ngModel)]="element.lastUpdated" class="edit-fields">
                  </ng-template>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="How Verified">
              <th mat-header-cell *matHeaderCellDef class="table_header"> How Verified? </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.howVerified}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  [(ngModel)]="element.howVerified" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="Active Status">
              <th mat-header-cell *matHeaderCellDef class="table_header">Active Status</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.activeStatus}}
                </div>
                <div *ngIf="element.actionType=='Edit'">
                  <select class="table-select" [(ngModel)]="element.activeStatus">
                    <option value="{{not_found_activeStatus}}">{{not_found_activeStatus}}</option>
                    <option *ngFor="let status of active_status"  value="{{status}}" selected="status">
                      {{status}}
                    </option>
                  </select>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="AddressSource">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Address Source </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                {{element.addressSource}}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row_darken" [ngClass]="{'gray-row': row.activeStatus == false}"></tr>
          </table>
        </div>
      </div>

      <p></p>
    </div>
    <div *ngIf="is_person_search">
      <div *ngIf="!is_data_available">
        No data available
      </div>
      <div>
        Do this action for all records within this group
        <mat-form-field appearance="fill">
          <mat-select value="{{bulkaction}}" (selectionChange)="ApplyActionToAll('person', $event)">
            <mat-option title="No action" value="None">None</mat-option>
            <mat-option title="Add this record to main database" value="Add">Add</mat-option>
            <mat-option title="Add to main database" value="AddToMainDatabase">Select</mat-option>
            <mat-option title="Merge to selected" value="Merge">Merge</mat-option>
            <mat-option title="Remove from group" value="Kick">Kick</mat-option>
            <mat-option title="Send group to research" value="ResearchGroup">Send group to research</mat-option>
<!--            <mat-option title="Delete" value="Delete">Delete</mat-option>-->
            <mat-option title="Edit fields" value="Edit">Edit</mat-option>
          </mat-select>
        </mat-form-field>
        <button class="button" (click)="MatchByID()" *ngIf="matchingByID">Match Person</button>
        <mat-form-field *ngIf="isBulkSendResearchSelected || isSendRecordsToResearchSelected"  class="research ">
          <mat-select (selectionChange)="selectResearchArea($event)" placeholder="Select Research Area">
            <mat-option *ngFor="let res of research"  [value]="res.researchId" >
              {{ res.researchName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button class="button" (click)="ManualMerge()"  *ngIf="are_actionButtons_visible > 0 && isBulkSendResearchSelected ==false && isSendRecordsToResearchSelected==false">Proceed Manually</button>
        <button class="button" (click)="VerifyBulkActions()"  *ngIf="are_actionButtons_visible > 0">Proceed</button>
        <!--      <button class="button" (click)="exportexcel('NonMatchesStagedPeople.xlsx', 'nonmatches-person')">Download To Excel</button>-->
      </div>
      <div *ngIf="is_data_available">
         <div class="person-table mat-elevation-z8">
          <table mat-table [dataSource]="dataSource" id="excel-table-non-matches-person">
            <ng-container matColumnDef="Action">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Action</th>
              <td mat-cell *matCellDef="let element" class="td-dropdown"
                  id="nonmatches-person-field-{{element.actionType}}">
                <mat-form-field appearance="fill" class="mat-dropdown" id="nonmatches-person-select-{{element.winPurePrimK}}">
                  <mat-select [(value)]="element.actionType" (selectionChange)="selectedAction(element.actionType, element)">
                    <mat-option title="No action" value="None" id="nonmatches-person-none">None</mat-option>
                    <mat-option title="Add this record to main database" value="Add" *ngIf="element.personId==0" id="nonmatches-person-add">Add</mat-option>
                    <mat-option title="Add to main database" value="AddToMainDatabase" id="nonmatches-person-select">Select</mat-option>
                    <mat-option title="Merge to selected" value="Merge" id="nonmatches-person-merge">Merge</mat-option>
                    <mat-option title="Remove from group" value="Kick" id="nonmatches-person-change">Kick</mat-option>
                    <mat-option title="Research" value="Research" id="nonmatches-person-research">Move to Research</mat-option>
<!--                              <mat-option title="Delete" value="Delete" id="nonmatches-person-delete">Delete</mat-option>-->
                    <mat-option title="Edit fields" value="Edit" id="nonmatches-person-edit">Edit</mat-option>
                    <mat-option title="Match by ID" value="MatchByID" id="nonmatches-person-MatchByID" *ngIf="element['source name'] == 'ImportedPerson'">Match by ID</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="Person Id">
              <th mat-header-cell *matHeaderCellDef class="table_header">Person Id</th>
              <td mat-cell *matCellDef="let element;  let row" [ngClass]="{'text-color': row.activeStatus == false}" class="pointer">
                <div (click)="LinkTo(element.personId)" *ngIf="element.personId!=0 && element.actionType!='Edit' && element.actionType!='MatchByID'">
                  {{element.personId}}
                </div>
                <div *ngIf="element.actionType=='Edit' || element.actionType=='MatchByID'" class="input-box">
                  <input type="text" [(ngModel)]="element.personId" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="SourceName">
              <th mat-header-cell *matHeaderCellDef class="table_header">Source Name</th>
              <td mat-cell *matCellDef="let element;  let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element['source name'] == 'ImportedPerson'" >
                  {{element.fileName}}
                </div>
                <div *ngIf="element['source name'] == 'Person'" >
                  ceo360 DB
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="First Name">
              <th mat-header-cell *matHeaderCellDef class="table_header">First Name</th>
              <td mat-cell *matCellDef="let element;  let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.firstName}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="nonmatches-person-firstname" [(ngModel)]="element.firstName" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Middle Name">
              <th mat-header-cell *matHeaderCellDef class="table_header">Middle Name</th>
              <td mat-cell *matCellDef="let element;  let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.middleName}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="nonmatches-person-middlename" [(ngModel)]="element.middleName" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Last Name">
              <th mat-header-cell *matHeaderCellDef class="table_header">Last Name</th>
              <td mat-cell *matCellDef="let element;  let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.lastName}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="nonmatches-person-lastname" [(ngModel)]="element.lastName" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Suffix">
              <th mat-header-cell *matHeaderCellDef class="table_header">Suffix</th>
              <td mat-cell *matCellDef="let element;  let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.suffix}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="nonmatches-person-suffix" [(ngModel)]="element.suffix" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Title">
              <th mat-header-cell *matHeaderCellDef class="table_header">Title</th>
              <td mat-cell *matCellDef="let element;  let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.title}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="nonmatches-person-title" [(ngModel)]="element.title" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Email">
              <th mat-header-cell *matHeaderCellDef class="table_header">Email</th>
              <td mat-cell *matCellDef="let element;  let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.email}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="nonmatches-person-email" [(ngModel)]="element.email" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="AltEmail">
              <th mat-header-cell *matHeaderCellDef class="table_header">AltEmail</th>
              <td mat-cell *matCellDef="let element;  let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.altEmail}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="nonmatches-person-altemail" [(ngModel)]="element.altEmail" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Direct Phone">
              <th mat-header-cell *matHeaderCellDef class="table_header">Direct Phone</th>
              <td mat-cell *matCellDef="let element;  let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.directPhone}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="nonmatches-person-directphone" [(ngModel)]="element.directPhone" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Mobile Phone">
              <th mat-header-cell *matHeaderCellDef class="table_header">Mobile Phone</th>
              <td mat-cell *matCellDef="let element;  let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.mobilePhone}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="nonmatches-person-mobilephone" [(ngModel)]="element.mobilePhone" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Primary Company">
              <th mat-header-cell *matHeaderCellDef class="table_header">Primary Company</th>
              <td mat-cell *matCellDef="let element;  let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.primaryCompany}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="nonmatches-person-primarycompany" [(ngModel)]="element.primaryCompany" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Primary Co ID">
              <th mat-header-cell *matHeaderCellDef class="table_header">Primary Co ID</th>
              <td mat-cell *matCellDef="let element;  let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.companyId}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="nonmatches-person-companyId" [(ngModel)]="element.companyId" class="edit-fields" (ngModelChange)="ClearCompanyName(element.personId)">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Board Company Associations">
              <th mat-header-cell *matHeaderCellDef class="table_header">Board Company Assoc.</th>
              <td mat-cell *matCellDef="let element;  let row" [ngClass]="{'text-color': row.activeStatus == false}">
                {{element.boardCompanyName}}
              </td>
            </ng-container>

            <ng-container matColumnDef="Ticker">
              <th mat-header-cell *matHeaderCellDef class="table_header">Ticker</th>
              <td mat-cell *matCellDef="let element;  let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.ticker}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="nonmatches-person-ticker" [(ngModel)]="element.ticker" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="Shipping Add1">
              <th mat-header-cell *matHeaderCellDef class="table_header">Shipping Add1</th>
              <td mat-cell *matCellDef="let element;  let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.primaryAddress1}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="nonmatches-person-primaryaddress1" [(ngModel)]="element.primaryAddress1" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Shipping Add2">
              <th mat-header-cell *matHeaderCellDef class="table_header">Shipping Add2</th>
              <td mat-cell *matCellDef="let element;  let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.primaryAddress2}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="nonmatches-person-primaryaddress2" [(ngModel)]="element.primaryAddress2" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="Shipping City">
              <th mat-header-cell *matHeaderCellDef class="table_header">Shipping City</th>
              <td mat-cell *matCellDef="let element;  let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.primaryCity}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="nonmatches-person-primarycity" [(ngModel)]="element.primaryCity" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Shipping State">
              <th mat-header-cell *matHeaderCellDef class="table_header">Shipping State</th>
              <td mat-cell *matCellDef="let element;  let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.primaryState}}
                </div>
                <div *ngIf="element.actionType=='Edit'">
                  <select class="dropdown-address" [(ngModel)]="element.primaryState">
                    <option value="{{not_found_primaryState}}">{{not_found_primaryState}}</option>
                    <option *ngFor="let state of states"  value="{{state.stateName}}" selected="state">
                      {{state.stateName}}
                    </option>
                  </select>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="Shipping Zipcode">
              <th mat-header-cell *matHeaderCellDef class="table_header">Shipping Zipcode</th>
              <td mat-cell *matCellDef="let element;  let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.primaryZip}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="nonmatches-person-primaryzipcode" [(ngModel)]="element.primaryZip" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Shipping Country">
              <th mat-header-cell *matHeaderCellDef class="table_header">Shipping Country</th>
              <td mat-cell *matCellDef="let element;  let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.primaryCountry}}
                </div>
                <div *ngIf="element.actionType=='Edit'">
                  <select class="dropdown-address" [(ngModel)]="element.primaryCountry">
                    <option value="{{not_found_country}}">{{not_found_country}}</option>
                    <option *ngFor="let country of countries"  value="{{country.countryName}}" selected="country">
                      {{country.countryName}}
                    </option>
                  </select>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Alternate Address Source">
              <th mat-header-cell *matHeaderCellDef class="table_header">Alternate Address Source</th>
              <td mat-cell *matCellDef="let element;  let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.alternateAddressSource}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="acrosstable-person-alternateAddressSource" [(ngModel)]="element.alternateAddressSource" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Alternate Address Preferred">
              <th mat-header-cell *matHeaderCellDef class="table_header">Alternate Address Preferred</th>
              <td mat-cell *matCellDef="let element;  let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.alternateAddressPreferred}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="nonmatches-person-alternateAddressPreferred" [(ngModel)]="element.alternateAddressPreferred" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Alternate Address Type">
              <th mat-header-cell *matHeaderCellDef class="table_header">Alternate Address Type</th>
              <td mat-cell *matCellDef="let element;  let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.alternateAddressType}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="acrosstable-person-alternateAddressType" [(ngModel)]="element.alternateAddressType" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="CEODigitalAndPrint">
              <th mat-header-cell *matHeaderCellDef class="table_header">CEO Digital & Print</th>
              <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.ceoDigitalAndPrint}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="wordpress-person-CEODigitalAndPrint" [(ngModel)]="element.ceoDigitalAndPrint" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="CEODigitalOnly">
              <th mat-header-cell *matHeaderCellDef class="table_header">CEO Digital Only</th>
              <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.ceoDigitalOnly}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="wordpress-person-CEODigitalOnly" [(ngModel)]="element.ceoDigitalOnly" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="CBMDigitalAndPrint">
              <th mat-header-cell *matHeaderCellDef class="table_header">CBM Digital & Print</th>
              <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.cbmDigitalAndPrint}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="wordpress-person-CBMDigitalAndPrint" [(ngModel)]="element.cbmDigitalAndPrint" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="CBMDigitalOnly">
              <th mat-header-cell *matHeaderCellDef class="table_header">CBM Digital Only</th>
              <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.cbmDigitalOnly}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="wordpress-person-CBMDigitalOnly" [(ngModel)]="element.cbmDigitalOnly" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="CEN">
              <th mat-header-cell *matHeaderCellDef class="table_header">CEN</th>
              <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.cen}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="wordpress-person-cen" [(ngModel)]="element.cen" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="SEN">
              <th mat-header-cell *matHeaderCellDef class="table_header">SEN</th>
              <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.sen}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="wordpress-person-sen" [(ngModel)]="element.sen" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="CEO100">
              <th mat-header-cell *matHeaderCellDef class="table_header">CEO100</th>
              <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.ceO100}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="wordpress-person-ceo100" [(ngModel)]="element.ceO100" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="CFO100">
              <th mat-header-cell *matHeaderCellDef class="table_header">CFO100</th>
              <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.cfO100}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="wordpress-person-cfo100" [(ngModel)]="element.cfO100" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="NextLevelLeaders">
              <th mat-header-cell *matHeaderCellDef class="table_header">NLL</th>
              <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.nextLevelLeaders}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="wordpress-person-nll" [(ngModel)]="element.nextLevelLeaders" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="CEBI">
              <th mat-header-cell *matHeaderCellDef class="table_header">CEBI</th>
              <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.cebi}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="wordpress-person-cebi" [(ngModel)]="element.cebi" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="BPAQualDate">
              <th mat-header-cell *matHeaderCellDef class="table_header">BPA Qual Date</th>
              <td mat-cell *matCellDef="let element;  let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.bpaQualDate}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="nonmatches-person-bpaQualDate" [(ngModel)]="element.bpaQualDate" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="BPAJobTitle">
              <th mat-header-cell *matHeaderCellDef class="table_header">BPA Job Title</th>
              <td mat-cell *matCellDef="let element;  let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.bpaJobTitle}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  name="nonmatches-person-bpaJobTitle" [(ngModel)]="element.bpaJobTitle" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="BPAQualSource">
              <th mat-header-cell *matHeaderCellDef class="table_header">BPA Qual Source</th>
              <td mat-cell *matCellDef="let element;  let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.qualificationSource}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="nonmatches-person-qualificationSource" [(ngModel)]="element.qualificationSource" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Verified Date">
              <th mat-header-cell *matHeaderCellDef class="table_header">Verified Date</th>
              <td mat-cell *matCellDef="let element;  let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  <div *ngIf="element.verifiedDate; else lastupdated">
                    {{element.verifiedDate}}
                  </div>
                  <ng-template #lastupdated>
                    {{element.lastUpdated}}
                  </ng-template>
                </div>
                <div *ngIf="element.actionType=='Edit'">
                  <div *ngIf="element.verifiedDate; else lastupdatedEdit" class="input-box">
                    <input type="text" name="nonmatches-person-verifieddate" [(ngModel)]="element.verifiedDate" class="edit-fields">
                  </div>
                  <ng-template #lastupdatedEdit>
                    <input type="text" name="nonmatches-person-lastupdated" [(ngModel)]="element.lastUpdated" class="edit-fields">
                  </ng-template>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="HowVerified">
              <th mat-header-cell *matHeaderCellDef class="table_header">How Verified?</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.howVerified}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  [(ngModel)]="element.howVerified" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Active Status">
              <th mat-header-cell *matHeaderCellDef class="table_header">Active Status</th>
              <td mat-cell *matCellDef="let element;  let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.activeStatus}}
                </div>
                <div *ngIf="element.actionType=='Edit'">
                  <select class="table-select" [(ngModel)]="element.activeStatus">
                    <option value="{{not_found_activeStatus}}">{{not_found_activeStatus}}</option>
                    <option *ngFor="let status of active_status"  value="{{status}}" selected="status">
                      {{status}}
                    </option>
                  </select>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row_darken" [ngClass]="{'gray-row': row.activeStatus == false}"></tr>
          </table>
        </div>
      </div>
      <p></p>
    </div>
    <div class="paginator-wp" *ngIf="is_NonMatches">
      <div>
        <div *ngIf="currentPage < totalPages ;  else lastPage">{{fetchData * currentPage - fetchData + 1}} - {{fetchData * currentPage}}
          from {{notMatched}}</div>
        <ng-template #lastPage>{{fetchData * currentPage - fetchData + 1}} - {{notMatched}} from {{notMatched}}</ng-template>
      </div>
      <div class="back" (click)="goToFirstPage('nonMatches')"> <<</div>
      <div class="back" (click)="previousPage('nonMatches')"> <</div>
      <div class="current-page">Current Page:  {{currentPage}}</div>
      <div  *ngIf="currentPage < totalPages" class="forward" (click)="nextPage('nonMatches')"> > </div>
      <div  *ngIf="currentPage < totalPages" class="forward" (click)="goToLastPage('nonMatches')"> >></div>
      <div  *ngIf="currentPage === totalPages" style="margin-right: 5vh" class="forward"></div>
    </div>
    <div class="paginator-wp" *ngIf="isPublicDataCompanyNonMatches">
      <div>
        <div *ngIf="currentPage < totalPages ;  else lastPage">{{fetchData * currentPage - fetchData + 1}} - {{fetchData * currentPage}}
          from {{publicCoDataStatistics.totalNotMatchedRecords}}</div>
        <ng-template #lastPage>{{fetchData * currentPage - fetchData + 1}} - {{publicCoDataStatistics.totalNotMatchedRecords}} from {{publicCoDataStatistics.totalNotMatchedRecords}}</ng-template>
      </div>
      <div class="back" (click)="goToFirstPage('nonMatchesPublicCo')"> <<</div>
      <div class="back" (click)="previousPage('nonMatchesPublicCo')"> <</div>
      <div class="current-page">Current Page:  {{currentPage}}</div>
      <div  *ngIf="currentPage < totalPages" class="forward" (click)="nextPage('nonMatchesPublicCo')"> > </div>
      <div  *ngIf="currentPage < totalPages" class="forward" (click)="goToLastPage('nonMatchesPublicCo')"> >></div>
      <div  *ngIf="currentPage === totalPages" style="margin-right: 5vh" class="forward"></div>
    </div>
  </div>

  <div *ngIf="is_AllData">
    <div *ngIf="is_company_search">
      <div *ngIf="!is_data_available">
        No data available
      </div>
      <div *ngIf="is_data_available">
        Do this action for all records within this group
        <mat-form-field appearance="fill">
          <mat-select value="{{bulkaction}}" (selectionChange)="ApplyActionToAll('company', $event)">
            <mat-option title="No action" value="None">None</mat-option>
            <mat-option title="Add this record to main database" value="Add">Add</mat-option>
            <mat-option title="Add to main database" value="AddToMainDatabase">Select</mat-option>
            <mat-option title="Merge to selected" value="Merge">Merge</mat-option>
            <mat-option title="Remove from group" value="Kick">Kick</mat-option>
            <mat-option title="Send group to research" value="ResearchGroup">Send group to research</mat-option>
<!--            <mat-option title="Delete" value="Delete">Delete</mat-option>-->
            <mat-option title="Edit fields" value="Edit">Edit</mat-option>
          </mat-select>
        </mat-form-field>
        <button class="button" (click)="MatchByID()" *ngIf="matchingByID">Match Company</button>
        <mat-form-field *ngIf="isBulkSendResearchSelected || isSendRecordsToResearchSelected"  class="research ">
          <mat-select (selectionChange)="selectResearchArea($event)" placeholder="Select Research Area">
            <mat-option *ngFor="let res of research"  [value]="res.researchId" >
              {{ res.researchName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button class="button" (click)="ManualMerge()"  *ngIf="are_actionButtons_visible > 0 && isBulkSendResearchSelected ==false && isSendRecordsToResearchSelected==false">Proceed Manually</button>
        <button class="button" (click)="VerifyBulkActions()"  *ngIf="are_actionButtons_visible > 0">Proceed</button>
        <!--      <button class="button" (click)="exportexcel('AllDataStagedCompanies.xlsx', 'alldata-company')">Download To Excel</button>-->
        <div class="mat-elevation-z8 company-table">
          <table mat-table [dataSource]="dataSource" id="excel-table-file-company">
            <ng-container matColumnDef="Action">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Action</th>
              <td mat-cell *matCellDef="let element" class="td-dropdown" id="file-company-field-{{element.actionType}}">
                <mat-form-field appearance="fill" class="mat-dropdown" id="file-company-select-{{element.winPurePrimK}}">
                  <mat-select [(value)]="element.actionType" (selectionChange)="selectedAction(element.actionType, element)">
                    <mat-option title="No action" value="None" id="file-company-none">None</mat-option>
                    <mat-option title="Add this record to main database" value="Add" id="file-company-add" *ngIf="element.companyId==0">Add</mat-option>
                    <mat-option title="Add to main database" value="AddToMainDatabase" id="file-company-select">Select</mat-option>
                    <mat-option title="Merge to selected" value="Merge" id="file-company-merge">Merge</mat-option>
                    <mat-option title="Remove from group" value="Kick" id="file-company-change">Kick</mat-option>
                    <mat-option title="MigratePeople" value="MigratePeople" id="file-company-migratepeople">Migrate People</mat-option>
                    <mat-option title="Research" value="Research" id="file-company-research">Move to Research</mat-option>
                    <!--                              <mat-option title="Delete" value="Delete" id="file-company-delete">Delete</mat-option>-->
                    <mat-option title="Edit fields" value="Edit" id="file-company-edit">Edit</mat-option>
                    <mat-option title="Match by ID" value="MatchByID" id="file-company-MatchByID" *ngIf="element['source name'] == 'ImportedCompany'">Match by ID</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="CompanyId">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Company Id</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}"  class="pointer" >
                <div  (click)="LinkTo(element.companyId)" *ngIf="element.companyId !== 0 && element.actionType!='Edit' && element.actionType!='MatchByID'">
                  {{element.companyId}}
                </div>
                <div *ngIf="element.actionType=='Edit' || element.actionType=='MatchByID'" class="input-box">
                  <input type="text" name="companyName" [(ngModel)]="element.companyId" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="SourceName">
              <th mat-header-cell *matHeaderCellDef class="table_header">Source Name</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element['source name'] == 'ImportedCompany'" >
                  {{element.fileName}}
                </div>
                <div *ngIf="element['source name'] == 'Company'" >
                  ceo360 DB
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Company Name">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Company Name</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.companyName}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="companyname" [(ngModel)]="element.companyName" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Address 1">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Address1</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit' ">
                  {{element.address1}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="address1" [(ngModel)]="element.address1" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Address 2">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Address2</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'  ">
                  {{element.address2}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  name="address2" [(ngModel)]="element.address2" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="City">
              <th mat-header-cell *matHeaderCellDef class="table_header"> City</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'  ">
                  {{element.city}}
                </div>
                <div *ngIf="element.actionType=='Edit' " class="input-box">
                  <input type="text" name="city" [(ngModel)]="element.city" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="State">
              <th mat-header-cell *matHeaderCellDef class="table_header"> State</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.state}}
                </div>
                <div *ngIf="element.actionType=='Edit' ">
                  <select class="dropdown-address" [(ngModel)]="element.state">
                    <option value="{{not_found_state}}">{{not_found_state}}</option>
                    <option *ngFor="let state of states"  value="{{state.stateName}}" selected="state">
                      {{state.stateName}}
                    </option>
                  </select>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Zip">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Zip</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.zip}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="zip" [(ngModel)]="element.zip" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Country">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Country</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.country}}
                </div>
                <div *ngIf="element.actionType=='Edit'">
                  <select class=" dropdown-address" [(ngModel)]="element.country">
                    <option value="{{not_found_country}}">{{not_found_country}}</option>
                    <option *ngFor="let country of countries"  value="{{country.countryName}}" selected="country">
                      {{country.countryName}}
                    </option>
                  </select>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Phone">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Phone</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.phone}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="phone" [(ngModel)]="element.phone" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Website">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Website </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.website}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  name="website" [(ngModel)]="element.website" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Ticker">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Ticker </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.ticker}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="alldata-company-ticker" [(ngModel)]="element.ticker" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="CIK ID">
              <th mat-header-cell *matHeaderCellDef class="table_header"> CIK ID </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.cikId}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  name="cikId" [(ngModel)]="element.cikId" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="SIC Code">
              <th mat-header-cell *matHeaderCellDef class="table_header"> SIC Code </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                {{element.sicCode}}
              </td>
            </ng-container>
            <ng-container matColumnDef="Exchange">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Exchange </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                {{element.exchange}}
              </td>
            </ng-container>
            <ng-container matColumnDef="Annual Revenue">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Annual Revenue </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.annualRevenue}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="annualRevenue" [(ngModel)]="element.annualRevenue" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Employee Exact">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Employee Exact </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.numberOfEmployees}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  [(ngModel)]="element.numberOfEmployees" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Industry Category">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Industry Category </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.businessIndustry}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" [(ngModel)]="element.businessIndustry" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Ownership">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Ownership Type </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.ownershipTypeDesc}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" [(ngModel)]="element.ownershipTypeDesc" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="Ownership Sub Type">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Ownership Sub Type </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.ownershipSubType}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" [(ngModel)]="element.ownershipSubType" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="IPO Date">
              <th mat-header-cell *matHeaderCellDef class="table_header"> IPO Date </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.ipoDate}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  [(ngModel)]="element.ipoDate" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="Verified Date">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Verified Date </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  <div *ngIf="element.verifiedDate; else lastupdated">
                    {{element.verifiedDate}}
                  </div>
                  <ng-template #lastupdated>
                    {{element.lastUpdated}}
                  </ng-template>
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <div *ngIf="element.verifiedDate; else lastupdatedEdit">
                    <input type="text" name="person-verifieddate" [(ngModel)]="element.verifiedDate" class="edit-fields">
                  </div>
                  <ng-template #lastupdatedEdit>
                    <input type="text" name="company-lastupdated" [(ngModel)]="element.lastUpdated" class="edit-fields">
                  </ng-template>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="How Verified">
              <th mat-header-cell *matHeaderCellDef class="table_header"> How Verified? </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.howVerified}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" [(ngModel)]="element.howVerified" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="Active Status">
              <th mat-header-cell *matHeaderCellDef class="table_header">Active Status</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.activeStatus}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <select class="table-select" [(ngModel)]="element.activeStatus">
                    <option value="{{not_found_activeStatus}}">{{not_found_activeStatus}}</option>
                    <option *ngFor="let status of active_status"  value="{{status}}" selected="status">
                      {{status}}
                    </option>
                  </select>
                </div>
              </td>
            </ng-container>


            <ng-container matColumnDef="AddressSource">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Address Source </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                {{element.addressSource}}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row_darken"></tr>
          </table>
        </div>
      </div>
    </div>
    <div *ngIf="is_person_search">
      <div *ngIf="!is_data_available">
        No data available
      </div>
      <div *ngIf="is_data_available">
        Do this action for all records within this group
        <mat-form-field appearance="fill">
          <mat-select value="{{bulkaction}}" (selectionChange)="ApplyActionToAll('person', $event)">
            <mat-option title="No action" value="None">None</mat-option>
            <mat-option title="Add this record to main database" value="Add">Add</mat-option>
            <mat-option title="Add to main database" value="AddToMainDatabase">Select</mat-option>
            <mat-option title="Merge to selected" value="Merge">Merge</mat-option>
            <mat-option title="Remove from group" value="Kick">Kick</mat-option>
            <mat-option title="Send group to research" value="ResearchGroup">Send group to research</mat-option>
<!--            <mat-option title="Delete" value="Delete">Delete</mat-option>-->
            <mat-option title="Edit fields" value="Edit">Edit</mat-option>
          </mat-select>
        </mat-form-field>
        <button class="button" (click)="MatchByID()" *ngIf="matchingByID">Match Person</button>
        <mat-form-field *ngIf="isBulkSendResearchSelected || isSendRecordsToResearchSelected"  class="research ">
          <mat-select (selectionChange)="selectResearchArea($event)" placeholder="Select Research Area">
            <mat-option *ngFor="let res of research"  [value]="res.researchId" >
              {{ res.researchName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button class="button" (click)="ManualMerge()"  *ngIf="are_actionButtons_visible > 0 && isBulkSendResearchSelected ==false && isSendRecordsToResearchSelected==false">Proceed Manually</button>
        <button class="button" (click)="VerifyBulkActions()"  *ngIf="are_actionButtons_visible > 0">Proceed</button>
        <!--        <button class="button" (click)="exportexcel('AllDataStagedPeople.xlsx', 'alldata-person')">Download To Excel</button>-->
      </div>
      <div *ngIf="is_data_available">
          <div class="person-table mat-elevation-z8">
            <table mat-table [dataSource]="dataSource"  id="excel-table-alldata-person">
              <ng-container matColumnDef="Action">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Action</th>
                <td mat-cell *matCellDef="let element" class="td-dropdown"
                    id="alldata-person-field-{{element.actionType}}">
                  <mat-form-field appearance="fill" class="mat-dropdown" id="alldata-person-select-{{element.winPurePrimK}}">
                    <mat-select [(value)]="element.actionType" (selectionChange)="selectedAction(element.actionType, element)">
                      <mat-option title="No action" value="None" id="alldata-person-none">None</mat-option>
                      <mat-option title="Add this record to main database" value="Add" *ngIf="element.personId==0" id="alldata-person-add">Add</mat-option>
                      <mat-option title="Add to main database" value="AddToMainDatabase" id="alldata-person-select">Select</mat-option>
                      <mat-option title="Merge to selected" value="Merge" id="alldata-person-merge">Merge</mat-option>
                      <mat-option title="Remove from group" value="Kick" id="alldata-person-change">Kick</mat-option>
                      <mat-option title="Research" value="Research" id="alldata-person-research">Move to Research</mat-option>
<!--                              <mat-option title="Delete" value="Delete" id="alldata-person-delete">Delete</mat-option>-->
                      <mat-option title="Edit fields" value="Edit" id="alldata-person-edit">Edit</mat-option>
                      <mat-option title="Match by ID" value="MatchByID" id="alldata-person-MatchByID" *ngIf="element['source name'] == 'ImportedPerson'">Match by ID</mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </ng-container>
              <ng-container matColumnDef="Person Id">
                <th mat-header-cell *matHeaderCellDef class="table_header">Person Id</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}" class="pointer">
                  <div (click)="LinkTo(element.personId)" *ngIf="element.personId!=0 && element.actionType!='Edit' && element.actionType!='MatchByID'">
                    {{element.personId}}
                  </div>
                  <div *ngIf="element.actionType=='Edit' || element.actionType=='MatchByID'" class="input-box">
                    <input type="text" [(ngModel)]="element.personId" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="SourceName">
                <th mat-header-cell *matHeaderCellDef class="table_header">Source Name</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element['source name'] == 'ImportedPerson'" >
                    {{element.fileName}}
                  </div>
                  <div *ngIf="element['source name'] == 'Person'" >
                    ceo360 DB
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="First Name">
                <th mat-header-cell *matHeaderCellDef class="table_header">First Name</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.firstName}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="alldata-person-firstname" [(ngModel)]="element.firstName" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Middle Name">
                <th mat-header-cell *matHeaderCellDef class="table_header">Middle Name</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.middleName}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="alldata-person-middlename" [(ngModel)]="element.middleName" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Last Name">
                <th mat-header-cell *matHeaderCellDef class="table_header">Last Name</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.lastName}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="alldata-person-lastname" [(ngModel)]="element.lastName" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Suffix">
                <th mat-header-cell *matHeaderCellDef class="table_header">Suffix</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.suffix}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="alldata-person-suffix" [(ngModel)]="element.suffix" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Title">
                <th mat-header-cell *matHeaderCellDef class="table_header">Title</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.title}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="alldata-person-title" [(ngModel)]="element.title" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Email">
                <th mat-header-cell *matHeaderCellDef class="table_header">Email</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.email}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text"  name="alldata-person-email" [(ngModel)]="element.email" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="AltEmail">
                <th mat-header-cell *matHeaderCellDef class="table_header">AltEmail</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.altEmail}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text"name="alldata-person-altemail" [(ngModel)]="element.altEmail" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Direct Phone">
                <th mat-header-cell *matHeaderCellDef class="table_header">Direct Phone</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.directPhone}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'"class="input-box">
                    <input type="text" name="alldata-person-directphone" [(ngModel)]="element.directPhone" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Mobile Phone">
                <th mat-header-cell *matHeaderCellDef class="table_header">Mobile Phone</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.mobilePhone}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="alldata-person-mobilephone" [(ngModel)]="element.mobilePhone" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Primary Company">
                <th mat-header-cell *matHeaderCellDef class="table_header">Primary Company</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.primaryCompany}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'"class="input-box">
                    <input type="text" name="alldata-person-primarycompany" [(ngModel)]="element.primaryCompany" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Primary Co ID">
                <th mat-header-cell *matHeaderCellDef class="table_header">Primary Co ID</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.companyId}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="alldata-person-companyId" [(ngModel)]="element.companyId" class="edit-fields" (ngModelChange)="ClearCompanyName(element.personId)">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Board Company Associations">
                <th mat-header-cell *matHeaderCellDef class="table_header">Board Company Assoc.</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}" >
                  {{element.boardCompanyName}}
                </td>
              </ng-container>

              <ng-container matColumnDef="Ticker">
                <th mat-header-cell *matHeaderCellDef class="table_header">Ticker</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  {{element.ticker}}
                  <!--                    <div *ngIf="element.actionType!='Edit'">-->
                  <!--                      {{element.ticker}}-->
                  <!--                    </div>-->
                  <!--                    <div *ngIf="element.actionType=='Edit'">-->
                  <!--                      <input type="text" id="alldata-person-ticker" name="alldata-person-ticker" [(ngModel)]="element.ticker" class="edit-fields">-->
                  <!--                    </div>-->
                </td>
              </ng-container>
              <ng-container matColumnDef="Shipping Add1">
                <th mat-header-cell *matHeaderCellDef class="table_header">Shipping Add1</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.primaryAddress1}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="alldata-person-primaryaddress1" [(ngModel)]="element.primaryAddress1" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Shipping Add2">
                <th mat-header-cell *matHeaderCellDef class="table_header">Shipping Add2</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.primaryAddress2}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text"name="alldata-person-primaryaddress2" [(ngModel)]="element.primaryAddress2" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="Shipping City">
                <th mat-header-cell *matHeaderCellDef class="table_header">Shipping City</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.primaryCity}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="alldata-person-primarycity" [(ngModel)]="element.primaryCity" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Shipping State">
                <th mat-header-cell *matHeaderCellDef class="table_header">Shipping State</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.primaryState}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'">
                    <select class="dropdown-address" [(ngModel)]="element.primaryState">
                      <option value="{{not_found_primaryState}}">{{not_found_primaryState}}</option>
                      <option *ngFor="let state of states"  value="{{state.stateName}}" selected="state">
                        {{state.stateName}}
                      </option>
                    </select>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Shipping Zipcode">
                <th mat-header-cell *matHeaderCellDef class="table_header">Shipping Zipcode</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.primaryZip}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="alldata-person-primaryzipcode" [(ngModel)]="element.primaryZip" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Shipping Country">
                <th mat-header-cell *matHeaderCellDef class="table_header">Shipping Country</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.primaryCountry}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'">
                    <select class="dropdown-address" [(ngModel)]="element.primaryCountry">
                      <option value="{{not_found_country}}">{{not_found_country}}</option>
                      <option *ngFor="let country of countries"  value="{{country.countryName}}" selected="country">
                        {{country.countryName}}
                      </option>
                    </select>
                  </div>
                </td>
              </ng-container>


              <ng-container matColumnDef="Alternate Address Source">
                <th mat-header-cell *matHeaderCellDef class="table_header">Alternate Address Source</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.alternateAddressSource}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text"  name="alldata-person-alternateAddressSource" [(ngModel)]="element.alternateAddressSource" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Alternate Address Preferred">
                <th mat-header-cell *matHeaderCellDef class="table_header">Alternate Address Preferred</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.alternateAddressPreferred}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text"  name="alldata-person-alternateAddressPreferred" [(ngModel)]="element.alternateAddressPreferred" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Alternate Address Type">
                <th mat-header-cell *matHeaderCellDef class="table_header">Alternate Address Type</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.alternateAddressType}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="alldata-person-alternateAddressType" [(ngModel)]="element.alternateAddressType" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="CEODigitalAndPrint">
                <th mat-header-cell *matHeaderCellDef class="table_header">CEO Digital & Print</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.ceoDigitalAndPrint}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-CEODigitalAndPrint" [(ngModel)]="element.ceoDigitalAndPrint" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="CEODigitalOnly">
                <th mat-header-cell *matHeaderCellDef class="table_header">CEO Digital Only</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.ceoDigitalOnly}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-CEODigitalOnly" [(ngModel)]="element.ceoDigitalOnly" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="CBMDigitalAndPrint">
                <th mat-header-cell *matHeaderCellDef class="table_header">CBM Digital & Print</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.cbmDigitalAndPrint}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-CBMDigitalAndPrint" [(ngModel)]="element.cbmDigitalAndPrint" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="CBMDigitalOnly">
                <th mat-header-cell *matHeaderCellDef class="table_header">CBM Digital Only</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.cbmDigitalOnly}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-CBMDigitalOnly" [(ngModel)]="element.cbmDigitalOnly" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="CEN">
                <th mat-header-cell *matHeaderCellDef class="table_header">CEN</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.cen}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-cen" [(ngModel)]="element.cen" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="SEN">
                <th mat-header-cell *matHeaderCellDef class="table_header">SEN</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.sen}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-sen" [(ngModel)]="element.sen" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="CEO100">
                <th mat-header-cell *matHeaderCellDef class="table_header">CEO100</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.ceO100}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-ceo100" [(ngModel)]="element.ceO100" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="CFO100">
                <th mat-header-cell *matHeaderCellDef class="table_header">CFO100</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.cfO100}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-cfo100" [(ngModel)]="element.cfO100" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="NextLevelLeaders">
                <th mat-header-cell *matHeaderCellDef class="table_header">NLL</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.nextLevelLeaders}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-nll" [(ngModel)]="element.nextLevelLeaders" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="CEBI">
                <th mat-header-cell *matHeaderCellDef class="table_header">CEBI</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.cebi}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-cebi" [(ngModel)]="element.cebi" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="BPAQualDate">
                <th mat-header-cell *matHeaderCellDef class="table_header">BPA Qual Date</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.bpaQualDate}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="alldata-person-bpaQualDate" [(ngModel)]="element.bpaQualDate" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="BPAJobTitle">
                <th mat-header-cell *matHeaderCellDef class="table_header">BPA Job Title</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.bpaJobTitle}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="alldata-person-bpaJobTitle" [(ngModel)]="element.bpaJobTitle" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="BPAQualSource">
                <th mat-header-cell *matHeaderCellDef class="table_header">BPA Qual Source</th>
                <td mat-cell *matCellDef="let element" id="alldata-person-qualificationSource-{{element.actionType}}-{{element.activeStatus}}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.qualificationSource}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'">
                    <input type="text" id="alldata-person-qualificationSource" name="alldata-person-qualificationSource" [(ngModel)]="element.qualificationSource" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Verified Date">
                <th mat-header-cell *matHeaderCellDef class="table_header">Verified Date</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    <div *ngIf="element.verifiedDate; else lastupdated">
                      {{element.verifiedDate}}
                    </div>
                    <ng-template #lastupdated>
                      {{element.lastUpdated}}
                    </ng-template>
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <div *ngIf="element.verifiedDate; else lastupdatedEdit">
                      <input type="text" name="alldata-person-verifieddate" [(ngModel)]="element.verifiedDate" class="edit-fields">
                    </div>
                    <ng-template #lastupdatedEdit>
                      <input type="text" name="alldata-person-lastupdated" [(ngModel)]="element.lastUpdated" class="edit-fields">
                    </ng-template>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="HowVerified">
                <th mat-header-cell *matHeaderCellDef class="table_header">How Verified?</th>
                <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.howVerified}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text"  [(ngModel)]="element.howVerified" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Active Status">
                <th mat-header-cell *matHeaderCellDef class="table_header">Active Status</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.activeStatus}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'">
                    <select class="table-select" [(ngModel)]="element.activeStatus">
                      <option value="{{not_found_activeStatus}}">{{not_found_activeStatus}}</option>
                      <option *ngFor="let status of active_status"  value="{{status}}" selected="status">
                        {{status}}
                      </option>
                    </select>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row_darken" [ngClass]="{'gray-row': row.activeStatus == false}"></tr>
            </table>
            <!--              <mat-paginator [pageSizeOptions]="[20, 25, 30]" showFirstLastButtons></mat-paginator>-->
          </div>
      </div>
    </div>
  </div>
  <div *ngIf="is_onlyFileData">
    <div *ngIf="is_company_search">
      <div *ngIf="!is_data_available">
        No data available
      </div>
      <div *ngIf="is_data_available">
        Do this action for all records within this group
        <mat-form-field appearance="fill">
          <mat-select value="{{bulkaction}}" (selectionChange)="ApplyActionToAll('company', $event)">
            <mat-option title="No action" value="None">None</mat-option>
            <mat-option title="Add this record to main database" value="Add">Add</mat-option>
            <mat-option title="Add to main database" value="AddToMainDatabase">Select</mat-option>
            <mat-option title="Merge to selected" value="Merge">Merge</mat-option>
            <mat-option title="Remove from group" value="Kick">Kick</mat-option>
            <mat-option title="Send group to research" value="ResearchGroup">Send group to research</mat-option>
<!--            <mat-option title="Delete" value="Delete">Delete</mat-option>-->
            <mat-option title="Edit fields" value="Edit">Edit</mat-option>
          </mat-select>
        </mat-form-field>
        <button class="button" (click)="MatchByID()" *ngIf="matchingByID">Match Company</button>
        <mat-form-field *ngIf="isBulkSendResearchSelected || isSendRecordsToResearchSelected"  class="research ">
          <mat-select (selectionChange)="selectResearchArea($event)" placeholder="Select Research Area">
            <mat-option *ngFor="let res of research"  [value]="res.researchId" >
              {{ res.researchName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button class="button" (click)="ManualMerge()"  *ngIf="are_actionButtons_visible > 0 && isBulkSendResearchSelected ==false && isSendRecordsToResearchSelected==false">Proceed Manually</button>
        <button class="button" (click)="VerifyBulkActions()"  *ngIf="are_actionButtons_visible > 0">Proceed</button>
        <!--      <button class="button" (click)="exportexcel('AllDataStagedCompanies.xlsx', 'alldata-company')">Download To Excel</button>-->
        <div class="mat-elevation-z8 company-table">
          <table mat-table [dataSource]="dataSource" id="excel-table-alldata-company">
            <ng-container matColumnDef="Action">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Action</th>
              <td mat-cell *matCellDef="let element" class="td-dropdown" id="alldata-company-field-{{element.actionType}}">
                <mat-form-field appearance="fill" class="mat-dropdown" id="alldata-company-select-{{element.winPurePrimK}}">
                  <mat-select [(value)]="element.actionType" (selectionChange)="selectedAction(element.actionType, element)">
                    <mat-option title="No action" value="None" id="alldata-company-none">None</mat-option>
                    <mat-option title="Add this record to main database" value="Add" id="alldata-company-add" *ngIf="element.companyId==0">Add</mat-option>
                    <mat-option title="Add to main database" value="AddToMainDatabase" id="alldata-company-select">Select</mat-option>
                    <mat-option title="Merge to selected" value="Merge" id="alldata-company-merge">Merge</mat-option>
                    <mat-option title="Remove from group" value="Kick" id="alldata-company-change">Kick</mat-option>
                    <mat-option title="MigratePeople" value="MigratePeople" id="alldata-company-migratepeople">Migrate People</mat-option>
                    <mat-option title="Research" value="Research" id="alldata-company-research">Move to Research</mat-option>
                    <!--                              <mat-option title="Delete" value="Delete" id="alldata-company-delete">Delete</mat-option>-->
                    <mat-option title="Edit fields" value="Edit" id="alldata-company-edit">Edit</mat-option>
                    <mat-option title="Match by ID" value="MatchByID" id="alldata-company-MatchByID" *ngIf="element['source name'] == 'ImportedCompany'">Match by ID</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="CompanyId">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Company id</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}"  class="pointer" >
                <div  (click)="LinkTo(element.companyId)" *ngIf="element.companyId !== 0 && element.actionType!='Edit' && element.actionType!='MatchByID'">
                  {{element.companyId}}
                </div>
                <div *ngIf="element.actionType=='Edit' || element.actionType=='MatchByID'" class="input-box">
                  <input type="text" name="companyName" [(ngModel)]="element.companyId" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="SourceName">
              <th mat-header-cell *matHeaderCellDef class="table_header">Source Name</th>
              <td mat-cell *matCellDef="let element" >
                <div *ngIf="element['source name'] == 'ImportedCompany'" >
                  {{element.fileName}}
                </div>
                <div *ngIf="element['source name'] == 'Company'" >
                  ceo360 DB
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Company Name">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Company Name</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.companyName}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="companyname" [(ngModel)]="element.companyName" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Address 1">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Address1</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.address1}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="address1" [(ngModel)]="element.address1" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Address 2">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Address2</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.address2}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="address2" [(ngModel)]="element.address2" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="City">
              <th mat-header-cell *matHeaderCellDef class="table_header"> City</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.city}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  name="city" [(ngModel)]="element.city" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="State">
              <th mat-header-cell *matHeaderCellDef class="table_header"> State</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.state}}
                </div>
                <div *ngIf="element.actionType=='Edit'">
                  <select class="dropdown-address" [(ngModel)]="element.state">
                    <option value="{{not_found_state}}">{{not_found_state}}</option>
                    <option *ngFor="let state of states"  value="{{state.stateName}}" selected="state">
                      {{state.stateName}}
                    </option>
                  </select>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Zip">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Zip</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.zip}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="zip" [(ngModel)]="element.zip" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Country">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Country</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.country}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <select class="table-select dropdown-address" [(ngModel)]="element.country">
                    <option value="{{not_found_country}}">{{not_found_country}}</option>
                    <option *ngFor="let country of countries"  value="{{country.countryName}}" selected="country">
                      {{country.countryName}}
                    </option>
                  </select>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Phone">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Phone</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit' ">
                  {{element.phone}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="phone" [(ngModel)]="element.phone" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Website">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Website </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.website}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="website" [(ngModel)]="element.website" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Ticker">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Ticker </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.ticker}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="alldata-company-ticker" [(ngModel)]="element.ticker" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="CIK ID">
              <th mat-header-cell *matHeaderCellDef class="table_header"> CIK ID </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.cikId}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  name="cikId" [(ngModel)]="element.cikId" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="SIC Code">
              <th mat-header-cell *matHeaderCellDef class="table_header"> SIC Code </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                {{element.sicCode}}
              </td>
            </ng-container>

            <ng-container matColumnDef="Exchange">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Exchange </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                {{element.exchange}}
              </td>
            </ng-container>

            <ng-container matColumnDef="Annual Revenue">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Annual Revenue </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit' ">
                  {{element.annualRevenue}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" name="annualRevenue" [(ngModel)]="element.annualRevenue" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Employee Exact">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Employee Exact </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.numberOfEmployees}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  [(ngModel)]="element.numberOfEmployees" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Industry Category">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Industry Category </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.businessIndustry}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" [(ngModel)]="element.businessIndustry" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Ownership">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Ownership Type </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.ownershipTypeDesc}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  [(ngModel)]="element.ownershipTypeDesc" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="Ownership Sub Type">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Ownership Sub Type </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.ownershipSubType}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  [(ngModel)]="element.ownershipSubType" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="IPO Date">
              <th mat-header-cell *matHeaderCellDef class="table_header"> IPO Date </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.ipoDate}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text"  [(ngModel)]="element.ipoDate" class="edit-fields">
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Verified Date">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Verified Date </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  <div *ngIf="element.verifiedDate; else lastupdated">
                    {{element.verifiedDate}}
                  </div>
                  <ng-template #lastupdated>
                    {{element.lastUpdated}}
                  </ng-template>
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <div *ngIf="element.verifiedDate; else lastupdatedEdit">
                    <input type="text" name="company-verifieddate" [(ngModel)]="element.verifiedDate" class="edit-fields">
                  </div>
                  <ng-template #lastupdatedEdit>
                    <input type="text" name="company-lastupdated" [(ngModel)]="element.lastUpdated" class="edit-fields">
                  </ng-template>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="How Verified">
              <th mat-header-cell *matHeaderCellDef class="table_header"> How Verified? </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.howVerified}}
                </div>
                <div *ngIf="element.actionType=='Edit'" class="input-box">
                  <input type="text" [(ngModel)]="element.howVerified" class="edit-fields">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="Active Status">
              <th mat-header-cell *matHeaderCellDef class="table_header">Active Status</th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                <div *ngIf="element.actionType!='Edit'">
                  {{element.activeStatus}}
                </div>
                <div *ngIf="element.actionType=='Edit'">
                  <select class="table-select" [(ngModel)]="element.activeStatus">
                    <option value="{{not_found_activeStatus}}">{{not_found_activeStatus}}</option>
                    <option *ngFor="let status of active_status"  value="{{status}}" selected="status">
                      {{status}}
                    </option>
                  </select>
                </div>
              </td>
            </ng-container>


            <ng-container matColumnDef="AddressSource">
              <th mat-header-cell *matHeaderCellDef class="table_header"> Address Source </th>
              <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                {{element.addressSource}}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row_darken" [ngClass]="{'gray-row': row.activeStatus == false}"></tr>
          </table>
          <!--              <mat-paginator [pageSizeOptions]="[20, 25, 30]"-->
          <!--                             showFirstLastButtons></mat-paginator>-->
        </div>
      </div>
    </div>
    <div *ngIf="is_person_search">
      <div *ngIf="!is_data_available">
        No data available
      </div>
      <div *ngIf="is_data_available">
        Do this action for all records within this group
        <mat-form-field appearance="fill">
          <mat-select value="{{bulkaction}}" (selectionChange)="ApplyActionToAll('person', $event)">
            <mat-option title="No action" value="None">None</mat-option>
            <mat-option title="Add this record to main database" value="Add">Add</mat-option>
            <mat-option title="Add to main database" value="AddToMainDatabase">Select</mat-option>
            <mat-option title="Merge to selected" value="Merge">Merge</mat-option>
            <mat-option title="Remove from group" value="Kick">Kick</mat-option>
            <mat-option title="Send group to research" value="ResearchGroup">Send group to research</mat-option>
<!--            <mat-option title="Delete" value="Delete">Delete</mat-option>-->
            <mat-option title="Edit fields" value="Edit">Edit</mat-option>
          </mat-select>
        </mat-form-field>
        <button class="button" (click)="MatchByID()" *ngIf="matchingByID">Match Person</button>
        <mat-form-field *ngIf="isBulkSendResearchSelected || isSendRecordsToResearchSelected"  class="research ">
          <mat-select (selectionChange)="selectResearchArea($event)" placeholder="Select Research Area">
            <mat-option *ngFor="let res of research"  [value]="res.researchId" >
              {{ res.researchName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button class="button" (click)="ManualMerge()"  *ngIf="are_actionButtons_visible > 0 && isBulkSendResearchSelected ==false && isSendRecordsToResearchSelected==false">Proceed Manually</button>
        <button class="button" (click)="VerifyBulkActions()"  *ngIf="are_actionButtons_visible > 0">Proceed</button>
        <!--        <button class="button" (click)="exportexcel('AllDataStagedPeople.xlsx', 'alldata-person')">Download To Excel</button>-->
      </div>
      <div *ngIf="is_data_available">
          <div class="person-table">
            <table mat-table [dataSource]="dataSource"  id="excel-table-file-person">
              <ng-container matColumnDef="Action">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Action</th>
                <td mat-cell *matCellDef="let element" class="td-dropdown"
                    id="file-person-field-{{element.actionType}}">
                  <mat-form-field appearance="fill" class="mat-dropdown" id="file-person-select-{{element.winPurePrimK}}">
                    <mat-select [(value)]="element.actionType" (selectionChange)="selectedAction(element.actionType, element)">
                      <mat-option title="No action" value="None" id="file-person-none">None</mat-option>
                      <mat-option title="Add this record to main database" value="Add" *ngIf="element.personId==0" id="file-person-add">Add</mat-option>
                      <mat-option title="Add to main database" value="AddToMainDatabase" id="file-person-select">Select</mat-option>
                      <mat-option title="Merge to selected" value="Merge" id="file-person-merge">Merge</mat-option>
                      <mat-option title="Remove from group" value="Kick" id="file-person-change">Kick</mat-option>
                      <mat-option title="Research" value="Research" id="file-person-research">Move to Research</mat-option>
<!--                              <mat-option title="Delete" value="Delete" id="file-person-delete">Delete</mat-option>-->
                      <mat-option title="Edit fields" value="Edit" id="file-person-edit">Edit</mat-option>
                      <mat-option title="Match by ID" value="MatchByID" id="file-person-MatchByID" *ngIf="element['source name'] == 'ImportedPerson'">Match by ID</mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </ng-container>
              <ng-container matColumnDef="Person Id">
                <th mat-header-cell *matHeaderCellDef class="table_header">Person Id</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}" class="pointer">
                  <div (click)="LinkTo(element.personId)" *ngIf="element.personId!=0 && element.actionType!='Edit' && element.actionType!='MatchByID'">
                    {{element.personId}}
                  </div>
                  <div *ngIf="element.actionType=='Edit' || element.actionType=='MatchByID'" class="input-box">
                    <input type="text"  [(ngModel)]="element.personId" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="SourceName">
                <th mat-header-cell *matHeaderCellDef class="table_header">Source Name</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element['source name'] == 'ImportedPerson'" >
                    {{element.fileName}}
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="First Name">
                <th mat-header-cell *matHeaderCellDef class="table_header">First Name</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.firstName}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="alldata-person-firstname" [(ngModel)]="element.firstName" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Middle Name">
                <th mat-header-cell *matHeaderCellDef class="table_header">Middle Name</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.middleName}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="alldata-person-middlename" [(ngModel)]="element.middleName" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Last Name">
                <th mat-header-cell *matHeaderCellDef class="table_header">Last Name</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.lastName}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="alldata-person-lastname" [(ngModel)]="element.lastName" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Suffix">
                <th mat-header-cell *matHeaderCellDef class="table_header">Suffix</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.suffix}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="alldata-person-suffix" [(ngModel)]="element.suffix" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Title">
                <th mat-header-cell *matHeaderCellDef class="table_header">Title</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.title}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text"  name="alldata-person-title" [(ngModel)]="element.title" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Email">
                <th mat-header-cell *matHeaderCellDef class="table_header">Email</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.email}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="alldata-person-email" [(ngModel)]="element.email" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="AltEmail">
                <th mat-header-cell *matHeaderCellDef class="table_header">AltEmail</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.altEmail}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text"  name="alldata-person-altemail" [(ngModel)]="element.altEmail" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Direct Phone">
                <th mat-header-cell *matHeaderCellDef class="table_header">Direct Phone</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.directPhone}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text"  name="alldata-person-directphone" [(ngModel)]="element.directPhone" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Mobile Phone">
                <th mat-header-cell *matHeaderCellDef class="table_header">Mobile Phone</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.mobilePhone}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text"  name="alldata-person-mobilephone" [(ngModel)]="element.mobilePhone" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Primary Company">
                <th mat-header-cell *matHeaderCellDef class="table_header">Primary Company</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.primaryCompany}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="alldata-person-primarycompany" [(ngModel)]="element.primaryCompany" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Primary Co ID">
                <th mat-header-cell *matHeaderCellDef class="table_header">Primary Co ID</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.companyId}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text"  [(ngModel)]="element.companyId" class="edit-fields" (ngModelChange)="ClearCompanyName(element.personId)">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Board Company Associations">
                <th mat-header-cell *matHeaderCellDef class="table_header">Board Company Assoc.</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  {{element.boardCompanyName}}
                </td>
              </ng-container>

              <ng-container matColumnDef="Ticker">
                <th mat-header-cell *matHeaderCellDef class="table_header">Ticker</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  {{element.ticker}}
                </td>
              </ng-container>
              <ng-container matColumnDef="Shipping Add1">
                <th mat-header-cell *matHeaderCellDef class="table_header">Shipping Add1</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.primaryAddress1}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="file-person-addr2" [(ngModel)]="element.primaryAddress1" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Shipping Add2">
                <th mat-header-cell *matHeaderCellDef class="table_header">Shipping Add2</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.primaryAddress2}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="file-person-addr1" [(ngModel)]="element.primaryAddress2" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="Shipping City">
                <th mat-header-cell *matHeaderCellDef class="table_header">Shipping City</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.primaryCity}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="file-person-primarycity" [(ngModel)]="element.primaryCity" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Shipping State">
                <th mat-header-cell *matHeaderCellDef class="table_header">Shipping State</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.primaryState}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'">
                    <select class="dropdown-address" [(ngModel)]="element.primaryState">
                      <option value="{{not_found_primaryState}}">{{not_found_primaryState}}</option>
                      <option *ngFor="let state of states"  value="{{state.stateName}}" selected="state">
                        {{state.stateName}}
                      </option>
                    </select>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Shipping Zipcode">
                <th mat-header-cell *matHeaderCellDef class="table_header">Shipping Zipcode</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.primaryZip}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text"  [(ngModel)]="element.primaryZip" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Shipping Country">
                <th mat-header-cell *matHeaderCellDef class="table_header">Shipping Country</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.primaryCountry}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <select class="dropdown-address" [(ngModel)]="element.primaryCountry">
                      <option value="{{not_found_country}}">{{not_found_country}}</option>
                      <option *ngFor="let country of countries"  value="{{country.countryName}}" selected="country">
                        {{country.countryName}}
                      </option>
                    </select>
                  </div>
                </td>
              </ng-container>


              <ng-container matColumnDef="Alternate Address Source">
                <th mat-header-cell *matHeaderCellDef class="table_header">Alternate Address Source</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.alternateAddressSource}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text"  [(ngModel)]="element.alternateAddressSource" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Alternate Address Preferred">
                <th mat-header-cell *matHeaderCellDef class="table_header">Alternate Address Preferred</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.alternateAddressPreferred}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text"  [(ngModel)]="element.alternateAddressPreferred" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Alternate Address Type">
                <th mat-header-cell *matHeaderCellDef class="table_header">Alternate Address Type</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.alternateAddressType}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text"  [(ngModel)]="element.alternateAddressType" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="CEODigitalAndPrint">
                <th mat-header-cell *matHeaderCellDef class="table_header">CEO Digital & Print</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.ceoDigitalAndPrint}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-CEODigitalAndPrint" [(ngModel)]="element.ceoDigitalAndPrint" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="CEODigitalOnly">
                <th mat-header-cell *matHeaderCellDef class="table_header">CEO Digital Only</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.ceoDigitalOnly}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-CEODigitalOnly" [(ngModel)]="element.ceoDigitalOnly" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="CBMDigitalAndPrint">
                <th mat-header-cell *matHeaderCellDef class="table_header">CBM Digital & Print</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.cbmDigitalAndPrint}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-CBMDigitalAndPrint" [(ngModel)]="element.cbmDigitalAndPrint" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="CBMDigitalOnly">
                <th mat-header-cell *matHeaderCellDef class="table_header">CBM Digital Only</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.cbmDigitalOnly}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-CBMDigitalOnly" [(ngModel)]="element.cbmDigitalOnly" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="CEN">
                <th mat-header-cell *matHeaderCellDef class="table_header">CEN</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.cen}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-cen" [(ngModel)]="element.cen" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="SEN">
                <th mat-header-cell *matHeaderCellDef class="table_header">SEN</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.sen}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-sen" [(ngModel)]="element.sen" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="CEO100">
                <th mat-header-cell *matHeaderCellDef class="table_header">CEO100</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.ceO100}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-ceo100" [(ngModel)]="element.ceO100" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="CFO100">
                <th mat-header-cell *matHeaderCellDef class="table_header">CFO100</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.cfO100}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-cfo100" [(ngModel)]="element.cfO100" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="NextLevelLeaders">
                <th mat-header-cell *matHeaderCellDef class="table_header">NLL</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.nextLevelLeaders}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-nll" [(ngModel)]="element.nextLevelLeaders" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="CEBI">
                <th mat-header-cell *matHeaderCellDef class="table_header">CEBI</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.cebi}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-cebi" [(ngModel)]="element.cebi" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="CBMNetwork">
                <th mat-header-cell *matHeaderCellDef class="table_header">CBM Network</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.cbmNetwork}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-cebi" [(ngModel)]="element.cbmNetwork" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="DirectorFundamentals">
                <th mat-header-cell *matHeaderCellDef class="table_header">Director Fundamentals</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.directorFundamentals}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-cebi" [(ngModel)]="element.directorFundamentals" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="ACCD">
                <th mat-header-cell *matHeaderCellDef class="table_header">ACCD</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.accd}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" [(ngModel)]="element.accd" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="BPAQualDate">
                <th mat-header-cell *matHeaderCellDef class="table_header">BPA Qual Date</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.bpaQualDate}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" [(ngModel)]="element.bpaQualDate" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="BPAJobTitle">
                <th mat-header-cell *matHeaderCellDef class="table_header">BPA Job Title</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.bpaJobTitle}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text"  [(ngModel)]="element.bpaJobTitle" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="BPAQualSource">
                <th mat-header-cell *matHeaderCellDef class="table_header">BPA Qual Source</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.qualificationSource}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" [(ngModel)]="element.qualificationSource" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="Verified Date">
                <th mat-header-cell *matHeaderCellDef class="table_header">Verified Date</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    <div *ngIf="element.verifiedDate; else lastupdated">
                      {{element.verifiedDate}}
                    </div>
                    <ng-template #lastupdated>
                      {{element.lastUpdated}}
                    </ng-template>
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <div *ngIf="element.verifiedDate; else lastupdatedEdit">
                      <input type="text"  [(ngModel)]="element.verifiedDate" class="edit-fields">
                    </div>
                    <ng-template #lastupdatedEdit>
                      <input type="text" [(ngModel)]="element.lastUpdated" class="edit-fields">
                    </ng-template>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="HowVerified">
                <th mat-header-cell *matHeaderCellDef class="table_header">How Verified?</th>
                <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.howVerified}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text"  [(ngModel)]="element.howVerified" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Active Status">
                <th mat-header-cell *matHeaderCellDef class="table_header">Active Status</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.activeStatus}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'">
                    <select class="table-select" [(ngModel)]="element.activeStatus">
                      <option value="{{not_found_activeStatus}}">{{not_found_activeStatus}}</option>
                      <option *ngFor="let status of active_status"  value="{{status}}" selected="status">
                        {{status}}
                      </option>
                    </select>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row_darken" [ngClass]="{'gray-row': row.activeStatus == false}"></tr>
            </table>
          </div>
      </div>

    </div>
  </div>
  <div *ngIf="is_Wordpress">
    <div *ngIf="is_person_search">
      <div *ngIf="!is_data_available">
        No data available
      </div>
      <div *ngIf="is_data_available">
        <br>
        <p> Total groups: {{group_list_length_wordpress}} (Total number of records across all groups: {{totalRecordsWordpress}}) </p>
        <div *ngIf="selected_group">
          Do this action for all records within this group
          <mat-form-field appearance="fill">
            <mat-select value="{{bulkaction}}" (selectionChange)="ApplyActionToAll('person', $event)">
              <mat-option title="No action" value="None">None</mat-option>
              <mat-option title="Add this record to main database" value="Add">Add</mat-option>
              <mat-option title="Add to main database" value="AddToMainDatabase">Select</mat-option>
              <mat-option title="Merge to selected" value="Merge">Merge</mat-option>
              <mat-option title="Remove from group" value="Kick">Kick</mat-option>
              <mat-option title="Send group to research" value="ResearchGroup">Send group to research</mat-option>
<!--              <mat-option title="Delete" value="Delete">Delete</mat-option>-->
              <mat-option title="Edit fields" value="Edit">Edit</mat-option>
            </mat-select>
          </mat-form-field>
          <button class="button" (click)="MatchByID()" *ngIf="matchingByID">Match Person</button>
          <mat-form-field *ngIf="isBulkSendResearchSelected || isSendRecordsToResearchSelected"  class="research ">
            <mat-select (selectionChange)="selectResearchArea($event)" placeholder="Select Research Area">
              <mat-option *ngFor="let res of research"  [value]="res.researchId" >
                {{ res.researchName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!--        <button class="button" (click)="exportexcel('AcrossTableStagedPeople.xlsx', 'acrosstable-person')">Download To Excel</button>-->
          <button class="button" (click)="ManualMerge()"  *ngIf="are_actionButtons_visible > 0 && isBulkSendResearchSelected ==false && isSendRecordsToResearchSelected==false">Proceed Manually</button>
          <button class="button" (click)="VerifyBulkActions()"  *ngIf="are_actionButtons_visible > 0">Proceed</button>
          <!--        <button class="button" (click)="SendGroupToResearch()" >Send Selected group to Research</button>-->
        </div>
        <p></p>
        <div *ngIf="is_long_list">
          <div class="scrollable">
            <table class="in-table">
              <tr>
                <td *ngFor="let group of group_list_wordpress" id="person-wordpress-group-{{group}}" class="table-group" (click)="GetWordpressData(group)">
                  Group <br> {{group}}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div *ngIf="!is_long_list">
          <div class="single-nonscrollable">
            <table class="in-table">
              <tr>
                <td class="single-table-group" id="person-wordpress-group-1" (click)="Group_Data(group_list_wordpress[0])">
                  Group <br> {{group_list_wordpress[0]}}
                </td>
                <td class="table-group-noscroll">
                  <button class="moregroups-button" (click)="ShowAllGroups()">Load all groups</button>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="api-data-table">
            <table mat-table [dataSource]="dataSource"  id="excel-table-wordpress-person">
              <ng-container matColumnDef="Action">
                <th mat-header-cell *matHeaderCellDef class="table_header action-person"> Action</th>
                <td mat-cell *matCellDef="let element" class="td-dropdown"
                    id="wordpress-person-field-{{element.actionType}}">
                  <mat-form-field appearance="fill" class="mat-dropdown" id="wordpress-person-select-{{element.winPurePrimK}}">
                    <mat-select [(value)]="element.actionType" (selectionChange)="selectedAction(element.actionType, element)">
                      <mat-option title="No action" value="None" id="wordpress-person-none">None</mat-option>
                      <mat-option title="Add this record to main database" value="Add" *ngIf="element.personId==0" id="wordpress-person-add">Add</mat-option>
                      <mat-option title="Add to main database" value="AddToMainDatabase" id="wordpress-person-select">Select</mat-option>
                      <mat-option title="Merge to selected" value="Merge" id="wordpress-person-merge">Merge</mat-option>
                      <mat-option title="Remove from group" value="Kick" id="wordpress-person-change">Kick</mat-option>
                      <mat-option title="Research" value="Research" id="wordpress-person-research">Move to Research</mat-option>
<!--                              <mat-option title="Delete" value="Delete" id="wordpress-person-delete">Delete</mat-option>-->
                      <mat-option title="Edit fields" value="Edit" id="wordpress-person-edit">Edit</mat-option>
                      <mat-option title="Match by ID" value="MatchByID" id="wordpress-person-MatchByID" *ngIf="element['source name'] == 'ImportedPerson'">Match by ID</mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="Person Id">
                <th mat-header-cell *matHeaderCellDef class="table_header">Person Id</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}" class="pointer" >
                  <div (click)="LinkTo(element.personId)" *ngIf="element.personId!=0 && element.actionType!='Edit' && element.actionType!='MatchByID'">
                    {{element.personId}}
                  </div>
                  <div *ngIf="element.actionType=='Edit' || element.actionType=='MatchByID'" class="input-box">
                    <input type="text" [(ngModel)]="element.personId" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="SourceName">
                <th mat-header-cell *matHeaderCellDef class="table_header action-person">Data Source </th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element['source name'] == 'ImportedPerson'" >
                    {{element.fileName}}
                  </div>
                  <div *ngIf="element['source name'] == 'Person'" >
                    ceo360 DB
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="WordpressId">
                <th mat-header-cell *matHeaderCellDef class="table_header">WP ID</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit' && element.wordpressId!=0">
                    {{element.wordpressId}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-wordpressId" [(ngModel)]="element.wordpressId" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="BoardMembersWPId">
                <th mat-header-cell *matHeaderCellDef class="table_header">BM WP ID</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit' && element.boardMembersWPId!=0">
                    {{element.boardMembersWPId}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-wordpressId" [(ngModel)]="element.boardMembersWPId" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="MemberClicksId">
                <th mat-header-cell *matHeaderCellDef class="table_header">Member Clicks Id</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit' && element.memberClicksId!=0">
                    {{element.memberClicksId}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-memberClicksId" [(ngModel)]="element.memberClicksId" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="First Name">
                <th mat-header-cell *matHeaderCellDef class="table_header">First Name</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.firstName}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-firstname" [(ngModel)]="element.firstName" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="Last Name">
                <th mat-header-cell *matHeaderCellDef class="table_header">Last Name</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.lastName}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-lastname" [(ngModel)]="element.lastName" class="edit-fields">
                  </div>
                </td>
              </ng-container>


              <ng-container matColumnDef="Title">
                <th mat-header-cell *matHeaderCellDef class="table_header">Title</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.title}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-title" [(ngModel)]="element.title" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Email">
                <th mat-header-cell *matHeaderCellDef class="table_header">Email</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.email}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-email" [(ngModel)]="element.email" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="Alt Email">
                <th mat-header-cell *matHeaderCellDef class="table_header">Alt Email</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.altEmail}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-altemail" [(ngModel)]="element.altEmail" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="Direct Phone">
                <th mat-header-cell *matHeaderCellDef class="table_header">Direct Phone</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.directPhone}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-directphone" [(ngModel)]="element.directPhone" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <!--                    <ng-container matColumnDef="Mobile Phone">-->
              <!--                      <th mat-header-cell *matHeaderCellDef class="table_header">Mobile Phone</th>-->
              <!--                      <td mat-cell *matCellDef="let element" id="wordpress-person-mobilephone-{{element.actionType}}-{{element.activeStatus}}">-->
              <!--                        <div *ngIf="element.actionType!='Edit'">-->
              <!--                          {{element.mobilePhone}}-->
              <!--                        </div>-->
              <!--                        <div *ngIf="element.actionType=='Edit'">-->
              <!--                          <input type="text" id="wordpress-person-mobilephone" name="wordpress-person-mobilephone" [(ngModel)]="element.mobilePhone" class="edit-fields">-->
              <!--                        </div>-->
              <!--                      </td>-->
              <!--                    </ng-container>-->

              <ng-container matColumnDef="Primary Company">
                <th mat-header-cell *matHeaderCellDef class="table_header">Primary Company</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  {{element.primaryCompany}}
                </td>
              </ng-container>

              <ng-container matColumnDef="Primary Co ID">
                <th mat-header-cell *matHeaderCellDef class="table_header">Primary Co ID</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.companyId}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-CEXSubStatus" [(ngModel)]="element.companyId" class="edit-fields" (ngModelChange)="ClearCompanyName(element.personId)">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="CEXSubStatus">
                <th mat-header-cell *matHeaderCellDef class="table_header">CEX Sub Status</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.cexSubStatus}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-CEXSubStatus" [(ngModel)]="element.cexSubStatus" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="CBMSubStatus">
                <th mat-header-cell *matHeaderCellDef class="table_header">CBM Sub Status</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.cbmSubStatus}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-CBMSubStatus" [(ngModel)]="element.cbmSubStatus" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Shipping Add1">
                <th mat-header-cell *matHeaderCellDef class="table_header">Shipping Add1</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.primaryAddress1}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-primaryaddress1" [(ngModel)]="element.primaryAddress1" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="Shipping Add2">
                <th mat-header-cell *matHeaderCellDef class="table_header">Shipping Add2</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.primaryAddress2}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-primaryaddress2" [(ngModel)]="element.primaryAddress2" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="Shipping City">
                <th mat-header-cell *matHeaderCellDef class="table_header">Shipping City</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  {{element.primaryCity}}
                </td>
              </ng-container>

              <ng-container matColumnDef="Shipping State">
                <th mat-header-cell *matHeaderCellDef class="table_header">Shipping State</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.primaryState}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'">
                    <select class="dropdown-address" [(ngModel)]="element.primaryState">
                      <option value="{{not_found_primaryState}}">{{not_found_primaryState}}</option>
                      <option *ngFor="let state of states"  value="{{state.stateName}}" selected="state">
                        {{state.stateName}}
                      </option>
                    </select>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="Shipping Country">
                <th mat-header-cell *matHeaderCellDef class="table_header">Shipping Country</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.primaryCountry}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <select class="dropdown-address" [(ngModel)]="element.primaryCountry">
                      <option value="{{not_found_country}}">{{not_found_country}}</option>
                      <option *ngFor="let country of countries"  value="{{country.countryName}}" selected="country">
                        {{country.countryName}}
                      </option>
                    </select>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Shipping Zipcode">
                <th mat-header-cell *matHeaderCellDef class="table_header">Shipping Zipcode</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.primaryZip}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-primaryzipcode" [(ngModel)]="element.primaryZip" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Alternate Address Preferred">
                <th mat-header-cell *matHeaderCellDef class="table_header">Alternate Address Preferred</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.alternateAddressPreferred=='1'">
                    <div *ngIf="element.actionType!='Edit'">
                      True
                    </div>
                  </div>
                  <div *ngIf="element.alternateAddressPreferred=='0'">
                    <div *ngIf="element.actionType!='Edit'">
                      False
                    </div>
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-alternateAddressPreferred" [(ngModel)]="element.alternateAddressPreferred" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Alternate Address Type">
                <th mat-header-cell *matHeaderCellDef class="table_header">Alternate Address Type</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.alternateAddressType}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-alternateAddressType" [(ngModel)]="element.alternateAddressType" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="CEODigitalAndPrint">
                <th mat-header-cell *matHeaderCellDef class="table_header">CEO Digital & Print</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.ceoDigitalAndPrint}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-CEODigitalAndPrint" [(ngModel)]="element.ceoDigitalAndPrint" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="CEODigitalOnly">
                <th mat-header-cell *matHeaderCellDef class="table_header">CEO Digital Only</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.ceoDigitalOnly}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-CEODigitalOnly" [(ngModel)]="element.ceoDigitalOnly" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="CBMDigitalAndPrint">
                <th mat-header-cell *matHeaderCellDef class="table_header">CBM Digital & Print</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.cbmDigitalAndPrint}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-CBMDigitalAndPrint" [(ngModel)]="element.cbmDigitalAndPrint" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="CBMDigitalOnly">
                <th mat-header-cell *matHeaderCellDef class="table_header">CBM Digital Only</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.cbmDigitalOnly}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-CBMDigitalOnly" [(ngModel)]="element.cbmDigitalOnly" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="CEN">
                <th mat-header-cell *matHeaderCellDef class="table_header">CEN</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.cen}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-cen" [(ngModel)]="element.cen" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="SEN">
                <th mat-header-cell *matHeaderCellDef class="table_header">SEN</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.sen}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-sen" [(ngModel)]="element.sen" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="CEO100">
                <th mat-header-cell *matHeaderCellDef class="table_header">CEO100</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.ceO100}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-ceo100" [(ngModel)]="element.ceO100" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="CFO100">
                <th mat-header-cell *matHeaderCellDef class="table_header">CFO100</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.cfO100}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-cfo100" [(ngModel)]="element.cfO100" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="NextLevelLeaders">
                <th mat-header-cell *matHeaderCellDef class="table_header">NLL</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.nextLevelLeaders}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-nll" [(ngModel)]="element.nextLevelLeaders" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="CEBI">
                <th mat-header-cell *matHeaderCellDef class="table_header">CEBI</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.cebi}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-cebi" [(ngModel)]="element.cebi" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="CBMNetwork">
                <th mat-header-cell *matHeaderCellDef class="table_header">CBM Network</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.cbmNetwork}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-cebi" [(ngModel)]="element.cbmNetwork" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="DirectorFundamentals">
                <th mat-header-cell *matHeaderCellDef class="table_header">Director Fundamentals</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.directorFundamentals}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-cebi" [(ngModel)]="element.directorFundamentals" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="ACCD">
                <th mat-header-cell *matHeaderCellDef class="table_header">ACCD</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.accd}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" [(ngModel)]="element.accd" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Revenue">
                <th mat-header-cell *matHeaderCellDef class="table_header">Anual Revenue BPA</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.revenue}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-revenue" [(ngModel)]="element.revenue" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Ownership">
                <th mat-header-cell *matHeaderCellDef class="table_header">Ownership Type BPA</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.ownership}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-ownerhip" [(ngModel)]="element.ownership" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="OwnershipSubType">
                <th mat-header-cell *matHeaderCellDef class="table_header">Ownership Sub Type </th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.ownershipSubType}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text"  name="wordpress-person-ownerhipSubType" [(ngModel)]="element.ownershipSubType" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Employees">
                <th mat-header-cell *matHeaderCellDef class="table_header">Number of Employees BPA</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.employees}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-employees" [(ngModel)]="element.employees" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Industry">
                <th mat-header-cell *matHeaderCellDef class="table_header">Business/ Industry BPA</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.industry}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-industry" [(ngModel)]="element.industry" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="OtherIndustry">
                <th mat-header-cell *matHeaderCellDef class="table_header">Other Industry </th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.otherIndustry}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-otherIndustry" [(ngModel)]="element.otherIndustry" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="BPAQualData">
                <th mat-header-cell *matHeaderCellDef class="table_header">Qualification Date</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.bpaQualDate}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-bpaQualDate" [(ngModel)]="element.bpaQualDate" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="BPAJobTitle">
                <th mat-header-cell *matHeaderCellDef class="table_header">BPA Job Title</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.bpaJobTitle}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-bpaJobTitle" [(ngModel)]="element.bpaJobTitle" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="BPAQualSource">
                <th mat-header-cell *matHeaderCellDef class="table_header">BPA Qual Source</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.qualificationSource}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text"  name="wordpress-person-qualificationSource" [(ngModel)]="element.qualificationSource" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="HowVerified">
                <th mat-header-cell *matHeaderCellDef class="table_header">How Verified?</th>
                <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.howVerified}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text"  [(ngModel)]="element.howVerified" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Active Status">
                <th mat-header-cell *matHeaderCellDef class="table_header">Active Status</th>
                <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.activeStatus}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'">
                    <select class="table-select" [(ngModel)]="element.activeStatus">
                      <option value="{{not_found_activeStatus}}">{{not_found_activeStatus}}</option>
                      <option *ngFor="let status of active_status"  value="{{status}}" selected="status">
                        {{status}}
                      </option>
                    </select>
                  </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row_darken" [ngClass]="{'gray-row': row.activeStatus == false}"></tr>
            </table>
        </div>
      </div>
      <p></p>
    </div>
  </div>
  <div *ngIf="is_Wordpress_non_matches">
    <div *ngIf="is_person_search">
      <div *ngIf="!is_data_available">
        No data available
      </div>
      <div *ngIf="is_data_available">
        <div>
          Do this action for all records within this group
          <mat-form-field appearance="fill">
            <mat-select value="{{bulkaction}}" (selectionChange)="ApplyActionToAll('person', $event)">
              <mat-option title="No action" value="None">None</mat-option>
              <mat-option title="Add this record to main database" value="Add">Add</mat-option>
              <mat-option title="Add to main database" value="AddToMainDatabase">Select</mat-option>
              <mat-option title="Merge to selected" value="Merge">Merge</mat-option>
              <mat-option title="Remove from group" value="Kick">Kick</mat-option>
              <mat-option title="Send group to research" value="ResearchGroup">Send group to research</mat-option>
<!--              <mat-option title="Delete" value="Delete">Delete</mat-option>-->
              <mat-option title="Edit fields" value="Edit">Edit</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="isBulkSendResearchSelected || isSendRecordsToResearchSelected"  class="research ">
            <mat-select (selectionChange)="selectResearchArea($event)" placeholder="Select Research Area">
              <mat-option *ngFor="let res of research"  [value]="res.researchId" >
                {{ res.researchName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!--        <button class="button" (click)="exportexcel('AcrossTableStagedPeople.xlsx', 'acrosstable-person')">Download To Excel</button>-->
          <button class="button" (click)="ManualMerge()"  *ngIf="are_actionButtons_visible > 0 && isBulkSendResearchSelected ==false && isSendRecordsToResearchSelected==false">Proceed Manually</button>
          <button class="button" (click)="VerifyBulkActions()"  *ngIf="are_actionButtons_visible > 0">Proceed</button>
          <button class="button" (click)="MatchByID()" *ngIf="matchingByID">Match Person</button>
          <!--        <button class="button" (click)="SendGroupToResearch()" >Send Selected group to Research</button>-->
        </div>
        <div *ngIf="is_data_available">
          <div class="api-data-table">
            <table mat-table [dataSource]="dataSource"  id="excel-table-wordpress-non-matches-person">
              <ng-container matColumnDef="Action" >
                <th mat-header-cell *matHeaderCellDef class="table_header action-person"> Action</th>
                <td mat-cell *matCellDef="let element" class="td-dropdown"
                    id="wordpress-non-matches-person-field-{{element.actionType}}">
                  <mat-form-field appearance="fill" class="mat-dropdown" id="wordpress-non-matches-person-select-{{element.winPurePrimK}}">
                    <mat-select [(value)]="element.actionType" (selectionChange)="selectedAction(element.actionType, element)">
                      <mat-option title="No action" value="None" id="wordpress-non-matches-person-none">None</mat-option>
                      <mat-option title="Add this record to main database" value="Add" *ngIf="element.personId==0" id="wordpress-non-matches-person-add">Add</mat-option>
                      <mat-option title="Add to main database" value="AddToMainDatabase" id="wordpress-non-matches-person-select">Select</mat-option>
                      <mat-option title="Merge to selected" value="Merge" id="wordpress-non-matches-person-merge">Merge</mat-option>
                      <mat-option title="Remove from group" value="Kick" id="wordpress-non-matches-person-change">Kick</mat-option>
                      <mat-option title="Research" value="Research" id="wordpress-non-matches-person-research">Move to Research</mat-option>
<!--                                <mat-option title="Delete" value="Delete" id="wordpress-non-matches-person-delete">Delete</mat-option>-->
                      <mat-option title="Edit fields" value="Edit" id="wordpress-non-matches-person-edit">Edit</mat-option>
                      <mat-option title="Match by ID" value="MatchByID" id="wordpress-non-matches-person-MatchByID" *ngIf="element['source name'] == 'ImportedPerson'">Match by ID</mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="WordpressId">
                <th mat-header-cell *matHeaderCellDef class="table_header">WP ID</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit' && element.wordpressId!=0">
                    {{element.wordpressId}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-non-matches-person-wordpressId" [(ngModel)]="element.wordpressId" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="MemberClicksId">
                <th mat-header-cell *matHeaderCellDef class="table_header">Member Clicks Id</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit' && element.memberClicksId!=0">
                    {{element.memberClicksId}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-memberClicksId" [(ngModel)]="element.memberClicksId" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="BoardMembersWPId">
                <th mat-header-cell *matHeaderCellDef class="table_header">BM WP ID</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit' && element.boardMembersWPId!=0">
                    {{element.boardMembersWPId}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-wordpressId" [(ngModel)]="element.boardMembersWPId" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="Person Id">
                <th mat-header-cell *matHeaderCellDef class="table_header">Person Id</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}" class="pointer">
                  <div (click)="LinkTo(element.personId)" *ngIf="element.personId!=0 && element.actionType!='Edit' && element.actionType!='MatchByID'">
                    {{element.personId}}
                  </div>
                  <div *ngIf="element.actionType=='Edit' || element.actionType=='MatchByID'" class="input-box">
                    <input type="text"  [(ngModel)]="element.personId" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="SourceName">
                <th mat-header-cell *matHeaderCellDef class="table_header action-person">Data Source </th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element['source name'] == 'ImportedPerson'" >
                    {{element.fileName}}
                  </div>
                  <div *ngIf="element['source name'] == 'Person'" >
                    ceo360 DB
                  </div>
                </td>
              </ng-container>



              <ng-container matColumnDef="First Name">
                <th mat-header-cell *matHeaderCellDef class="table_header">First Name</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.firstName}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-non-matches-person-firstname" [(ngModel)]="element.firstName" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="Last Name">
                <th mat-header-cell *matHeaderCellDef class="table_header">Last Name</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.lastName}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text"  name="wordpress-non-matches-person-lastname" [(ngModel)]="element.lastName" class="edit-fields">
                  </div>
                </td>
              </ng-container>


              <ng-container matColumnDef="Title">
                <th mat-header-cell *matHeaderCellDef class="table_header">Title</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.title}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-non-matches-person-title" [(ngModel)]="element.title" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Email">
                <th mat-header-cell *matHeaderCellDef class="table_header">Email</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.email}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-non-matches-person-email" [(ngModel)]="element.email" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Alt Email">
                <th mat-header-cell *matHeaderCellDef class="table_header">Alt Email</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.altEmail}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text"  name="wordpress-non-matches-person-altemail" [(ngModel)]="element.email" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Direct Phone">
                <th mat-header-cell *matHeaderCellDef class="table_header">Direct Phone</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.directPhone}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-non-matches-person-directphone" [(ngModel)]="element.directPhone" class="edit-fields">
                  </div>
                </td>
              </ng-container>


              <ng-container matColumnDef="Primary Company">
                <th mat-header-cell *matHeaderCellDef class="table_header">Primary Company</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  {{element.primaryCompany}}
                </td>
              </ng-container>

              <ng-container matColumnDef="Primary Co ID">
                <th mat-header-cell *matHeaderCellDef class="table_header">Primary Co ID</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.companyId}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-non-matches-person-CEXSubStatus" [(ngModel)]="element.companyId" class="edit-fields" (ngModelChange)="ClearCompanyName(element.personId)">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="CEXSubStatus">
                <th mat-header-cell *matHeaderCellDef class="table_header">CEX Sub Status</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.cexSubStatus}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-non-matches-person-CEXSubStatus" [(ngModel)]="element.cexSubStatus" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="CBMSubStatus">
                <th mat-header-cell *matHeaderCellDef class="table_header">CBM Sub Status</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.cbmSubStatus}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-non-matches-person-CBMSubStatus" [(ngModel)]="element.cbmSubStatus" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Shipping Add1">
                <th mat-header-cell *matHeaderCellDef class="table_header">Shipping Add1</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.primaryAddress1}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-non-matches-person-primaryaddress1" [(ngModel)]="element.primaryAddress1" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="Shipping Add2">
                <th mat-header-cell *matHeaderCellDef class="table_header">Shipping Add2</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.primaryAddress2}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-non-matches-person-primaryaddress2" [(ngModel)]="element.primaryAddress2" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="Shipping City">
                <th mat-header-cell *matHeaderCellDef class="table_header">Shipping City</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  {{element.primaryCity}}
                </td>
              </ng-container>

              <ng-container matColumnDef="Shipping State">
                <th mat-header-cell *matHeaderCellDef class="table_header">Shipping State</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.primaryState}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'">
                    <select class="dropdown-address" [(ngModel)]="element.primaryState">
                      <option value="{{not_found_primaryState}}">{{not_found_primaryState}}</option>
                      <option *ngFor="let state of states"  value="{{state.stateName}}" selected="state">
                        {{state.stateName}}
                      </option>
                    </select>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="Shipping Country">
                <th mat-header-cell *matHeaderCellDef class="table_header">Shipping Country</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.primaryCountry}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'">
                    <select class="dropdown-address" [(ngModel)]="element.primaryCountry">
                      <option value="{{not_found_country}}">{{not_found_country}}</option>
                      <option *ngFor="let country of countries"  value="{{country.countryName}}" selected="country">
                        {{country.countryName}}
                      </option>
                    </select>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Shipping Zipcode">
                <th mat-header-cell *matHeaderCellDef class="table_header">Shipping Zipcode</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.primaryZip}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-non-matches-person-primaryzipcode" [(ngModel)]="element.primaryZip" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Alternate Address Preferred">
                <th mat-header-cell *matHeaderCellDef class="table_header">Alternate Address Preferred</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.alternateAddressPreferred=='1'">
                    <div *ngIf="element.actionType!='Edit'">
                      True
                    </div>
                  </div>
                  <div *ngIf="element.alternateAddressPreferred=='0'">
                    <div *ngIf="element.actionType!='Edit'">
                      False
                    </div>
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text"  [(ngModel)]="element.alternateAddressPreferred" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Alternate Address Type">
                <th mat-header-cell *matHeaderCellDef class="table_header">Alternate Address Type</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.alternateAddressType}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="dropdown-address">
                    <input type="text"  [(ngModel)]="element.alternateAddressType" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="CEODigitalAndPrint">
                <th mat-header-cell *matHeaderCellDef class="table_header">CEO Digital & Print</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.ceoDigitalAndPrint}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text"  [(ngModel)]="element.ceoDigitalAndPrint" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="CEODigitalOnly">
                <th mat-header-cell *matHeaderCellDef class="table_header">CEO Digital Only</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.ceoDigitalOnly}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text"  [(ngModel)]="element.ceoDigitalOnly" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="CBMDigitalAndPrint">
                <th mat-header-cell *matHeaderCellDef class="table_header">CBM Digital & Print</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.cbmDigitalAndPrint}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text"  [(ngModel)]="element.cbmDigitalAndPrint" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="CBMDigitalOnly">
                <th mat-header-cell *matHeaderCellDef class="table_header">CBM Digital Only</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.cbmDigitalOnly}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text"  [(ngModel)]="element.cbmDigitalOnly" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="CEN">
                <th mat-header-cell *matHeaderCellDef class="table_header">CEN</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.cen}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-non-match-person-cen" [(ngModel)]="element.cen" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="SEN">
                <th mat-header-cell *matHeaderCellDef class="table_header">SEN</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.sen}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-non-match-person-sen" [(ngModel)]="element.sen" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="CEO100">
                <th mat-header-cell *matHeaderCellDef class="table_header">CEO100</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.ceO100}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-non-match-person-ceo100" [(ngModel)]="element.ceO100" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="CFO100">
                <th mat-header-cell *matHeaderCellDef class="table_header">CFO100</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.cfO100}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-non-match-person-cfo100" [(ngModel)]="element.cfO100" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="NextLevelLeaders">
                <th mat-header-cell *matHeaderCellDef class="table_header">NLL</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.nextLevelLeaders}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-non-match-person-nll" [(ngModel)]="element.nextLevelLeaders" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="CEBI">
                <th mat-header-cell *matHeaderCellDef class="table_header">CEBI</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.cebi}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-non-match-person-cebi" [(ngModel)]="element.cebi" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="CBMNetwork">
                <th mat-header-cell *matHeaderCellDef class="table_header">CBM Network</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.cbmNetwork}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-cebi" [(ngModel)]="element.cbmNetwork" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="DirectorFundamentals">
                <th mat-header-cell *matHeaderCellDef class="table_header">Director Fundamentals</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.directorFundamentals}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-person-cebi" [(ngModel)]="element.directorFundamentals" class="edit-fields">
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="ACCD">
                <th mat-header-cell *matHeaderCellDef class="table_header">ACCD</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.accd}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" [(ngModel)]="element.accd" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Revenue">
                <th mat-header-cell *matHeaderCellDef class="table_header">Anual Revenue BPA</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.revenue}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-non-matches-person-revenue" [(ngModel)]="element.revenue" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Ownership">
                <th mat-header-cell *matHeaderCellDef class="table_header">Ownership Type BPA</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.ownership}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-non-matches-person-ownership" [(ngModel)]="element.ownership" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="OwnershipSubType">
                <th mat-header-cell *matHeaderCellDef class="table_header">Ownership Sub Type</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.ownershipSubType}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-non-matches-person-ownershipSubType" [(ngModel)]="element.ownershipSubType" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Employees">
                <th mat-header-cell *matHeaderCellDef class="table_header">Number of Employees BPA</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.employees}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-non-matches-person-employees" [(ngModel)]="element.employees" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Industry">
                <th mat-header-cell *matHeaderCellDef class="table_header">Business/ Industry BPA</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.industry}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text" name="wordpress-non-matches-person-industry" [(ngModel)]="element.industry" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="OtherIndustry">
                <th mat-header-cell *matHeaderCellDef class="table_header">Other Industry</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.otherIndustry}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text"  name="wordpress-non-matches-person-otherIndustry" [(ngModel)]="element.otherIndustry" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="BPAQualData">
                <th mat-header-cell *matHeaderCellDef class="table_header">Qualification Date</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.bpaQualDate}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text"  name="wordpress-non-matches-person-bpaQualDate" [(ngModel)]="element.bpaQualDate" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="BPAJobTitle">
                <th mat-header-cell *matHeaderCellDef class="table_header">BPA Job Title</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.bpaJobTitle}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text"  name="wordpress-non-matches-person-bpaJobTitle" [(ngModel)]="element.bpaJobTitle" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="BPAQualSource">
                <th mat-header-cell *matHeaderCellDef class="table_header">BPA Qual Source</th>
                <td mat-cell *matCellDef="let element; let row"  [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.qualificationSource}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text"  [(ngModel)]="element.qualificationSource" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="HowVerified">
                <th mat-header-cell *matHeaderCellDef class="table_header">How Verified?</th>
                <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.howVerified}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'" class="input-box">
                    <input type="text"  [(ngModel)]="element.howVerified" class="edit-fields">
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Active Status">
                <th mat-header-cell *matHeaderCellDef class="table_header">Active Status</th>
                <td mat-cell *matCellDef="let element; let row" [ngClass]="{'text-color': row.activeStatus == false}">
                  <div *ngIf="element.actionType!='Edit'">
                    {{element.activeStatus}}
                  </div>
                  <div *ngIf="element.actionType=='Edit'">
                    <select class="table-select" [(ngModel)]="element.activeStatus">
                      <option value="{{not_found_activeStatus}}">{{not_found_activeStatus}}</option>
                      <option *ngFor="let status of active_status"  value="{{status}}" selected="status">
                        {{status}}
                      </option>
                    </select>
                  </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row_darken" [ngClass]="{'gray-row': row.activeStatus == false}" ></tr>
            </table>
          </div>
        </div>
      </div>
      <p></p>
    </div>
    <div class="paginator-wp">
      <div>
        <div *ngIf="currentPage < totalPages; else lastPage">{{fetchData * currentPage - fetchData + 1}} - {{fetchData * currentPage}}
          from {{notMatchedWordpress}}</div>
        <ng-template #lastPage>{{fetchData * currentPage - fetchData + 1}} - {{notMatchedWordpress}} from {{notMatchedWordpress}}</ng-template>
      </div>
      <div class="back" (click)="goToFirstPage('nonMatchesWordpress')"> <<</div>
      <div class="back" (click)="previousPage('nonMatchesWordpress')"> <</div>
      <div class="current-page">Current Page:  {{currentPage}}</div>
      <div  *ngIf="currentPage < totalPages" class="forward" (click)="nextPage('nonMatchesWordpress')"> > </div>
      <div  *ngIf="currentPage < totalPages" class="forward" (click)="goToLastPage('nonMatchesWordpress')"> >></div>
      <div  *ngIf="currentPage == totalPages" style="margin-right: 5vh" class="forward"></div>
    </div>
  </div>
</div>


<div *ngIf="selectedDataType === 'BoardDirector'">
  <app-board-directors [section]="'stage'"></app-board-directors>
</div>
</div>
</body>

