<app-navbar></app-navbar>
<h1>
    <a class="black-box">
        Potential Delisted Companies
    </a>
    <a *ngIf="environment==='Production'" class="page-env prod" >PRODUCTION</a>
    <a *ngIf="environment==='Development'" class="page-env dev" >DEVELOPMENT</a>
</h1>
<div class="my-overlay" *ngIf="showOverlay">
    <div class="spinner">
        <span class="spinner-inner-1"></span>
        <span class="spinner-inner-2"></span>
        <span class="spinner-inner-3"></span>
    </div>
</div>
<div class="container-log">
    <div class="companies-table">
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
            <ng-container matColumnDef="reviewed">
                <th mat-header-cell *matHeaderCellDef class="table_header">
                   Reviewed?
                </th>
                <td mat-cell *matCellDef="let element">
                    <button (click)="markAsReviewed(element)" mat-icon-button  mat-mini-fab color="">
                    <mat-icon>clear</mat-icon>
                    </button>
                </td>
            </ng-container>
            <ng-container matColumnDef="createdDate" style="width: 120px">
                <th mat-header-cell *matHeaderCellDef class="table_header">
                    <div> Date Added</div>
                    <div>
                        <input (ngModelChange)="searchCompanies($event)" [(ngModel)]="dataFilter" class="date-added-sort "[ngModelOptions]="{standalone: true}" type="date">
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.createdDate | date: 'MM/dd/yyyy'}}
                </td>
            </ng-container>
            <ng-container matColumnDef="ipoDate">
                <th mat-header-cell *matHeaderCellDef class="table_header">
                    IPO Date
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.ipoDate | date: 'MM/dd/yyyy'}}
                </td>
            </ng-container>
            <ng-container matColumnDef="companyId">
                <th mat-header-cell *matHeaderCellDef class="table_header">
                    Company Id
                </th>
                <td mat-cell *matCellDef="let element">
                    <a (click)="linkToCeo(element['companyId'])">
                        {{element['companyId']}}
                    </a>
                </td>
            </ng-container>
            <ng-container matColumnDef="{{column}}" *ngFor="let column of filteredColumns">
                <th mat-header-cell *matHeaderCellDef class="table_header"> {{getTableHeaderToDisplay(column)}} </th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="column === 'cikId' && element.cikId">
                        <a (click)="linkToCeo(element['companyId'])">
                            {{element[column]}}
                        </a>
                    </div>
                    <div *ngIf="column !== 'cikId'">
                        {{element[column]}}
                    </div>

                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row_darken" ></tr>
        </table>

    </div>
    <mat-paginator [pageSizeOptions]="[50, 100]"
                          [pageIndex]="pageNumber"
                          [pageSize]="pageSize"
                          [length]="totalSize"
                          [showFirstLastButtons] = true
                          (page)="onPageFired($event)"></mat-paginator>

</div>