<app-navbar></app-navbar>
<body>
<button id="succeeded_snack" class="snack_button" at-raised-button color="primary" (click)="snackbar_SucceededPost()">
    Snackbar CSS Styles
</button>
<button id="failed_snack" class="snack_button" at-raised-button color="primary" (click)="snackbar_FailedPost()">
    Snackbar CSS Styles
</button>
<div class="my-overlay" *ngIf="showOverlay">
    <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
</div>
<h1>
    <a class="black-box">
        WordPress Log
    </a>
    <a *ngIf="environment==='Production'" class="page-env prod" >PRODUCTION</a>
    <a *ngIf="environment==='Development'" class="page-env dev" >DEVELOPMENT</a>
</h1>
<table>
    <tr class="full-width">
        <td>
            <p class="check-jobs" (click)="showRunningJobs()">
                Check running jobs
                <img id="research-running-jobs" src="assets/img/down_simple_arrow.svg" alt="down_icon"
                     class="icon-right">
            </p>
            <div *ngIf="are_jobsVisible" id="running-jobs" class="running-jobs">
                <div *ngIf="jobs">
                    <p *ngIf="time_last_job_started !== ''">Last job started at: {{time_last_job_started}}</p>
                    <p>There are {{jobs.length}} job(s) running</p>
                    <div *ngFor="let job of jobs">
                        <p>
                            Job type: {{job.type}}
                        </p>
                        <p>
                            Job state: {{job.state}}
                        </p>
                        <button (click)="deleteJob(job.key.toString())" class="button" style="margin: 0 0 0 1%">Delete
                        </button>
                        <p>--------------------------------------------------------------</p>
                    </div>
                </div>
            </div>
            <div class="tabs">
                <button (click)="selectTableLog('ChiefExecutive')" id="chief-results" class="button">Chief Executive Log</button>
                <button (click)="selectTableLog('BoardMembers')"  id="board-results" class="button">Board Members Log</button>
            </div>
        </td>
    </tr>
</table>
<div class="main-wordpress" *ngIf="tabCategory=='ChiefExecutive'">
    <div class="table-content" >
        <button  class="start-job-btn" (click)="startWordpressApi()"> Start Processing Data</button>
        <table class="wordpress-log" mat-table [dataSource]="wordpressData">
            <ng-container matColumnDef="WordPressId">
                <th mat-header-cell *matHeaderCellDef class="table_header-wp"> WordPress Id </th>
                <td class="wpId" mat-cell *matCellDef="let profile" (click)="LinkToWP(profile.sourceSite,profile.wpId)"> {{profile.wpId}}
                </td>
            </ng-container>
            <ng-container matColumnDef="Ceo360Id">
                <th mat-header-cell *matHeaderCellDef class="table_header-wp"> Mapped to Ceo360 Id </th>
                <td mat-cell *matCellDef="let profile" >
                    <div  class="ceoId" *ngIf="profile.ceoId" (click)="LinkToCeo(profile.ceoId)">
                        {{profile.ceoId}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="Imported">
                <th mat-header-cell *matHeaderCellDef class="table_header-wp"> Imported in Amplify </th>
                <td mat-cell *matCellDef="let profile" >
                    <div *ngIf="profile.status== true && profile.ceoId==null && (profile.duplicateProfile == undefined || profile.duplicateProfile == false)">
                        Imported
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="Status">
                <th mat-header-cell *matHeaderCellDef class="table_header-wp"> Status </th>
                <td mat-cell *matCellDef="let profile" >
                    <div *ngIf="profile.status== true">
                        Done
                    </div>
                    <div *ngIf="profile.status!= true">
                        Waiting to be processed
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="Duplicate">
                <th mat-header-cell *matHeaderCellDef class="table_header-wp">Duplicate profile  </th>
                <td mat-cell *matCellDef="let profile" >
                    <div *ngIf="profile.duplicateProfile!=undefined">
                        <div *ngIf="profile.duplicateProfile== true"> True</div>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="CeoDigitalAndPrint" >
                <th mat-header-cell *matHeaderCellDef class="table_header-wp">CEO Digital & Print  </th>
                <td mat-cell *matCellDef="let profile" >
                    <div *ngIf="profile.ceoDigitalAndPrint!=undefined">
                        <div  *ngIf="profile.ceoDigitalAndPrint==true">checked</div>
                        <div  *ngIf="profile.ceoDigitalAndPrint==false">unchecked</div>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="CeoDigitalOnly">
                <th mat-header-cell *matHeaderCellDef class="table_header-wp">CEO Digital Only  </th>
                <td mat-cell *matCellDef="let profile" >
                    <div *ngIf="profile.ceoDigitalOnly!=undefined">
                        <div  *ngIf="profile.ceoDigitalOnly==true">checked</div>
                        <div  *ngIf="profile.ceoDigitalOnly==false">unchecked</div>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="CbmDigitalAndPrint">
                <th mat-header-cell *matHeaderCellDef class="table_header-wp">CBM Digital & Print </th>
                <td mat-cell *matCellDef="let profile" >
                    <div *ngIf="profile.cbmDigitalAndPrint!=undefined">
                        <div  *ngIf="profile.cbmDigitalAndPrint==true">checked</div>
                        <div  *ngIf="profile.cbmDigitalAndPrint==false">unchecked</div>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="CbmDigitalOnly">
                <th mat-header-cell *matHeaderCellDef class="table_header-wp">CBM Digital Only  </th>
                <td mat-cell *matCellDef="let profile" >
                    <div *ngIf="profile.cbmDigitalOnly!=undefined">
                        <div  *ngIf="profile.cbmDigitalOnly==true">checked</div>
                        <div  *ngIf="profile.cbmDigitalOnly==false">unchecked</div>
                    </div>
                </td>
            </ng-container>
            <ng-container  matColumnDef="AltWordpressId" >
                <th style="padding:2px" mat-header-cell *matHeaderCellDef class="table_header-wp">Found Alt WP Id  </th>
                <td  mat-cell *matCellDef="let profile" >
                    <div class="wpId" *ngIf="profile.altWordpressId!=undefined" (click)="LinkToWP(profile.sourceSite,profile.altWordpressId)">
                        {{profile.altWordpressId}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="DateAdded">
                <th mat-header-cell *matHeaderCellDef class="table_header-wp date-column">
                    <div>Date added</div>
                    <input (ngModelChange)="filterData=$event" (change)="filterByDate()" [ngModel]="filterData" class="date-added-sort" type="date"></th>
                <td mat-cell *matCellDef="let profile" >
                    <div>
                        <div> {{profile.createdDate | date:'MM/dd/yyyy hh:mm:ss'}}</div>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
        </table>
    </div>
    <div class="paginator-wp" *ngIf="wordpressData.length>0">
        <div>
            <div *ngIf="currentPage < totalPages; else lastPage">{{fetchData * currentPage - fetchData + 1}} - {{fetchData * currentPage}}
                from {{totalCounts}}</div>
            <ng-template #lastPage>{{fetchData * currentPage - fetchData + 1}} - {{totalCounts}} from {{totalCounts}}</ng-template>
        </div>
        <div class="back" (click)="goToFirstPage()"> <<</div>
        <div class="back" (click)="previousPage()"> <</div>
        <div class="current-page">Current Page:  {{currentPage}}</div>
        <div  *ngIf="currentPage < totalPages" class="forward" (click)="nextPage()"> > </div>
        <div  *ngIf="currentPage < totalPages" class="forward" (click)="goToLastPage()"> >></div>
        <div  *ngIf="currentPage == totalPages" style="margin-right: 5vh" class="forward"></div>
    </div>
    <div class="no-data-wp" *ngIf="wordpressData.length==0">
        No data available
    </div>
</div>
<div class="alt-wordpress" *ngIf="tabCategory && tabCategory!=='ChiefExecutive'">
    <div class="table-content" >
        <button  class="start-job-btn" (click)="startBoardMembersApi()"> Start Processing Data</button>
        <table class="wordpress-log" mat-table [dataSource]="altWordpressData">
            <ng-container matColumnDef="WordPressId">
                <th mat-header-cell *matHeaderCellDef class="table_header-wp"> Board WP Id </th>
                <td class="wpId" mat-cell *matCellDef="let profile" (click)="LinkToWP(profile.sourceSite,profile.wpId)"> {{profile.wpId}}
                </td>
            </ng-container>
            <ng-container matColumnDef="Ceo360Id">
                <th mat-header-cell *matHeaderCellDef class="table_header-wp"> Mapped to Ceo360 Id </th>
                <td mat-cell *matCellDef="let profile" >
                    <div  class="ceoId" *ngIf="profile.ceoId" (click)="LinkToCeo(profile.ceoId)">
                        {{profile.ceoId}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="Imported">
                <th mat-header-cell *matHeaderCellDef class="table_header-wp"> Imported in Amplify </th>
                <td mat-cell *matCellDef="let profile" >
                    <div *ngIf="profile.status== true && profile.ceoId==null && (profile.duplicateProfile == undefined || profile.duplicateProfile == false)">
                        Imported
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="Status">
                <th mat-header-cell *matHeaderCellDef class="table_header-wp"> Status </th>
                <td mat-cell *matCellDef="let profile" >
                    <div *ngIf="profile.status== true">
                        Done
                    </div>
                    <div *ngIf="profile.status!= true">
                        Waiting to be processed
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="Duplicate">
                <th mat-header-cell *matHeaderCellDef class="table_header-wp">Duplicate profile  </th>
                <td mat-cell *matCellDef="let profile" >
                    <div *ngIf="profile.duplicateProfile!=undefined">
                        <div *ngIf="profile.duplicateProfile== true"> True</div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="CbmNetwork">
                <th mat-header-cell *matHeaderCellDef class="table_header-wp">CBM Network  </th>
                <td mat-cell *matCellDef="let profile" >
                    <div *ngIf="profile.cbmNetwork!=undefined">
                        <div  *ngIf="profile.cbmNetwork==true">checked</div>
                        <div  *ngIf="profile.cbmNetwork==false">unchecked</div>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="ACCD">
                <th mat-header-cell *matHeaderCellDef class="table_header-wp">ACCD</th>
                <td mat-cell *matCellDef="let profile" >
                    <div *ngIf="profile.accd!=undefined">
                        <div  *ngIf="profile.accd==true">checked</div>
                        <div  *ngIf="profile.accd==false">unchecked</div>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="DirectorFundamentals">
                <th mat-header-cell *matHeaderCellDef class="table_header-wp">Director Fundamentals  </th>
                <td mat-cell *matCellDef="let profile" >
                    <div *ngIf="profile.directorFundamentals!=undefined">
                        <div  *ngIf="profile.directorFundamentals==true">checked</div>
                        <div  *ngIf="profile.directorFundamentals==false">unchecked</div>
                    </div>
                </td>
            </ng-container>
            <ng-container  matColumnDef="AltWordpressId" >
                <th style="padding:2px" mat-header-cell *matHeaderCellDef class="table_header-wp">Found Alt WP Id  </th>
                <td  mat-cell *matCellDef="let profile" >
                    <div class="wpId" *ngIf="profile.altWordpressId!=undefined" (click)="LinkToWP(profile.sourceSite,profile.altWordpressId)">
                        {{profile.altWordpressId}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="DateAdded">
                <th mat-header-cell *matHeaderCellDef class="table_header-wp date-column">
                    <div>Date added</div>
                    <input (ngModelChange)="filterData=$event" (change)="filterByDate()" [ngModel]="filterData" class="date-added-sort" type="date"></th>
                <td mat-cell *matCellDef="let profile" >
                    <div>
                        <div> {{profile.createdDate | date:'MM/dd/yyyy hh:mm:ss'}}</div>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsToDisplayAltWordpress"></tr>
            <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplayAltWordpress"></tr>
        </table>
    </div>
    <div class="paginator-wp" *ngIf="altWordpressData.length>0">
        <div>
            <div *ngIf="currentPage < totalPages; else lastPage">{{fetchData * currentPage - fetchData + 1}} - {{fetchData * currentPage}}
                from {{totalCounts}}</div>
            <ng-template #lastPage>{{fetchData * currentPage - fetchData + 1}} - {{totalCounts}} from {{totalCounts}}</ng-template>
        </div>
        <div class="back" (click)="goToFirstPage()"> <<</div>
        <div class="back" (click)="previousPage()"> <</div>
        <div class="current-page">Current Page:  {{currentPage}}</div>
        <div  *ngIf="currentPage < totalPages" class="forward" (click)="nextPage()"> > </div>
        <div  *ngIf="currentPage < totalPages" class="forward" (click)="goToLastPage()"> >></div>
        <div  *ngIf="currentPage == totalPages" style="margin-right: 5vh" class="forward"></div>
    </div>
    <div class="no-data-wp" *ngIf="altWordpressData.length==0">
        No data available
    </div>
</div>

</body>