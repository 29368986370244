import {Component, OnInit, Input, Inject} from '@angular/core';
import { ReviewComponent} from 'src/app/review/review.component';
import { HomeComponent } from 'src/app/home/home.component';
import {ImportComponent} from 'src/app/import/import.component';
import {QuarantineComponent} from 'src/app/quarantine/quarantine.component';
import {SearchComponent} from 'src/app/search/search.component';
import {NewConfigComponent} from '../new-config/new-config.component';
import {DOCUMENT} from '@angular/common';
import {AuthService} from '@auth0/auth0-angular';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
  @Input() review: ReviewComponent;
  @Input() home: HomeComponent;
  @Input() import: ImportComponent;
  @Input() quarantine: QuarantineComponent;
  @Input() search: SearchComponent;

  constructor(@Inject(DOCUMENT) public document: Document, public auth: AuthService) { }

  // auth0 data
  user: string;
  userEmail: string;
  userRole: string;
  isAdmin: boolean;
  profileJson: string = null;
  ngOnInit(): void {
      this.userRole = localStorage.getItem('userRole');
      if (this.userRole === 'admin'){
          this.isAdmin = true;
      }
      else{
          this.isAdmin = false;
      }

      // this.auth.user$.subscribe(
      //   (profile) => {
      //     (this.profileJson = JSON.parse(JSON.stringify(profile, null, 2)));
      //     // console.log('profile', profile.email);
      //     // console.log('profile', profile.name);
      //     // console.log('profile', profile['https://schemas.quickstarts.com/roles'][0]);
      //     // localStorage.setItem('user', profile.name);
      //     // localStorage.setItem('userEmail', profile.email);
      //     localStorage.setItem('userRole', profile['https://schemas.quickstarts.com/roles'][0]);
      //     this.userRole = profile['https://schemas.quickstarts.com/roles'][0];
      //     console.log('user role', this.userRole);
      //     if (this.userRole === 'admin'){
      //       this.isAdmin = true;
      //       console.log('is admin');
      //     }
      //     else{
      //       this.isAdmin = false;
      //       console.log('is not admin');
      //     }
      //   });
  }
}
