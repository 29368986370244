<body>
<div class="container">
  <div class="sub-container">
    <img src="assets/img/logo.png" alt="Logo" class="logo">
    <br>
    <p>Login to your account</p>
    <button (click)="auth.loginWithRedirect()" class="simple-button">Log in</button>
  </div>
</div>
</body>

