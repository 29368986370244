import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {response} from 'express';
import {from, fromEvent, Observable, Subscription} from 'rxjs';
import {first, switchMap} from 'rxjs/operators';
import {AuthService} from '@auth0/auth0-angular';
import {SearchFilters} from '../shared/CompanyApiData';
import {BoardDirector} from '../shared/boardDirectors/boardDirectorModel';

@Injectable({providedIn: 'root'})
export class ConfigService {
  constructor(private http: HttpClient, public auth: AuthService) {
  }

  // tslint:disable-next-line:variable-name
  backend_address = '/com';

  // tslint:disable-next-line:typedef
  getConfigurations_ImportPage() {
    return this.http.get(this.backend_address + '/api/v1/external-sources/all');
  }

  // tslint:disable-next-line:typedef
  deleteConfig_ImportPage() {
    const url = this.backend_address + '/api/v1/external-sources/delete?configurationName=';
    return url;
  }

  // tslint:disable-next-line:typedef
  getSourceData_SimpleSearch() {
    return this.http.get(this.backend_address + '/api/v1/settings/source-data');
  }

  // tslint:disable-next-line:typedef variable-name
  getFields_SimpleSearch(search_field: string) {
    return this.http.get(this.backend_address + '/api/v1/search/source-fields?dataType=' + search_field);
  }

  // tslint:disable-next-line:typedef variable-name
  getSimpleSearch(search_field: string) {
    return this.http.get(this.backend_address + '/api/v1/search/field?' + search_field);
  }

  // tslint:disable-next-line:typedef
  getDictionaries_AdvancedSearch() {
    return this.http.get(this.backend_address + '/api/v1/settings/dictionaries');
  }

  // tslint:disable-next-line:typedef variable-name
  getAdvancedSearch(search_field: string) {
    return this.http.get(this.backend_address + '/api/v1/search/parameter?' + search_field);
  }

  // tslint:disable-next-line:typedef variable-name
  getSearchDefultParameters() {
    const url1 = this.backend_address + '/api/v1/search/get-search-template?templateName=DefaultSearchParameters';

    const xhr1 = new XMLHttpRequest();
    xhr1.open('GET', url1);

    xhr1.setRequestHeader('accept', 'text/plain');

    // tslint:disable-next-line:only-arrow-functions typedef
    xhr1.onreadystatechange = function() {
      if (xhr1.readyState === 4) {
        console.log('status ' + xhr1.status);
        console.log('response' + xhr1.responseText);
        return xhr1.responseText;
      }
    };
  }

  // tslint:disable-next-line:typedef ban-types
  url_postSimpleSearchSettings() {
    return this.backend_address;
  }

  // tslint:disable-next-line:typedef variable-name
  get_QuarantineStatistics(search_field: string) {
    return this.http.get(this.backend_address + '/api/v1/quarantine/get-statistic?dataType=' + search_field);
  }
  // tslint:disable-next-line:typedef variable-name
  get_QuarantineStatisticsWordpress(search_field: string) {
    return this.http.get(this.backend_address + '/api/v1/quarantine/get-statistic-wordpress?dataType=' + search_field);
  }
  // tslint:disable-next-line:typedef variable-name
  getQuarantineStatisticsCoApi(dataType: string){
    return this.http.get(this.backend_address + '/api/v1/automatic-company-update/get-statistic-public-co?dataType=' + dataType);
  }
  // tslint:disable-next-line:typedef variable-name
  getQuarantineStatisticsCoApiNotMatched( CurrentPage: string, PageSize: string){
    // tslint:disable-next-line:max-line-length
     return this.http.get(this.backend_address + '/api/v1/automatic-company-update/not-matched?CurrentPage=' + CurrentPage + '&PageSize=' + PageSize);
  }
  // tslint:disable-next-line:typedef variable-name
  getResearchStatisticsCoApiNotMatched( CurrentPage: string, PageSize: string, researchArea: string){
    // tslint:disable-next-line:max-line-length
    return this.http.get(this.backend_address + '/api/v1/automatic-company-update/not-matched-research?CurrentPage=' + CurrentPage + '&PageSize=' + PageSize + '&researchArea=' + researchArea);
  }
  // tslint:disable-next-line:typedef variable-name
  getResearchStatisticsCoApi(dataType: string, researchArea: string){
    return this.http.get(this.backend_address + '/api/v1/automatic-company-update/get-statistic-public-co-research?dataType=' + dataType + '&researchArea=' + researchArea);
  }

  // tslint:disable-next-line:typedef variable-name
  get_ResearchStatisticsWordpress(search_field: string, researchAreaId: string) {
    // tslint:disable-next-line:max-line-length
    return this.http.get(this.backend_address + '/api/v1/research/get-statistic-wordpress?dataType=' + search_field + '&researchAreaId=' + researchAreaId);
  }

  // tslint:disable-next-line:typedef
  get_QuarantineNotMatched(DataType: string, CurrentPage: string, PageSize: string) {
    return this.http.get(this.backend_address + '/api/v1/quarantine/not-matched?dataType=' + DataType +
      '&CurrentPage=' + CurrentPage + '&PageSize=' + PageSize);
  }
  // tslint:disable-next-line:typedef
  get_ResearchNotMatched(DataType: string, CurrentPage: string, PageSize: string, researchAreaId: string) {
    return this.http.get(this.backend_address + '/api/v1/research/not-matched?dataType=' + DataType +
        '&CurrentPage=' + CurrentPage + '&PageSize=' + PageSize + '&researchAreaId=' + researchAreaId);
  }
  // tslint:disable-next-line:typedef
  get_QuarantineNotMatchedWordpress(DataType: string, CurrentPage: string, PageSize: string) {
    return this.http.get(this.backend_address + '/api/v1/quarantine/not-matched-wordpress?dataType=' + DataType +
        '&CurrentPage=' + CurrentPage + '&PageSize=' + PageSize);
  }
  // tslint:disable-next-line:typedef
  get_ResearchNotMatchedWordpress(DataType: string, CurrentPage: string, PageSize: string, researchAreaId: string) {
    return this.http.get(this.backend_address + '/api/v1/research/not-matched-wordpress?dataType=' + DataType +
        '&CurrentPage=' + CurrentPage + '&PageSize=' + PageSize + '&researchAreaId=' + researchAreaId);
  }
  // tslint:disable-next-line:typedef
  get_QuarantineAllData(DataType: string, CurrentPage: string, PageSize: string) {
    return this.http.get(this.backend_address + '/api/v1/quarantine/all-data?dataType=' + DataType +
      '&CurrentPage=' + CurrentPage + '&PageSize=' + PageSize);
  }
  // tslint:disable-next-line:typedef
  get_QuarantineOnlyFileData(DataType: string) {
    return this.http.get(this.backend_address + '/api/v1/quarantine/file-data?dataType=' + DataType);
  }
  // tslint:disable-next-line:typedef
  get_ResearchAllData(DataType: string, CurrentPage: string, PageSize: string, researchAreaId: string) {
    return this.http.get(this.backend_address + '/api/v1/research/all-data?dataType=' + DataType +
        '&CurrentPage=' + CurrentPage + '&PageSize=' + PageSize + '&researchAreaId=' + researchAreaId);
  }

  // tslint:disable-next-line:typedef
  get_QuarantineGroupData(GroupId: string, datatype: string) {
    return this.http.get(this.backend_address + '/api/v1/quarantine/get-group?dataType=' + datatype + '&groupId=' + GroupId);
  }

  // tslint:disable-next-line:typedef
  post_ReviewStart(DataType: string) {
    const url = this.backend_address + '/api/v1/review/start?dataType=' + DataType;
    return  url;
  }

  // tslint:disable-next-line:typedef
  get_ReviewStatistics(Datatype: string) {
    return this.http.get(this.backend_address + '/api/v1/review/get-statistic?dataType=' + Datatype);
  }

  // tslint:disable-next-line:typedef
  get_ReviewAllData(DataType: string, CurrentPage: string, PageSize: string) {
    return this.http.get(this.backend_address + '/api/v1/review/all-data?dataType=' + DataType +
      '&CurrentPage=' + CurrentPage + '&PageSize=' + PageSize);
  }

  // tslint:disable-next-line:typedef
  get_ReviewGroupData(DataType: string, GroupId: string) {
    return this.http.get(this.backend_address + '/api/v1/review/get-group?dataType=' + DataType + '&groupId=' + GroupId);
  }

  // tslint:disable-next-line:typedef
  get_fieldMap(dataType: string, type: string) {
    return this.http.get(this.backend_address + '/api/v1/settings/get-settings?settingName=FieldMap' + type + dataType);
  }

  // tslint:disable-next-line:typedef
  get_Match(dataType: string, type: string) {
    // tslint:disable-next-line:max-line-length
    return this.http.get(this.backend_address + '/api/v1/settings/get-settings?settingName=Match' + type + 'Data' + dataType + 'Parameters');
  }
  // tslint:disable-next-line:typedef
  url_post_PocessdMoveMultipleGroups(sourceDataType: string){
    return (this.backend_address + '/api/v1/research/move-multiple-groups?sourceDataType=' + sourceDataType);
  }
  // tslint:disable-next-line:typedef
  url_post_WordpressData(){
    return (this.backend_address + '/api/v1/wordpress/upload-wordpress-data');
  }
  // tslint:disable-next-line:typedef
  url_post_Externaldata() {
    return (this.backend_address + '/api/v1/import/upload-external-data?configurationName=');
  }

  // tslint:disable-next-line:typedef
  url_post_SetSettings() {
    return (this.backend_address + '/api/v1/settings/set-setting');
  }

  // tslint:disable-next-line:typedef
  url_post_ExternalSource() {
    return (this.backend_address + '/api/v1/external-sources/set');
  }
  // tslint:disable-next-line:typedef
  url_post_KickAllData(datatype: string) {
    return (this.backend_address + '/api/v1/quarantine/kick-all-data?dataType=' + datatype );
  }
  // tslint:disable-next-line:typedef
  url_post_QuarantineMatchByID(datatype: string, groupId: string, tableName: string, researchAreaId: string) {
    return (this.backend_address + '/api/v1/quarantine/match-by-id-' + datatype + '?groupId=' + groupId + '&tableName=' + tableName + '&researchAreaId=' + researchAreaId);
  }
  // tslint:disable-next-line:typedef
  url_post_ReviewProcessGroup(datatype: string) {
    return (this.backend_address + '/api/v1/review/process-' + datatype + '-group');
  }

  // tslint:disable-next-line:typedef
  url_post_QuarantineProcessGroup(datatype: string, researchAreaId: string) {
    return (this.backend_address + '/api/v1/quarantine/process-' + datatype + '-group?researchAreaId=' + researchAreaId);
  }

  // tslint:disable-next-line:typedef
  url_post_ResearchProcessGroup(datatype: string) {
    return (this.backend_address + '/api/v1/research/process-' + datatype + '-group');
  }
  // tslint:disable-next-line:typedef
  url_post_ResearchChanges(userResearch: string) { // userId
     return (this.backend_address + '/api/v1/settings/process-research-changes?userResearch=' + userResearch);
   // return (this.backend_address + '/api/v1/settings/process-research-changes?userId=' + userResearch + 'idList';
  }
  // tslint:disable-next-line:typedef
  get_Jobs() {
    return this.http.get(this.backend_address + '/api/v1/Job/get');
  }
  // tslint:disable-next-line:typedef
  get_synchronization() {
    const url = this.backend_address + '/api/v1/review/synchronization';
    return url;
  }
  // tslint:disable-next-line:typedef
  get_matching(datatype: string) {
    const url = this.backend_address + '/api/v1/review/matchImportedDataManual?dataType=' + datatype;
    return url;
  }
  async getXMLRequestHeader(): Promise<string>{
    try {
      // Convert Observable to Promise
      const authToken = await this.auth.getAccessTokenSilently().toPromise();
      console.log(authToken);
      // Construct XML request header
      const xmlRequestHeader = `Bearer ${authToken}`;
      console.log(xmlRequestHeader);
      return xmlRequestHeader;
    } catch (error) {
      console.error('Error obtaining access token:', error);
      throw error;
    }
  }
  // tslint:disable-next-line:typedef
  get_MemberClicks() {
    const url = this.backend_address + '/api/v1/memberClicks/start-import';
    return url;
  }
  // tslint:disable-next-line:typedef
  get_JobsURL() {
    let url = this.backend_address;
    url += '/api/v1/Job/get';
    return url;
  }
  // tslint:disable-next-line:typedef
  delete_Job(jobId: string){
    const url = this.backend_address + '/api/v1/Job/delete?jobId=' + jobId;
    return url;
  }

  // tslint:disable-next-line:typedef variable-name
  get_Archive(imported_datatype: string, filename: string){
    return this.http.get(this.backend_address + '/api/v1/quarantine/get-archive?table=' + imported_datatype  + '&filename=' + filename);
  }
  // tslint:disable-next-line:typedef
  get_Archive_Original( filename: string){
    return this.http.get(this.backend_address + '/api/v1/quarantine/get-archive-original-file?filename='  + filename, { responseType: 'arraybuffer' });
  }

  // tslint:disable-next-line:typedef variable-name
  get_Archive_Combined(imported_datatype: string,  filename: string){
    // tslint:disable-next-line:max-line-length
     return this.http.get(this.backend_address + '/api/v1/quarantine/get-archive-combined-file?table=' + imported_datatype + '&filename=' + filename, { responseType: 'arraybuffer' });
  }
  // tslint:disable-next-line:typedef
  get_fileList(datatype: string){
    return this.http.get(this.backend_address + '/api/v1/quarantine/get-files-names?dataType=' + datatype);
  }
  // tslint:disable-next-line:typedef
  get_sendGroupToResearch(datatype: string, groupId: string, researchAreaId: string ){
    return this.http.get(this.backend_address + 'api/v1/research/move-group?dataType=' + datatype + '&groupId=' + groupId + '&researchAreaId=' + researchAreaId);
  }
  // tslint:disable-next-line:typedef
  get_sendAllDataToResearch(datatype: string, researchAreaId: string ){
    return this.http.get(this.backend_address + 'api/v1/research/move-all-data?dataType=' + datatype + '&researchAreaId=' + researchAreaId);
  }
  // tslint:disable-next-line:typedef
  get_sendWordpressNonMatchesToResearch(offset: number, fetchData: number, researchAreaId: string ){
    return this.http.get(this.backend_address + 'api/v1/research/move-wordpress-non-matches?CurrentPage=' + offset + '&PageSize=' + fetchData + '&researchAreaId=' + researchAreaId);
  }
  // tslint:disable-next-line:typedef
  get_sendNonMatchesToResearch(datatype: string, researchAreaId: string, offset: number, fetchData: number ){
    // tslint:disable-next-line:max-line-length
    return this.http.get(this.backend_address + 'api/v1/research/move-non-matches?dataType=' + datatype + '&researchAreaId=' + researchAreaId + '&CurrentPage=' + offset + '&PageSize=' + fetchData);
  }
  sendNonMatchesPublicCoToResearch(offset: number, fetchData: number, researchAreaId: string ): Observable<any>{
    // tslint:disable-next-line:max-line-length
    return this.http.get(this.backend_address + 'api/v1/research/move-non-matches-public-co?CurrentPage=' + offset + '&PageSize=' + fetchData + '&researchAreaId=' + researchAreaId);
  }
  // tslint:disable-next-line:typedef
  get_ResearchGroupData(groupId: string, researchAreaId: string, datatype: string){
    return this.http.get(this.backend_address + '/api/v1/research/get-group?dataType=' + datatype + '&researchAreaId=' + researchAreaId + '&groupId=' + groupId);
  }
  // tslint:disable-next-line:typedef
  get_ResearchStatistics(datatype: string, researchAreaId: string){
    return this.http.get(this.backend_address + 'api/v1/research/get-statistic?dataType=' + datatype + '&researchAreaId=' + researchAreaId);
  }
  // tslint:disable-next-line:typedef
  get_User_ResearchAreas(email: string){
    return this.http.get(this.backend_address + '/api/v1/research/get-user-researchAreas?email=' + email);
  }
  // tslint:disable-next-line:typedef
  // get_ResearchAllData(datatype: string){
  //   return this.http.get(this.backend_address + 'research/get-statistic?dataType=' + datatype);
  // }


  // tslint:disable-next-line:typedef
  get_Countries(){
    return this.http.get(this.backend_address + '/api/v1/settings/get-countries');
  }
  // tslint:disable-next-line:typedef
  get_Users(){
    return this.http.get(this.backend_address + '/api/v1/settings/get-users');
  }
  // tslint:disable-next-line:typedef
  get_User_Research(){
    return this.http.get(this.backend_address + '/api/v1/settings/get-user-research');
  }
  // tslint:disable-next-line:typedef
  get_Research(){
    return this.http.get(this.backend_address + '/api/v1/settings/get-research');
  }
  // tslint:disable-next-line:typedef
  get_States(){
    return this.http.get(this.backend_address + '/api/v1/settings/get-states');
  }
  // tslint:disable-next-line:typedef
  get_Wordpress_Table(offset: number, fetchData: number, filterData: string){
    return this.http.get(this.backend_address + '/api/v1/wordpress/get-wordpress-table?offset=' + offset + '&fetchData=' + fetchData + '&filterDate=' + filterData);
  }
  getAltWordpressTable(wordpressSource: string, offset: number, fetchData: number, filterData: string): Observable<any>{
    const params = new HttpParams()
        .set('wordpressSource', wordpressSource)
        .set('offset', offset.toString())
        .set('fetchData', fetchData.toString())
        .set('filterDate', filterData);
    return this.http.get(this.backend_address + '/api/v1/wordpress/get-alt-wordpress-table', { params });
  }
  // tslint:disable-next-line:typedef
  get_MemberClicks_Table(offset: number, fetchData: number, filterData: string){
    return this.http.get(this.backend_address + '/api/v1/memberClicks/get-memberClicks-table?offset=' + offset + '&fetchData=' + fetchData + '&filterDate=' + filterData);
  }

  // tslint:disable-next-line:typedef
  getCompaniesApiTable(offset: number, fetchData: number, filters: SearchFilters): Observable<any>{
    const url = this.backend_address + '/api/v1/automatic-company-update/get-table?offset=' + offset + '&fetchData=' + fetchData;
    const headers =  new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(url, JSON.stringify(filters), { headers });
  }
  getMappingsInformation(date: string): Observable<any>{
    return this.http.get(this.backend_address + '/api/v1/automatic-company-update/get-mappings-information?date=' + date);
  }

  writeCeoIdInWordpress(wordpressId: number, ceoId: number, userId: number, token: string , sourceId: number): Promise<any>{
    const headers: Headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');
    headers.set('Authorization', `Bearer ${token}`);
    const toSend = {WordpressId: wordpressId, CeoId: ceoId, UserId: userId , SourceId: sourceId};
    const request: RequestInfo = new Request(this.backend_address + '/api/v1/wordpress/write-ceo-id', {
      // We need to set the `method` to `POST` and assign the headers
      method: 'POST',
      headers,
      body: JSON.stringify(toSend)
    });
    return fetch(request);
  }
  writeCeoIdInMemberClicks(memberClicksId: number, ceoId: number ,  token: string ): Promise<any>{
    const headers: Headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');
    headers.set('Authorization', `Bearer ${token}`);
    const request: RequestInfo = new Request(this.backend_address + `/api/v1/memberClicks/write-ceo-id?ceoId=${ceoId}&memberClicksId=${memberClicksId}`, {
      // We need to set the `method` to `POST` and assign the headers
      method: 'POST',
      headers,
    });
    return fetch(request);
  }
  startWordPressApi(token: string): Promise<any>{
    const headers: Headers = new Headers();
    headers.set('Accept', 'application/json');
    headers.set('Authorization', `Bearer ${token}`);
    const request: RequestInfo = new Request(this.backend_address + `/api/v1/wordpress/trigger-wordpress-Job`, {
      // We need to set the `method` to `POST` and assign the headers
      method: 'POST',
      headers,
    });
    return fetch(request);
  }
  startBoardMembersApi(token: string): Promise<any>{
    const headers: Headers = new Headers();
    headers.set('Accept', 'application/json');
    headers.set('Authorization', `Bearer ${token}`);
    const request: RequestInfo = new Request(this.backend_address + `/api/v1/wordpress/parse-board-members-data-job`, {
      // We need to set the `method` to `POST` and assign the headers
      method: 'POST',
      headers,
    });
    return fetch(request);
  }
  getAuthToken(): Observable<any>{
    return this.auth.getAccessTokenSilently();
  }

  getBoardDirectorsList(pageNumber: number, pageSize: number, researchId: number = null ): Observable<any>{
    const url = this.backend_address + `/api/v1/board-directors/get-board-list?pageNumber=${pageNumber}&pageSize=${pageSize}&researchId=${researchId}`;
    return this.http.get(url);
  }
  getBoardDirectorsLog(pageNumber: number, pageSize: number, filterDate: string ): Observable<any>{
    const url = this.backend_address + `/api/v1/board-directors/get-board-log?pageNumber=${pageNumber}&pageSize=${pageSize}&filterDate=${filterDate}`;
    return this.http.get(url);
  }
  getBoardDirectorsArchive(pageNumber: number, pageSize: number, filterDate: string ): Observable<any>{
    const url = this.backend_address + `/api/v1/board-directors/get-board-archive?pageNumber=${pageNumber}&pageSize=${pageSize}&filterDate=${filterDate}`;
    return this.http.get(url);
  }
  getPotentialDelistedCompanies(pageNumber: number, pageSize: number, filterDate: string ): Observable<any>{
    const url = this.backend_address + `/api/v1/automatic-company-update/get-delisted-companies?pageNumber=${pageNumber}&pageSize=${pageSize}&filterDate=${filterDate}`;
    return this.http.get(url);
  }
  markDelistedCompanyAsReviewed(id: number): Observable<any>{
    const url = this.backend_address + `/api/v1/automatic-company-update/mark-as-reviewed?id=` + id;
    return this.http.put(url, {});
  }
  getBoardMatch(board: BoardDirector, fuzzyParameters: any): Observable<any>{
    const url = this.backend_address + '/api/v1/board-directors/get-board-match';
    return this.http.post<BoardDirector>(url, {board, fuzzyParameters});
  }
  matchByPersonId(personId: number, importId: number, tableName: string): Observable<any>{
    const url = this.backend_address + `/api/v1/board-directors/match-by-personId?personId=${personId}&importId=${importId}&tableName=${tableName}`;
    return this.http.get<BoardDirector[]>(url);
  }
  addOrUpdateBoard(boardData: BoardDirector): Observable<any>{
    const url = this.backend_address + '/api/v1/board-directors/process-board';
    return this.http.post<BoardDirector>(url, boardData);
  }
  proceedSelectedBoardActions(jsonToSend: string, token: string): Promise<any>{
    const url = this.backend_address + '/api/v1/board-directors/process-actions';
    const headers: Headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');
    headers.set('Authorization', `Bearer ${token}`);
    return fetch(url, {
      method: 'Post',
      body: jsonToSend,
      headers
    });
  }
  saveFuzzySettings(userEmail: string, settings: string, token: string): Promise<any>{
    const url = this.backend_address + '/api/v1/board-directors/save-settings';
    const headers: Headers = new Headers();
    const body = {
      userEmail,
      settings
    };
    headers.set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');
    headers.set('Authorization', `Bearer ${token}`);
    return fetch(url, {
      method: 'Post',
      body: JSON.stringify(body) ,
      headers
    });
  }
}
