import { Injectable } from '@angular/core';
import { environment as env } from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Actions} from '../boardDirectors/boardDirectorModel';
import {Observable} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private readonly environment: string;
  constructor(private http: HttpClient, private snackBar: MatSnackBar) {
    this.environment = env.environment;
  }
  backendAddress = '/com';
  public linkToCompany(id: number): void{
    let href = '';
    if (this.environment === 'Development'){
      href = 'https://dev.ceo360.pro/#/';
    }
    else{
      href = 'https://ceo360.pro/#/';
    }
    href += 'Company/' + id;
    const win = window.open(href, '_blank');
    win.focus();
  }
  public linkToPerson(id: number): void{
    let href = '';
    if (this.environment === 'Development'){
      href = 'https://dev.ceo360.pro/#/';
    }
    else{
      href = 'https://ceo360.pro/#/';
    }
    href += 'Person/' + id;
    const win = window.open(href, '_blank');
    win.focus();
  }
  public getActions(): Observable<Actions[]>{
     return this.http.get<Actions[]>(this.backendAddress + '/api/v1/settings/get-actions');
  }
  successfulAction(): void{
    this.snackBar.open('Your modifications have been successfully proceeded!', '', {
      duration: 1000,
      panelClass: ['succeed'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
  failedPost(): void {
    const sb = this.snackBar.open('Your modifications were NOT proceeded!', '', {
      duration: 1000,
      panelClass: ['failed'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
  filtersNotSelected(): void {
    const sb = this.snackBar.open('Please select at least one filter from dropdown!', '', {
      duration: 2000,
      panelClass: ['failed'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
}
