import {Component, OnInit, Input } from '@angular/core';
import {ConfigService} from '../config/config.service';
import {environment as env} from '../../environments/environment';
import {MatTableDataSource} from '@angular/material/table';
import {Actions, BoardDirector, Filters} from '../shared/boardDirectors/boardDirectorModel';
import {BoardMatchComponent} from '../board-match/board-match.component';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SharedService} from '../shared/sharedService/shared.service';
import {Research} from '../shared/Research';
import {WarningComponent} from '../warning/warning.component';
import {AuthService} from '@auth0/auth0-angular';
import {UsersToView} from '../shared/UsersToView';

@Component({
    selector: 'app-board-directors',
    templateUrl: './board-directors.component.html',
    styleUrls: ['./board-directors.component.scss', '../shared/helpers_scss/spinner.scss']
})
export class BoardDirectorsComponent implements OnInit {
    @Input() section: string;

    constructor(private api: ConfigService,
                public dialog: MatDialog,
                private snackBar: MatSnackBar,
                public sharedService: SharedService,
                public auth: AuthService) {
    }
    userEmail: string;
    users: UsersToView[] = [];
    boardDirectors: BoardDirector[];
    environment: string;
    dataSource;
    token: string;
    showOverlay = false;
    // tslint:disable-next-line:max-line-length
    displayedColumns: string[] = ['Actions', 'PersonId', 'FirstName', 'LastName', 'PrimaryCompany', 'PrimaryTitle', 'BoardCikId', 'BoardCompanyName', 'DirectorSince', 'I/O/N/C/L', 'Audit', 'Comp', 'Gov/Nom'];
    allFiltersAvailable: any[] = [{key: 'firstName', label: 'First Name', isSelected: true},
                                      {key: 'lastName', label: 'Last Name', isSelected: true},
                                      {key: 'boardCompanyCik', label: 'Board CIK ID', isSelected: false},
                                      {key: 'primaryCompany', label: 'Primary Company', isSelected: false}];
    actions: Actions[] = [];
    bulkActions: Actions[] = [];
    selectedFilters: Filters[] =  [{key: 'firstName', label: 'First Name',  exactMatch: true, fuzzyLevel: 100},
        {key: 'lastName', label: 'Last Name', exactMatch: true, fuzzyLevel: 100}];
    areSettingsVisible = false;
    pageNumber: number;
    pageSize: number;
    length: number;
    totalSize: number;
    research: Research[];
    bulkSendToResearch = false;
    sendRecordsToResearch = false;
    researchAreaId: number;
    currentResearchAreaId: number = null;
    bulkAction: string = null;
    showActionsBtn =  false;
    showData = false;
    tableName: string;

    ngOnInit(): void {
        console.log('this.section', this.section);
        this.api.getAuthToken().subscribe(auth => {
            this.token = auth;
        });
        this.auth.user$.subscribe(
            (profile) => {
                this.userEmail = profile.email;
            });
        this.api.get_Users().subscribe(result => {
            // @ts-ignore
            this.users = result;
            this.populateFilters();
        });
        this.environment = env.environment;
        this.pageNumber = 0;
        this.pageSize = 50;
        this.getActions();
        this.getResearch();
        this.chooseSection();
    }
    populateFilters(): void{
        const jsonSettings = this.users.find(x => x.email === this.userEmail).fuzzySettingsBoard;
        if (jsonSettings !== undefined && jsonSettings !== ''){
            this.selectedFilters = JSON.parse(jsonSettings);
        }
    }
    exactMatch(item: Filters): void{
        console.log(item);
        item.exactMatch = !item.exactMatch;
        if (item.exactMatch === true){
            this.selectedFilters.find(i => i.key === item.key).fuzzyLevel = 100;
        }
        console.log('this.allFiltersAvailable3', this.allFiltersAvailable);
    }
    fuzzyLevelChanges(item: Filters): void{
        console.log('fuzzy', item);
        if (item.fuzzyLevel === 100){
            this.selectedFilters.find(i => i.key === item.key).exactMatch = true;
        }
        else{
            this.selectedFilters.find(i => i.key === item.key).exactMatch = false;
        }
        console.log(this.selectedFilters);
        console.log('allfilters', this.allFiltersAvailable);
    }
    filterSelection(filter: any): void{
        filter.isSelected = !filter.isSelected;
        console.log(filter);
        if (filter.isSelected === true){
            const item = new Filters(filter.key, filter.label, true, 100);

            this.selectedFilters.push(item);
        }
        else{
            this.selectedFilters = this.selectedFilters.filter(item => item.key !== filter.key);
        }
    }
    saveSettings(): void{
        console.log(this.selectedFilters);
        console.log(JSON.stringify(this.selectedFilters));
        console.log(this.userEmail);
        this.api.saveFuzzySettings(this.userEmail, JSON.stringify(this.selectedFilters), this.token).then(response => {
            console.log(response);
            if (response.status === 200){
                this.snackBar.open('Settings saved', '', {
                    duration: 3000,
                    panelClass: ['succeed'],
                    horizontalPosition: 'right',
                    verticalPosition: 'top'
                });
            }
        });
    }
    chooseSection(): void{
         switch (this.section) {
             case 'stage':
                 this.getDirectors();
                 this.tableName = 'BoardAssociationsStage';
                 break;
             case 'research':
                 this.tableName = 'BoardAssociationsResearch';
                 break;
         }
    }
    showSettings(): void{
        this.areSettingsVisible = !(this.areSettingsVisible);
        if (this.areSettingsVisible){
            const img = document.getElementById('show-settings');
            img.style.transform = 'rotate(180deg)';
        }
        else{
            const img = document.getElementById('show-settings');
            img.style.transform = 'rotate(0deg)';
        }
    }
    getActions(): void
    {
        this.sharedService.getActions().subscribe((resp) => {
            if (this.section === 'stage'){
                // tslint:disable-next-line:max-line-length
                this.actions = resp.filter(item => !['selectBoard', 'mergeBoard', 'matchById', 'addPerson', 'addBoard'].includes(item.action));
                // tslint:disable-next-line:max-line-length
                this.bulkActions = resp.filter(item => !['selectBoard' , 'mergeBoard' , 'matchBoard', 'addPerson', 'matchById', 'addBoard'].includes(item.action));
            }
            else{
                // tslint:disable-next-line:max-line-length
                this.actions = resp.filter(item => !['selectBoard', 'mergeBoard', 'research', 'matchById', 'addPerson', 'addBoard'].includes(item.action));
                // tslint:disable-next-line:max-line-length
                this.bulkActions = resp.filter(item => !['selectBoard' , 'mergeBoard' , 'matchBoard', 'addPerson', 'research', 'matchById', 'addBoard'].includes(item.action));
            }
        });
    }
    getDirectors(): void {
        this.showOverlay = true;
        this.api.getBoardDirectorsList(this.pageNumber, this.pageSize, this.currentResearchAreaId).subscribe(result => {
            this.boardDirectors = result.item1;
            this.totalSize = result.item2;
            this.dataSource = new MatTableDataSource<BoardDirector>(this.boardDirectors);
            this.showOverlay = false;
            this.showData  = true;
        });
    }
    // only for ResearchArea Section
    afterResearchAreaSelection(researchId: number): void{
        console.log('research area', researchId);
        this.showOverlay = true;
        this.currentResearchAreaId = researchId;
        this.getDirectors();
    }

    matchingProfileSearch(item: BoardDirector): void {
        const dialogRef = this.dialog.open(BoardMatchComponent, {
            disableClose: true,
        });
        item.importedRecord = true;
        dialogRef.componentInstance.boardDirectorString = JSON.stringify(item);
        dialogRef.componentInstance.selectedFilters = this.selectedFilters;
        dialogRef.componentInstance.tableName = this.tableName;
        dialogRef.componentInstance.userEmail = this.userEmail;
        dialogRef.componentInstance.token = this.token;
        dialogRef.afterClosed().subscribe(resp => {
            item.action = null;
            if (resp === 'Success') {
                this.snackBar.open('Modification proceed', '', {
                    duration: 1000,
                    panelClass: ['succeed'],
                    horizontalPosition: 'right',
                    verticalPosition: 'top'
                });

            }
            this.getDirectors();
        });
    }
    LinkToCeo(id: number): void {
        this.sharedService.linkToPerson(id);
    }
    selectFilters(event: any): void {
    }
    selectResearchArea(event: any): void {
        this.researchAreaId = event.value;
    }
    selectActions(action: any, board: BoardDirector): void {
        switch (action) {
            case 'matchBoard':
                console.log(this.selectedFilters);
                board.action = null;
                if (this.selectedFilters.length > 0){
                    this.matchingProfileSearch(board);
                }
                else{
                   this.sharedService.filtersNotSelected();
                }
                break;
            case'none':
                board.action = null;
                console.log('board', board);
                break;
            case 'research':
                this.sendRecordsToResearch = true;
                break;
        }
        // if no individual action selected
        if (this.boardDirectors.filter(item => item.action === null || item.action === undefined).length === this.boardDirectors.length){
            this.showActionsBtn = false;
        }
        else{
            this.showActionsBtn = true;
        }
        if (this.boardDirectors.filter(item => item.action === 'research').length === 0){
            this.sendRecordsToResearch = false;
        }
        console.log(this.sendRecordsToResearch);
    }
    bulkSelectActions(event): void{
        console.log(event);
        console.log(this.bulkAction);
        this.showActionsBtn = true;
        const actionId = this.bulkActions.find(item => item.action === event.value).id;
        switch (event.value){
            case 'research':
                this.bulkSendToResearch = true;
                this.boardDirectors.forEach(item => {
                    item.action = 'research';
                    item.actionId = actionId;
                });
                break;
            case 'edit':
            case 'kick':
                this.boardDirectors.forEach(item => {
                    item.action = event.value;
                    item.actionId = actionId;
                });
                break;
            default:
                this.bulkSendToResearch = false;
                this.showActionsBtn = false;
                this.boardDirectors.forEach(item => {
                    item.action = null;
                    item.actionId = null;
                });
                this.bulkAction = null;
                break;
        }
        console.log(this.boardDirectors);
    }
    proceedActions(): void{
        console.log(this.bulkAction);
        if ((this.bulkSendToResearch || this.sendRecordsToResearch) && this.researchAreaId === undefined){
            this.openWarningResearch();
            return;
        }
        else{
            const directorsToReview = this.boardDirectors.filter(item => (item.action !== undefined && item.action !== null));
            const jsonToSend = {
                isBulkAction: this.bulkAction !== null ? true : false,
                researchId : this.researchAreaId,
                bulkActionName : this.bulkAction,
                pageSize : this.pageSize,
                pageNumber: this.pageNumber,
                tableName: this.tableName,
                userEmail: this.userEmail,
                directorsList: this.bulkAction === null ? directorsToReview : null,
            };
            this.api.proceedSelectedBoardActions(JSON.stringify(jsonToSend), this.token ).then(result => {
                console.log('api finished', result);
                if (result.status === 200){
                    this.sharedService.successfulAction();
                    this.getDirectors();
                }
                else{
                    this.sharedService.failedPost();
                }
            });
        }
    }
    public linkToCompany(id: number): void {
        this.sharedService.linkToCompany(id);
    }
    openWarningResearch(): void {
        const dialogRef = this.dialog.open(WarningComponent);
        dialogRef.componentInstance.action = 'Research';
        dialogRef.afterClosed().subscribe(result => {
        });
    }

    getSelectClass(value: string): string {
        switch (value) {
            case 'matchBoard':
                return 'match-action';
            case 'kick':
                return 'kick-action';
            case 'research':
                return 'research-action';
            case 'edit':
                return 'edit-action';
            default:
                return '';
        }
    }
    onPageFired(event: any): void{
        console.log(event);
        this.pageNumber = event.pageIndex;
        this.pageSize = event.pageSize;
        this.getDirectors();
    }
    getResearch(): void {
        this.api.get_Research().subscribe(res => {
            // @ts-ignore
            this.research = res;
        },
        err => {
            console.log(err);
        });
    }
}
