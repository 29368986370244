<body>
<div *ngIf="action=='DeleteJob'">
    <p>Are you sure you want to Delete this job?</p>
    <p>
        <button (click)="Response('yes')"> Yes </button>
        <button (click)="Response('no')"> No </button>
    </p>
</div>
<div *ngIf="isBulckAnction && action != 'ResearchGroup' && action != 'Research'">
    <p *ngIf="action==='AddEdit'">Are you sure you want to Add this records to ceo360? </p>
    <p *ngIf="action!=='AddEdit'">Are you sure you want to {{action}} this records ?</p>
    <p>
        <button (click)="Response('yes')"> Yes </button>
        <button (click)="Response('no')"> No </button>
    </p>
</div>
<div *ngIf="action=='DeleteRecord'">
    <p>Are you sure you want to Delete this record? </p>
    <p>
        <button (click)="Response('yes')"> Yes </button>
        <button (click)="Response('no')"> No </button>
    </p>
</div>
<div *ngIf="action=='ResearchGroup' || action=='Research'">
    <p>Please select a Research Area</p>
    <p>
        <button (click)="Response('Close')"> Close </button>
    </p>
</div>
<div *ngIf="action=='Delete'">
    <p>Are you sure you want to Delete this record? </p>
    <p>
        <button (click)="Response('yes')"> Yes </button>
        <button (click)="Response('no')"> No </button>
    </p>
</div>
<div *ngIf="action=='KickAll'">
    <p>Are you sure you want to Kick all data? </p>
    <p>
        <button (click)="Response('yes')"> Yes </button>
        <button (click)="Response('no')"> No </button>
    </p>
</div>
<div *ngIf="isBulckAnction === false && action=='ManualMerge'">
   <div>
       <p>Do you want to Manual Merge these records?</p>
       <p>
           <button (click)="Response('yes')"> Yes </button>
           <button (click)="Response('no')"> No </button>
       </p>
   </div>
</div>
<div *ngIf="action=='AddBoard'">
    <div>
        <p>Are you sure you want to proceed this action?</p>
        <p>
            <button (click)="Response('yes')"> Yes </button>
            <button (click)="Response('no')"> No </button>
        </p>
    </div>
</div>
</body>
