<div class="my-overlay" *ngIf="showOverlay">
    <div class="spinner">
        <span class="spinner-inner-1"></span>
        <span class="spinner-inner-2"></span>
        <span class="spinner-inner-3"></span>
    </div>
</div>
<div class="board-container">
    <div class="table-container">
        <table class="matches" mat-table [dataSource]="dataSource" multiTemplateDataRows>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Actions </th>
                <td mat-cell *matCellDef="let element;let row">
                    <div *ngIf="!isFirstRow(row); else elseBlock" class="action-section">
                        <mat-form-field [ngClass]="getSelectClass(element.action)" appearance="fill" class="unique-dropdown" >
                            <mat-label>Select action</mat-label>
                            <mat-select [(value)]="element.action" (selectionChange)="selectActions(element.action, element)" >
                                <mat-option *ngFor="let item of actions" [value]="item.action">
                                    {{ item.actionDescription }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <ng-template #elseBlock>
                        <mat-form-field [ngClass]="getSelectClass(element.action)" appearance="fill" class="unique-dropdown" >
                            <mat-label>Select action</mat-label>
                            <mat-select [(value)]="element.action" (selectionChange)="selectMainProfileActions(element.action, element)" >
                                <mat-option *ngFor="let item of mainProfileActions" [value]="item.action">
                                    {{ item.actionDescription }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-template>
                </td>
            </ng-container>
            <ng-container matColumnDef="personId">
                <th mat-header-cell *matHeaderCellDef class="table_header">Person Id </th>
                <td mat-cell *matCellDef="let element; let row">
                    <div *ngIf="element.personId !== 0 && element.action!=='edit' && element.action!='matchById'">
                        <a (click)="LinkToCeo(element.personId)">
                            {{element.personId}}
                        </a>
                    </div>
                    <div *ngIf="element.action==='edit' || element.action==='matchById'" class="input-box">
                        <input type="text"  [(ngModel)]="element.personId" class="editable">
                    </div>

                </td>
            </ng-container>
            <ng-container matColumnDef="source">
                <th mat-header-cell *matHeaderCellDef class="table_header">Data Source </th>
                <td mat-cell *matCellDef="let element; let row">
                    <p *ngIf="element.importedRecord !== true">
                        ceo360 DB
                    </p>
                    <p *ngIf="element.importedRecord === true">
                        pubdirAPI
                    </p>
                </td>
            </ng-container>
            <ng-container matColumnDef="firstName">
                <th mat-header-cell *matHeaderCellDef class="table_header"> First </th>
                <td mat-cell *matCellDef="let element; let row">
                    <div *ngIf="element.action ==='edit'" class="input-box">
                        <input type="text" name="comp" [(ngModel)]="element.firstName" class="editable">
                    </div>
                    <div *ngIf="element.action!=='edit'">
                        {{element.firstName}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="lastName">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Last </th>
                <td mat-cell *matCellDef="let element; let row">
                    <div *ngIf="element.action ==='edit'" class="input-box">
                        <input type="text" name="comp" [(ngModel)]="element.lastName" class="editable">
                    </div>
                    <div *ngIf="element.action!=='edit'">
                        {{element.lastName}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="primaryCompany">
                <th mat-header-cell *matHeaderCellDef class="table_header">Primary Company </th>
                <td mat-cell *matCellDef="let element; let row">
                    {{element.primaryCompany}}
                </td>
            </ng-container>
            <ng-container matColumnDef="primaryCompanyId">
                <th mat-header-cell *matHeaderCellDef class="table_header">Primary Company ID</th>
                <td mat-cell *matCellDef="let element; let row">
                    <div *ngIf="element.companyId !== 0 && element.action!=='edit'">
                        <a (click)="LinkToCompany(element.personId)">
                            {{element.primaryCompanyId}}
                        </a>
                    </div>
                    <div *ngIf="element.action==='edit'" class="input-box">
                        <input type="text"  [(ngModel)]="element.primaryCompanyId" class="editable">
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="primaryTitle">
                <th mat-header-cell *matHeaderCellDef class="table_header">Primary Title</th>
                <td mat-cell *matCellDef="let element; let row">
                    {{element.primaryTitle}}
                </td>
            </ng-container>
            <ng-container matColumnDef="boardCompanyCik">
                <th mat-header-cell *matHeaderCellDef class="table_header">Board CIK ID </th>
                <td mat-cell *matCellDef="let element; let row">
                    <a (click)="linkToCompany(element['boardCompanyId'])">
                        {{element.boardCompanyCik}}
                    </a>
                </td>
            </ng-container>
            <ng-container matColumnDef="boardCompanyName">
                <th mat-header-cell *matHeaderCellDef class="table_header">Board Name </th>
                <td mat-cell *matCellDef="let element; let row">
                    {{element.boardCompanyName}}
                </td>
            </ng-container>
            <ng-container matColumnDef="directorSince">
                <th mat-header-cell *matHeaderCellDef class="table_header">Director Since</th>
                <td mat-cell *matCellDef="let element; let row">
                    {{element.directorSince}}
                </td>
            </ng-container>
            <ng-container matColumnDef="audit">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Audit </th>
                <td mat-cell *matCellDef="let element; let row">
                    <div *ngIf="element.action ==='edit'" class="input-box">
                        <input type="text" name="comp" [(ngModel)]="element.audit" class="editable">
                    </div>
                    <div *ngIf="element.action!=='edit'">
                        {{element.audit}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="comp">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Comp </th>
                <td mat-cell *matCellDef="let element; let row">
                    <div *ngIf="element.action ==='edit'" class="input-box">
                        <input type="text" name="comp" [(ngModel)]="element.comp" class="editable">
                    </div>
                    <div *ngIf="element.action!=='edit'">
                        {{element.comp}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="govNom">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Gov/Nom </th>
                <td mat-cell *matCellDef="let element; let row">
                    <div *ngIf="element.action ==='edit'" class="input-box">
                        <input type="text" name="comp" [(ngModel)]="element.govNom" class="editable">
                    </div>
                    <div *ngIf="element.action!=='edit'">
                        {{element.govNom}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="insideOutside">
                <th mat-header-cell *matHeaderCellDef class="table_header"> Inside Outside </th>
                <td mat-cell *matCellDef="let element; let row">
                    <div *ngIf="element.action ==='edit'" class="input-box">
                        <input type="text" name="comp" [(ngModel)]="element.insideOutside" class="editable">
                    </div>
                    <div *ngIf="element.action!=='edit'">
                        {{element.insideOutside}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="education" >
                <th mat-header-cell *matHeaderCellDef class="table_header education-column">Education</th>
                <td mat-cell class="education-column" *matCellDef="let element; let row">
                    {{element.education}}
                </td>
            </ng-container>
            <ng-container matColumnDef="activeStatus">
                <th mat-header-cell *matHeaderCellDef class="table_header">Active Status</th>
                <td mat-cell *matCellDef="let element; let row">
                    {{element.activeStatus}}
                </td>
            </ng-container>
            <ng-container matColumnDef="removed">
                <th mat-header-cell *matHeaderCellDef class="table_header">Removed</th>
                <td mat-cell *matCellDef="let element; let row">
                    {{element.removed}}
                </td>
            </ng-container>
            <ng-container matColumnDef="cbmStatus">
                <th mat-header-cell *matHeaderCellDef class="table_header">CBM Sub Status</th>
                <td mat-cell *matCellDef="let element; let row">
                    {{element.cbmStatus}}
                </td>
            </ng-container>
            <ng-container matColumnDef="cbmDigitalAndPrint">
                <th mat-header-cell *matHeaderCellDef class="table_header">CBM Digital & Print</th>
                <td mat-cell *matCellDef="let element; let row">
                    {{element.cbmDigitalAndPrint}}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;"  [ngClass]="{'gray-row': element.activeStatus == false || element.removed == true}" ></tr>
        </table>
    </div>
    <div class="buttons">
        <div class="proceed-buttons">
            <button class=" btn proceed-btn" (click)="processAssociation()"  *ngIf="showActionsBtn">Proceed</button>
            <button class=" btn proceed-btn" style="margin-left: 5px" (click)="manualMerge()"  *ngIf="showActionsBtn">Proceed Manually</button>

            <button *ngIf="isMatchById" (click)="matchByPersonId()" class="btn insert-button" >Match Person Id</button>
        </div>

         <div>
             <button class="btn cancel-button" (click)="emptyActions()" mat-dialog-close>Cancel</button>
         </div>



    </div>



</div>
